import { Box, Button, FormControl, Grid, IconButton, MenuItem, Select, TextField, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { Close, Delete } from '@material-ui/icons';
import DataGrid from '../../../grid';
import GetAppIcon from '@material-ui/icons/GetApp';
import axios from '../../../../../common/AxiosConfig';
import { useContext } from 'react';
import { AppContext } from '../../../../../../RoutesWithAuth';
import { useEffect } from 'react';
import { RESPONSE_ACTION_MAPPING } from '../../applications/constants';

const useStyles = makeStyles(() => ({

    "attachmentsWrapper": {
        '& label': {
            fontWeight: "bold"
        },
        marginLeft: '15px',
        marginRight: '15px',
        marginBottom: '15px',
        boxShadow: 'rgba(0, 0, 0, 0.08) 0rem 0rem 0.25rem inset',
        '& .header': {
            background: '#7f9ed7',
            padding: '5px',
            color: "white",
            fontWeight: 'bold'
        },
        '& input[type="file"]::file-selector-button': {

            height: '35px',
            marginTop: '-9px',
            marginLeft: '-9px',
        },
        '& .MuiInputBase-root': {
            height: '35px'
        },
        '& .isRowDeleted-row-cust-cls': {
            color: 'red',
            textDecoration: "line-through"
        },
        '& .isRowAdded-row-cust-cls': {
            color: 'green',
        }
    },
    attachmentUploadWrapper: {
        marginTop: '10px',
        '& input#file_upload::file-selector-button': {
            display: 'none'
        }
    },
    tabCloseIcon: {
        right: '25px',
        position: 'absolute',
        marginTop: '-2px',
        color: 'white'
    }
}));


const Attachments = ({
    attachments,
    setDisplayAttachments,
    setLoading,
    editEnabled = false,
    responseData,
    tabInfo,
    setDisplayErrMsg,
    handleAttachmentSuccess,
    handleAttachmentsReset
}) => {
    const classes = useStyles();
    const context = useContext(AppContext)
    const authToken = context.authToken.get
    const userId = context.userId.get
    const setReloadHealth = context.reloadHealth.set;

    const [data, setData] = useState(attachments);
    const [attachmentType, setAttachmentType] = useState("attachment");

    useEffect(() => {
        setData(attachments);
    }, [JSON.stringify(attachments)])
    const handleFormClear = () => {
        setData(attachments);
        handleAttachmentsReset();
    }
    const handleFormSubmit = () => {
        let responseAction = "response_action_edit" in responseData ? responseData.response_action_edit : responseData.response_action;

        if(!responseAction || !Object.keys(RESPONSE_ACTION_MAPPING).includes(responseAction) ){
            setDisplayErrMsg("Response Action cannot be blank");
            return;
        }
        setLoading(true);
        const newFiles = [];
        const deleteFiles = [];
        data.forEach((file) => {
            if (file.isNewRecord) {
                newFiles.push({
                    attachmentType: file.attachment_type,
                    fileName: file.filename
                });
            } else if (file.isDeleted) {
                deleteFiles.push(file);
            }
        })
        Promise.all(deleteFiles.map((dFile) => {
            return deleteAttachment(dFile);
        }))
        axios.post('ticket/update_response', {
            dsar_id: tabInfo.dsarRequestId,
            app_id: tabInfo.app_id,
            creator_id: userId,
            response_json: JSON.stringify(responseData.response_json, null, 2) !== '' ? JSON.stringify(responseData.response_json, null, 2) : '{}',
            response_action: responseAction,
            fileNames: newFiles,
            expectedAttachments: "expected_attachments_count_edit" in responseData ? responseData.expected_attachments_count_edit : responseData.expected_attachments
        }, {
            headers: {
                Authorization: authToken
            }
        }).then(async res => {
            const posts = res.data.presignedPosts

            // Trigger Health Status to update the Calender ad=nd Error Icon
            setReloadHealth(Math.round(Math.random() * 1e5));

            for (const post of posts) {
                // For the case where there are multiple files, find the proper file from the array
                const filename = post.fields.key.split('/').pop()

                // Populate the form data
                const formData = new FormData()
                for (const key in post.fields) {
                    formData.append(key, post.fields[key])
                }

                // Iterate through all the files and file one with the matching filename
                for (const file of data) {
                    if (file.filename === filename) {
                        formData.append('file', file.fileObject);
                    }
                }

                await new Promise((resolve, reject) => {
                    axios.post(post.url, formData, {
                        headers: { 'Content-Type': 'multipart/form-data' }
                    }).then(res => {
                        resolve(res)
                    }).catch(err => {
                        reject(err)
                    })
                })
            }
            handleAttachmentSuccess();
            setLoading(false);

        }).catch(err => {
            setLoading(false);
        })
    }


    const deleteAttachment = ({ filename, appId, dsarRequestId }) => {

        return new Promise((resolve, reject) => {
            axios.delete('response/attachments', {
                headers: { Authorization: authToken },
                data: { s3_key: `${dsarRequestId}/${appId}/${filename}` }
            }).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    }

    const downloadFile = ({ filename, appId, dsarRequestId }) => {
        setLoading(true);
        axios.get(`ticket/response/download/${dsarRequestId}/${appId}/${filename}`, {
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            setLoading(false);
            const link = document.createElement('a');
            link.href = res.data.presignedGet;
            link.setAttribute('download', `${filename}`);
            document.body.appendChild(link);
            link.click();
            link.remove()

        }).catch(() => {
            setLoading(false);
        })
    }
    const getGridData = () => {
        let sortedAttachments = data?.sort((a, b) => a.attachment_type.localeCompare(b.attachment_type) || a.filename.localeCompare(b.filename)) || [];
        return sortedAttachments.map((attachment, index) => {
            return {
                ...attachment,
                id: index + 1,
            }
        });
    }
    return (
        <>
            <div data-testid={"attachmentsWrapper"} className={classes.attachmentsWrapper}>
                <div className={"header"}>
                    Attachments

                    <IconButton
                        className={classes.tabCloseIcon}
                        size="small"
                        aria-label="close"
                        onClick={(e) => {
                            e.stopPropagation();
                            setDisplayAttachments(false);
                        }}
                    >
                        <Close fontSize="small" />
                    </IconButton>
                </div>
                <Box padding={"10px"}>
                    <DataGrid
                        customHeight={"auto"}
                        autoHeight={true}
                        themeColors={{
                            headerColumnColor: '#dae3f3',
                            headerColumnfontColor: 'black',
                            rowbackground: '#f2f2f2 !important',
                            cellBorder: '1px solid white',
                            rowOddBackground: '#f8f9fa !important'
                        }}
                        columns={[{
                            name: "#",
                            mapping: "id",
                            width: 50
                        }, {
                            name: "File Name",
                            mapping: "filename",
                            renderCell: (params) => {
                                return (
                                    <>
                                        {params.row["filename"]}
                                    </>
                                )
                            }
                        }, {
                            name: "Type",
                            width: 120,
                            mapping: "attachment_type"
                        }, {
                            name: "Download",
                            mapping: "actions_download",
                            width: 120,
                            disableColumnMenu: true,
                            align: 'center',
                            headerAlign: 'center',
                            renderCell: (params) => {
                                return (
                                    <IconButton
                                        className={classes.textPrimary}
                                        aria-label="download"
                                        disabled={params.row["isNewRecord"] || params.row["isDeleted"]}
                                        data-testid={"download"}
                                        onClick={() => {
                                            downloadFile(params.row)
                                        }}
                                    >
                                        <GetAppIcon
                                        />
                                    </IconButton>
                                )
                            }
                        }, {
                            name: "Delete",
                            mapping: "actions_delete",
                            width: 120,
                            disableColumnMenu: true,
                            align: 'center',
                            headerAlign: 'center',
                            renderCell: (params) => {
                                return (
                                    <IconButton
                                        className={classes.textPrimary}
                                        aria-label="delete"
                                        data-testid={"delete"}
                                        disabled={!editEnabled || params.row["isDeleted"]}
                                        onClick={() => {
                                            const newData = [];
                                            data.forEach((attachments) => {
                                                if (params.row["filename"] === attachments.filename) {

                                                    if (!attachments.isNewRecord) {

                                                        newData.push({
                                                            ...attachments,
                                                            isDeleted: true,
                                                        });
                                                    }
                                                } else {
                                                    newData.push(attachments);
                                                }
                                            });
                                            setData(newData);
                                        }}
                                    >
                                        <Delete
                                        />
                                    </IconButton>
                                )
                            }
                        }]}
                        getRowClassName={(params) => {
                            const _classes = [];
                            if (params?.row?.isDeleted === true) {
                                _classes.push("isRowDeleted-row-cust-cls");
                            } else if (params?.row?.isNewRecord === true) {
                                _classes.push("isRowAdded-row-cust-cls");
                            }
                            return _classes.join(" ");
                        }}
                        rows={getGridData()}
                    />
                </Box>

                <div className={classes.attachmentUploadWrapper}>
                    <Grid container>
                        <Grid item xs={6}>
                            <div className="form-group col">
                                <label for={"file_upload"} className="ml-1">{"Upload File"}</label>
                                <Box display={"flex"}>
                                    <Button
                                        style={{ marginRight: "10px" }}
                                        variant="contained"
                                        color="primary"
                                        component="span"
                                        disabled={!editEnabled}
                                        onClick={() => {
                                            document.getElementById("file_upload").click();
                                        }}
                                    >
                                        Choose Files
                                    </Button>
                                    <TextField
                                        style={{ flex: 1 }}
                                        size={"small"}
                                        type={"file"}
                                        fullWidth
                                        disabled={!editEnabled}
                                        id={"file_upload"}
                                        label=""
                                        variant="outlined"
                                        onChange={(ev) => {
                                            if (ev.target?.files?.length > 0) {
                                                const newFiles = [];
                                                const duplicateFileNames = [];
                                                const allFiles = ev.target?.files;
                                                for (let i = 0; i < allFiles.length; i++) {
                                                    if (data.findIndex((file) => file.filename === allFiles[i].name) === -1) {
                                                        newFiles.push({
                                                            fileObject: allFiles[i],
                                                            isNewRecord: true,
                                                            id: (data.length + 1 + i),
                                                            filename: allFiles[i].name,
                                                            attachment_type: attachmentType
                                                        });
                                                    } else {
                                                        duplicateFileNames.push(allFiles[i].name);
                                                    }
                                                }
                                                setData([
                                                    ...data,
                                                    ...newFiles
                                                ]);
                                                if (duplicateFileNames.length > 0) {
                                                    setDisplayErrMsg(`A file with same name ${duplicateFileNames.join(",")} already exists!`);
                                                }
                                                setTimeout(() => {
                                                    document.getElementById("file_upload").value = "";
                                                }, 500);
                                            }
                                        }}
                                        inputProps={{
                                            accept: attachmentType === "Screenshot" ? "image/*" : ".pdf, .csv, .zip",
                                            required: true,
                                            multiple: true
                                        }}
                                    />
                                </Box>
                            </div>
                        </Grid>
                        <Grid item xs={3}>
                            <div className="form-group col">
                                <label for={"fileType"} className="ml-1">{"Response File Type"}</label>
                                <FormControl fullWidth variant="outlined" >
                                    <Select
                                        size={"small"}
                                        id={"fileType"}
                                        multiple={false}
                                        disabled={!editEnabled}
                                        value={attachmentType}
                                        onChange={(e) => {
                                            setAttachmentType(e.target.value);
                                        }}
                                        renderValue={(selected) => selected}
                                    >
                                        {["Attachment", "Screenshot"].map((name) => (
                                            <MenuItem key={name} className={`${classes.menuitemmultiselect}`} value={name}>
                                                {name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            style={{ display: "inline-flex", marginBottom: "15px" }}
                            justifyContent='center' >
                            <>
                                <Button
                                    style={{ marginRight: '50px' }}
                                    variant="contained"
                                    color="primary"
                                    disabled={!editEnabled}
                                    onClick={handleFormClear}
                                >
                                    Clear
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    data-testid={"attachment-submit"}
                                    disabled={!editEnabled}
                                    onClick={handleFormSubmit}
                                >
                                    Submit
                                </Button>
                            </>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    )
}
export default React.memo(Attachments);

