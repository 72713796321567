import React, { useState} from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { IconButton} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';


const useStyles = makeStyles(() => ({
    documentWrapper : {
        height : 'calc( 100% - 100px)'
    },
    cpsOperations :{
        marginTop: '10px',
        padding: '0px 0px 10px 0px',
        boxShadow: '0px 0px 1px 0px rgb(0 0 0 / 41%)',
        height: 'calc( 100% - 35px)',
        marginRight: '10px',
        '& .header':{
            background : '#7f9ed7',
            padding: '5px',
            color: "white",
            fontWeight: 'bold'
        }
    },
    frameWrapper:{
        height : 'calc( 100% - 44px )'
    },
    downloadIcon:{
        color: 'white',
        float: 'right',
        padding: '0px'
    },
    documentsList : {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: '15px',
        marginLeft: '10px',
        marginRight: '20px',
        marginBottom: '15px',
        '& a':{
            textDecoration : 'underline'
        }
    },
    activeLink :{
        fontWeight : 'bold'
    }
}));
function Documents (){
    const classes = useStyles()
    const [activeDocument, setActiveDocument] = useState(0)

    return (
        <div className={classes.documentWrapper}>
            <div className={classes.documentsList}>
                {ListOfDocuments.map(({title,link},index)=>(
                    <div key={title} className={`${activeDocument === index && classes.activeLink}`}>
                        <a href={link} onClick={(e)=>{e.preventDefault();setActiveDocument(index);}} target="_blank" rel="noopener noreferrer">{title}</a>
                    </div>
                ))}
            </div>
            <div className={classes.cpsOperations}>
                <div className={"header"}>
                    CPS Operations
                   <IconButton className={classes.downloadIcon} onClick={()=> window.open(ListOfDocuments[activeDocument].downloadUrl)} >
                        <GetAppIcon />
                    </IconButton>
                </div>
                <div className={classes.frameWrapper}>
                    <iframe src={`${ListOfDocuments[activeDocument].embedCode}&embed={"z":"width"}`} width="100%" height="100%" frameborder="0" scrolling="no" allowfullscreen title={ListOfDocuments[activeDocument].title}></iframe>
                </div>
            </div>
            
        </div>
    );
}

const ListOfDocuments = [{
    title: "Admin Functionality",
    embedCode : "https://mypartners.toyota.com/sites/tmnaCVT/CCPA/_layouts/15/embed.aspx?UniqueId=a28d7d4e-9621-4f1e-9a14-3d1f8cfe566d",
    link: "https://mypartners.toyota.com/sites/tmnaCVT/CCPA/Shared%20Documents/CPS_Resources/Admin%20Functionality.pdf",
    downloadUrl : "https://mypartners.toyota.com/sites/tmnaCVT/CCPA/_layouts/15/download.aspx?SourceUrl=%2Fsites%2FtmnaCVT%2FCCPA%2FShared%20Documents%2FCPS%5FResources%2FAdmin%20Functionality%2Epdf",
},
{
    title: "CCPA Workflow",
    link: "https://mypartners.toyota.com/:b:/r/sites/tmnaCVT/CCPA/Shared%20Documents/CPS_Resources/CCPA%20Workflow.pdf",
    embedCode : "https://mypartners.toyota.com/sites/tmnaCVT/CCPA/_layouts/15/embed.aspx?UniqueId=02749279-b0fa-421e-a99d-14fd9fca2b29",
    downloadUrl : "https://mypartners.toyota.com/sites/tmnaCVT/CCPA/_layouts/15/download.aspx?SourceUrl=%2Fsites%2FtmnaCVT%2FCCPA%2FShared%20Documents%2FCPS%5FResources%2FCCPA%20Workflow%2Epdf"
},
{
    title: "Discontinued Downstreams",
    link: "https://mypartners.toyota.com/:b:/r/sites/tmnaCVT/CCPA/Shared Documents/CPS_Resources/Discontinued Downstream.pdf",
    embedCode:"https://mypartners.toyota.com/sites/tmnaCVT/CCPA/_layouts/15/embed.aspx?UniqueId=ae375ada-570e-4249-a982-db60188d5fd3",
    downloadUrl : "https://mypartners.toyota.com/sites/tmnaCVT/CCPA/_layouts/15/download.aspx?SourceUrl=%2Fsites%2FtmnaCVT%2FCCPA%2FShared%20Documents%2FCPS%5FResources%2FDiscontinued%20Downstream%2Epdf"
},{
    title : "DSAR Updates",
    link : "https://mypartners.toyota.com/sites/tmnaCVT/CCPA/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FtmnaCVT%2FCCPA%2FShared%20Documents%2FCPS%5FResources%2FDSAR%20Updates%2Epdf&parent=%2Fsites%2FtmnaCVT%2FCCPA%2FShared%20Documents%2FCPS%5FResources",
    embedCode : "https://mypartners.toyota.com/sites/tmnaCVT/CCPA/_layouts/15/embed.aspx?UniqueId=b485574a-b003-4945-bc42-8bb5c9f8dec2",
    downloadUrl : "https://mypartners.toyota.com/sites/tmnaCVT/CCPA/_layouts/15/download.aspx?SourceUrl=%2Fsites%2FtmnaCVT%2FCCPA%2FShared%20Documents%2FCPS%5FResources%2FDSAR%20Updates%2Epdf"
},
{
    title: "FTP Key Expiry",
    link: "https://mypartners.toyota.com/:b:/r/sites/tmnaCVT/CCPA/Shared Documents/CPS_Resources/FTP Key Expiry.pdf?csf=1&web=1&e=MlpaG9pdf",
    embedCode:"https://mypartners.toyota.com/sites/tmnaCVT/CCPA/_layouts/15/embed.aspx?UniqueId=a7ed4365-7d31-4e95-b637-7fac1c93955c",
    downloadUrl : "https://mypartners.toyota.com/sites/tmnaCVT/CCPA/_layouts/15/download.aspx?SourceUrl=%2Fsites%2FtmnaCVT%2FCCPA%2FShared%20Documents%2FCPS%5FResources%2FFTP%20Key%20Expiry%2Epdf"
},
{
    title: "Operations Manual",
    embedCode : "https://mypartners.toyota.com/sites/tmnaCVT/CCPA/_layouts/15/embed.aspx?UniqueId=555bdd2e-94db-4041-91dd-976603c26fe6",
    link: "https://mypartners.toyota.com/sites/tmnaCVT/CCPA/Shared%20Documents/CPS_Resources/Operations%20Manual.pdf",
    downloadUrl : "https://mypartners.toyota.com/sites/tmnaCVT/CCPA/_layouts/15/download.aspx?SourceUrl=%2Fsites%2FtmnaCVT%2FCCPA%2FShared%20Documents%2FCPS%5FResources%2FOperations%20Manual%2Epdf"
    
},
{
    title: "Timing Chart",
    link: "https://mypartners.toyota.com/sites/tmnaCVT/CCPA/Shared%20Documents/CPS_Resources/Timing%20Chart.pdf",
    embedCode:"https://mypartners.toyota.com/sites/tmnaCVT/CCPA/_layouts/15/embed.aspx?UniqueId=f3683254-724b-4f31-b9ee-42e50a7ca47e",
    downloadUrl : "https://mypartners.toyota.com/sites/tmnaCVT/CCPA/_layouts/15/download.aspx?SourceUrl=%2Fsites%2FtmnaCVT%2FCCPA%2FShared%20Documents%2FCPS%5FResources%2FTiming%20Chart%2Epdf"

},
{
    title: "TIPS Escalation Matrix",
    link: "https://mypartners.toyota.com/:b:/r/sites/tmnaCVT/CCPA/Shared%20Documents/CPS_Resources/TIPS%20Escalation%20Matrix.pdf",
    embedCode:"https://mypartners.toyota.com/sites/tmnaCVT/CCPA/_layouts/15/embed.aspx?UniqueId=82800024-bf34-418f-9f98-6ccb66664407",
    downloadUrl : "https://mypartners.toyota.com/sites/tmnaCVT/CCPA/_layouts/15/download.aspx?SourceUrl=%2Fsites%2FtmnaCVT%2FCCPA%2FShared%20Documents%2FCPS%5FResources%2FTIPS%20Escalation%20Matrix%2Epdf"
}
];
export default Documents;