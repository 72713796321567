/**
 * Downstream Report Bar Component - This Incldues the Bar Component Configuration for Downstream report
 */
import React from 'react';
import { ResponsiveBar } from '@nivo/bar'


const assignColor = (bar) => {
    const colors = {
        RTC: "#70ad47",
        RTD: "#5b9bd5",
        RTK: "#ffc000",
        RTO: "#43682b",
    }

    return colors[bar.id]
}

const getTooltip = (data) => {
    return `${data.id}: ${data.value}`
}

const DownstreamBarGraph = ({ data }) => (
    <ResponsiveBar
        data={data}
        keys={['RTC', 'RTD', 'RTK', 'RTO']}
        layout="horizontal"
        indexBy="name"
        margin={{ top: 50, right: 100, bottom: 50, left: 180 }}
        padding={0.3}
        colors={assignColor}
        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        tooltip={getTooltip}
        axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            // legend: 'Requests',
            legendPosition: 'middle',
            legendOffset: 32
        }}
        labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        legends={[
            {
                dataFrom: 'keys',
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemOpacity: 1
                        }
                    }
                ]
            }
        ]}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
        enableGridX={true}
        enableGridY={false}
    />
)

export default DownstreamBarGraph;
