import * as React from "react";
import {
  GridPanelContent,
  GridApiContext,
  GridPanelWrapper
} from "@mui/x-data-grid";
import { GridFilterForm } from './FilterForm';

export default function FilterPanel({
  activeColumn,
  gridFilters,
  setGridFilters,
  setGridFilterOrder,
  gridFilterOrder
}) {
  const gridApicontext = React.useContext(GridApiContext);
  return (
    <GridPanelWrapper>
      <GridPanelContent>
        {[
          {
            columnField: activeColumn.field,
            id: 435,
            operatorValue:
              gridFilters[activeColumn.field]?.operatorValue ||
              activeColumn?.filterOperators?.[0].value,
            value: gridFilters[activeColumn.field]?.value || ""
          }
        ].map((item) => {
          return (
            <GridFilterForm
              item={item}
              key={item.id}
              deleteFilter={() => {
                setGridFilters({
                  ...gridFilters,
                  [activeColumn.field]: false
                });
                const index = gridFilterOrder.indexOf(activeColumn.field);
                if (index > -1) {
                  gridFilterOrder.splice(index, 1);
                }
                setGridFilterOrder(gridFilterOrder);
                gridApicontext.current.hideFilterPanel();
              }}
              applyFilterChanges={(appliedFilters) => {
                const index = gridFilterOrder.indexOf(activeColumn.field);
                if (index > -1) {
                  gridFilterOrder.splice(index, 1);
                }
                gridFilterOrder.push(activeColumn.field);
                setGridFilterOrder(gridFilterOrder);
                setGridFilters({
                  ...gridFilters,
                  [activeColumn.field]: {
                    ...appliedFilters,
                    columnInfo: { ...activeColumn }
                  }
                });
              }}
            />
          );
        })}
      </GridPanelContent>
    </GridPanelWrapper>
  );
}
