import { Box, Typography, makeStyles } from '@material-ui/core';
import React, { useState, useEffect, useContext } from 'react';
import { Tab, Tabs, Accordion, AccordionDetails, AccordionSummary, LoadingIndicator } from './MuiStyled';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ReconcilationGrid from './ReconcilationGrid';
import axios from '../../../../common/AxiosConfig';
import { AppContext } from '../../../../../RoutesWithAuth';
import UpStreamDSAR from './UpStreamDSAR';
import moment from 'moment';
import Papa from 'papaparse';
import { useHistory } from 'react-router-dom';
import { v2UIRoutePath } from '../../../../common/commons';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flex: '1',
        height: '100%'
    },
    accordianTitle: {
        fontWeight: 'bold'
    }
}));
const reconciliationTabs = ["", "upstreamdsars"];

const ReconciliationReport = ({ tabActiveParam }) => {
    const classes = useStyles();
    // State to handle the Main Tab Information
    const [activeTab, setActiveTab] = useState(0);
    // State to handle the Internal Accordian State (TIPS, QB64 )
    const [activeAccordian, setActiveAccordian] = useState("TIPS")
    // State to Hold the Complete Form and Grid Data
    const [requestConcilationData, setRequestConcilationData] = useState()
    // State to Hold the Complete Form and Grid Data for QB 64 Request Concilation View
    const [QB64RequestConcilationData, setQB64RequestConcilationData] = useState()

    const history = useHistory();

    const [isInProgress, setIsInProgress] = useState(false);

    const [upstreamDsarData, setUpstreamDsarData] = useState()
    const [qb64UpstreamDsarData, setQb64UpstreamDsarData] = useState()

    const context = useContext(AppContext)
    const authToken = context.authToken.get

    useEffect(() => {
        setActiveAccordian("TIPS");
        if(activeTab === 0){
            fetchReconReportDetails();
        } else {
            fetchupstreamDsarData();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTab])

    useEffect(() => {
        // Activating the Tab based on the tab param in the route url
        let activeTabIndex = reconciliationTabs.indexOf(tabActiveParam);
        // activating the first tab when the tab name does not match the list
        if (activeTabIndex < 0) {
            activeTabIndex = 0;
        }
        setActiveTab(activeTabIndex);
    }, [tabActiveParam])

    useEffect(() => {
        // retriving API Data when QB64 is Activated in Reconcilation Tab
        if (activeAccordian === "QB64" && activeTab === 0) {
            // retriving the data for QB64 view
            fetchReconReportQB64Details();
        } else if(activeAccordian === "QB64") {
            fetchQB64UpstreamDsarData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeAccordian])

    /**
     * This Method is called when the user click s on the Download ICon
     * @param {Object} payload - Contains the Filtered key value pairs 
     * @param {boolean} isTips - This Param will differentiate between tips and QB64 download
     * Method triggers the API by passing the active filters in the params
     * Formats the response and triggers the Download as a CSV file
     */
    const handleDownload = (payload, isTips) => {
        let url = '/reports/on-demand-recon-report_v2?operation=download';
        // updating URL for QB64 view
        if (!isTips) {
            url = '/reports/on-demand-recon-report-qb64?operation=download';
        }
        axios.get(url, {
            headers: {
                Authorization: authToken
            },
            params: payload
        }).then(res => {

            let data = res.data.cps_tips_ondemand_reconciliation_report;
            // Updating the Response data when download is triggered for QB64
            if (!isTips) {
                data = res.data.cps_qb64_ondemand_reconciliation_report;
            }

            const csv = Papa.unparse(data)

            const downloadLink = document.createElement("a");
            const blob = new Blob(["\ufeff", csv])
            const url = URL.createObjectURL(blob)
            downloadLink.href = url
            const timestamp = moment().format("YYYYMMDD")
            // Formating the File name of the csv File 
            // Sample Format -- Request_Reconciliation_{TIPS/QB64}_{TimeStamp}
            downloadLink.download = `Request_Reconciliation_${activeAccordian}_${timestamp}.csv`
            document.body.appendChild(downloadLink)
            downloadLink.click()
            document.body.removeChild(downloadLink)
        }).catch(err => {
            console.log(err)
        })
    }
    const fetchQB64UpstreamDsarData = () => {
        axios.get('reports/reconciliation/get_qb64_reconciliation_reports', {
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            setQb64UpstreamDsarData({
                gridData: res.data.qb64_reconciliation_files?.map((_files, index) => {
                    return {
                        ..._files,
                        id: index + 1,
                        rowId: index + 1
                    }
                })
            })
        })
    }
    const fetchupstreamDsarData = () => {
        axios.get('reports/reconciliation/get_tips_reconciliation_reports', {
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            setUpstreamDsarData({
                gridData: res.data.tips_reconciliation_files?.map((_files, index) => {
                    return {
                        ..._files,
                        id: index + 1,
                        rowId: index + 1
                    }
                })
            })
        })
    }
    /**
     * This Method is triggered when we want to retrive latest data for TIPS reconcilation report.
     * Internally it calls the API and formats the data and updates the "RequestConcilationData" state 
     */
    const fetchReconReportDetails = () => {
        setIsInProgress(true);
        axios.get('reports/on-demand-recon-report_v2', {
            params: { "operation": "report" },
            headers: {
                Authorization: authToken,
            },
        }).then((res) => {
            let responseData = res.data.cps_tips_ondemand_reconciliation_report;
            let gridData = [];
            let fileNameSplit = responseData?.tips_source_file?.split(/[_.]/);
            if (responseData?.full_report?.length > 0) {
                gridData = responseData.full_report
            }
            // Parsing the Report From and To from the File Name
            let report_generated_from, report_generated_to;
            if (fileNameSplit?.length > 0) {
                report_generated_to = fileNameSplit[fileNameSplit.length - 2]
                report_generated_from = fileNameSplit[fileNameSplit.length - 3]
            }
            setRequestConcilationData({
                ...responseData,
                report_generated_from,
                report_generated_to,
                gridData: gridData?.map((data, index) => {
                    return {
                        ...data,
                        id: index + 1,
                        rowId: index + 1,
                        isStatusMismatch: data.isStatusMismatch ? "Yes" : "No",
                        isPresentcps: data.isPresentcps ? "Yes" : "No",
                        isPresentTips: data.isPresentTips ? "Yes" : "No"
                    }
                })
            });
            setIsInProgress(false);
        }).catch((error) => {
            console.error("Error in the API Response")
            setIsInProgress(false);
        })
    }
    /**
     * This Method is triggered when we want to retrive latest data for QB64 reconcilation report.
     * Internally it calls the API and formats the data and updates the "QB64RequestConcilationData" state 
     */

    const fetchReconReportQB64Details = () => {
        setIsInProgress(true);
        axios.get('reports/on-demand-recon-report-qb64', {
            params: { "operation": "report" },
            headers: {
                Authorization: authToken,
            },
        }).then((res) => {
            let responseData = res.data.cps_qb64_ondemand_reconciliation_report;
            let gridData = [];
            let fileNameSplit = responseData?.qb64_source_file?.split(/[_.]/);
            if (responseData?.full_report?.length > 0) {
                gridData = responseData.full_report
            }
            // Parsing the Report From and To from the File Name
            let report_generated_from, report_generated_to;
            if (fileNameSplit?.length > 0) {
                report_generated_to = fileNameSplit[fileNameSplit.length - 2]
                report_generated_from = fileNameSplit[fileNameSplit.length - 3]
            }
            setQB64RequestConcilationData({
                ...responseData,
                report_generated_from,
                report_generated_to,
                gridData: gridData?.map((data, index) => {
                    return {
                        ...data,
                        id: index + 1,
                        rowId: index + 1,
                        isStatusMismatch: data.isStatusMismatch ? "Yes" : "No",
                        isPresentcps: data.isPresentcps ? "Yes" : "No",
                        isPresentQB64: data.isPresentQB64 ? "Yes" : "No"
                    }
                })
            });
            setIsInProgress(false);
        }).catch((error) => {
            console.error("Error in the API Response")
            setIsInProgress(false);
        })
    }
    return (
        <div className={classes.root}>
            {isInProgress && (<>
                <LoadingIndicator />
            </>)}
            <Typography variant="h6" className={'tabHeader'}>
                RECONCILIATION REPORT
            </Typography>
            <Tabs
                variant="scrollable"
                aria-label="tabs"
                value={activeTab}
                onChange={(_event, newValue) => {
                    setActiveTab(newValue);
                    // redirecting to the selected tab url so that refresh will retain in same page
                    history.push(`${v2UIRoutePath}reports/reconciliationreport/${reconciliationTabs[newValue]}`)

                }}
                sx={{ borderRight: 1, borderColor: 'divider' }}
            >
                <Tab label="Requests Conciliation" />
                <Tab label="Upstream DSARs" />
            </Tabs>
            {(
                <Box flex={1} display={"flex"} flexDirection={"column"}>
                    <Accordion square expanded={activeAccordian === "TIPS"} onChange={() => {
                        setActiveAccordian(activeAccordian === "TIPS" ? "" : "TIPS")
                    }}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            {activeAccordian === "TIPS" ? (<ExpandMoreIcon />) :
                                (<ChevronRightIcon />)}
                            <Typography className={classes.accordianTitle}>TIPS</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {(activeTab === 0) ?
                                (<ReconcilationGrid
                                    handleDownload={handleDownload}
                                    data={requestConcilationData} />) : (
                                    <UpStreamDSAR
                                        data={upstreamDsarData} />
                                )}
                        </AccordionDetails>
                    </Accordion>
                    <Accordion square expanded={activeAccordian === "QB64"} onChange={() => {
                        setActiveAccordian(activeAccordian === "QB64" ? "" : "QB64")
                    }}>
                        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                            {activeAccordian === "QB64" ? (<ExpandMoreIcon />) :
                                (<ChevronRightIcon />)}
                            <Typography className={classes.accordianTitle}>QB64</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {(activeTab === 0) ?
                                (<ReconcilationGrid
                                    isTIPS={false}
                                    handleDownload={handleDownload}
                                    data={QB64RequestConcilationData} />) : (
                                    <UpStreamDSAR
                                        isTIPS={false}
                                        data={qb64UpstreamDsarData} />
                                )}
                        </AccordionDetails>
                    </Accordion>
                </Box>)}

        </div>
    )
};

export default ReconciliationReport;