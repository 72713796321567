import React from 'react';
import PageHeader from '../common/PageHeader';

function AdminsOnly(props) {
    const { header } = props

    return (
        <div className="container">

            <PageHeader header={header} />

            <p className="h3 text-center mt-3">
                Admins Only!<br />
            </p>
            <p className="text-muted text-center">
                This page is restricted to users with admin access.
                <br />
                Please contact the CT-CPS Team to request access.
            </p>

        </div>
    )

}

export default AdminsOnly;
