import * as React from 'react';
import InputBase from '@material-ui/core/InputBase';
import { Checkbox, ListItemText, MenuItem, Select } from '@material-ui/core';


export function GridEditSelectCell(props) {
  const {
    id,
    value,
    formattedValue,
    api,
    field,
    row,
    colDef,
    cellMode,
    isEditable,
    tabIndex,
    hasFocus,
    getValue,
    ...other
  } = props;

  const inputRef = React.useRef();
  const [valueState, setValueState] = React.useState(value || []);

  const handleChange = React.useCallback(
    (event) => {
      let newValue = event.target.value;
      newValue = newValue?.sort((a,b)=>a.localeCompare(b)) 
      setValueState(newValue);
      api.setEditCellValue({ id, field, value: newValue }, event);
    },
    [api, field, id],
  );

  React.useEffect(() => {
    setValueState(value);
  }, [value]);
  return (
    <Select
      inputRef={inputRef}
      required={true}
      fullWidth
      multiple
      value={valueState || []}
      onChange={handleChange}
      renderValue={(selected) => selected?.join(', ')}
      style={{
        border : '1px solid rgba(0, 0, 0, 0.23)',
        borderRadius : '5px'
      }}
      {...other}
    >
        {colDef.valueOptions?.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox style={{color : "#267db7"}} checked={(valueState || []).indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
    </Select>
  );
}
export const renderEditSelectCell = (params) => <GridEditSelectCell {...params} />;