import React, { useState, useEffect } from "react";
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import axios from "../../../../../common/AxiosConfig";
import moment from "moment";
import RequestCountChart from './RequestCountChart';


function RequestChartReport(props) {
    const { authToken } = props
    const today = moment()
    const[isYear, setIsYear] = useState(false)
    const [date, setDate] = useState(today)
    const [duration,setDuration] = useState("Month")
    const [year, setYear] = useState(today.format("YYYY"))

    const [reportData, setReportData] = useState([])
    const [toFilter, setToFilter] = useState([])
    const [filteredReportData, setFilteredReportData] = useState([])

    const [dateFooter, setDateFooter] = useState(null)

    useEffect(() => {
        const getFormatedDate = (isUIDisplay)=>{
            if(duration === "Year"){
                setIsYear(true)
                return year;
            }
            if(isUIDisplay){
                return date.format("MMMM YYYY");
            }
            return date.format("YYYY-MM");
        }
        if (authToken) {
            axios.get("/reports/request_count_chart", {
                headers: {
                    Authorization: authToken
                },
                params: {
                    metric : duration,
                    year : duration === "Year" ? year : date.format("YYYY"),
                    month : duration === "Year" ? 0 : date.format("MM"),
                }
            }).then(res => {
                setReportData(res.data)
                setFilteredReportData(res.data)
                setDateFooter(getFormatedDate(true))
            }).catch(err => {
                console.log(err)
            })
        }
    }, [authToken, date, year, duration])

    useEffect(() => {
        // Deep Copy
        const reportDataCopy = JSON.parse(JSON.stringify(reportData))
        setFilteredReportData(
            reportDataCopy.map(el => {
                if (toFilter.includes(el.id)) {
                    el.data = []
                }
                return el
            })
        )
    }, [toFilter, reportData])

    return (
        <div className="mb-5">

            <h4 className="text-center mt-4 mb-1">Request Count Chart</h4>
            <div className="small text-center">Click on Legend to toggle display</div>

            <div className="form-inline">
                <div className="form-group mr-2 mb-2">
                    <label className="mr-1" htmlFor="duration">Duration:</label>
                    <select className="form-control" id="duration" value={duration} onChange={e => setDuration(e.target.value)}>
                        <option>Month</option>
                        <option>Year</option>
                    </select>
                </div>
                { duration === "Month" && (<div className="form-group mr-2 mb-2">
                    <input type="month" className="form-control date-form" value={date.format("YYYY-MM")}
                        min="2020-01" max={today.format("YYYY-MM")} onChange={e => setDate(moment(e.target.value))} required
                    />
                </div>)}
                { duration === "Year" && (<div className="form-group mr-2 mb-2">
                    <input type="number" className="form-control date-form" id="year" value={year}
                        min="2020" max={today.format("YYYY")} onChange={e => setYear(e.target.value)} required
                    />
                </div>)}
            </div>
            <div style={{ height: "500px" }}>
                <RequestCountChart data={filteredReportData} toFilter={toFilter} setToFilter={setToFilter} isYear={isYear} />
            </div>

            {dateFooter ?
                <SwitchTransition>
                    <CSSTransition key={dateFooter} timeout={250} classNames="swipeR" appear={true}>
                        <div className="font-italic text-center">Date Range: {dateFooter}</div>
                    </CSSTransition>
                </SwitchTransition>
                :
                null
            }
        </div>
    );
}

export default RequestChartReport;
