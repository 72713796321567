import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from '../../RoutesWithAuth';
import { Link } from 'react-router-dom';
import { withNavbar } from '../common/Navbar';
import axios from '../common/AxiosConfig';
import PageHeader from '../common/PageHeader';
import { getResponseConfig, responseMapping, ticketTypeRTK } from '../common/commons';


function CreateResponse(props) {
	const { history } = props;
	const { dsarRequestId, appId } = props.match.params;
	const typeConfig = getResponseConfig("Upload")

	const context = useContext(AppContext)
	const authToken = context.authToken.get
	const userId = context.userId.get

	const [ticketType, setTicketType] = useState('');
	const [appName, setAppName] = useState('');
	const [json, setJson] = useState('');
	const [responseAction, setResponseAction] = useState(typeConfig[ticketType]['radioActions'][0]);
	const [responseIndex, setResponseIndex] = useState(0);
	const [files, setFiles] = useState([]);
	const [fileNames, setFileNames] = useState([]);
	const [middleClassLicense, setMiddleClassLicense] = useState("")
	const [driverwaiver, setDriverwaiver] = useState("")
	const [applicantResume, setApplicantResume] = useState("")

	const [responseAutomated, setResponseAutomated] = useState(null);

	useEffect(() => {
		if (authToken !== null) {
			axios.get(`ticket/search/`, {
				headers: {
					Authorization: authToken
				},
				params: {
					id: dsarRequestId
				}
			}).then(res => {
				setTicketType(res.data[0].ticket_type)
			}).catch(err => {
				console.log('There was an error fetching sub-system data!')
			})

			// Ideally create a new API for this that just returns the system with the particular app id
			axios.get(`app_tickets/${dsarRequestId}`, {
				headers: {
					Authorization: authToken
				}
			}).then(res => {
				res.data.forEach(app => {
					if (app.id.toString() === appId) {
						setResponseAutomated(app.is_response_automated)
						setAppName(app.display)
					}
				})
			}).catch(err => {
				console.log('There was an error fetching sub-system data!')
			})
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authToken])

	useEffect(() => {
		setResponseAction(typeConfig[ticketType]['radioActions'][0])
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ticketType])

	const handleFileChange = (e) => {
		e.persist() // Persist the state of the event
		let attachments = document.getElementById('responseAttachment')
		const screenshots = document.getElementById('responseScreenshot').files

		// Initialize the new state of 'files'
		const arrayOfFiles = []
		const arrayOfFileNames = []

		// Add all the attachments
		if (attachments) {
			attachments = attachments.files
			for (let attachment of attachments) {
				arrayOfFileNames.push({
					fileName: attachment.name,
					attachmentType: 'attachment',
				})
				arrayOfFiles.push(attachment)
			}
		}
		// Add all the screenshots
		for (const screenshot of screenshots) {
			arrayOfFileNames.push({
				fileName: screenshot.name,
				attachmentType: 'screenshot',
			})
			arrayOfFiles.push(screenshot)
		}

		// Set the state after arrayOfFiles has been populated
		setFiles(arrayOfFiles)
		setFileNames(arrayOfFileNames)

		const label = document.getElementById(e.target.id).nextSibling;
		if (e.target.files.length === 1) {
			label.innerHTML = e.target.files[0].name;
		} else if (e.target.files.length > 1) {
			label.innerHTML = `${e.target.files.length} files`
		}
	}

	const submitResponse = async (e) => {
		e.preventDefault()

		// Disable the submit button
		const submitBtn = document.getElementById('create-response-btn');
		submitBtn.setAttribute('disabled', true)

		axios.post('ticket/response', {
			dsar_id: dsarRequestId,
			app_id: appId,
			creator_id: userId,
			response_json: json !== '' ? json : '{}',
			response_action: responseMapping[responseIndex],
			fileNames: fileNames,
			license: middleClassLicense,
			driverwaiver: driverwaiver,
			resume: applicantResume
		}, {
			headers: {
				Authorization: authToken
			}
		}).then(async res => {
			const posts = res.data.presignedPosts

			for (const post of posts) {
				// For the case where there are multiple files, find the proper file from the array
				const filename = post.fields.key.split('/').pop()

				// Populate the form data
				const formData = new FormData()
				for (const key in post.fields) {
					formData.append(key, post.fields[key])
				}

				// Iterate through all the files and file one with the matching filename
				for (const file of files) {
					if (file.name === filename) {
						formData.append('file', file)
					}
				}

				await axios.post(post.url, formData, {
					headers: { 'Content-Type': 'multipart/form-data' }
				}).then(res => {
					console.log(res)
				}).catch(err => {
					console.log(err)
				})
			}
			// Call API Only for Cerence,ITGFleet,HireVue Apps
			const updateCSVAPI = ["7", "29", "27"];
			// Save an API call if we don't need to update the CSV
			if (updateCSVAPI.includes(appId.toString())) {
				await axios.post('ticket/update_csv', {
					dsar_id: dsarRequestId,
					app_id: appId,
					creator_id: userId,
					fileNames: fileNames
				}, {
					headers: {
						Authorization: authToken
					}
				})
			}

			history.replace(`/ticket/${dsarRequestId}/`)
		}).catch(err => {
			console.log(err.response)
			// Display an error message to the user if the creation did not complete

			const invalidFeedback = document.getElementById('invalid-feedback-rtk')
			invalidFeedback.innerHTML = err.response.data.error.split('\n')[0]
			document.getElementById('JSON').classList.add('is-invalid')
			submitBtn.removeAttribute('disabled')
		})

	}
	const attachmentRequired = () => {
		if (ticketType === ticketTypeRTK) {
			return (responseAutomated === false && responseIndex === 0 && (appName === 'Service Connect' || appName === 'Cerence' || appName === 'SXM'))
		}
		return true;
	}
	const screenshotRequired = () => {
		return !(ticketType === 'Right-to-Delete' || ticketType === 'Right-to-Correct' || ticketType === ticketTypeRTK || ticketType === 'Right-to-OptOut')
	}

	return (
		<div className="container">

			<PageHeader header="DSAR Response" removeMargin={true} />

			<p className="lead mb-4">
				<Link to={`/`}>Home&nbsp;</Link>
				&gt; <Link to={`/ticket/${dsarRequestId}`}>Request ID: {dsarRequestId}&nbsp;</Link>
				&gt; Create Response<br />
				System Name: {appName}

				{['29', '28'].includes(appId) && (<div className='row mt-2'>
					<div className="col-2">
						Driver Waiver:
					</div>
					<div className="col-4 align-self-start">
						<select className="form-control" id="driverWaiver" value={driverwaiver} onChange={e => setDriverwaiver(e.target.value)}>
							<option value=""> </option>
							<option value="Available">Available</option>
							<option value="Not Available">Not Available</option>
						</select>
					</div>
				</div>)}
				{['27'].includes(appId) && (<div className='row mt-2'>
					<div className="col-2">
						Applicant Resume:
					</div>
					<div className="col-4 align-self-center">
						<select className="form-control" id="applicant_resume" value={applicantResume} onChange={e => setApplicantResume(e.target.value)}>
							<option value=""> </option>
							<option value="Available">Available</option>
							<option value="Not Available">Not Available</option>
						</select>
					</div>
				</div>)}
				{["29"].includes(appId) && (<div className='row mt-2 mb-2'>
					<div className="pl-3">
						Middle Class License:
					</div>
					<div className="col-4 align-self-center">
						<select className="form-control" id="middle_class_license" value={middleClassLicense} onChange={(e) => setMiddleClassLicense(e.target.value)}>
							<option value=""> </option>
							<option value="Available">Available</option>
							<option value="Not Available">Not Available</option>
						</select>
					</div>
				</div>)}
			</p>
			<form onSubmit={submitResponse}>
				{/* Do not display the JSON input field for RTD and RTOO */}
				{ticketType === ticketTypeRTK ?
					<div className="form-group row">
						<label htmlFor="JSON"></label>
						<textarea className="form-control" id="JSON" rows="6" placeholder="Enter valid Response JSON here..."
							value={json} onChange={(e) => { setJson(e.target.value) }}
							required={responseAutomated === true}
						>
						</textarea>
						<div className="invalid-feedback" id="invalid-feedback-rtk">
							Could not create a response. Please check if JSON is valid.
						</div>
					</div>
					:
					null
				}

				{/* Different ticket types require different radio buttons  */}
				<div className="form-group-row text-center mb-3">
					{ticketType ?
						typeConfig[ticketType]['radioActions'].map((action, index) => (
							<div className="form-check" key={action}>
								<input className="form-check-input" type="radio" id={action}
									checked={responseAction === action} data-index={index}
									onChange={e => { setResponseAction(e.target.id); setResponseIndex(parseInt(e.target.dataset.index)) }}
								/>
								<label className="form-check-label" htmlFor={action} >
									{action}
								</label>
							</div>
						))
						:
						null
					}
				</div>

				{ticketType === ticketTypeRTK ?
					<div className="mb-5">
						<div className="form-group-row text-center">
							<div className="custom-file mb-5 col-6">
								<input type="file" multiple className="custom-file-input col-6" id="responseAttachment"
									required={attachmentRequired()} accept=".pdf, .csv, .zip" onChange={(e) => handleFileChange(e)}
								/>
								<label className="custom-file-label text-left overflow-hidden" htmlFor="responseAttachment">
									Upload Attachments
								</label>
								<small id="responseHelp" className="form-text text-muted">You may select multiple attachments to upload</small>
							</div>
						</div>
						<hr />
					</div>
					: null
				}

				<div className="form-group-row text-center">
					<div className="custom-file mb-5 col-6">
						<input type="file" multiple className="custom-file-input col-6" id="responseScreenshot"
							required={screenshotRequired()} accept="image/*" onChange={(e) => handleFileChange(e)}
						/>
						<label className="custom-file-label text-left overflow-hidden" htmlFor="responseScreenshot">
							Upload Screenshots
						</label>
						<small id="responseHelp" className="form-text text-muted">You may select multiple screenshots to upload</small>
					</div>
				</div>

				<div className="form-group-row text-center">
					<button type="submit" className="btn btn-primary" id="create-response-btn">Create Response</button>
				</div>
			</form>

		</div>
	);
}

export default withNavbar(CreateResponse);
