import React, { useState, useRef } from 'react';
import axios from '../../common/AxiosConfig';
import { feedbackTimeout } from '../../common/commons';
import moment from 'moment';
import { UncontrolledTooltip, Tooltip, ModalHeader, Modal, ModalFooter, ModalBody } from 'reactstrap';

import '../../../css/smallComponents.css';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const ErrorDeletionReason = "Reason too short. Insufficient characters (at least 10) in reason for deletion"

function ProcessingRequestRow(props) {
    const { authToken, serialNumber, data, fetchData, isAdmin, email } = props

    const [updatedDate, setUpdatedDate] = useState(data.updated_date)
    const [error, setError] = useState(data.error)
    const [displayErrMsg, setDisplayErrMsg] = useState();
    const [retryAttempts, setRetryAttempts] = useState(data.retry_attempts)

    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [tooltipText, setTooltipText] = useState(null);
    const [processing, setProcessing] = useState(false);

    const [reviewProcessing, setReviewProcessing] = useState(false);
    const [reviewStatusText, setReviewStatusText] = useState(null);


    const [deleteInProcess, setDeleteInProcess] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [deleteSuccessFlag, setDeleteSuccessFlag] = useState(false);


    const reasonInput = useRef(null);

    const downloadJSON = () => {
        axios.get(`/queued_requests/${data.dsar_id}/json`, {
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            const downloadLink = document.createElement("a");
            const blob = new Blob([JSON.stringify(res.data, null, 4)], { type: 'application/json' })
            const url = URL.createObjectURL(blob)
            downloadLink.href = url
            downloadLink.download = `${data.dsar_id}.json`
            document.body.appendChild(downloadLink)
            downloadLink.click()
            document.body.removeChild(downloadLink)
        }).catch(err => {
            console.log(err)
        })
    }
    const manualReview = () => {
        const reviewBtn = document.getElementById(`review-${serialNumber}`)
        reviewBtn.setAttribute("disabled", true)
        setReviewProcessing(true)

        axios.post(`/queued_requests/${data.dsar_id}/approve`, {}, {
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            setReviewStatusText("Successful approve!")
            setTimeout(function () { fetchData() }, feedbackTimeout)
        }).catch(err => {
            setReviewStatusText("Failed!")
            if (err.response && err.response.data && err.response.data.error) {
                setError(err.response.data.error)
            }
        }).finally(() => {
            setReviewProcessing(false)
            reviewBtn.removeAttribute("disabled")
            setTimeout(
                function () {
                    setReviewStatusText(null)
                }, feedbackTimeout
            )
        })
    }
    const manualRetry = () => {
        const retryBtn = document.getElementById(`retry-${serialNumber}`)
        retryBtn.setAttribute("disabled", true)
        setProcessing(true)

        axios.post(`/queued_requests/${data.dsar_id}/retry`, {}, {
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            setTooltipText("Successful retry!")
            setTooltipOpen(true)
            setTimeout(function () { fetchData() }, feedbackTimeout)
        }).catch(err => {
            setTooltipText("Failed!")
            setTooltipOpen(true)
            if (err?.response?.data?.message) {
                setDisplayErrMsg(err.response.data.message)
            }
            if (err.response && err.response.data && err.response.data.error) {
                setError(err.response.data.error)
                setRetryAttempts(err.response.data.retry_attempts)
                setUpdatedDate(err.response.data.updated_date)
            }
        }).finally(() => {
            setProcessing(false)
            retryBtn.removeAttribute("disabled")
            setTimeout(
                function () {
                    setTooltipOpen(false)
                    setTooltipText(null)
                }, feedbackTimeout
            )
        })
    }
    const deleteRequest = () => {
        setDeleteInProcess(true);
        setDeleteDialogOpen(true);
        setDeleteSuccessFlag(false);
    }
    const onCancelDeleteRequest = () => {

        const feedback = document.getElementById("deletion-error");
        feedback.innerHTML = "";
        setDeleteInProcess(false);
        setDeleteDialogOpen(false);
        if (deleteSuccessFlag) {
            setDeleteSuccessFlag(false);
            setTimeout(() => {
                fetchData();
            }, 100)

        }
    }
    const deleteRequestApi = () => {
        const feedback = document.getElementById("deletion-error");
        const deletionReason = reasonInput.current.value;
        if (deletionReason == null) {
            feedback.innerHTML = ErrorDeletionReason;
        } else {
            const reasonText = deletionReason.replace(/\s/g, "");

            if (reasonText.length >= 10) {
                axios.delete(`/requests/incoming/${data.dsar_id}`, {
                    headers: { Authorization: authToken },
                    data: {
                        ...data,
                        deletion_reason: reasonText,
                        deletion_by: email
                    }
                }).then((res) => {
                    setDeleteSuccessFlag(true);
                    feedback.innerHTML = "Deletion is completed";
                }).catch((err) => {
                    console.log(err);
                    if (err.response) {
                        console.log("Insufficient characters in reason for deletion");
                        feedback.innerHTML = ErrorDeletionReason
                    }
                });
            } else {
                console.log("Insufficient characters in reason for deletion");
                feedback.innerHTML = ErrorDeletionReason
            }
        }
    };
    const handleClose = () => {
        setDisplayErrMsg(null);
    }
    return (
        <>
        <Snackbar open={!!displayErrMsg} autoHideDuration={6000} onClose={handleClose}>
                <Alert elevation={6} variant="filled" onClose={handleClose} severity="error">
                    {displayErrMsg}
                </Alert>
            </Snackbar>
        <tr>
            <td>{serialNumber}</td>
            <td>
                <button className="btn btn-link m-0 p-0" onClick={downloadJSON}>
                    {data.dsar_id}
                </button>
            </td>
            <td>
                {data.requestor}
            </td>
            <td>
                {data.ticket_type}
            </td>
            <td id={`created-${serialNumber}`}>
                {moment(data.created_date).utc().format("MMM Do, YYYY HH:mm:ss (UTC)")}
                <UncontrolledTooltip placement="bottom" target={`created-${serialNumber}`}>
                    {moment(data.created_date).local().format('MMM Do, YYYY HH:mm:ss [(local)]')}
                </UncontrolledTooltip>
            </td>
            <td id={`updated-${serialNumber}`}>
                {updatedDate ?
                    <span>
                        {moment(updatedDate).utc().format("MMM Do, YYYY HH:mm:ss (UTC)")}
                        <UncontrolledTooltip placement="bottom" target={`updated-${serialNumber}`}>
                            {moment(updatedDate).local().format('MMM Do, YYYY HH:mm:ss [(local)]')}
                        </UncontrolledTooltip>
                    </span>
                    : null
                }
            </td>
            <td>{retryAttempts}</td>
            <td>{error && error.length > 30 ? error.split('\n')[0] : error}</td>
            <td>{data.ingest_status}</td>
            <td>
                <button className="btn btn-warning btn-sm small-button" id={`retry-${serialNumber}`}
                    onClick={manualRetry}
                >
                    {processing ? <span className="spinner-border spinner-border-sm mr-1"></span> : null}
                    Retry
                </button>
                <Tooltip placement="bottom" isOpen={tooltipOpen} target={`retry-${serialNumber}`}
                    toggle={() => setTooltipOpen(!tooltipOpen)} trigger=""
                >
                    {tooltipText}
                </Tooltip>
            </td>
            <td>
                {data.ingest_status === "Review" && (<><button className="btn btn-warning btn-sm small-button" id={`review-${serialNumber}`}
                    onClick={manualReview}
                >
                    {reviewProcessing ? <span className="spinner-border spinner-border-sm mr-1"></span> : null}
                    Approve
                </button>
                    <Tooltip placement="bottom" isOpen={!!reviewStatusText} target={`review-${serialNumber}`}
                        toggle={() => setReviewStatusText(null)} trigger=""
                    >
                        {reviewStatusText}
                    </Tooltip>
                </>
                )}

            </td>
            {isAdmin && (<td>
                <button type={"button"}
                    className="btn btn-danger btn-sm small-button"
                    onClick={deleteRequest}
                >
                    {deleteInProcess ? <span className="spinner-border spinner-border-sm mr-1"></span> : null}
                    Delete
                </button>
                <Modal isOpen={deleteDialogOpen}>
                    <ModalHeader toggle={onCancelDeleteRequest}>Delete Request</ModalHeader>
                    <ModalBody>
                        {!deleteSuccessFlag && (
                            <div className="modal-body text-center">
                                Please enter a reason for deletion:
                                <input
                                    className="form-control mt-2"
                                    type="text"
                                    ref={reasonInput}
                                    required
                                />
                            </div>
                        )}
                        <span
                            className={!deleteSuccessFlag ? "text-middle mb-0 small text-danger ml-2" : "text-middle mb-0 small ml-2 pt-2 pb-2"}
                            id="deletion-error"
                        ></span>
                    </ModalBody>
                    <ModalFooter className='p-2'>
                        <button
                            type="button"
                            className="btn btn-secondary"
                            data-dismiss="modal"
                            onClick={onCancelDeleteRequest}
                        >
                            Close
                        </button>
                        {!deleteSuccessFlag && <button
                            type="button"
                            className="btn btn-primary"
                            onClick={deleteRequestApi}
                        >
                            Confirm
                        </button>}
                    </ModalFooter>
                </Modal>
            </td>)}
        </tr>
        </>
    )

}

export default ProcessingRequestRow;
