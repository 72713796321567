import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from '../../../../RoutesWithAuth';
import axios from '../../../common/AxiosConfig';
import { Link, useHistory } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import { Button, CircularProgress, Typography } from '@material-ui/core';
import FormGroup from '../FormGroup';
import { v2UIRoutePath } from '../../../common/commons';

const useStyles = makeStyles(() => ({
    documentWrapper: {
        height: 'calc( 100% )'
    },
    createDsrRequest: {
        marginTop: '10px',
        padding: '0px 0px 10px 0px',
        boxShadow: '0px 0px 1px 0px rgb(0 0 0 / 41%)',
        height: 'calc( 100% - 60px )',
        marginRight: '10px',
        '& .header': {
            background: '#7f9ed7',
            padding: '5px',
            color: "white",
            fontWeight: 'bold'
        },
        '& .invalid-feedback':{
            width: '100%',
            marginTop: '0.25rem',
            fontSize: '100%',
            color: '#dc3545'
        }
    },
    frameWrapper: {
        height: 'calc( 100% - 44px )'
    },
    createdsrHeader: {
        padding: '10px 20px',
        fontSize: '1rem',
        fontWeight: 'bold'
    },
    wrapper: {
        position: 'relative',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        '& label': {
            textAlign: 'center'
        }
    },
    buttonProgress: {
        color: 'green',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    actionButton: {
        textTransform: 'initial'
    }

}));
function CreateDSAR() {
    const history = useHistory();
    const classes = useStyles()
    const [json, setJson] = useState()
    const [users, setUsers] = useState([]);
    const [error, setError] = useState(null);
    const [assignedTo, setAssignedTo] = useState('');
    const [processing, setProcessing] = useState(false);

    const context = useContext(AppContext)
    const authToken = context.authToken.get
    const userId = context.userId.get
    const isAdmin = context.isAdmin.get

    useEffect(() => {
        fetchData()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authToken])

    const handleDsarCreate = () => {

        if (!json || (json && json.trim()) === "") {
            setError({
                errorMessage: 'Please enter valid JSON.'
            });
            return false;
        }
        setProcessing(true)

        axios.post('ticket', {
            creatorId: userId,
            assigned_to: assignedTo === "" ? null : assignedTo,
            body: json
        }, {
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            setProcessing(false)
            history.replace(`${v2UIRoutePath}requestdetails/${res.data.id}`)
        }).catch(err => {
            console.log('There was an error creating the ticket!', err)
            // Display an error message to the user if the creation did not complete
            let errorMessage = "";
            if (err.response.data.error) {
                if (err.response.data.error.includes("Error 522 Bad Gateway")) {
                    errorMessage = `${err.response.data.message.split('\n')[0]} --- CTPs /v5/vehicle endpoint returned an error: Error 522 Bad Gateway`;
                }
                else if (err.response.data.error.includes("APIGW-401")) {
                    errorMessage = `${err.response.data.message.split('\n')[0]} --- CTP Vehicle API endpoint returned an error: Unauthorized - Access Denied`;
                }
                else if (err.response.data.error.includes("Failed validating")) {
                    errorMessage = `${err.response.data.message.split('\n')[0]} --- JSON Validation Failed due to incorrect schema, pls check logs for info`;
                }
                else {
                    errorMessage = `${err.response.data.message.split('\n')[0]} --- ${err.response.data.error}`;
                }
            }
            else {
                errorMessage = err.response.data.message.split('\n')[0]
            }
            let existingDsrid;
            if (err.response.data.dsar_id) {
                existingDsrid = err.response.data.dsar_id;
            }
            setError({
                errorMessage,
                id: existingDsrid
            });
            setProcessing(false)
        })
    }
    const fetchData = () => {
        // Get the list of all users
        axios.get('users', {
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            if (res.data.length) {
                const filteredUsers = res.data.filter(user => user.user_status === 'Y' && !user.is_disabled);
                setAssignedTo(userId)
                setUsers(filteredUsers)
            }
        }).catch(err => {
            console.log('There was an error fetching users!', err)
        })
    }

    return (
        <div className={classes.documentWrapper}>
            <Typography variant="h6" className={classes.createdsrHeader}>
                CREATE DSAR REQUEST
            </Typography>
            <div className={classes.createDsrRequest}>
                <div className={"header"}>
                    DSAR JSON
                </div>
                <textarea className={`${error && "is-invalid"} form-control`} id="JSON" rows="20" placeholder="Enter valid JSON here..."
                    value={json} onChange={e => { setJson(e.target.value) }} required>
                </textarea>
                {error && (
                    <>
                        <div className="invalid-feedback" id="error-message">
                            {error.errorMessage}
                        </div>
                        {error.id && (<Link to={`${v2UIRoutePath}requestdetails/${error.id}`}>View Existing Ticket</Link>)}
                    </>
                )}
                <div  >
                    <FormGroup
                        formWrapperClassName={classes.wrapper}
                        fields={[{
                            type: 'multiselect',
                            name: 'assign_to',
                            label: 'Assign To',
                            options: users.map(user => {
                                return {
                                    id: user.user_id,
                                    user_name: `${user.first_name} ${user.last_name}`
                                }
                            }),
                            removeCheckBox: true,
                            displayOption: 'user_name',
                            defaultOption: assignedTo
                        }]}
                        disableFormActions={true}
                        customFormUpdates={(formData) => {
                            setAssignedTo(formData.assign_to)
                        }}
                    />
                </div>
                <div className={classes.wrapper}>
                    <Button disabled={!isAdmin || processing} className={classes.actionButton} onClick={handleDsarCreate} variant="contained" color="primary">
                        Create
                    </Button>
                    {processing && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>

            </div>

        </div>
    );
}
export default CreateDSAR;