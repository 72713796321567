import React, { useState, useEffect } from 'react';
import { withNavbar } from './Navbar';
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import { Grid, Tab, Tabs } from '@material-ui/core';
import {
    AutoCloseSettingsTab,
    AppScopeTab,
    Catalog,
    Settings,
    CreateDSAR,
    EscalationSettingsTab,
    RequestReviewSetting,
    ResponseReviewSetting
} from './admin/index';


const useStyles = makeStyles(() => ({
    homePageWrapper: {
        fontSize: '0.8rem',
        height: "100%",
        margin: "0 10px 0px 10px"
    },
    adminTabWrapper: {
        '& .MuiGrid-item': {
            background: '#f8f9fa',
        },
        background: '#f8f9fa',
        boxShadow: "0px 0px 0.8px 0px rgb(0 0 0 / 40%)",
        height: "calc( 100% - 40px)"
    },
    headerTitle: {
        textAlign: "center",
        flexGrow: 1,
        fontWeight: "bold",
        marginTop: '20px',
        marginBottom: '14px'
    },
    verticalTabGridItem: {
        maxWidth: '180px',
    },
    verticalTabGridContent: {
        maxWidth: 'calc( 100% - 180px)',
    },
    verticalTabWrapper: {
        borderRight: "1px solid #dedfdf",
        margin: "0px 10px 10px 0px",
        padding: "10px",
        height: '100%',
        '& .MuiTab-wrapper': {
            alignItems: 'flex-start',
            textTransform: 'capitalize'
        },
        '& .MuiTab-root': {
            background: "#dae3f3",
            marginBottom: '5px',
            outline: '0px',
            minHeight: '35px',
        },
        '& .MuiTabs-indicator': {
            left: '0px',
            background: '#7f9ed7',
            width: '4px',
            height: '35px',
        }
    },
    appsScopeWrapper: {
        '& .MuiTabs-root': {
            minHeight: '35px'
        },
        '& .scopeHeader': {
            padding: '10px 20px',
            fontSize: '1rem',
            fontWeight: 'bold',
            '& a': {
                color: "#212529"
            }
        },
        '& .MuiTab-root.Mui-selected': {
            background: "white",
        },
        '& .MuiTab-root': {
            background: "#dae3f3",
            marginRight: '5px',
            outline: '0px',
            minHeight: '35px',
            '& .MuiTab-wrapper': {
                fontWeight: 'bold',
            },
        },
        '& .MuiTabs-indicator': {
            left: '0px',
            height: '3px'
        }
    }
}));
// TabHistoryPaths Variable Holds all the tab paths available in the Admin Screen
const TabHistoryPaths = [
    "appsscope",
    "autoclosesetting",
    "catalog",
    "createdsr",
    "escalationsetting",
    "requestreviewsettings",
    "responsereviewsettings",
    "systemsettings"
];

function Admin(props) {
    const classes = useStyles();
    const [verticalTab, setVerticalTab] = useState(0);
    const { routerProps } = props
    const { history, match } = routerProps;
    useEffect(() => {
        if (match?.params?.page) {
            setVerticalTab(TabHistoryPaths.indexOf(match?.params?.page));
        }
    }, [match])
    const activeTabName = TabHistoryPaths[verticalTab];
    return (
        <div className={`pl-2 pr-2 ${classes.homePageWrapper}`}>
            <Typography variant="h6" className={classes.headerTitle}>
                Admin Activities
            </Typography>
            <Grid container className={classes.adminTabWrapper}>
                <Grid item xs className={classes.verticalTabGridItem}>
                    <div className={classes.verticalTabWrapper}>
                        <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            value={verticalTab}
                            onChange={(_event, newValue) => {
                                setVerticalTab(newValue);
                                const path = TabHistoryPaths[newValue];
                                history.push(`/newuiadmin/${path}`)
                            }}
                            aria-label="Vertical tabs"
                            sx={{ borderRight: 1, borderColor: 'divider' }}
                        >
                            <Tab label="Apps Scope" />
                            <Tab label="Auto Close Settings" />
                            <Tab label="Catalog" />
                            <Tab label="Create DSAR" />
                            <Tab label="Escalation Settings" />
                            <Tab label="Request Review" />
                            <Tab label="Response Review" />
                            <Tab label="System Settings" />
                        </Tabs>
                    </div>

                </Grid>
                <Grid item xs className={classes.verticalTabGridContent}>
                    {activeTabName === "appsscope" && (
                        <div className={classes.appsScopeWrapper}>
                            <AppScopeTab
                                active={match?.params?.tab}
                            />

                        </div>
                    )}
                    {activeTabName === "escalationsetting" && (
                        <div className={classes.appsScopeWrapper}>
                            <EscalationSettingsTab
                                active={match?.params?.tab}
                            />
                        </div>
                    )}
                    {activeTabName === "autoclosesetting" && (
                        <div className={classes.appsScopeWrapper}>
                            <AutoCloseSettingsTab
                                active={match?.params?.tab}
                            />
                        </div>
                    )}
                    {activeTabName === "systemsettings" && (
                        <Settings active={match?.params?.tab} />
                    )}
                    {activeTabName === "catalog" && (
                        <Catalog active={match?.params?.tab} />
                    )}
                    {activeTabName === "createdsr" && (
                        <CreateDSAR />
                    )}
                    {activeTabName === "requestreviewsettings" && (
                        <div className={classes.appsScopeWrapper}>
                            <RequestReviewSetting
                                active={match?.params?.tab} />
                        </div>
                    )}
                    {activeTabName === "responsereviewsettings" && (
                        <div className={classes.appsScopeWrapper}>
                            <ResponseReviewSetting
                                active={match?.params?.tab}
                            />
                        </div>
                    )}
                    {verticalTab !== 0 && (
                        <div>

                        </div>
                    )}

                </Grid>
            </Grid>
        </div>
    );
}

export default withNavbar(Admin, 3);
