/**
 * Requests Report Grid file which loads the different tabs in Manual actions reports Page.
 */
import React, { useState, useContext, useEffect } from "react";
import moment from "moment";
import axios from '../../../../common/AxiosConfig';
import { AppContext } from '../../../../../RoutesWithAuth';
import { Snackbar, makeStyles } from "@material-ui/core";
import DataGrid from '../../grid';
import { LoadingIndicator } from "../reconcilationreport/MuiStyled";
import { DATEFORMATUTC, DATEFORMATLOCAL } from '../../../../common/commons';
import MuiAlert from '@material-ui/lab/Alert';
import Papa from 'papaparse';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(() => ({
}));
function RequestsReportGrid(props) {

    const context = useContext(AppContext)
    const authToken = context.authToken.get
    const classes = useStyles();
    const [reportData, setReportData] = useState([])
    const [isInProgress, setIsInProgress] = useState(false)
    const [displayErrMsg, setDisplayErrMsg] = useState("")

    useEffect(() => {
        if (authToken) {
            fetchData()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authToken, props?.fromredirection])

    const handleDownloadGrid = () => {

        const downloadLink = document.createElement("a");
        
        const formatedReportData = reportData?.map((record)=>{
            const formatedRecord = {};
            props.columns.forEach(element => {
                if(element.name !== "#")
                    formatedRecord[element.name] = record[element.mapping];
            });
            return formatedRecord;
        });
        const csv = Papa.unparse(formatedReportData)
        const blob = new Blob(["\ufeff", csv])
        const url = URL.createObjectURL(blob)
        downloadLink.href = url
        // Formating the CSV File Name
        const timestamp = moment().format("YYYYMMDDHHmm")
        downloadLink.download = `CPS_Review_Requests_${timestamp}.csv`
        document.body.appendChild(downloadLink)
        downloadLink.click()
        document.body.removeChild(downloadLink)
    }

    const fetchData = () => {
        setIsInProgress(true);
        axios.get(props.api, {
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            setIsInProgress(false)
            if (res.status === 204) {
                setReportData([])
                setDisplayErrMsg("No record Found")
            } else {
                setReportData(res.data?.map((data, index) => {
                    return {
                        id: index + 1,
                        ...data,
                        createdDateandTimeLocal: data.created_date ? moment(data.created_date).local().format(DATEFORMATLOCAL) : '--',
                        createdDateandTimeUTC: data.created_date ? moment(data.created_date).utc().format(DATEFORMATUTC) : '--',

                    }
                }))
            }

        }).catch(err => {
            setIsInProgress(false)
            setReportData([])
            setDisplayErrMsg("API FAILED")
        })
    }
    const handleClose = () => {
        setDisplayErrMsg("")
    }
    return (

        <div className={`${classes.root}`}>
            {isInProgress && (<>
                <LoadingIndicator />
            </>)}
            <Snackbar open={!!displayErrMsg} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    {displayErrMsg}
                </Alert>
            </Snackbar>
            <DataGrid
                customRowHeight={30}
                columns={props.columns}
                onCellClick={(cellparams) => {
                    props.onCellClick(cellparams)
                }}
                toolbarConfig={{
                    downloadLabel: props.downloadEnabled ? "Download" : null,
                    handleDownloadGrid
                }}
                rows={reportData}
            />
        </div>
    );
}

export default React.memo(RequestsReportGrid);
