import React from 'react';
import {Modal,ModalFooter,ModalBody, Button} from 'reactstrap';

function Sessiontimeout(props) {    
    const { stayActive,modelIsOpen,seconds } = props
    return (
        <div>
            <Modal isOpen={modelIsOpen} modalClassName='ssoMainblock'>
                <div className="text-center p-2 ssoHeader">Session Timeout</div>
                    <ModalBody>
                        <div>
                             You are being timed out in {seconds} seconds due to inactivity. Click "Keep working" if you want to continue and stay logged in.
                        </div>
                    </ModalBody>
                    <ModalFooter className='p-2 removeBoder'>
                        <Button color='primary' onClick={stayActive}>Keep Working</Button>
                    </ModalFooter>
            </Modal>
        </div>
    );
}
export default Sessiontimeout;
