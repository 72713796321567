/**
 * DownStream Report Main index file which is refered in reports Page
 */
import React, { useState, useContext, useEffect, useRef } from "react";
import moment from "moment";
import axios from "../../../../common/AxiosConfig";
import { AppContext } from "../../../../../RoutesWithAuth";
import { Typography, makeStyles } from "@material-ui/core";
import FormGroup from "../../FormGroup";
import { RequestorMapping, v2UIRoutePath } from '../../../../common/commons';
import TrendingChart from "./TrendingChart";
import { Link, useLocation } from 'react-router-dom';
import { LoadingIndicator } from "../reconcilationreport/MuiStyled";

const useStyles = makeStyles(() => ({
    root: {

    },
    formGroupWrapper: {
        width: '600px',
        '& .multiSelectedOptions': {
            width: '140px'
        }
    },
    customInputCls: {
        width: '140px',
    },

}));
function TrendingReport() {
    const context = useContext(AppContext)
    const location = useLocation();
    const authToken = context.authToken.get
    const classes = useStyles();
    const today = moment();
    const formGroupRef = useRef();
    // To Store the Year DropDown Data
    const [metricYears, setMetricYears] = useState([])
    const [toFilter, setToFilter] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [footerText, setFooterText] = useState(null)

    //state for maintaining form related data
    const [formData, setFormData] = useState({
        duration: "Month",
        date: today.format("YYYY-MM"),
        requestor: ['All']
    });
    const [trendData, setTrendData] = useState([])
    const [filteredReportData, setFilteredReportData] = useState([])


    useEffect(() => {
        
        setFormData({
            duration: "Month",
            date: today.format("YYYY-MM"),
            requestor: ['All']
        })
        setTimeout(() => {
            // resetting the Form when there is change in the Location state.
            if(formGroupRef && formGroupRef.current){
                formGroupRef.current.resetFormToDefaults();
            }
            
        }, 20);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location?.state?.fromredirection])

    useEffect(() => {
        if (authToken) {
            fetchData()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authToken, formData])

    const fetchData = () => {
        let requestor = formData.requestor.join(', ')?.toLowerCase();
        if (requestor === "all") {
            requestor = "ALL"
        } else if(requestor === ""){
            return false
        }
        setIsLoading(true);

        axios.get("/reports/request_count_chart", {
            params: {
                metric: formData.duration,
                year: formData.duration === "Year" ? formData.date : moment(formData.date).format("YYYY"),
                month: formData.duration === "Year" ? 0 : moment(formData.date).format("MM"),
                requestor
            },
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            setTrendData(res.data)
            const dateRange = formData.duration === "Year" ? formData.date : moment(formData.date).format("MMMM YYYY");
            const footerText = `Date Range: ${dateRange}, Requestor: ${formData.requestor.join(', ')}`
            setFooterText(footerText);
            setIsLoading(false);
        }).catch(err => {
            setFooterText(null);
            setIsLoading(false);
        })
    }
    useEffect(() => {
        // Setting up the year Drop Down data
        let years = [];
        for (let i = 2020; i <= today.format("YYYY"); i++) {
            years.push(i);
        }
        setMetricYears(years);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        // Deep Copy
        const reportDataCopy = JSON.parse(JSON.stringify(trendData))
        setFilteredReportData(
            reportDataCopy.map(el => {
                if (toFilter.includes(el.id)) {
                    el.data = []
                }
                return el
            })
        )
    }, [toFilter, trendData])

    return (

        <div className={`${classes.root}`}>
            {isLoading && (<LoadingIndicator />)}
            <Typography variant="h6" className={'tabHeader'}>
                <Link color="primary" to={() => {
                    // Generating a Random Id and passing it as part of state 
                    // Every click on the Header will generate a unique Id
                    const autoId = Math.round(Math.random() * 1e5);
                    return {
                        pathname: v2UIRoutePath + "reports/requeststrendingreport",
                        state: { fromredirection: autoId }
                    }
                }}
                >
                    REQUESTS TRENDING REPORT
                </Link>
            </Typography>
            <FormGroup
                formWrapperClassName={classes.formGroupWrapper}
                ref={formGroupRef}
                fields={[{
                    type: 'multiselect',
                    name: 'duration',
                    label: 'Select Duration',
                    isMulti: false,
                    removeCheckBox: true,
                    options: ["Month", "Year"],
                }, {
                    type: 'multiselect',
                    name: 'date',
                    hidden: (formData.duration !== "Year"),
                    label: 'Select Year',
                    customInputCls: classes.customInputCls,
                    removeCheckBox: true,
                    options: metricYears,
                }, {
                    type: 'month',
                    name: "date",
                    isHidden: (formData.duration === "Year"),
                    label: "Select Option",
                    inputAdditionalProps: {
                        min: "2020-01",
                        max: today.format("YYYY-MM")
                    }
                }, {
                    type: 'multiselect',
                    name: 'requestor',
                    label: 'Requestor',
                    isMulti: true,
                    customInputCls: classes.customInputCls,
                    options: ["All", ...Object.values(RequestorMapping)],
                    size: 2
                }]}
                disableFormActions={true}
                defaultFormData={formData}
                customFormUpdates={(u_formData) => {
                    // This Method is called when ever there is any change in the form fields
                    if (u_formData.duration !== formData.duration) {
                        if (u_formData.duration === "Year") {
                            u_formData.date = metricYears[metricYears.length - 1];
                        } else {
                            u_formData.date = today.format("YYYY-MM");
                        }
                    }
                    setFormData(u_formData)
                }}
            />
            <div style={{ height: "570px" }}>
                <TrendingChart
                    data={filteredReportData}
                    toFilter={toFilter}
                    setToFilter={setToFilter}
                    isYear={formData.duration === "Year"}
                />
            </div>
            <div className="font-italic text-center">{footerText}</div>
        </div>
    );
}

export default TrendingReport;
