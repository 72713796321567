
import { Button, IconButton, Snackbar, TextField, Tooltip, Typography, makeStyles } from '@material-ui/core';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Alert } from '@material-ui/lab';
import { AppContext } from '../../../../RoutesWithAuth';
import axios from '../../../common/AxiosConfig';
import { LoadingIndicator, Tab, Tabs } from '../reports/reconcilationreport/MuiStyled';
import { DATEFORMATLOCAL, DATEFORMATUTC, feedbackTimeout, v2UIRoutePath } from '../../../common/commons';
import DataGrid from '../grid';
import moment from 'moment';
import RowEditActions from '../grid/RowEditActions';
import DeleteDialog from '../admin/settings/DeleteDialog';
import FormGroup from '../FormGroup';
import { Close } from '@material-ui/icons';
import ViewFile from './ViewFile';


const COLUMNS = [{
    name: "Request File Type",
    mapping: 'requestfiletype',
    width: 200,

    disableColumnMenu: true
}, {
    name: "Request File",
    mapping: 'requestfilename',
    disableColumnMenu: true,
    renderCell: (params) => {
        const requestfilename = params.row['requestfilename'];
        if (["NA", "File not found"].includes(requestfilename)) {
            return (<span className={`${requestfilename !== "NA" && "linkNotavailable"}`}>
                {requestfilename}
            </span>)
        }
        return (<Link>
            {requestfilename}
        </Link>)
    },
}, {
    name: "Request Check File",
    mapping: 'requestcheckfile',
    disableColumnMenu: true,
    renderCell: (params) => {
        const requestcheckfile = params.row['requestcheckfile'];
        if (["NA", "File not found"].includes(requestcheckfile)) {
            return (<span className={`${requestcheckfile !== "NA" && "linkNotavailable"}`}>
                {requestcheckfile}
            </span>)
        }
        return (<Link >
            {requestcheckfile}
        </Link>)
    },

}, {
    name: "Regenerate File",
    mapping: 'regeneratefile',
    disableColumnMenu: true,
    width: 200,
}, {
    name: "Resend File",
    mapping: 'resendfile',
    disableColumnMenu: true,
    width: 150,
}, {
    name: "Process Response",
    mapping: 'processresponse',
    disableColumnMenu: true,
    width: 200
}]
// Custom page Level Styling
const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flex: '1',
        height: '100%',
        '& .linkNotavailable': {
            color: 'red'
        }
    },
    actionButton: {
        '& .MuiButton-label': {
            fontSize: '0.7rem'
        }
    },
    accordianTitle: {
        fontWeight: 'bold'
    },
    dialogWrapper: {
        width: '860px'
    },
    dialogheader: {
        textAlign: 'center',
        fontSize: '0.75rem',
        '& span': {
            fontWeight: 'bold'
        },
        '& .modelheader': {
            paddingBottom: '5px',
            fontWeight: 'bold'
        }
    },
    deleteDialogContext: {
        width: '300px',
        height: '90px',
        '& .errorMessage': {
            fontSize: '11px',
            color: 'red'
        },
        '& .MuiFormControl-root': {
            height: '100%',
            width: '100%',
        },
        '& .MuiInputLabel-root': {
            marginLeft: '10px',
            color: 'black',
            '& .MuiFormLabel-asterisk': {
                float: 'left',
                paddingRight: '2px',
                color: 'red'
            }
        },
        '& .MuiInputBase-root': {
            border: '1px solid #95a3bd',
            borderRadius: '10px',
            marginTop: '21px',
        },
        '& .MuiInput-underline:before,.MuiInput-underline:after,.MuiInput-underline:hover:not(.Mui-disabled):before': {
            border: '0px'
        }
    },
    formGroupWrapper: {
        width: '600px',
        marginTop: '10px'
    },
    tabCloseIcon: {
        marginLeft: '10px',
        color: 'gray',
        fontSize: '1rem',
        marginTop: '-3px'
    }
}));

const DailyFeed = ({ tabActiveParam }) => {
    const classes = useStyles();
    const yesterday = moment(new Date()).subtract(1, 'days').format('YYYY-MM-DD')

    // State to handle the Main Tab Information
    const [activeTab, setActiveTab] = useState(0);
    const [dailyfeedData, setDailyFeedData] = useState([]);
    const [displayErrorMsg, setDisplayErrorMsg] = useState("");
    const [successMsg, setSuccessMsg] = useState("");
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogRowInfo, setDialogRowInfo] = useState();


    //state for maintaining form related data
    const [formData, setFormData] = useState({
        date: null
    });
    const location = useLocation();
    // State to handle the Progress status
    const [isInProgress, setIsInProgress] = useState(false);
    const [checkFile, setCheckFile] = useState();
    const [requestFile, setRequestFile] = useState();
    const [activeTabs, setActiveTabs] = useState([]);

    const context = useContext(AppContext)
    const formGroupRef = useRef();


    const authToken = context.authToken.get

    useEffect(() => {
        if (location?.state?.fromredirection) {
            // resetting the Form when there is change in the Location state.
            formGroupRef.current.resetFormToDefaults();
            setFormData({
                date: yesterday
            })
            fetchData();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location?.state?.fromredirection])

    useEffect(()=>{
        setFormData({
            date: yesterday
        })
        fetchData(yesterday)
    },[]);

    const fetchDataWithDelay = () => {
        setTimeout(
            function () {
                fetchData(formData.date);
            }, feedbackTimeout
        )
    }
    const fetchData = (date) => {
        console.log("API CALLED")
        setIsInProgress(true)

        axios.get(`apps/daily-feed?date=${moment(date).format("YYYY-MM-DD")}`, {
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            let dailyfeedResponse = res.data;
            // Formating the report based on the Grid Required format
            dailyfeedResponse = dailyfeedResponse?.map((_record, index) => {
                return {
                    ..._record,
                    id: index + 1
                }
            })
            setDailyFeedData(dailyfeedResponse)
            setIsInProgress(false)
        }).catch(err => {
            setIsInProgress(false)
        })
    }
    const handleClose = () => {
        setDisplayErrorMsg("");
        setSuccessMsg("");
    }
    const handleDialogClose = () => {
        setOpenDialog(false);
    }
    const handleDialogSubmit = () => {
        setOpenDialog(false);
        setIsInProgress(true)
        const additionalDetails = {};
        if (dialogRowInfo.rtd == true) {
            additionalDetails.rtd = true;
        }
        axios.post(`apps/daily-feed/resend`, {
            app_id: dialogRowInfo.appid,
            ...additionalDetails,
            date: formData.date,
        }, {
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            setSuccessMsg("File has been regenerated successfully!");
            fetchDataWithDelay();
        }).catch(err => {
            let errorMsg = "Failed to resend file!";
            if (err.response?.data.message) {
                errorMsg = err.response.data.message
            }
            setDisplayErrorMsg(errorMsg);
        }).finally(() => {
            setIsInProgress(false)

        })
    }
    const resendFile = (row) => {
        setDialogRowInfo(row);
        setOpenDialog(true)
    }
    const reGenerateFile = (rowInfo) => {
        setIsInProgress(true);
        const additionalDetails = {};
        if (rowInfo.rtd == true) {
            additionalDetails.rtd = true;
        }
        axios.post(`apps/daily-feed/regenerate`, {
            app_id: rowInfo.appid,
            ...additionalDetails,
            date: formData.date,
        }, {
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            // Set validity and messages based on the response code
            if (res.status === 201) {
                setSuccessMsg(res.data.message)
            } else if (res.status === 204) {
                setDisplayErrorMsg("Requests in scope are not available for the selected date")
            } 
            fetchDataWithDelay();
        }).catch(err => {
            setDisplayErrorMsg("Failed to regenerate file!")
        }).finally(() => {

            setIsInProgress(false);
        })
    }
    const processResponse = (rowInfo) => {
        setIsInProgress(true);

        axios.post(`/apps/daily-feed/response/${rowInfo.appid}`, {}, {
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            // Set validity and messages based on the response code
            if (res.status === 500) {
                setDisplayErrorMsg(res.data.message);
            } else if (res.status === 200) {
                setSuccessMsg(res.data.message)
            }
            fetchDataWithDelay();
        }).catch(err => {
            setDisplayErrorMsg("Response process encountered an error")
        }).finally(() => {

            setIsInProgress(false);
        })
    }
    const dataGridColumns = () => {
        return COLUMNS.map((column) => {
            if (column.mapping === "regeneratefile") {
                column.renderCell = (params) => {
                    return (<Button
                        variant="contained"
                        color="primary"
                        className={classes.actionButton}
                        onClick={() => {
                            reGenerateFile(params.row)
                        }}
                    >
                        Regenerate File
                    </Button>)
                }
            } else if (column.mapping === "resendfile") {
                column.renderCell = (params) => {
                    return (<Button
                        variant="contained"
                        color="primary"
                        className={classes.actionButton}
                        onClick={() => {
                            resendFile(params.row)
                        }}
                    >
                        Resend File
                    </Button>)
                }
            } else if (column.mapping === "processresponse") {
                column.renderCell = (params) => {
                    return (<Button
                        variant="contained"
                        color="primary"
                        className={classes.actionButton}
                        onClick={() => {
                            processResponse(params.row)
                        }}

                    >
                        Process Response
                    </Button>)
                }
            }
            return column
        });
    }
    return (
        <div className={classes.root}>
            {isInProgress && (<>
                <LoadingIndicator />
            </>)}
            <Snackbar open={!!displayErrorMsg} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    {displayErrorMsg}
                </Alert>
            </Snackbar>
            <Snackbar open={!!successMsg} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                    {successMsg}
                </Alert>
            </Snackbar>
            <Typography variant="h6" className={'scopeHeader'}>
                <Link color="primary" to={() => {
                    // Generating a Random Id and passing it as part of state 
                    // Every click on the Header will generate a unique Id
                    const autoId = Math.round(Math.random() * 1e5);

                    return {
                        pathname: v2UIRoutePath + "tracking/dailyfeed",
                        state: { fromredirection: autoId }
                    }
                }}
                >
                    DAILY FEED
                </Link>
            </Typography>
            <Tabs
                variant="scrollable"
                aria-label="tabs"
                value={activeTab}
                onChange={(_event, newValue) => {
                    setActiveTab(newValue);

                }}
                sx={{ borderRight: 1, borderColor: 'divider' }}
            >
                <Tab label="FTP SYSTEMS" />
                {activeTabs?.map((type) => {
                    if (type === "CHECK" && checkFile) {
                        return (<Tab label={(<span>
                            REQUEST CHECK FILE
                            <IconButton
                                className={classes.tabCloseIcon}
                                size="small"
                                aria-label="close"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setActiveTab(0);
                                    setActiveTabs(activeTabs.filter((tab) => tab != "CHECK"))
                                    setCheckFile(null);
                                }}
                            >
                                <Close fontSize="small" />
                            </IconButton>
                        </span>)} />)
                    } else if (requestFile) {
                        return (<Tab label={(<span>
                            REQUEST FILE
                            <IconButton
                                className={classes.tabCloseIcon}
                                size="small"
                                aria-label="close"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setActiveTab(0);
                                    setActiveTabs(activeTabs.filter((tab) => tab === "CHECK"))
                                    setRequestFile(null);
                                }}
                            >
                                <Close fontSize="small" />
                            </IconButton>
                        </span>)} />)
                    }
                })}
            </Tabs>
            {activeTab === 0 && (
                <>
                    <FormGroup
                        formWrapperClassName={classes.formGroupWrapper}
                        ref={formGroupRef}
                        fields={[{
                            type: 'date',
                            name: 'date',
                            defaultOption: (formData.date || yesterday ),
                            label: 'Select Date',
                            size: 4,
                            inputAdditionalProps: {
                                max: yesterday,
                                min: "2020-01-01",
                            }
                        }]}
                        disableFormActions={true}
                        customFormUpdates={(_formData) => {

                            // This Method is called when ever there is any change in the form fields
                            if (_formData.date !== formData.date) {
                                setFormData(_formData)
                                fetchData(_formData.date)
                            }

                        }}
                    />
                    <DataGrid
                        columns={dataGridColumns()}
                        rows={dailyfeedData || []}
                        customRowHeight={60}
                        onCellClick={(cellparams) => {
                            if (["requestfilename", "requestcheckfile"].includes(cellparams.colDef.mapping)) {
                                if (!(["NA", "File not found"].includes(cellparams.row[cellparams.colDef.mapping]))) {
                                    const isCheck = cellparams.colDef.mapping === "requestcheckfile";
                                    let tabType = "CHECK"
                                    if (!isCheck) {
                                        tabType = "REQUEST"
                                    }
                                    let activeTabIndex = activeTabs.indexOf(tabType);
                                    if (activeTabIndex == -1) {
                                        activeTabs.push(tabType);
                                        activeTabIndex = activeTabs.length;
                                        setActiveTabs(activeTabs);
                                    } else {
                                        activeTabIndex = activeTabIndex + 1;
                                    }
                                    setActiveTab(activeTabIndex)
                                    const documentInfo = {
                                        ...cellparams.row,
                                        selectedDate: formData.date,
                                        fileName: cellparams.row[cellparams.colDef.mapping]
                                    };
                                    if (isCheck) {
                                        setCheckFile(documentInfo)
                                    } else {
                                        setRequestFile(documentInfo)
                                    }
                                }


                            }
                        }}
                    />
                </>
            )}
            {activeTabs?.map((_tab, index) => {
                let file = requestFile;
                if (_tab === "CHECK") {
                    file = checkFile
                }
                if (index + 1 === activeTab) {
                    return (
                        <ViewFile
                            documentInfo={file}
                            is_check={_tab === "CHECK"}
                            displayErrorMsg={setDisplayErrorMsg}
                        />
                    )
                }
                return null

            })}

            {openDialog && (<DeleteDialog
                openDeleteDialog={openDialog}
                handleDeleteDialogClose={handleDialogClose}
                title={"Confirm Resend to FTP"}
                handleDeleteDialogSubmit={handleDialogSubmit}
            >
                Do you really want to resend the file for {moment(formData?.date).format('MMM Do, YYYY')} to {dialogRowInfo?.requestfiletype} ?
            </DeleteDialog>)}
        </div>
    )
};

export default React.memo(DailyFeed);