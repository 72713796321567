import React, { useState, useEffect } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import axios from '../../../../common/AxiosConfig';
import moment from 'moment';

const dateFormats = {
    'Specific Date': 'YYYY-MM-DD',
    Month: 'YYYY-MM',
    Week: 'YYYY-[W]WW',
    Year: 'YYYY'
}

const prettyDateFormats = {
    'Specific Date': 'YYYY-MM-DD',
    Month: 'MMMM YYYY',
    Week: '[Week] WW [of] YYYY',
    Year: 'YYYY'
}

const AllRequests = 'All Requests';
const DateFormatSpecificDate = 'Specific Date';
function RequestCount(props) {
    const { authToken } = props
    const today = moment()

    const [duration, setDuration] = useState(AllRequests)
    const [date, setDate] = useState(today)
    const [year, setYear] = useState(today.format(dateFormats.Year));
    const [tableFooter, setTableFooter] = useState(AllRequests)
    const [requestCount, setRequestCount] = useState([])

    const getFormatedDate = (isPrettyDateFormat) => {
        let formatedDate = null;
        const formates = isPrettyDateFormat ? prettyDateFormats : dateFormats;
        if (duration === "Year") {
            formatedDate = year;
        } else if (duration !== AllRequests) {
            formatedDate = date.format(formates[duration]);
        }
        if (isPrettyDateFormat && duration === AllRequests) {
            formatedDate = "All Requests"
        }
        return formatedDate;
    }
    useEffect(() => {
        if (authToken) {
            axios.get('/reports/request_count', {
                headers: {
                    Authorization: authToken
                },
                params: {
                    duration: duration === AllRequests ? null : duration,
                    date: getFormatedDate()
                }
            }).then(res => {
                setRequestCount(res.data.request_count)
                setTableFooter(getFormatedDate(true))
            }).catch(err => {
                console.log(err)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authToken, duration, date, year])

    return (
        <div className="mb-5">

            <form className="form-inline mt-3">
                <div className="form-group mr-2 mb-2">
                    <label className="mr-1" htmlFor="duration">Duration:</label>
                    <select className="form-control" id="duration" value={duration} onChange={e => setDuration(e.target.value)}>
                        <option>{AllRequests}</option>
                        <option>{DateFormatSpecificDate}</option>
                        <option>Week</option>
                        <option>Month</option>
                        <option>Year</option>
                    </select>
                </div>
                {duration === DateFormatSpecificDate ?
                    <div className="form-group mr-2 mb-2">
                        <input type="date" className="form-control date-form" value={date.format(dateFormats[DateFormatSpecificDate])}
                            min="2020-01-01" max={today.format(dateFormats[DateFormatSpecificDate])} onChange={e => setDate(moment(e.target.value))} required
                        />
                    </div>
                    : null
                }
                {duration === 'Year' ?
                    <div className="form-group mr-2 mb-2">
                        <input type="number" className="form-control date-form"
                            value={year}
                            min="2020" max={today.format(dateFormats.Year)}
                            step="1"
                            onChange={e => setYear(e.target.value)}
                            required
                        />
                    </div>
                    : null
                }
                {duration === 'Month' ?
                    <div className="form-group mr-2 mb-2">
                        <input type="month" className="form-control date-form" value={date.format(dateFormats.Month)}
                            min="2020-01" max={today.format(dateFormats.Month)} onChange={e => setDate(moment(e.target.value))} required
                        />
                    </div>
                    : null
                }
                {duration === 'Week' ?
                    <div className="form-group mr-2 mb-2">
                        <input type="week" className="form-control date-form" value={date.format(dateFormats.Week)}
                            min="2020-W01" max={today.format(dateFormats.Week)} onChange={e => setDate(moment(e.target.value))} required
                        />
                    </div>
                    : null
                }
            </form>

            <h4 className="text-center mt-4">Request Count</h4>
            <table className="table table-striped">
                <thead>
                    <tr className="text-center">
                        <th scope="col" className="text-left">Request Type</th>
                        <th scope="col">Open</th>
                        <th scope="col">Closed</th>
                        <th scope="col">Uploaded</th>
                        <th scope="col">Upload Failed</th>
                        <th scope="col">Cancelled</th>
                        <th scope="col">Total</th>
                    </tr>
                </thead>
                <CSSTransition in={requestCount.length > 0} appear={true} timeout={125} classNames="swipeD">
                    <tbody className="text-center">
                        {requestCount.map(type => (
                            <tr key={type.name}>
                                <th scope="row" className="text-left">{type.name}</th>
                                {type.data.map((count, i) => (
                                    <td key={`${type.name}_count`}>{count}</td>
                                ))}
                            </tr>
                        ))}

                        {requestCount.length ?
                            <tr>
                                <th scope="row" className="text-left">Total</th>
                                <td className="font-weight-bold">{requestCount[0].data[0] + requestCount[1].data[0] + requestCount[2].data[0] + requestCount[3].data[0]}</td>
                                <td className="font-weight-bold">{requestCount[0].data[1] + requestCount[1].data[1] + requestCount[2].data[1] + requestCount[3].data[1]}</td>
                                <td className="font-weight-bold">{requestCount[0].data[2] + requestCount[1].data[2] + requestCount[2].data[2] + requestCount[3].data[2]}</td>
                                <td className="font-weight-bold">{requestCount[0].data[3] + requestCount[1].data[3] + requestCount[2].data[3] + requestCount[3].data[3]}</td>
                                <td className="font-weight-bold">{requestCount[0].data[4] + requestCount[1].data[4] + requestCount[2].data[4] + requestCount[3].data[4]}</td>
                                <td className="font-weight-bold">{requestCount[0].data[5] + requestCount[1].data[5] + requestCount[2].data[5] + requestCount[3].data[5]}</td>
                            </tr>
                            : null
                        }
                    </tbody>
                </CSSTransition>
            </table>

            <SwitchTransition>
                <CSSTransition key={tableFooter} timeout={250} classNames="swipeR">
                    <p className="font-italic text-center">Request Count: {tableFooter}</p>
                </CSSTransition>
            </SwitchTransition>
        </div>
    );
}

export default RequestCount;
