import React from 'react';
import { Auth } from 'aws-amplify';
import axios from "./AxiosConfig";
import PageFooter from './PageFooter';
import PageHeader from './PageHeader';

export const getAuthToken = async (context) => {
    const { idToken } = await Auth.currentSession()
    const { attributes: { email, sub, given_name, family_name } } = await Auth.currentUserInfo()
    const jwtToken = idToken.jwtToken
    // Set values if the state update functions are passed
    // if (groups && groups.includes('admin')){
    //     context.isAdmin.set(true)
    // }
    context.authToken.set(jwtToken)
    context.email.set(email)
    context.userId.set(sub)
    context.userName.set(given_name + " " + family_name)

    await checkSignIn(jwtToken, context.validUser.set)
}

export const checkSignIn = async (
    authToken, setSignInStatus
) => {
    console.log('check sign in')
    await axios.post("/users/check_sign_in", {
    },{
        headers: {
            Authorization: authToken,
        },
    })
    .then((res) => {
        setSignInStatus(res.data)
    })
    .catch((err) => {
        console.log(err);
    });
}

export const signOut = () => {
    Auth.signOut().catch(err => {
        console.log(err)
    })
}


export const returnable = (context, usersList) => {

    if (usersList !== null){
        const loggedInUserId = context.userId.get;
        const userRecord = usersList.find(({user_id})=> user_id === loggedInUserId);
        if(userRecord){
            let isAdmin = false;
            if(userRecord.role === 'Admin'){
                isAdmin = true;
            }
            // SET User Role based on query result
            context.isAdmin.set(isAdmin);
            // Check if User is disabled
            if(userRecord.user_status == 'N'){
                return invalidScreen(context, 'disabled');
            }
             // Display content based on role
            if(userRecord.user_status == "Y"){
                return;
            }
        }
    }
    // Throwing absent error since user Info was not found in table
    return invalidScreen(context, 'absent');
}

export const loadingScreen = () =>{
    return (<div>
                <nav className="navbar navbar-expand-lg navbar-light bg-light shadow-sm mb-3">
                    <span className="navbar-brand">
                        <img alt="" src="/ct-cps-logo.png" width="150" height="40"></img>
                    </span>
                    <div className="ml-auto">
                        <span className="d-none d-lg-block d-xl-block text-center" />
                    </div>
                </nav>
                <PageHeader header="CT Consumer Privacy System" />
                <div style={{marginBottom: '33vh',marginTop: '35vh'}} class="d-flex justify-content-center">
                    <img alt="" className="loading-spin" src={"/rotate_right_black_24dp.svg"} width="120"></img>
                </div>
                <PageFooter />
            </div>)
}

const invalidScreen = (context, error) => {
    return (
        <div>
            <nav className="navbar navbar-expand-lg navbar-light bg-light shadow-sm mb-3">
                <span className="navbar-brand">
                    <img alt="" src="/ct-cps-logo.png" width="150" height="40"></img>
                </span>

                <div className="ml-auto">
                    <span className="d-none d-lg-block d-xl-block text-center">
                        {context.userName.get}
                    </span>
                </div>
            </nav>
            <PageHeader header="CT Consumer Privacy System" />
            <p>The user {context.email.get} is {error} in CPS and hence does not have access to Connected Technologies Consumer Privacy System. Please reach out to Telematics_CCPA_Operations_Team@toyota.com with sufficient business justification for gaining access.</p>
            <PageFooter />
        </div>
    );
}