import React, { useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from '@material-ui/core';
import { Cancel, Edit, Save, Delete } from '@material-ui/icons';
import { AppContext } from '../../../../RoutesWithAuth';

const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
        flex: 1,
        justifyContent: 'space-evenly'
    }
}));
const RowMenuCell = (props) => {
    const {
        api,
        id,
        enableIconCallback,
        customValidation,
        validationColumns = [],
        enableDeleteAction,
        deleteActionCallBack,
        enableDeleteInSave
    } = props;
    const classes = useStyles();
    const context = useContext(AppContext);
    const isAdmin = context?.isAdmin?.get;


    const isInEditMode = api.getRowMode(id) === 'edit';
    const rowInfo = api.getRow(id);

    const handleDeleteClick = (event) => {
        event.stopPropagation();
        let editExists = false;
        const currentEditIDs = Object.keys(api.getEditRowsModel());
        currentEditIDs.forEach((_editId) => {
            editExists = true;
        });
        (enableDeleteInSave || !editExists) && deleteActionCallBack(id, rowInfo);
    }
    const handleEditClick = (event) => {
        event.stopPropagation();
        let editExists = false;
        const currentEditIDs = Object.keys(api.getEditRowsModel());
        currentEditIDs.forEach((_editId) => {
            editExists = true;
        });
        !editExists && api.setRowMode(id, 'edit');
    };
    /**
     * 
     * @param {*} row 
     * @returns Boolean Value
     *  true - when all the columns mentioned in validationColumns has value
     *  false - when columns mentioned in validationColumns do not have value
     */
    const isRowValid = (row) => {
        let isValid = true;
        // Check if the columns passed has value 
        validationColumns.forEach((column) => {
            if (row[column] && !(row[column].value && row[column].value?.trim?.() !== "")) {
                isValid = false;
            }
        })
        // check if any custom validations are enabled using the CustomValidation Prop
        if (isValid && customValidation) {
            isValid = customValidation(row);
        }
        return isValid;
    }
    const handleSaveClick = (event) => {
        event.stopPropagation();
        api.commitRowChange(id);

        const row = api.getRow(id);
        const rowModel = api.getEditRowsModel();
        if (isRowValid(rowModel[id])) {
            api.commitRowChange(id, {
                rowInfo: row,
                rowId: id,
                handleCommitCallBack: function (callback_id) {
                    api.setRowMode(callback_id, 'edit')
                }
            });
            api.setRowMode(id, 'view');
            api.updateRows([{ ...row, isNew: false }]);
        } else {
            api.commitRowChange(id, {
                invalidRowInfo: row
            });
        }
    };

    const handleCancelClick = (event) => {
        event.stopPropagation();
        api.setRowMode(id, 'view');
        const row = api.getRow(id);
        if (row.isNewRecord) {
            api.commitRowChange(id, {
                newRecord: row,
                action: 'delete'
            });
        }
        if (row.isNew) {
            api.updateRows([{ id, _action: 'delete' }]);
        }
    };
    const DeleteComponent = () => {
        let is_Enabled = true;
        if (enableIconCallback) {
            is_Enabled = enableIconCallback(rowInfo);
        }
        if (props?.colDef?.adminEnabled) {
            is_Enabled = isAdmin;
        }
        return (<IconButton
            className={classes.textPrimary}
            size="small"
            disabled={!is_Enabled}
            aria-label="delete"
            data-testid={"delete"}
            onClick={handleDeleteClick}
        >
            <Delete fontSize="small" />
        </IconButton>)
    }
    if (isInEditMode && !enableDeleteAction) {
        return (
            <div className={classes.root}>
                {enableDeleteInSave && !rowInfo.isNewRecord && (<DeleteComponent />)}
                <IconButton
                    size="small"
                    aria-label="save"
                    data-testid={"save"}
                    onClick={handleSaveClick}
                >
                    <Save fontSize="small" />
                </IconButton>
                <IconButton
                    size="small"
                    aria-label="cancel"
                    data-testid={"cancel"}
                    className={classes.textPrimary}
                    onClick={handleCancelClick}
                >
                    <Cancel fontSize="small" color="error" />
                </IconButton>
            </div>
        );
    }

    let editEnabled = true;
    if (props?.colDef?.adminEnabled) {
        editEnabled = isAdmin;
    }
    return (
        <div className={classes.root}>
            {!enableDeleteAction && (<IconButton
                className={classes.textPrimary}
                size="small"
                aria-label="edit"
                disabled={!editEnabled}
                data-testid={"edit"}
                onClick={handleEditClick}
            >
                <Edit fontSize="small" />
            </IconButton>)}
            {enableDeleteAction && (
                <DeleteComponent />
            )}
        </div>
    );
}
export default RowMenuCell;