import React, { useState } from 'react';
import axios from '../../common/AxiosConfig';
import { removeFeedback, DATEFORMATUTC, DATEFORMATLOCAL } from '../../common/commons';
import moment from 'moment';
import { UncontrolledTooltip } from "reactstrap";

function UserRow(props) {
    const { user, isAdmin, authToken, setFilteredUsers, filteredUsers, selectedFilter, users, setUsers } = props
    const [editMode, setEditMode] = useState(false)
    const [role, setRole] = useState(user.role === "Admin" ? "Admin" : "User")

    const setAdminStatus = () => {
        if (role === user.role) {
            setEditMode(false);
            return void 0;
        }

        const errorFeedback = document.getElementById(`set-role-error-${user.user_id}`)
        axios.post("users/set_admin", {
            user_id: user.user_id,
            is_admin: role === "Admin",
        }, {
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            setEditMode(false);
            if ((selectedFilter === 'Admin' || selectedFilter === 'User') && (role !== selectedFilter)) {

                const newFilteredUsers = filteredUsers.filter(p => p.user_id !== user.user_id);
                setFilteredUsers(newFilteredUsers);
            }
            const newUsers = [...users]
            const userIndex = users.findIndex(x => x.user_id === user.user_id);
            newUsers[userIndex].role = role;
            setUsers(newUsers);
        }).catch(err => {
            if (err.response && err.response.data.message) {
                errorFeedback.innerHTML = err.response.data.message
                removeFeedback(`set-role-error-${user.user_id}`)
            }
        })
    }
    return (
        <div key={user.user_id} className="card mb-2 bg-light">
            <div className="card-body">
                <div className="mb-2 d-flex align-items-center flex-nowrap">
                    <h4 className="d-inline mr-lg-5">
                        {user.display_name}
                        <span className="small">
                            {user.role && (<>&nbsp;({user.role}) </>)}
                            {user.user_status === 'N' && (<span className="text-danger">&nbsp;- Disabled</span>)}

                        </span>
                    </h4>
                    {isAdmin && user.user_status === 'Y' ?
                        <div className="flex-grow-1 ml-5">
                            {editMode ?
                                <div className="d-flex justify-content-end align-items-center">
                                    <span className="d-inline-block mr-4">
                                        <span className="form-inline">
                                            <label className="small" htmlFor={`role-${user.id}`}>Role: &nbsp;</label>
                                            <select id={`role-${user.id}`} className="form-control" style={{ fontSize: ".8rem" }} value={role} onChange={e => setRole(e.target.value)}>
                                                <option value="User">User</option>
                                                <option value="Admin">Admin</option>
                                            </select>
                                        </span>
                                    </span>

                                    <span>
                                        <button type="button" className="btn btn-sm small-button btn-danger mr-1" onClick={() => { setEditMode(false) }}>
                                            <i className="material-icons small-icon">clear</i>
                                        </button>
                                        <button type="button" className="btn btn-sm small-button btn-success" onClick={setAdminStatus}>
                                            <i className="material-icons small-icon">done</i>
                                        </button>
                                    </span>
                                </div>
                                :
                                <button type="button" className="btn btn-sm small-button btn-warning float-right" onClick={() => { setEditMode(true) }}>
                                    <i className="material-icons small-icon">edit</i>
                                </button>
                            }
                        </div>
                        : null
                    }
                </div>
                <div className="mt-2">
                    <div className="mb-0 d-flex align-items-center flex-nowrap justify-content-between">
                        <p className="mb-0 d-inline mr-lg-5 w-50">
                            <span className="font-weight-bold small">
                                First Name:
                            </span>
                            <span className="small">
                                &nbsp;{user.first_name ? user.first_name : '--'}
                            </span>
                        </p>
                        <p className="mb-0 d-inline mr-lg-5 w-50">
                            <span className="font-weight-bold small">
                                ID:
                            </span>
                            <span className="small">
                                &nbsp;{user.workday_id ? user.workday_id : '--'}
                            </span>
                        </p>
                    </div>

                    <div className="mb-0 d-flex align-items-center flex-nowrap justify-content-between">
                        <p className="mb-0 d-inline mr-lg-5 w-50">
                            <span className="font-weight-bold small">
                                Last Name:
                            </span>
                            <span className="small">
                                &nbsp;{user.last_name ? user.last_name : '--'}
                            </span>
                        </p>
                        <p className="mb-0 d-inline mr-lg-5 w-50">
                            <span className="font-weight-bold small">
                                User Status:
                            </span>
                            <span className="small">
                                &nbsp;{user.user_status === 'Y' ? 'Active' : 'Disabled'}
                            </span>
                        </p>
                    </div>

                    <div className="mb-0 d-flex align-items-center flex-nowrap justify-content-between">
                        <p className="mb-0 d-inline mr-lg-5 w-50">
                            <span className="font-weight-bold small">
                                Department:
                            </span>
                            <span className="small">
                                &nbsp;{user.department ? user.department : '--'}
                            </span>
                        </p>
                        <p className="mb-0 d-inline mr-lg-5 w-50">
                            <span className="font-weight-bold small">
                                Employee Status:
                            </span>
                            <span className="small">
                                &nbsp;{user.employee_status ? user.employee_status : '--'}
                            </span>
                        </p>
                    </div>

                    <div className="mb-0 d-flex align-items-center flex-nowrap justify-content-between">
                        <p className="mb-0 d-inline mr-lg-5 w-50">
                            <span className="font-weight-bold small">
                                Location:
                            </span>
                            <span className="small">
                                &nbsp;{user.location ? user.location : '--'}
                            </span>
                        </p>
                    </div>

                    <div className="mb-0 d-flex align-items-center flex-nowrap justify-content-between">
                        <p className="mb-0 d-inline mr-lg-5 w-50">
                            <span className="font-weight-bold small">
                                Email:
                            </span>
                            <span className="small">
                                &nbsp;{user.email ? user.email : '--'}
                            </span>
                        </p>
                        <p className="mb-0 d-inline mr-lg-5 w-50">
                            <span className="font-weight-bold small">
                                Created On:
                            </span>
                            &nbsp;{user.create_date ? (<>
                                <span className="small" id={`user-${user.user_id}-created-on-date`}>
                                    {moment(user.create_date).utc().format(DATEFORMATUTC)}
                                </span>
                                <UncontrolledTooltip
                                    placement="bottom"
                                    target={`user-${user.user_id}-created-on-date`}
                                >
                                    {moment(user.create_date).local().format(DATEFORMATLOCAL)}
                                </UncontrolledTooltip>
                            </>) : "--"}
                        </p>
                    </div>

                    <div className="mb-0 d-flex align-items-center flex-nowrap justify-content-between">
                        <p className="mb-0 d-inline mr-lg-5 w-50">
                            <span className="font-weight-bold small">
                                Phone:
                            </span>
                            <span className="small">
                                &nbsp;{user.phone ? user.phone : '--'}
                            </span>
                        </p>
                        <p className="mb-0 d-inline mr-lg-5 w-50">
                            <span className="font-weight-bold small">
                                Disabled On:
                            </span>
                            &nbsp;{user.user_status === 'N' && user.revoked_date ? (<>
                                <span className="small" id={`user-${user.user_id}-disable-on`}>
                                    {moment(user.revoked_date).utc().format(DATEFORMATUTC)}
                                </span>
                                <UncontrolledTooltip
                                    placement="bottom"
                                    target={`user-${user.user_id}-disable-on`}
                                >
                                    {moment(user.revoked_date).local().format(DATEFORMATLOCAL)}
                                </UncontrolledTooltip>
                            </>) : '--'}
                        </p>
                    </div>

                    <div className="mb-0 d-flex align-items-center flex-nowrap justify-content-between">
                        <p className="mb-0 d-inline mr-lg-5 w-50">
                            <span className="font-weight-bold small">
                                GUID:
                            </span>
                            <span className="small">
                                &nbsp;{user.guid ? user.guid : '--'}
                            </span>
                        </p>
                        <p className="mb-0 d-inline mr-lg-5 w-50">
                            <span className="font-weight-bold small">
                                Last Login:
                            </span>
                            &nbsp;{user.last_login_time ? (<>
                                <span className="small" id={`user-${user.user_id}-last-login-time`}>
                                    {moment(user.last_login_time).utc().format(DATEFORMATUTC)}
                                </span>
                                <UncontrolledTooltip
                                    placement="bottom"
                                    target={`user-${user.user_id}-last-login-time`}
                                >
                                    {moment(user.last_login_time).local().format(DATEFORMATLOCAL)}
                                </UncontrolledTooltip>
                            </>) : "--"}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserRow;
