import React, { useState, useEffect } from 'react';
import RequestReviewTab from './RequestReviewTab';
import RequestTypeWrapper from '../RequestTypeWrapper';


export const RequestReviewSetting = React.memo((parentprops) => {
    return (<RequestTypeWrapper
        title={"REQUEST REVIEW SETTINGS"}
        routePath={"requestreviewsettings"}
        
        renderTabContent={(props) => {
            return <RequestReviewTab
                workflow={"request"}
                noteContent={()=>{
                    return (<>
                        Note: If the check is enabled, then all the requests which has PI attributes greater than the threshold count are moved to 'Review' status without <b>ingesting</b> the request to CPS.
                    </>)
                }}
                {...props} />
        }}
        {...parentprops}
    />)
})
export const ResponseReviewSetting = React.memo((parentprops) => {
    return (<RequestTypeWrapper
        title={"RESPONSE REVIEW SETTINGS"}
        routePath={"responsereviewsettings"}
        renderTabContent={(props) => {
            return <RequestReviewTab
                workflow={"response"}
                noteContent={()=>{
                    return (<>
                        Note: If the check is enabled, then all the requests which has PI attribute mismatch between request and response are moved to 'Review' status without <b>uploading</b> the response to TIPS.
                    </>)
                }}
                {...props} />
        }}
        {...parentprops}
    />)
})
