
import { Button, IconButton, Snackbar, TextField, Tooltip, Typography, makeStyles } from '@material-ui/core';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Alert } from '@material-ui/lab';
import { AppContext } from '../../../../RoutesWithAuth';
import axios from '../../../common/AxiosConfig';
import { LoadingIndicator, Tab, Tabs } from '../reports/reconcilationreport/MuiStyled';
import { DATEFORMATLOCAL, DATEFORMATUTC, feedbackTimeout, v2UIRoutePath } from '../../../common/commons';
import DataGrid from '../grid';
import moment from 'moment';
import RowEditActions from '../grid/RowEditActions';
import DeleteDialog from '../admin/settings/DeleteDialog';
import FormGroup from '../FormGroup';
import ViewRequest from './ViewRequest';
import { Close, Edit } from '@material-ui/icons';


const COLUMNS = [{
    name: "#",
    mapping: 'id',
    disableColumnMenu: true,
    width: 55
}, {
    name: "Request Id",
    mapping: 'dsar_id',
    width: 334,
    renderCell: (params) => {
        return (<Link>
            {params.row["dsar_id"]}
        </Link>)
    },
    disableColumnMenu: true
}, {
    name: "Requestor",
    mapping: 'requestor',
    width: 100,
    disableColumnMenu: true,
}, {
    name: "Request Type",
    mapping: 'ticket_type',
    width: 160,
    disableColumnMenu: true,

}, {
    name: "Received Date Time",
    mapping: "created_date_UTC",
    width: 180,
    renderCell: (params) => (
        <Tooltip title={params.row["created_date_Local"]}>
            <span>
                {params.row["created_date_UTC"]}
            </span>
        </Tooltip>
    ),
    disableColumnMenu: true
}, {
    name: "Last Retried Time",
    mapping: "updated_date_UTC",
    width: 180,
    renderCell: (params) => (
        <Tooltip title={params.row["updated_date_Local"]}>
            <span>
                {params.row["updated_date_UTC"]}
            </span>
        </Tooltip>
    ),
    disableColumnMenu: true
}, {
    name: "# of Retries",
    mapping: 'retry_attempts',
    width: 100,
    disableColumnMenu: true,

}, {
    name: "Error Message",
    mapping: 'error',
    width: 160,
    disableColumnMenu: true,
    displayTootip: ["error"]
}, {

    name: "Status",
    mapping: 'ingest_status',
    width: 80,
    disableColumnMenu: true
}, {
    name: "Manual Retry",
    mapping: 'manual_retry',
    width: 120,
    disableColumnMenu: true,

}, {
    name: "Manual Review",
    mapping: 'manual_review',
    width: 130,
    disableColumnMenu: true
}, {
    name: "Delete",
    mapping: 'actions_delete',
    disableColumnMenu: true,
    width: 80,
    editable: false,
    align: 'center',
    headerAlign: 'center',
}]
// Custom page Level Styling
const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flex: '1',
        height: '100%'

    },
    actionButton: {
        '& .MuiButton-label': {
            fontSize: '0.7rem'
        }
    },
    accordianTitle: {
        fontWeight: 'bold'
    },
    dialogWrapper: {
        width: '860px'
    },
    dialogheader: {
        textAlign: 'center',
        fontSize: '0.75rem',
        '& span': {
            fontWeight: 'bold'
        },
        '& .modelheader': {
            paddingBottom: '5px',
            fontWeight: 'bold'
        }
    },
    deleteDialogContext: {
        width: '300px',
        height: '90px',
        '& .errorMessage': {
            fontSize: '11px',
            color: 'red'
        },
        '& .MuiFormControl-root': {
            height: '100%',
            width: '100%',
        },
        '& .MuiInputLabel-root': {
            marginLeft: '10px',
            color: 'black',
            '& .MuiFormLabel-asterisk': {
                float: 'left',
                paddingRight: '2px',
                color: 'red'
            }
        },
        '& .MuiInputBase-root': {
            border: '1px solid #95a3bd',
            borderRadius: '10px',
            marginTop: '21px',
        },
        '& .MuiInput-underline:before,.MuiInput-underline:after,.MuiInput-underline:hover:not(.Mui-disabled):before': {
            border: '0px'
        }
    },
    formGroupWrapper: {
        width: '600px',
        marginTop: '10px',
    },
    tabCloseIcon : {
        marginLeft: '10px',
        color: 'gray',
        fontSize: '1rem',
        marginTop: '-3px'
    }
}));

const RequestQueue = () => {
    const classes = useStyles();
    // State to handle the Main Tab Information
    const [activeTab, setActiveTab] = useState(0);

    const [queued_response, setQueueResponse] = useState([]);
    const [queued_requests_grid_data, setQueueGridData] = useState([]);
    const [displayErrorMsg, setDisplayErrorMsg] = useState("");
    const [successMsg, setSuccessMsg] = useState("");
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [dialogErrorStatus, setDialogErrorStatus] = useState(false);
    const [deleteDialogRowInfo, setDeleteDialogRowInfo] = useState(false);
    const [viewRequestRecord, setViewRequestRecord] = useState(null);
    const location = useLocation();
    //state for maintaining form related data
    const [formData, setFormData] = useState({
        status: location?.state?.status || 'All'
    });
    
    // State to handle the Progress status
    const [isInProgress, setIsInProgress] = useState(false);

    const context = useContext(AppContext)
    const deleteTextInputRef = useRef();
    const formGroupRef = useRef();


    const authToken = context.authToken.get
    const email = context.email.get

    useEffect(() => {

        // resetting the Form when there is change in the Location state.
        formGroupRef.current.resetFormToDefaults();
        setFormData({
            status: location?.state?.status || 'All'
        })
        fetchData();
        console.log(location?.state?.status);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location?.state?.fromredirection])

    useEffect(() => {
        let queuedFiltered = queued_response.filter(request => {
            if (formData.status === "All" || request.ingest_status === formData.status) {
                return true
            }
            return false;
        })
        setQueueGridData(queuedFiltered.map((queued_data, index)=>{
            return {
                ...queued_data,
                id : index + 1
            }
        }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queued_response, formData])

    const fetchData = () => {
        setIsInProgress(true)
        axios.get("queued_requests", {
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            let requestQueueResponse = res.data;
            // Formating the report based on the Grid Required format
            requestQueueResponse = requestQueueResponse?.map((_record, index) => {
                return {
                    ..._record,
                    id: index + 1,
                    created_date_Local: _record["created_date"] ? moment(_record["created_date"]).local().format(DATEFORMATLOCAL) : '--',
                    created_date_UTC: _record["created_date"] ? moment(_record["created_date"]).utc().format(DATEFORMATUTC) : '--',
                    updated_date_Local: _record["updated_date"] ? moment(_record["updated_date"]).local().format(DATEFORMATLOCAL) : '--',
                    updated_date_UTC: _record["updated_date"] ? moment(_record["updated_date"]).utc().format(DATEFORMATUTC) : '--',
                }
            })
            setQueueResponse(requestQueueResponse)
            setIsInProgress(false)
        }).catch(err => {
            setIsInProgress(false)
        })
    }
    const handleDeleteRow = (delete_id, rowInfo) => {
        setDeleteDialogRowInfo(rowInfo)
        setDialogErrorStatus(false);
        setOpenDeleteDialog(true);
    }
    const handleClose = () => {
        setDisplayErrorMsg("");
        setSuccessMsg("");
    }
    const handleDeleteDialogClose = () => {
        setOpenDeleteDialog(false);
    }
    const handleDeleteDialogSubmit = () => {
        if (deleteTextInputRef.current.value.length < 10) {
            setDialogErrorStatus(true);
            return;
        }
        setDialogErrorStatus(false);
        setIsInProgress(true);
        axios.delete(`/requests/incoming/${deleteDialogRowInfo.dsar_id}`, {
            headers: {
                Authorization: authToken
            },
            data: {
                ...deleteDialogRowInfo,
                deletion_reason: deleteTextInputRef.current.value.trim(),
                deletion_by: email
            }
        }).then(res => {
            setIsInProgress(false);

            setOpenDeleteDialog(false);
            fetchData();
        }).catch(err => {
            setIsInProgress(false);

            console.log(err)
        })
    }
    const manualRetry = (dsar_id) => {
        setIsInProgress(true)

        axios.post(`/queued_requests/${dsar_id}/retry`, {}, {
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            setSuccessMsg("Successful retry!")
            setTimeout(function () { fetchData() }, feedbackTimeout)
        }).catch(err => {
            if (err?.response?.data?.message) {
                setDisplayErrorMsg(err.response.data.message)
            }
            setTimeout(function () { fetchData() }, feedbackTimeout)

        }).finally(() => {
            setIsInProgress(false)
        })
    }
    const manualReview = (dsar_id) => {
        setIsInProgress(true)

        axios.post(`/queued_requests/${dsar_id}/approve`, {}, {
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            setSuccessMsg("Successful approve!")
            setTimeout(function () { fetchData() }, feedbackTimeout)
        }).catch(err => {
            if (err.response && err.response.data && err.response.data.error) {
                setDisplayErrorMsg(err.response.data.error)
            }
        }).finally(() => {
            setIsInProgress(false)
        })
    }
    const dataGridColumns = () => {
        return COLUMNS.map((column) => {
            if (column.mapping === "actions_delete") {
                column.renderCell = (params) => {
                    return <RowEditActions {...params} deleteActionCallBack={handleDeleteRow} enableDeleteAction={true} />
                }
            } else if (column.mapping === "manual_retry") {
                column.renderCell = (params) => {
                    return (<Button
                        variant="contained"
                        color="primary"
                        className={classes.actionButton}
                        onClick={() => {
                            manualRetry(params.row["dsar_id"])
                        }}
                        disabled={["review", "received"].includes(params.row["ingest_status"]?.toLowerCase())}
                    >
                        Retry
                    </Button>)
                }
            } else if (column.mapping === "manual_review") {
                column.renderCell = (params) => {
                    return (<Button
                        variant="contained"
                        color="primary"
                        className={classes.actionButton}
                        onClick={() => {
                            manualReview(params.row["dsar_id"])
                        }}
                        disabled={(params.row["ingest_status"]?.toLowerCase() !== "review")}
                    >
                        Approve
                    </Button>)
                }
            }
            return column
        });
    }
    return (
        <div className={classes.root}>
            {isInProgress && (<>
                <LoadingIndicator />
            </>)}
            <Snackbar open={!!displayErrorMsg} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    {displayErrorMsg}
                </Alert>
            </Snackbar>
            <Snackbar open={!!successMsg} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                    {successMsg}
                </Alert>
            </Snackbar>
            <Typography variant="h6" className={'scopeHeader'}>
                <Link color="primary" to={() => {
                    // Generating a Random Id and passing it as part of state 
                    // Every click on the Header will generate a unique Id
                    const autoId = Math.round(Math.random() * 1e5);

                    return {
                        pathname: v2UIRoutePath + "tracking/requestqueue",
                        state: { fromredirection: autoId }
                    }
                }}
                >
                    Request Processing
                </Link>
            </Typography>
            <Tabs
                variant="scrollable"
                aria-label="tabs"
                value={activeTab}
                onChange={(_event, newValue) => {
                    setActiveTab(newValue);

                }}
                sx={{ borderRight: 1, borderColor: 'divider' }}
            >
                <Tab label="Request Queue" />
                {viewRequestRecord && (<Tab label={(<span>
                    View Request
                    <IconButton
                        className={classes.tabCloseIcon}
                        size="small"
                        aria-label="close"
                        onClick={(e)=>{
                            e.stopPropagation();
                            setActiveTab(0);
                            setViewRequestRecord(null);
                        }}
                    >
                        <Close fontSize="small" />
                    </IconButton>
                </span>)} />)}
            </Tabs>
            {activeTab === 0 && (
                <><FormGroup
                    formWrapperClassName={classes.formGroupWrapper}
                    ref={formGroupRef}
                    defaultFormData={formData}
                    fields={[{
                        type: 'multiselect',
                        name: 'status',
                        label: 'Status',
                        removeCheckBox: true,
                        isMulti: false,
                        customInputCls: classes.customInputCls,
                        options: ["All", "Failed", "Received", "Review", "Retrying"],
                        defaultOption: 'All',
                        size: 4
                    }]}
                    disableFormActions={true}
                    customFormUpdates={(_formData) => {

                        // This Method is called when ever there is any change in the form fields
                        setFormData(_formData)
                    }}
                />
                    <DataGrid
                        onCellClick={(cellparams) => {
                            if (cellparams?.colDef?.mapping === "dsar_id") {
                                setViewRequestRecord(cellparams.row);
                                setActiveTab(1);
                            }
                        }}
                        columns={dataGridColumns()}
                        rows={queued_requests_grid_data || []}
                        customRowHeight={60}
                    />
                </>
            )}
            {activeTab === 1 && (
                <>
                    <ViewRequest
                        fetchRequestQueueData={(record)=>{
                            fetchData();
                            // Updating the request record to latest edited dsar_id
                            setViewRequestRecord({
                                ...viewRequestRecord,
                                dsar_id : record
                            })
                        }}
                        displayErrorMessage={(msg)=>{
                            setDisplayErrorMsg(msg);
                        }}
                        dsar_id={viewRequestRecord?.dsar_id}
                    />
                </>
            )}
            <DeleteDialog
                openDeleteDialog={openDeleteDialog}
                handleDeleteDialogClose={handleDeleteDialogClose}
                title={"Delete Request"}
                handleDeleteDialogSubmit={handleDeleteDialogSubmit}
            >
                <div className={classes.deleteDialogContext}>
                    <TextField
                        required
                        id="standard-multiline-static"
                        label="Please enter a reason for deletion"
                        multiline
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ ref: deleteTextInputRef }}
                        rows={4}
                        defaultValue=""

                    />
                    {dialogErrorStatus && (<div className="errorMessage">{"Message should be at least 10 chars"}</div>)}
                </div>
            </DeleteDialog>
        </div>
    )
};

export default React.memo(RequestQueue);