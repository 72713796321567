import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from '../../../RoutesWithAuth';
import { CSSTransition } from 'react-transition-group';
import PageHeader from '../../common/PageHeader';
import { withNavbar } from '../../common/Navbar';
import axios from '../../common/AxiosConfig';
import ProcessingRequestRow from './ProcessingRequestRow';


function ProcessingRequests() {
    const context = useContext(AppContext)
    const authToken = context.authToken.get
    const isAdmin = context.isAdmin.get
    const email = context.email.get

    const [requests, setRequests] = useState([])
    const [filteredRequests, setFilteredRequests] = useState([])
    const [filter, setFilter] = useState("All")
    const [showNullMsg, setShowNullMsg] = useState(false)
    const [isLoading, setIsLoading] = useState(false);

    
    useEffect(() => {
        if (authToken !== null) {
            fetchData()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authToken])

    useEffect(() => {
        setFilteredRequests(
            requests.filter(request => {
                if(filter === "All" || request.ingest_status === filter){
                    return true
                }
                return false;
            })
        )
    }, [requests, filter])

    const fetchData = () => {
        setIsLoading(true);
        axios.get(`/queued_requests`, {
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            setIsLoading(false);
            setRequests(res.data)
            if (res.data.length === 0) { setShowNullMsg(true) }
        }).catch(err => {
            setIsLoading(false);
            console.log(err)
        })
    }

    return (       
        <div className="container mb-5">

            <PageHeader header="Requests Queued for Processing" />

            <div className="d-flex align-items-center mb-3 mt-4">
                <div className="form-inline mr-auto">
                    <label className="small mr-1" htmlFor="requestFilter">Filter:</label>
                    <select className="form-control form-control-sm" id="keyFilter" value={filter} onChange={e => { setFilter(e.target.value) }}>
                        <option>All</option>
                        <option>Failed</option>
                        <option>Received</option>
                        <option>Review</option>
                        <option>Retrying</option>
                    </select>
                </div>
            </div>

            <table className="table table-striped table-sm text-center" >
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Request ID</th>
                        <th scope="col">Requestor</th>
                        <th scope="col">Request Type</th>
                        <th scope="col">Received Time</th>
                        <th scope="col">Last Retried Time</th>
                        <th scope="col"># of Retries</th>
                        <th scope="col">Error Message</th>
                        <th scope="col">Status</th>
                        <th scope="col">Manual Retry</th>
                        <th scope="col">Manual Review</th>
                        { isAdmin && ( <th scope="col">Delete Request</th> )}
                    </tr>
                </thead>
                <CSSTransition in={filteredRequests.length > 0} appear={true} timeout={125} classNames="swipeD">
                    <tbody>
                    { isLoading && (<><tr></tr><tr>
                            <td colSpan={10}>
                                <div class="d-flex justify-content-center">
                                    <img alt="loading" className="loading-spin" src={"/rotate_right_black_24dp.svg"} width="120"></img>
                                </div>
                            </td>
                        </tr></>)}
                        {filteredRequests.map((request, i) => (
                            <ProcessingRequestRow key={request.dsar_id} serialNumber={i + 1}
                                data={request} fetchData={fetchData} authToken={authToken} email={email} isAdmin={isAdmin}
                            />
                        ))}
                    </tbody>
                </CSSTransition>
            </table>
            {showNullMsg ?
                <CSSTransition in={showNullMsg} appear={true} timeout={125} classNames="swipeD">
                    <div className="mt-5">
                        <p className="h3 text-center mt-4">No Requests Queued</p>
                        <p className="text-muted text-center">There are no more requests waiting to be created, hooray!</p>
                    </div>
                </CSSTransition>
                :
                null
            }
        </div>
        
    );
}

export default withNavbar(ProcessingRequests, 3);
