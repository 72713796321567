/**
 * Constants specific to Reconcilreport
 */
import React from 'react';
import { Link } from 'react-router-dom';
import { RequestTypeMapping, RequestorMapping, v2UIRoutePath } from '../../../../common/commons';

// Columns Specific to TIPS
export const COLUMNSTIPS = [{
    name: "#",
    mapping: "rowId",
    disableColumnMenu: true,
    width: 40
}, {
    name: 'Request ID',
    mapping: "requestId",
    width: 420,
    disableColumnMenu: true,
    renderCell: (params) => {

        // Render HyperLink to request details when request is Present in CPS
        if (params.row["isPresentcps"] === "Yes") {
            return (<Link to={`${v2UIRoutePath}requestdetails/` + params.row["requestId"]}>
                {params.row["requestId"]}
            </Link>)
        }
        // Render plain Request ID when request is not present in CPS
        return params.row["requestId"]
    }
}, {
    name: 'Request Type',
    mapping: "requestType",
    width: 150,
    type: 'singleSelect',
    valueOptions: Object.keys(RequestTypeMapping),
    customSingleSelectFilter: true
}, {
    name: 'Requestor',
    mapping: "requestor",
    width: 130,
    type: 'singleSelect',
    valueOptions: Object.values(RequestorMapping),
    customSingleSelectFilter: true
}, {
    name: 'Status in TIPS',
    mapping: "status_tips",
    width: 180,
    type: 'singleSelect',
    valueOptions: [
        'RESPONSE_PENDING',
        'REQUEST_PENDING_SUBMISSION',
        'RESPONSE_PENDING_ATTACHMENTS_FETCH',
        'RECEIVED_RESPONSE_ATTACHMENTS',
        'RECEIVED_RESPONSE'
    ],
    customSingleSelectFilter: true
}, {
    name: 'Status in CPS',
    mapping: "status_cps",
    width: 180,
    valueOptions: [
        'Open',
        'Upload Failed',
        'Uploaded'
    ],
    type: 'singleSelect',
    customSingleSelectFilter: true
}, {
    name: 'Creation Time in TIPS',
    mapping: 'createdonTips',
    width: 180,
    disableColumnMenu: true,
}, {
    name: 'Creation Time in CPS',
    width: 180,
    mapping: 'createdoncps',

    disableColumnMenu: true
}, {
    name: 'Request in TIPS',
    mapping: 'isPresentTips',
    width: 140,
    type: 'singleSelect',
    valueOptions: ['Yes', 'No'],
    customSingleSelectFilter: true,

}, {
    name: 'Request in CPS',
    mapping: 'isPresentcps',
    width: 140,
    type: 'singleSelect',
    valueOptions: ['Yes', 'No'],
    customSingleSelectFilter: true

}, {
    name: 'Status Mismatch CPS-TIPS',
    mapping: 'isStatusMismatch',
    width: 200,
    type: 'singleSelect',
    valueOptions: ['Yes', 'No'],
    customSingleSelectFilter: true
}];

// Columns Specific to QB64
export const COLUMNSQB64 = [{
    name: "#",
    mapping: "rowId",
    disableColumnMenu: true,
    width: 40
}, {
    name: 'Request ID',
    mapping: "requestId",
    width: 420,
    disableColumnMenu: true,
    renderCell: (params) => {
        // Render HyperLink to request details when request is Present in CPS
        if (params.row["isPresentcps"] === "Yes") {
            return (<Link to={`${v2UIRoutePath}requestdetails/` + params.row["requestId"]}>
                {params.row["requestId"]}
            </Link>)
        }
        // Render plain Request ID when request is not present in CPS
        return params.row["requestId"]
    }
}, {
    name: 'Request Type',
    mapping: "requestType",
    width: 150,
    type: 'singleSelect',
    valueOptions: Object.keys(RequestTypeMapping),
    customSingleSelectFilter: true
}, {
    name: 'Requestor',
    mapping: "requestor",
    width: 130,
    type: 'singleSelect',
    valueOptions: ['TCI Customer'],
    customSingleSelectFilter: true
}, {
    name: 'Status in QB64',
    mapping: "status_qb64",
    width: 180,
    type: 'singleSelect',
    valueOptions: [
        'RESPONSE_PENDING',
        'REQUEST_PENDING_SUBMISSION',
        'RESPONSE_PENDING_ATTACHMENTS_FETCH',
        'RECEIVED_RESPONSE'
    ],
    customSingleSelectFilter: true
}, {
    name: 'Status in CPS',
    mapping: "status_cps",
    width: 180,
    valueOptions: [
        'Open',
        'Upload Failed',
        'Uploaded'
    ],
    type: 'singleSelect',
    customSingleSelectFilter: true
}, {
    name: 'Creation Time in QB64',
    mapping: 'createdonQB64',
    width: 180,
    disableColumnMenu: true,
}, {
    name: 'Creation Time in CPS',
    mapping: 'createdoncps',
    width: 180,
    disableColumnMenu: true
}, {
    name: 'Request in QB64',
    mapping: 'isPresentQB64',
    width: 150,
    type: 'singleSelect',
    valueOptions: ['Yes', 'No'],
    customSingleSelectFilter: true,

}, {
    name: 'Request in CPS',
    mapping: 'isPresentcps',
    width: 150,
    type: 'singleSelect',
    valueOptions: ['Yes', 'No'],
    customSingleSelectFilter: true

}, {
    name: 'Status Mismatch CPS-QB64',
    mapping: 'isStatusMismatch',
    type: 'singleSelect',
    width: 200,
    valueOptions: ['Yes', 'No'],
    customSingleSelectFilter: true
}];

// Display Fields Specific to QB64
export const displayFieldsQB64 = [{
    name: 'qb64_source_file',
    label: 'QB64 Source File:',
}, {
    name: 'dsars_in_qb64_request_pending',
    label: 'DSARs in QB64 (REQUEST_PENDING_SUBMISSION):'
}, {
    name: 'dsars_in_qb64_others',
    label: 'DSARs in QB64 (OTHERS):'
}, {
    name: 'dsars_in_cps',
    label: 'DSARs in CPS:'
}, {
    name: 'report_generated_from',
    label: 'DSARs Created From Date:'
}, {
    name: 'report_generated_to',
    label: 'DSARs Created To Date:'
}]
// Display Fields Specific to TIPS
export const displayFieldsTips = [{
    name: 'tips_source_file',
    label: 'TIPS Source File:',
}, {
    name: 'dsars_in_tips_request_pending',
    label: 'DSARs in TIPS (REQUEST_PENDING_SUBMISSION):'
}, {
    name: 'dsars_in_tips_others',
    label: 'DSARs in TIPS (OTHERS):'
}, {
    name: 'dsars_in_cps',
    label: 'DSARs in CPS:'
}, {
    name: 'report_generated_from',
    label: 'DSARs Created From Date:'
}, {
    name: 'report_generated_to',
    label: 'DSARs Created To Date:'
}]