import React from 'react';
import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"

function Swagger() {
    return (
        <div>
            <SwaggerUI url={`${process.env.REACT_APP_BASE_URL}/documentation`} />
        </div>
    )
}

export default Swagger;