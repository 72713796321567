import { Box, makeStyles } from '@material-ui/core';
import React from 'react';
import ListOfItems from '../ListOfItems';
import ResponseJsonContent from './ResponseJsonContent';
import { RESPONSE_ACTION_MAPPING } from '../applications/constants';

const useStyles = makeStyles(() => ({
    root: {
        '& .listOfItemsCls': {
            marginTop: "0px",
            marginLeft: "30px"
        }
    }
}));
const GuidDetails = ({ guidDetails, responseData, tabInfo }) => {
    const classes = useStyles();

    return (<Box height={`calc(100% - 150px)`} className={classes.root} overflow={"auto"} margin={"10px"} border={"1px solid #cdcbcc"}>
        {guidDetails.map((guidDetail) => {
            return (
                <>
                    <ListOfItems
                        items={[{
                            display: "GUID",
                            mapping: "guid"
                        }, {
                            display: "CREATED DATE",
                            mapping: "created_date_utc",
                            tooltip: "created_date_local"
                        }, {
                            display: "RESPONSE RECEIVED",
                            mapping: "updated_date_utc",
                            tooltip: "updated_date_local"
                        }, {
                            display: "STATUS",
                            mapping: "status",
                            optionset: RESPONSE_ACTION_MAPPING
                        }, {
                            display: "",
                            type: "empty"
                        }, {
                            display: "",
                            type: "empty"
                        }]}
                        data={guidDetail}
                    />
                    <ResponseJsonContent
                        customHeaderStyles={{
                            border: '1px solid #cdcbcc',
                            height: "auto",
                            overflowY: "hidden"
                        }}
                        responseData={{
                            ...guidDetail,
                            app_name: `${responseData.app_name}_${guidDetail.guid}`
                        }}
                        tabInfo={tabInfo}
                    />
                </>
            )
        })}
    </Box>)
}
export default React.memo(GuidDetails);