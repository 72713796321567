import React, { useEffect, useContext, useState, useRef } from 'react';
import { AppContext } from './RoutesWithAuth';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import axios from './components/common/AxiosConfig';
import IdleTimer from 'react-idle-timer';
import { Auth } from 'aws-amplify';

import { getAuthToken, returnable, loadingScreen } from './components/common/AuthHelper';

import Home from './components/routes/home/Home'
import NewHomeUI from './components/routes/newUI/Home';
import NewAdminUI from './components/routes/newUI/Admin';
import Reports from './components/routes/newUI/Reports';
import CreateRequest from './components/routes/CreateRequest';
import RequestDetails from './components/routes/requestDetails/RequestDetails';
import MarkRequestCreated from './components/routes/MarkRequestCreated';
import CreateResponse from './components/routes/CreateResponse';
import AuditTrail from './components/routes/AuditTrail';
import ViewJSON from './components/routes/ViewJSON';
import ViewResponse from './components/routes/ViewResponse';
import ViewResponseFiles from './components/routes/ViewResponseFiles';
import UpdateResponse from './components/routes/UpdateResponse';
import GuidDetails from './components/routes/GuidDetails';
import ContactsTab from './components/routes/contacts/Contacts';
import LinksPage from './components/routes/Documents';
import Health from './components/routes/CPSHealth';
import Automation from './components/routes/apps/Apps';
import MasterAttributeKeys from './components/routes/rtkDataDictionary/RtkDataDictionary';
import BulkAssign from './components/routes/BulkAssign';
import Users from './components/routes/users/Users';
import Retention from './components/routes/retention/Retention';
import LitigationHold from './components/routes/litigationHold/LitigationHold'
import ProcessingRequests from './components/routes/processingRequests/ProcessingRequests';
import CtpAggregator from './components/routes/ctpAggregator/CtpAggregator';
import Sessiontimeout from './components/common/Sessiontimeout';
import AutoCloseSettings from './components/routes/autocloseSettings/AutoCloseSettings';
import { ThemeProvider } from '@material-ui/core';
import newUITheme from './components/routes/newUI/theme';
import { v2UIRoutePath } from './components/common/commons';
import Tracking from './components/routes/newUI/tracking';
import RequestDetailView from './components/routes/newUI/requestdetails/DetailsView';

function Routes(props) {
    const { authState } = props;

    const context = useContext(AppContext);

    const authToken = context.authToken.get
    const isAdmin = context.isAdmin.get
    const validUserContext = context.validUser.get
    const [usersList, setUsersList] = useState(null)
    const [modelIsOpen, setModelIsOpen] = useState(false);
    const IdleTimerRef = useRef(null);
    const sessionTimeOutRef = useRef(null);
    const [seconds, setSeconds] = useState(60);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        if (seconds > 0 && modelIsOpen) {
            setTimeout(() => {
                setSeconds(seconds - 1);
            }, 1000);
        } else {
            setSeconds(60);
        }
    }, [seconds, modelIsOpen])
    const onIdle = () => {
        setModelIsOpen(true);
        sessionTimeOutRef.current = setTimeout(signOut, (60 * 1000 * 1));
    }
    const stayActive = () => {
        setSeconds(60);
        IdleTimerRef.current.reset();
        setModelIsOpen(false);
        clearTimeout(sessionTimeOutRef.current);
    }

    const signOut = () => {
        Auth.signOut().catch(err => {
            console.log(err)
        })
    }
    const loadAppDetails = () => {
        axios.get('apps/all', {
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            let appsData = res.data;
            appsData.sort((a, b) => {
                    const x = a.display.toLowerCase();
                    const y = b.display.toLowerCase();
                    if (x < y) { return -1; }
                    if (x > y) { return 1; }
                    return 0;
                })
            context.apps.set(appsData)
        }).catch(err => {
            context.apps.set([])
            console.log('There was an error fetching App Details!', err)
        })
    }
    useEffect(() => {
        if (authToken !== null) {
            setLoading(true);
            loadAppDetails();
            axios.get('users', {
                headers: {
                    Authorization: authToken
                }
            }).then(res => {
                setUsersList(res.data)
                setLoading(false);
            }).catch(err => {
                setLoading(false);
                console.log('There was an error fetching users!', err)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authToken, isAdmin])

    useEffect(() => {
        if (context.authToken.get === null) {
            setLoading(true);
            getAuthToken(context)
        }
    }, [context])

    if (loading) {
        return loadingScreen();
    }

    if (authState !== 'signedIn' && validUserContext !== "exists") {
        return null
    }

    const temp = returnable(context, usersList)
    if (temp != null){
        return temp
    }


    return (
        <div className="">
            {modelIsOpen && <Sessiontimeout stayActive={stayActive} modelIsOpen={modelIsOpen} seconds={seconds} />}
            <ThemeProvider theme={newUITheme}>
                <Router>
                    <Switch>
                        <Route exact path={v2UIRoutePath} render={(r) => <NewHomeUI routerProps={r} />} />
                        <Route exact path={v2UIRoutePath + "requestdetails/:dsarRequestId"} render={(r) => <RequestDetailView routerProps={r} />} />
                        <Route exact path="/newuiadmin" render={(r) => <NewAdminUI routerProps={r} />} />
                        <Route exact path="/newuiadmin/:page" render={(r) => <NewAdminUI routerProps={r} />} />
                        <Route exact path="/newuiadmin/:page/:tab" render={(r) => <NewAdminUI routerProps={r} />} />
                        <Route exact path={v2UIRoutePath + "page/:page"} render={(r) => <NewHomeUI routerProps={r} />} />
                        <Route exact path={v2UIRoutePath + "reports"} render={(r) => <Reports routerProps={r} />} />
                        <Route exact path={v2UIRoutePath + "reports/:reportname"} render={(r) => <Reports routerProps={r} />} />
                        <Route exact path={v2UIRoutePath + "reports/:reportname/:tab"} render={(r) => <Reports routerProps={r} />} />
                        <Route exact path={v2UIRoutePath + "tracking"} render={(r) => <Tracking routerProps={r} />} />
                        <Route exact path={v2UIRoutePath + "tracking/:page"} render={(r) => <Tracking routerProps={r} />} />


                        <Route exact path="/" render={(r) => <Home routerProps={r} tab="requests" />} />
                        <Route exact path="/page/:page" render={(r) => <Home routerProps={r} tab="requests" />} />
                        <Route exact path="/daily" render={(r) => <Home routerProps={r} tab="daily" />} />
                        <Route exact path="/bulk" render={(r) => <Home routerProps={r} tab="bulk" />} />
                        <Route exact path="/reports" render={(r) => <Home routerProps={r} tab="reports" />} />
                        <Route exact path="/reports/:reportType" render={(r) => <Home routerProps={r} tab="reports" />} />
                        <Route exact path="/ticket/create" component={CreateRequest} />
                        <Route exact path="/ticket/:dsarRequestId" component={RequestDetails} />
                        <Route exact path="/ticket/:dsarRequestId/guidDetails" component={GuidDetails} />
                        <Route exact path="/ticket/:dsarRequestId/viewRequest" component={ViewJSON} />
                        <Route exact path="/ticket/:dsarRequestId/auditTrail" component={AuditTrail} />
                        <Route exact path="/ticket/:dsarRequestId/viewResponseJSON" component={ViewJSON} />
                        <Route exact path="/ticket/:dsarRequestId/viewResponseFiles" component={ViewResponseFiles} />
                        <Route exact path="/ticket/:dsarRequestId/:appId/markCreated" component={MarkRequestCreated} />
                        <Route exact path="/ticket/:dsarRequestId/:appId/createResponse" component={CreateResponse} />
                        <Route exact path="/ticket/:dsarRequestId/:appId/viewResponse" component={ViewResponse} />
                        <Route exact path="/ticket/:dsarRequestId/:appId/updateResponse" component={UpdateResponse} />
                        <Route exact path="/ticket/:dsarRequestId/:appId/viewrequestjson" component={ViewJSON} />
                        <Route exact path="/contacts" component={ContactsTab} />
                        <Route exact path="/documents" render={(r) => <LinksPage header="Documents" />} />
                        <Route exact path="/cps_health" render={(r) => <Health header="CPS Health" />} />
                        <Route exact path="/apps" component={Automation} />
                        <Route exact path="/attribute_keys" component={MasterAttributeKeys} />
                        <Route exact path="/bulk_assign" component={BulkAssign} />
                        <Route exact path="/retention" component={Retention} />
                        <Route exact path="/users" component={Users} />
                        <Route exact path="/litigation_hold" component={LitigationHold} />
                        <Route exact path="/processing_requests" component={ProcessingRequests} />
                        <Route exact path="/ctp_aggregator_status" component={CtpAggregator} />
                        <Route exact path="/autoclose_settings" component={AutoCloseSettings} />
                        {/* <Route component={Notfound} /> */}
                    </Switch>
                </Router>
            </ThemeProvider>
            <IdleTimer
                ref={IdleTimerRef}
                timeout={60 * 1000 * 14}
                onIdle={onIdle}
            />
        </div>
    );

}

export default Routes;
