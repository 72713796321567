import * as React from "react";
import { GridColumnHeaderTitle, GridApiContext } from "@mui/x-data-grid";
import IconButton from "@material-ui/core/IconButton";
import createSvgIcon from "@material-ui/core/utils/createSvgIcon";

const GridFilterAltIcon = createSvgIcon(
  <path d="M4.25 5.61C6.27 8.2 10 13 10 13v6c0 .55.45 1 1 1h2c.55 0 1-.45 1-1v-6s3.72-4.8 5.74-7.39c.51-.66.04-1.61-.79-1.61H5.04c-.83 0-1.3.95-.79 1.61z" />,
  "FilterAlt"
);

const ColumnHeader = ({
  column,
  filterIconEnable,
  updateActiveColumn
}) => {
  const gridApicontext = React.useContext(GridApiContext);

  const handleFilterEnable = (event) => {
    event.preventDefault();
    event.stopPropagation();
    updateActiveColumn(gridApicontext.current.getColumn(column.field));
    gridApicontext.current.showFilterPanel(column.field);
  };
  return (
    <>
      <GridColumnHeaderTitle
        label={column.headerName || column.field}
        description={column.description}
        columnWidth={column.computedWidth}
      />
      {filterIconEnable && (
        <IconButton
          onClick={(event) => {
            handleFilterEnable(event);
          }}
          color="default"
          size="small"
          tabIndex={-1}
        >
          <GridFilterAltIcon fontSize="small" />
        </IconButton>
      )}
    </>
  );
};
export default ColumnHeader;
