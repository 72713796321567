import * as React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const renderSingleSelectOptions = (option) =>
    (
        <MenuItem key={option} value={option}>
            {option}
        </MenuItem>
    );

export function GridEditSingleSelectCell(props) {
    const {
        id,
        value,
        formattedValue,
        api,
        field,
        row,
        colDef,
        cellMode,
        isEditable,
        tabIndex,
        className,
        getValue,
        hasFocus,
        ...other
    } = props;

    const ref = React.useRef();
    const [open, setOpen] = React.useState(false);

    const handleChange = (event) => {
        setOpen(false);
        api.setEditCellValue({ id, field, value: event.target.value }, event);
    };

    const handleClose = (event, reason) => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    React.useEffect(() => {
        if (hasFocus) {
            ref.current.querySelector('[role="button"]').focus();
        }
    }, [hasFocus]);

    return (
        <Select
            ref={ref}
            value={value}
            onChange={handleChange}
            open={open}
            onOpen={handleOpen}
            MenuProps={{
                onClose: handleClose,
            }}
            fullWidth
            {...other}
        >
            {(colDef.valueOptions || colDef?.getValueOptions(row) || [])?.map(renderSingleSelectOptions)}
        </Select>
    );
}
export const renderEditSingleSelectCell = (params) => <GridEditSingleSelectCell {...params} />;