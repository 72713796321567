/**
 * 
 * File used to replace the auth details so that we can replace below details and run from the local
 */

const LoginDefaults = {
    authToken: null,
    validUser: null,
    email: null,
    userId: null,
    userName: null
}
export default LoginDefaults;