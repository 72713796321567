import React, { useState } from 'react';
import axios from '../../common/AxiosConfig';
import moment from 'moment';
import { UncontrolledTooltip } from 'reactstrap';

import '../../../css/smallComponents.css';


function LitigationVinRow(props) {
    const { authToken, isAdmin, serialNumber, data, setVins } = props

    const [editMode, setEditMode] = useState(false)
    const [startDate, setStartDate] = useState(data.start_date)
    const [endDate, setEndDate] = useState(data.end_date)
    const [active, setActive] = useState(data.is_active)
    const [requestedBy, setRequestedBy] = useState(data.requested_by)
    const [requestedByEmail, setRequestedByEmail] = useState(data.requested_by_email)

    const submitChanges = () => {
        axios.put(`/litigation_vins/${data.vin}`, {
            start_date: startDate,
            end_date: endDate,
            is_active: active,
            requested_by: requestedBy,
            requested_by_email: requestedByEmail,
        }, {
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            setEditMode(false)

            // Fetch the new data
            axios.get('/litigation_vins', {
                headers: {
                    Authorization: authToken
                }
            }).then(res => {
                setVins(res.data)
            }).catch(err => {
                console.log(err)
            })
        }).catch(err => {
            if (err.response) {
                const errorFeedback = document.getElementById(`error-vin-${serialNumber}`)
                errorFeedback.innerHTML = err.response.data.message
            }
        })
    }

    const exitEditMode = () => {
        setEditMode(false)
        setStartDate(data.start_date)
        setEndDate(data.end_date)
        setActive(data.is_active)
        setRequestedBy(data.requested_by)
        setRequestedByEmail(data.requested_by_email)
    }


    if (editMode) {
        return (
            <tr>
                <td>{serialNumber}</td>
                <td>{data.vin}</td>
                <td>
                    <input style={{width:"150px"}} className="form-control pr-0 pl-1" type="date" value={moment(startDate).utc().format("YYYY-MM-DD")} onChange={e => setStartDate(e.target.value)} />
                    <span id={`error-vin-${serialNumber}`} className="small text-danger mb-1"></span>
                </td>
                <td>
                    <input style={{width:"150px"}} className="form-control pr-0 pl-1" type="date" value={moment(endDate).utc().format("YYYY-MM-DD")} onChange={e => setEndDate(e.target.value)} />
                </td>
                <td>
                    <select style={{width:"65px"}} className="form-control pr-0 pl-1" value={active} onChange={e => { setActive(e.target.value === 'true' ? true : false) }}>
                        <option value={true}>True</option>
                        <option value={false}>False</option>
                    </select>
                </td>
                <td>
                    <input className="form-control" type="text" value={requestedBy} onChange={e => setRequestedBy(e.target.value)} />
                </td>
                <td>
                    <input className="form-control" type="email" value={requestedByEmail} onChange={e => setRequestedByEmail(e.target.value)} />
                </td>
                {isAdmin ?
                    <td className="text-nowrap">
                        <button type="button" className="btn btn-sm small-button btn-danger mr-1" onClick={exitEditMode}>
                            <i className="material-icons small-icon">clear</i>
                        </button>
                        <button type="button" className="btn btn-sm small-button btn-success" onClick={submitChanges}>
                            <i className="material-icons small-icon">done</i>
                        </button>
                    </td>
                    : null
                }
            </tr>
        )
    } else {
        return (
            <tr className={`${!active ? "text-danger" : ""}`}>
                <td>{serialNumber}</td>
                <td>
                    <span id={`lit-vin-${serialNumber}`}>{data.vin}</span>
                    <UncontrolledTooltip placement="bottom" target={`lit-vin-${serialNumber}`}>
                        Created By {data.created_by}<br />
                        {data.updated_at ? `Updated On: ${moment(data.updated_at).utc().format("MMM Do, YYYY HH:mm:ss [(UTC)]")}` : null}
                    </UncontrolledTooltip>
                </td>
                <td>{startDate ? moment(startDate).utc().format("MMM Do, YYYY") : null}</td>
                <td>{endDate ? moment(endDate).utc().format("MMM Do, YYYY") : null}</td>
                <td>{active ? "True" : "False"}</td>
                <td>{data.requested_by}</td>
                <td>{data.requested_by_email}</td>
                {isAdmin ?
                    <td>
                        <button type="button" className="btn btn-sm small-button btn-warning" onClick={() => { setEditMode(true) }}>
                            <i className="material-icons small-icon">edit</i>
                        </button>
                    </td>
                    : null
                }
            </tr>
        )
    }
}

export default LitigationVinRow;
