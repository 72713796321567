import { withOAuth } from 'aws-amplify-react';
import React from 'react';
import LoginDefaults from './login-defaults';

class SAMLSignIn extends React.Component {
  render() {
    
    if(LoginDefaults && LoginDefaults.samlCheckDisabled){
      return null
    }
    const { authState } = this.props;

    console.log('SAMLlogin ' + authState)

    if (authState === 'signIn') {
      this.props.OAuthSignIn()
    }
    return null
  }
}

export default withOAuth(SAMLSignIn);