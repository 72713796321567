/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../../RoutesWithAuth';
import { UncontrolledTooltip } from 'reactstrap';
import axios from '../common/AxiosConfig';
import moment from 'moment';
import PageHeader from '../common/PageHeader';
import { withNavbar } from '../common/Navbar';
import { feedbackTimeout } from '../common/commons';


function GuidDetails(props) {
    const { dsarRequestId } = props.match.params;

    const context = useContext(AppContext)
    const authToken = context.authToken.get
    const isAdmin = context.isAdmin.get

    const [guidDetails, setGuidDetails] = useState([])
    const [responseAction, setResponseAction] = useState(null)
    const [requestStatus, setRequestStatus] = useState("")

    const [editMode, setEditMode] = useState(false)
    const [newAction, setNewAction] = useState(null)
	const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        if (authToken !== null) {
            fetchData()
        }
    }, [authToken])

    useEffect(() => {
        if (!newAction && responseAction) {
            setNewAction(responseAction)
        }
    }, [responseAction])

    const fetchData = () => {
        axios.get(`ticket/${dsarRequestId}/guids`, {
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            setGuidDetails(res.data)
        }).catch(err => {
            if (err?.response?.status === 404) {
                setGuidDetails("no guids")
            }
        })

        axios.get(`ticket/response/${dsarRequestId}/12`, {
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            setResponseAction(res.data.response_action)
            setRequestStatus(res.data.status)
            setIsOpen(res.data.response_is_open)
        }).catch(err => {
            // Marking the Ticket status to Open When there is no response
            if(err?.response?.status === 404 && err?.response?.data?.message === "Response not found!"){
                setIsOpen(true);
            }
        })
    }

    const downloadJSON = (e) => {
        const guid = e.target.dataset.guid
        const json = e.target.value

        const link = document.createElement('a');
        const file = new Blob([json], { type: 'application/json' })
        link.href = URL.createObjectURL(file);
        link.setAttribute('download', `${guid}.json`);
        document.body.appendChild(link);
        link.click();
        link.remove()
    }

    const editResponseAction = () => {
        const errorFeedback = document.getElementById("edit-response-error")

        axios.post('ticket/update_response', {
            dsar_id: dsarRequestId,
            app_id: 12,
            creator_id: null,
            response_json: '{}',
            response_action: newAction,
            fileNames: [],
        }, {
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            setResponseAction(newAction)
            setEditMode(false)
        }).catch(err => {
            console.log(err)
            errorFeedback.innerHTML = "Could not update response action"
        }).finally(()=>removeFeedback("edit-response-error"))
    }

    const removeFeedback = (id) => {
        // Remove feedback after a specified timeout period
        setTimeout(
            function () {
                const invalidFeedback = document.getElementById(id)
                if (invalidFeedback) { invalidFeedback.innerHTML = "" }
            }, feedbackTimeout
        )
    }

    const responseMapping = {
        'success': 'Record Deleted',
        'not_found': 'Record not Found',
        'error': 'Unable to Delete, Due to Error',
        'data_exempt': 'Data Exempt by Policy'
    }

    return (
        <div className="container">

            <PageHeader header="GUID Details" removeMargin={true} />

            <p className="lead">
                <Link to={`/`}>Home&nbsp;</Link>
                &gt; <Link to={`/ticket/${dsarRequestId}`}>Request ID: {dsarRequestId}&nbsp;</Link>
                &gt; GUID Details<br />
                System Name: Zuora<br />
                Response Action: {responseAction ?
                    <span>
                        {editMode ?
                            <span>
                                <select className="form-control-inline form-control-sm" value={newAction} onChange={e => { setNewAction(e.target.value) }}>
                                    <option value='success'>Record Deleted</option>
                                    <option value='not_found'>Record not Found</option>
                                    <option value='error'>Unable to Delete, Due to Error</option>
                                    <option value='data_exempt'>Data Exempt by Policy</option>
                                </select>
                                <button type="button" className="btn btn-sm small-button btn-danger mr-1 ml-2" onClick={() => setEditMode(false)}>
                                    <i className="material-icons small-icon">clear</i>
                                </button>
                                <button type="button" className="btn btn-sm small-button btn-success" onClick={editResponseAction}>
                                    <i className="material-icons small-icon">done</i>
                                </button>
                            </span>
                            :
                            <span>
                                {responseMapping[responseAction]}
                                {isAdmin && (requestStatus !== "Uploaded") ?
                                    <button className="btn btn-sm small-button btn-warning ml-2" onClick={() => setEditMode(true)}>
                                        <i className="material-icons small-icon">edit</i>
                                    </button>
                                    : null
                                }
                            </span>
                        }
                    </span>
                    : "Pending Response"
                }
                <br />
                <span className="small text-danger" id="edit-response-error"></span>                
					Ticket Status: {isOpen ? "Open" : "Closed"}
            </p>
            <hr />

            <div className="mb-5">
                {guidDetails === "no guids" ?
                    <p className="h3 text-center mt-4">
                        No Guids!<br />
                        <small className="text-muted">System was unable to find any GUIDs attached to this DSAR Request.</small>
                    </p>
                    :
                    <div>
                        {guidDetails.map((guid, i) => (
                            <div key={guid.guid}>
                                <p className="mb-1">
                                    GUID {i + 1}: {guid.guid}<br />
                                    <span>
                                        Created Date:&nbsp;
                                        <span id={`guid-created-${guid.guid}`}>
                                            {guid && guid.created_date ? moment(guid.created_date).utc().format('MMM Do, YYYY HH:mm:ss (UTC)') : null}
                                        </span>
                                        <UncontrolledTooltip placement="bottom" target={`guid-created-${guid.guid}`}>
                                            {moment(guid.created_date).local().format('MMM Do, YYYY HH:mm:ss [(local)]')}
                                        </UncontrolledTooltip>
                                        <br />
                                    </span>
                                    <span>
                                        Response Received:&nbsp;
                                        <span id={`guid-updated-${guid.guid}`}>
                                            {guid && guid.updated_date ? moment(guid.updated_date).utc().format('MMM Do, YYYY HH:mm:ss (UTC)') : null}
                                        </span>
                                        <UncontrolledTooltip placement="bottom" target={`guid-updated-${guid.guid}`}>
                                            {moment(guid.updated_date).local().format('MMM Do, YYYY HH:mm:ss [(local)]')}
                                        </UncontrolledTooltip>
                                        <br />
                                    </span>
                                    Status: {guid.status}
                                </p>

                                <div className="bg-light rounded border p-2">
                                    {(<div><pre className="mb-0">{JSON.stringify(guid.json_response ? guid.json_response : {}, null, 2)}</pre></div>)}
                                </div>

                                <div className="text-right mt-2 mb-4">
                                    <button className="btn btn-sm btn-primary" data-guid={guid.guid} value={JSON.stringify(guid.json_response, null, 4)} onClick={downloadJSON}>
                                        Download
                                    </button>
                                </div>

                                <hr />
                            </div>
                        ))}
                    </div>
                }
            </div>

        </div>
    );
}

export default withNavbar(GuidDetails);
