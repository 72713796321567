/* eslint-disable jsx-a11y/scope */
import React, { useState, useEffect } from 'react';
import axios from '../../common/AxiosConfig';
import { Scope_Not_Applicable } from '../../common/commons';

const SUBSYSTEMS = [{
    name: "17cy",
    displayName: "17+"
}, {
    name: "ctp_21mm_adr_flag",// flag21MM_ADR
    displayName: "21MM-ADR",
    enableOnlyCust: true,
}, {
    name: "ctp_21mm_audio_flag",//flag21MM_Audio
    displayName: "21MM-AUDIO",
    enableOnlyCust: true,
}, {
    name: "ctp_21mm_ip_apps_flag",//flag21MM_IP_Apps
    displayName: "21MM-IP Apps",
    enableOnlyCust: true,
}, {
    name: "ctp_21mm_up_flag",//flag21MM_UP
    displayName: "21MM-UP",
    enableOnlyCust: true,
}, {
    name: "mp",//flagMP
    displayName: "MP"
}];
const Scope_Request_Only = 'Request Only';
const Scope_Response_Only = 'Response Only';

function AppCard(props) {
    const { app, authToken, isAdmin, fetchData, requestor } = props;

    const [editMode, setEditMode] = useState(false)
    const [appUpdateError, setAppUpdateError] = useState(false);
    // Creating a Flags and a subsystem variable for each Sub-system of CTP
    const [flagResponse, setFlagResponse] = useState();
    const [editModeFlagResponse, setEditModeFlagResponse] = useState();

    const [requestAutomated, setRequestAutomated] = useState(app.is_request_automated)
    const [responseAutomated, setResponseAutomated] = useState(app.is_response_automated)
    const [rtdScope, setRtdScope] = useState(app.rtd_scope)
    const [rtkScope, setRtkScope] = useState(app.rtk_scope)
    const [rtooScope, setRtooScope] = useState(app.rtoo_scope)
    const [rtcScope, setRtcScope] = useState(app.rtc_scope)
    const [copyAppData, setCopyAppData] = useState(app)

    // Variables for tips
    const [rtcRequestAutomated, setRTCRequestAutomated] = useState([])
    const [rtcResponseAutomated, setRTCResponseAutomated] = useState([])
    const [rtdRequestAutomated, setRTDRequestAutomated] = useState([])
    const [rtdResponseAutomated, setRTDResponseAutomated] = useState([])
    const [rtkRequestAutomated, setRTKRequestAutomated] = useState([])
    const [rtkResponseAutomated, setRTKResponseAutomated] = useState([])
    const [rtooRequestAutomated, setRTOORequestAutomated] = useState([])
    const [rtooResponseAutomated, setRTOOResponseAutomated] = useState([])

    const [reqType, setReqType] = useState(['rtc_scope', 'rtd_scope', 'rtk_scope', 'rtoo_scope']);
    const [ctpData, setCtpData] = useState();
    const onChangeCTPValue = (reqTypeName, subSystemName, flagType) => {
        const ctpdataCopy = ctpData
        ctpdataCopy[reqTypeName] = {
            ...ctpdataCopy[reqTypeName],
            [subSystemName]: flagType === "true" ? "false" : "true"
        };

        setReqType([...reqType]);
        setCtpData(ctpdataCopy);
    }

    const onChangeUpdateAppData = (reqchangeType, reqchangeScope) => {
        setCopyAppData({
            ...copyAppData,
            [reqchangeType]: reqchangeScope
        });
        if (reqchangeScope === Scope_Not_Applicable) {
            const scopeNotAvailableCTPData = ctpData;
            SUBSYSTEMS.forEach(({ name }) => {
                scopeNotAvailableCTPData[reqchangeType] = {
                    ...scopeNotAvailableCTPData[reqchangeType],
                    [name]: "false"
                }
            })
            setCtpData(scopeNotAvailableCTPData);
        }
    }

    useEffect(() => {
        if (!editMode && appUpdateError) {
            setAppUpdateError(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editMode]);
    // Getting the status of the flags
    useEffect(() => {
        if (authToken !== null && isAdmin === true && app.name === "CTP") {
            axios.get(`/ctp/flags`, {
                headers: {
                    Authorization: authToken
                },
                params: {
                    requestor
                }
            }).then(res => {
                const { rtc, rtd, rtk, rtoo } = res.data;
                const ctpResponseFlags = {
                    'rtc_scope': rtc, 'rtd_scope': rtd, 'rtk_scope': rtk, 'rtoo_scope': rtoo
                }
                setCtpData(ctpResponseFlags)
                setFlagResponse(ctpResponseFlags);
                setEditModeFlagResponse({ ...ctpResponseFlags });
            }).catch(err => {
                console.log(err)
            })
        }

        if (app.name === "TIPS") {
            setRTCRequestAutomated(["All", Scope_Request_Only].includes(rtcScope))
            setRTCResponseAutomated(["All", Scope_Response_Only].includes(rtcScope))
            setRTDRequestAutomated(["All", Scope_Request_Only].includes(rtdScope))
            setRTDResponseAutomated(["All", Scope_Response_Only].includes(rtdScope))
            setRTKRequestAutomated(["All", Scope_Request_Only].includes(rtkScope))
            setRTKResponseAutomated(["All", Scope_Response_Only].includes(rtkScope))
            setRTOORequestAutomated(["All", Scope_Request_Only].includes(rtooScope))
            setRTOOResponseAutomated(["All", Scope_Response_Only].includes(rtooScope))

        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authToken, isAdmin])

    const tipsautomationmapping = (a, b) => {
        if (a && b) {
            return "All"
        }
        if (a) {
            return Scope_Request_Only
        }
        if (b) {
            return Scope_Response_Only
        }
        return Scope_Not_Applicable
    }

    const updateApp = () => {
        axios.post(`apps`, {
            app_id: app.id,
            is_request_automated: requestAutomated,
            is_response_automated: responseAutomated,
            rtc_scope: rtcScope,
            rtd_scope: rtdScope,
            rtk_scope: rtkScope,
            rtoo_scope: rtooScope,
            requestor
        }, {
            headers: {
                Authorization: authToken
            }
        })
            .then(res => {
                fetchData()
                setEditMode(false)
            }).catch(err => {
                if (err?.response?.status === 401) {
                    setAppUpdateError(err?.response?.data?.message || "You are not an admin!");
                }
                console.log(err)
            })
    }

    const exitEditMode = () => {
        setRequestAutomated(app.is_request_automated)
        setResponseAutomated(app.is_response_automated)

        setRtcScope(app.rtc_scope)
        setRtdScope(app.rtd_scope)
        setRtkScope(app.rtk_scope)
        setRtooScope(app.rtoo_scope)

        setRTCRequestAutomated(["All", Scope_Request_Only].includes(app.rtc_scope))
        setRTCResponseAutomated(["All", Scope_Response_Only].includes(app.rtc_scope))
        setRTDRequestAutomated(["All", Scope_Request_Only].includes(app.rtd_scope))
        setRTDResponseAutomated(["All", Scope_Response_Only].includes(app.rtd_scope))
        setRTKRequestAutomated(["All", Scope_Request_Only].includes(app.rtk_scope))
        setRTKResponseAutomated(["All", Scope_Response_Only].includes(app.rtk_scope))
        setRTOORequestAutomated(["All", Scope_Request_Only].includes(app.rtoo_scope))
        setRTOOResponseAutomated(["All", Scope_Response_Only].includes(app.rtoo_scope))

        setEditMode(false)
    }

    const updateAppCTP = () => {

        ctpData['app_id'] = app.id;
        ctpData['is_request_automated'] = requestAutomated;
        ctpData['is_response_automated'] = responseAutomated;
        ctpData['requestor'] = requestor;
        ctpData['rtc_scope']['scope'] = rtcScope;
        ctpData['rtd_scope']['scope'] = rtdScope;
        ctpData['rtk_scope']['scope'] = rtkScope;
        ctpData['rtoo_scope']['scope'] = rtooScope;
        console.log(ctpData)
        axios.post(`apps`, ctpData, {
            headers: { Authorization: authToken }
        })
            .then(res => {
                fetchData()
                setEditMode(false)

                const errorFeedback = document.getElementById("edit-app-error")
                errorFeedback.innerHTML = ""
                setFlagResponse({ ...editModeFlagResponse });
                setEditModeFlagResponse({ ...ctpData });

            }).catch(err => {
                if (err?.response?.status === 401) {
                    setAppUpdateError(err?.response?.data?.message || "You are not an admin!");
                }
                console.log(err)

            })
    }

    const exitEditModeCTP = () => {
        setCopyAppData({ ...app });
        setCtpData({ ...editModeFlagResponse });
        const errorFeedback = document.getElementById("edit-app-error")
        errorFeedback.innerHTML = ""

        exitEditMode()
    }
    const appUpdateErrorUI = (
        <div className="d-flex flex-row-reverse">
            <span className="small text-danger" id="edit-app-error">{appUpdateError}</span>
        </div>
    );
    const editModeUI = (
        <h4>
            {appUpdateErrorUI}
            {app.display}
            <span className="float-right">
                {editMode ?
                    <div>
                        <button type="button" className="btn btn-sm small-button btn-danger mr-2" onClick={exitEditMode}>
                            <i className="material-icons small-icon">clear</i>
                        </button>
                        <button type="button" className="btn btn-sm small-button btn-success" onClick={updateApp}>
                            <i className="material-icons small-icon">done</i>
                        </button>
                        <br />
                    </div>
                    :
                    <div>
                        {isAdmin ?
                            <button type="button" className="btn btn-sm btn-warning small-button" onClick={() => { setEditMode(true) }}>
                                <i className="material-icons small-icon">edit</i>
                            </button>
                            : null
                        }
                    </div>
                }
            </span>
        </h4>
    )

    const automationUI = (
        <div>
            <p className="custom-control custom-switch mb-1">
                <input type="checkbox" className="custom-control-input" id={app.id + "request"}
                    checked={requestAutomated} disabled={!editMode}
                    onChange={e => setRequestAutomated(e.target.checked)}
                />
                <label className={`custom-control-label ${!requestAutomated ? "text-danger" : ""}`} htmlFor={app.id + "request"}>Request Automated</label>
            </p>

            <p className="custom-control custom-switch mb-0">
                <input type="checkbox" className="custom-control-input" id={app.id + "response"}
                    checked={responseAutomated} disabled={!editMode}
                    onChange={e => setResponseAutomated(e.target.checked)}
                />
                <label className={`custom-control-label ${!responseAutomated ? "text-danger" : ""}`} htmlFor={app.id + "response"}>Response Automated</label>
            </p>
        </div>
    )

    const scopeUI = (
        <table className="table table-striped table-bordered table-sm">
            <thead>
                <tr>
                    <th scope="col">Request Type</th>
                    <th scope="col">Scope</th>
                </tr>
            </thead>
            <tbody>
                <tr className={rtcScope === Scope_Not_Applicable ? "text-danger" : ""}>
                    <td>Right to Correct</td>
                    {editMode ?
                        <td>
                            <select className="form-control form-control-sm" value={rtcScope} onChange={e => { setRtcScope(e.target.value); onChangeUpdateAppData('rtc_scope', e.target.value) }}>
                                <option>All</option>
                                <option>California Only</option>
                                <option>Non-California Only</option>
                                <option>{Scope_Not_Applicable}</option>
                            </select>
                        </td>
                        :
                        <td>{rtcScope}</td>
                    }
                </tr>
                <tr className={rtdScope === Scope_Not_Applicable ? "text-danger" : ""}>
                    <td>Right to Delete</td>
                    {editMode ?
                        <td>
                            <select className="form-control form-control-sm" value={rtdScope} onChange={e => { setRtdScope(e.target.value); onChangeUpdateAppData('rtd_scope', e.target.value) }}>
                                <option>All</option>
                                <option>California Only</option>
                                <option>Non-California Only</option>
                                <option>{Scope_Not_Applicable}</option>
                            </select>
                        </td>
                        :
                        <td>{rtdScope}</td>
                    }
                </tr>
                <tr className={rtkScope === Scope_Not_Applicable ? "text-danger" : ""}>
                    <td>Right to Know</td>
                    {editMode ?
                        <td>
                            <select className="form-control form-control-sm" value={rtkScope} onChange={e => { setRtkScope(e.target.value); onChangeUpdateAppData('rtk_scope', e.target.value) }}>
                                <option>All</option>
                                <option>California Only</option>
                                <option>Non-California Only</option>
                                <option>{Scope_Not_Applicable}</option>
                            </select>
                        </td>
                        :
                        <td>{rtkScope}</td>
                    }
                </tr>
                <tr className={rtooScope === Scope_Not_Applicable ? "text-danger" : ""}>
                    <td>Right to Opt Out</td>
                    {editMode ?
                        <td>
                            <select className="form-control form-control-sm" value={rtooScope} onChange={e => { setRtooScope(e.target.value); onChangeUpdateAppData('rtoo_scope', e.target.value) }}>
                                <option>All</option>
                                <option>California Only</option>
                                <option>Non-California Only</option>
                                <option>{Scope_Not_Applicable}</option>
                            </select>
                        </td>
                        :
                        <td>{rtooScope}</td>
                    }
                </tr>
            </tbody>
        </table>

    )

    if (app.name === "CTP") {
        return (
            <div className="card mb-2 bg-light" key={app.id}>
                <div className="card-body">
                    <h4>
                        {appUpdateErrorUI}
                        {app.display}
                        <span className="float-right">
                            {editMode ?
                                <div>

                                    <button type="button" className="btn btn-sm small-button btn-danger mr-2" onClick={exitEditModeCTP}>
                                        <i className="material-icons small-icon">clear</i>
                                    </button>
                                    <button type="button" className="btn btn-sm small-button btn-success" onClick={updateAppCTP}>
                                        <i className="material-icons small-icon">done</i>
                                    </button>
                                    <br />
                                </div>
                                :
                                <div>
                                    {isAdmin ?
                                        <button type="button" className="btn btn-sm btn-warning small-button" onClick={() => { setEditMode(true) }}>
                                            <i className="material-icons small-icon">edit</i>
                                        </button>
                                        : null
                                    }
                                </div>
                            }
                        </span>
                    </h4>
                    <div className="d-flex flex-row-reverse">
                        <span className="small text-danger" id="edit-app-error"></span>
                    </div>

                    <div className="d-flex align-items-center justify-content-around">
                        {automationUI}
                        <div>
                            {scopeUI}
                        </div>
                    </div>
                    <div>
                        <table className="table table-striped table-bordered table-sm ctp-app-subsystem">
                            <thead>
                                <tr>
                                    <th scope="col">Sub System</th>
                                    <th scope="col">Right to Correct</th>
                                    <th scope="col">Right to Delete</th>
                                    <th scope="col">Right to Know</th>
                                    <th scope="col">Right to Opt Out</th>
                                </tr>
                            </thead>
                            <tbody>
                                {flagResponse && ctpData && SUBSYSTEMS.map((record) => {
                                    return (
                                        <tr key={record.name}>
                                            <td>{record.displayName}</td>
                                            {reqType.map((reqItem) => {
                                                return (
                                                    <td key={`${record.name}-${reqItem}`}>
                                                        <p className="custom-control custom-switch custom-switch-lg mb-1">
                                                            <input
                                                                checked={ctpData[reqItem][record.name]?.toString() === "true"}
                                                                type="checkbox"
                                                                id={`${record.name}-${reqItem}`}
                                                                className={`custom-control-input ${(copyAppData[reqItem] === Scope_Not_Applicable || (record.enableOnlyCust && requestor !== "Customer")) && 'custom-switch-disabled'}`}
                                                                disabled={((record.enableOnlyCust && requestor !== "Customer") || !editMode || copyAppData[reqItem] === Scope_Not_Applicable)}
                                                                onChange={() => onChangeCTPValue(reqItem, record.name, ctpData[reqItem][record.name]?.toString())}
                                                            />
                                                            <label htmlFor={`${record.name}-${reqItem}`} className={`custom-control-label`} > </label>
                                                        </p>
                                                    </td>)
                                            })}
                                        </tr>
                                    );
                                })}

                            </tbody>
                        </table>
                    </div>
                    <div>
                        Integration Pattern:
                        <span className="font-italic ml-1">
                            {app.integration_pattern}
                        </span>
                    </div>
                    <div>
                        Additional Rules:
                        <span className="font-italic ml-1">
                            {app.additional_rules || "None"}
                        </span>
                    </div>
                </div>
            </div>
        );
    }

    if (app.name === "TIPS") {
        return (
            <div className="card mb-2 bg-light" key={app.id}>
                <div className="card-body">
                    {editModeUI}
                    <div className="d-flex align-items-right justify-content-around">
                        <table className="table table-bordered table-sm">
                            <thead>
                                <tr>
                                    <th scope="col">Right To Correct</th>
                                    <th scope="col">Right To Delete</th>
                                    <th scope="col">Right to Know</th>
                                    <th scope="col">Right to Opt Out</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td scope="col">
                                        <p className="custom-control custom-switch mb-1">
                                            <input type="checkbox" className="custom-control-input" id={app.id + "rtc_request"}
                                                checked={rtcRequestAutomated} disabled={!editMode}
                                                onChange={e => { setRTCRequestAutomated(e.target.checked); setRtcScope(tipsautomationmapping(e.target.checked, rtcResponseAutomated)) }}
                                            />
                                            <label className={`custom-control-label ${!rtcRequestAutomated ? "text-danger" : ""}`} htmlFor={app.id + "rtc_request"}>Request Automated</label>
                                        </p>
                                        <p className="custom-control custom-switch mb-0">
                                            <input type="checkbox" className="custom-control-input" id={app.id + "rtc_response"}
                                                checked={rtcResponseAutomated} disabled={!editMode}
                                                onChange={e => { setRTCResponseAutomated(e.target.checked); setRtcScope(tipsautomationmapping(rtcRequestAutomated, e.target.checked)) }}
                                            />
                                            <label className={`custom-control-label ${!rtcResponseAutomated ? "text-danger" : ""}`} htmlFor={app.id + "rtc_response"}>Response Automated</label>
                                        </p>
                                    </td>
                                    <td scope="col">
                                        <p className="custom-control custom-switch mb-1">
                                            <input type="checkbox" className="custom-control-input" id={app.id + "rtd_request"}
                                                checked={rtdRequestAutomated} disabled={!editMode}
                                                onChange={e => { setRTDRequestAutomated(e.target.checked); setRtdScope(tipsautomationmapping(e.target.checked, rtdResponseAutomated)) }}
                                            />
                                            <label className={`custom-control-label ${!rtdRequestAutomated ? "text-danger" : ""}`} htmlFor={app.id + "rtd_request"}>Request Automated</label>
                                        </p>
                                        <p className="custom-control custom-switch mb-0">
                                            <input type="checkbox" className="custom-control-input" id={app.id + "rtd_response"}
                                                checked={rtdResponseAutomated} disabled={!editMode}
                                                onChange={e => { setRTDResponseAutomated(e.target.checked); setRtdScope(tipsautomationmapping(rtdRequestAutomated, e.target.checked)) }}
                                            />
                                            <label className={`custom-control-label ${!rtdResponseAutomated ? "text-danger" : ""}`} htmlFor={app.id + "rtd_response"}>Response Automated</label>
                                        </p>
                                    </td>
                                    <td scope="col">
                                        <p className="custom-control custom-switch mb-1">
                                            <input type="checkbox" className="custom-control-input" id={app.id + "rtk_request"}
                                                checked={rtkRequestAutomated} disabled={!editMode}
                                                onChange={e => { setRTKRequestAutomated(e.target.checked); setRtkScope(tipsautomationmapping(e.target.checked, rtkResponseAutomated)) }}
                                            />
                                            <label className={`custom-control-label ${!rtkRequestAutomated ? "text-danger" : ""}`} htmlFor={app.id + "rtk_request"}>Request Automated</label>
                                        </p>
                                        <p className="custom-control custom-switch mb-0">
                                            <input type="checkbox" className="custom-control-input" id={app.id + "rtk_response"}
                                                checked={rtkResponseAutomated} disabled={!editMode}
                                                onChange={e => { setRTKResponseAutomated(e.target.checked); setRtkScope(tipsautomationmapping(rtkRequestAutomated, e.target.checked)) }}
                                            />
                                            <label className={`custom-control-label ${!rtkResponseAutomated ? "text-danger" : ""}`} htmlFor={app.id + "rtk_response"}>Response Automated</label>
                                        </p>
                                    </td>
                                    <td scope="col">
                                        <p className="custom-control custom-switch mb-1">
                                            <input type="checkbox" className="custom-control-input" id={app.id + "rtoo_request"}
                                                checked={rtooRequestAutomated} disabled={!editMode}
                                                onChange={e => { setRTOORequestAutomated(e.target.checked); setRtooScope(tipsautomationmapping(e.target.checked, rtooResponseAutomated)) }}
                                            />
                                            <label className={`custom-control-label ${!rtooRequestAutomated ? "text-danger" : ""}`} htmlFor={app.id + "rtoo_request"}>Request Automated</label>
                                        </p>

                                        <p className="custom-control custom-switch mb-0">
                                            <input type="checkbox" className="custom-control-input" id={app.id + "rtoo_response"}
                                                checked={rtooResponseAutomated} disabled={!editMode}
                                                onChange={e => { setRTOOResponseAutomated(e.target.checked); setRtooScope(tipsautomationmapping(rtooRequestAutomated, e.target.checked)) }}
                                            />
                                            <label className={`custom-control-label ${!rtooResponseAutomated ? "text-danger" : ""}`} htmlFor={app.id + "rtoo_response"}>Response Automated</label>
                                        </p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }

    if (app.name === "SAILPOINT") {
        return null
    }

    return (
        <div className="card mb-2 bg-light" key={app.id}>
            <div className="card-body">
                {editModeUI}

                <div className="d-flex align-items-center justify-content-around">
                    {automationUI}

                    <div>
                        {scopeUI}
                    </div>

                </div>
                <div>
                    Integration Pattern:
                    <span className="font-italic ml-1">
                        {app.integration_pattern}
                    </span>
                </div>
                <div>
                    Additional Rules:
                    <span className="font-italic ml-1">
                        {app.additional_rules || "None"}
                    </span>
                </div>
            </div>
        </div>
    );
}

export default AppCard;
