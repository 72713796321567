/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from '../../RoutesWithAuth';
import { Link } from 'react-router-dom';
import { withNavbar } from '../common/Navbar';
import axios from '../common/AxiosConfig';
import PageHeader from '../common/PageHeader';
import { removeFeedback, getResponseConfig, responseMapping, ticketTypeRTK } from '../common/commons';
import { Modal, ModalFooter, ModalBody, Button } from 'reactstrap';

const ErrorDomId = 'update-response-expected-error';

function UpdateResponse(props) {

    const { history } = props;
    const { dsarRequestId, appId } = props.match.params;
    const typeConfig = getResponseConfig("Add")
    const context = useContext(AppContext)
    const authToken = context.authToken.get
    const isAdmin = context.isAdmin.get
    const userId = context.userId.get

    const [appName, setAppName] = useState('');
    const [ticketType, setTicketType] = useState('');
    const [json, setJson] = useState('');
    const [responseIndex, setResponseIndex] = useState(1);
    const [files, setFiles] = useState([]);
    const [fileNames, setFileNames] = useState([]);

    const [responseAutomated, setResponseAutomated] = useState(null);
    const [attachments, setAttachments] = useState([]);
    const [responseIsOpen, setResponseIsOpen] = useState(false);
    const [jsonEditable, setJsonEditable] = useState(false);
    const [requestStatus, setRequestStatus] = useState("");
    const [fileToDelete, setFileToDelete] = useState(null);
    const [expectedAttachments, setExpectedAttachments] = useState(0)
    const [editExpectedAttachments, setEditExpectedAttachments] = useState(0)
    const [existingAttachments, setExistingAttachments] = useState(0)
    const [middleClassLicense, setMiddleClassLicense] = useState("")
    const [driverwaiver, setDriverwaiver] = useState("")
    const [applicantResume, setApplicantResume] = useState("")

    const attachmentRequired = () => {
        let attachmentExists = false;
        attachments.forEach(({ attachment_type }) => {
            if (attachment_type === "attachment") {
                attachmentExists = true;
            }
        })
        if (attachmentExists) {
            return false;
        }
        if (ticketType === ticketTypeRTK) {
            return (responseAutomated === false && responseIndex === 0 && (appName === 'Service Connect' || appName === 'Cerence' || appName === 'SXM'))
        }
        return true;
    }
    const screenshotRequired = () => {
        return !(ticketType === 'Right-to-Delete' || ticketType === 'Right-to-Correct' || ticketType === ticketTypeRTK || ticketType === 'Right-to-OptOut')
    }
    const fetchData = () => {
        axios.get(`ticket/search/`, {
            headers: {
                Authorization: authToken
            },
            params: {
                id: dsarRequestId,
                exact_match: true,
                include_open: true,
                include_closed: true,
            }
        }).then(res => {
            setTicketType(res.data[0].ticket_type)
        }).catch(err => {
            console.log('There was an error fetching the ticket data!', err)
        })

        // Ideally create a new API for this that just returns the system with the particular app id
        axios.get(`app_tickets/${dsarRequestId}`, {
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            res.data.forEach(app => {
                if (app.id.toString() === appId) {
                    setResponseAutomated(app.is_response_automated)
                }
            })
        }).catch(err => {
            console.log('There was an error fetching sub-system data!', err)
        })

        // Display the current response
        axios.get(`ticket/response/${dsarRequestId}/${appId}`, {
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            setJson(JSON.stringify(res.data.response_json, null, 2))
            setAppName(res.data.app_name)
            setResponseIndex(responseMapping.indexOf(res.data.response_action))
            setAttachments(res.data.attachment_names)
            setRequestStatus(res.data.status)
            setResponseIsOpen(res.data.response_is_open)
            setExpectedAttachments(res.data.expected_attachments)
            setEditExpectedAttachments(res.data.expected_attachments);
            setExistingAttachments(res.data.existing_attachments);
            if (typeof res.data.license !== "string") {
                res.data.license = ""
            }
            setMiddleClassLicense(res.data.license)
            if (typeof res.data.driverwaiver !== "string") {
                res.data.driverwaiver = ""
            }
            setDriverwaiver(res.data.driverwaiver)
            if (typeof res.data.resume !== "string") {
                res.data.resume = ""
            }
            setApplicantResume(res.data.resume)

            if (res.data.response_action === 'error') {
                setJsonEditable(true)
            }
        }).catch(err => {
            console.log(err)
        })
    }
    useEffect(() => {
        if (authToken !== null) {
            fetchData();
        }
    }, [authToken])

    const downloadFile = (e) => {
        const filename = e.target.value

        axios.get(`ticket/response/download/${dsarRequestId}/${appId}/${filename}`, {
            headers: {
                Authorization: authToken
            }
        }).then(res => {

            const link = document.createElement('a');
            link.href = res.data.presignedGet;
            link.setAttribute('download', `${filename}`);
            document.body.appendChild(link);
            link.click();
            link.remove()

        })
    }

    const deleteAttachment = (filename) => {

        return new Promise((resolve, reject) => {
            axios.delete('response/attachments', {
                headers: { Authorization: authToken },
                data: { s3_key: `${dsarRequestId}/${appId}/${filename}` }
            }).then(res => {
                setFileToDelete(null);
                setAttachments([...attachments.filter(el => el.filename !== filename)]);
                fetchData();
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    }

    const markToDelete = (e) => {
        const filename = e.target.value
        setFileToDelete(filename);
    }

    const handleFileChange = (e) => {
        e.persist() // Persist the state of the event
        let attachments = document.getElementById('responseAttachment')
        const screenshots = document.getElementById('responseScreenshot').files

        // Initialize the new state of 'files'
        const arrayOfFiles = []
        const arrayOfFileNames = []

        // Add all the attachments
        if (attachments) {
            attachments = attachments.files
            for (const attachment of attachments) {
                arrayOfFileNames.push({
                    fileName: attachment.name,
                    attachmentType: 'attachment',
                })
                arrayOfFiles.push(attachment)
            }
        }
        // Add all the screenshots
        for (const screenshot of screenshots) {
            arrayOfFileNames.push({
                fileName: screenshot.name,
                attachmentType: 'screenshot',
            })
            arrayOfFiles.push(screenshot)
        }

        // Set the state after arrayOfFiles has been populated
        setFiles(arrayOfFiles)
        setFileNames(arrayOfFileNames)

        const label = document.getElementById(e.target.id).nextSibling;
        if (e.target.files.length === 1) {
            label.innerHTML = e.target.files[0].name;
        } else if (e.target.files.length > 1) {
            label.innerHTML = `${e.target.files.length} files`
        }
    }

    const submitResponse = async (e) => {
        e.preventDefault()
        if (editExpectedAttachments > 99) {
            const feedback = document.getElementById(ErrorDomId)
            if (feedback) {
                feedback.innerHTML = "Expected attachments should be within 0-99"
                removeFeedback(ErrorDomId)
                return false;
            }
        }
        removeFeedback(ErrorDomId)

        // Disable the submit button
        const submitBtn = document.getElementById('update-response-btn');
        submitBtn.setAttribute('disabled', true)

        axios.post('ticket/update_response', {
            dsar_id: dsarRequestId,
            app_id: appId,
            creator_id: userId,
            response_json: json !== '' ? json : '{}',
            response_action: responseMapping[responseIndex],
            fileNames: fileNames,
            expectedAttachments: editExpectedAttachments,
            license: middleClassLicense,
            driverwaiver: driverwaiver,
            resume: applicantResume
        }, {
            headers: {
                Authorization: authToken
            }
        }).then(async res => {
            const posts = res.data.presignedPosts

            for (const post of posts) {
                // For the case where there are multiple files, find the proper file from the array
                const filename = post.fields.key.split('/').pop()

                // Populate the form data
                const formData = new FormData()
                for (const key in post.fields) {
                    formData.append(key, post.fields[key])
                }

                // Iterate through all the files and file one with the matching filename
                for (const file of files) {
                    if (file.name === filename) {
                        formData.append('file', file)
                    }
                }

                await new Promise((resolve, reject) => {
                    axios.post(post.url, formData, {
                        headers: { 'Content-Type': 'multipart/form-data' }
                    }).then(res => {
                        resolve(res)
                    }).catch(err => {
                        reject(err)
                    })
                })
            }
            // Call API Only for Cerence,ITGFleet,HireVue Apps
            const updateCSVAPI = ["7", "29", "27"];
            // Save an API call if we don't need to update the CSV
            if (updateCSVAPI.includes(appId.toString())) {

                await axios.post('ticket/update_csv', {
                    dsar_id: dsarRequestId,
                    app_id: appId,
                    creator_id: userId,
                    fileNames: fileNames
                }, {
                    headers: {
                        Authorization: authToken
                    }
                })
            }

            history.replace(`/ticket/${dsarRequestId}/${appId}/viewResponse`)
        }).catch(err => {
            // Display an error message to the user if the update did not complete
            const attachmentInput = document.getElementById('responseAttachment')
            if (attachmentInput) { attachmentInput.classList.add('is-invalid') }
            document.getElementById('responseScreenshot').classList.add('is-invalid')
            document.getElementById('invalid-feedback').innerHTML = err.response.data.error
            submitBtn.removeAttribute('disabled')
        })
    }


    if (!isAdmin) {
        return (
            <div className="container">
                <p className="h3 text-center mt-4">
                    Admins Only!<br />
                    <small className="text-muted">You must be an administrator to edit responses.</small>
                </p>
            </div>
        )
    }

    if (requestStatus === "Uploaded") {
        return (
            <div className="container">
                <p className="h3 text-center mt-4">
                    Data has been uploaded!<br />
                    <small className="text-muted">Editing a response after data has been uploaded is forbidden</small>
                </p>
            </div>
        )
    }
    const pendingAttachmentCount = expectedAttachments - existingAttachments;
    return (
        <div className="mb-4 container">

            <PageHeader header="DSAR Response" removeMargin={true} />

            <p className="lead mb-4">
                <Link to={`/`}>Home&nbsp;</Link>
                &gt; <Link to={`/ticket/${dsarRequestId}`}>Request ID: {dsarRequestId}&nbsp;</Link>
                &gt; Update Response<br />
                System Name: {appName}<br />
                <div className='row mt-2'>
                    <div className="col-2">
                        Response Action:
                    </div>
                    <div className="col-4 align-self-center">
                        <select value={responseIndex} className="form-control" id="responseAction" onChange={e => { setResponseIndex(parseInt(e.target.value)) }} >
                            {typeConfig[ticketType]['radioActions'].map((action, index) => (
                                <option key={action} value={index}>{action}</option>
                            ))}
                        </select>
                    </div>
                </div>
                {['29', '28'].includes(appId) && (<div className='row mt-2'>
                    <div className="col-2">
                        Driver Waiver:
                    </div>
                    <div className="col-4 align-self-start">
                        <select className="form-control" id="driverWaiver" value={driverwaiver} onChange={e => setDriverwaiver(e.target.value)}>
                            <option value=""> </option>
                            <option value="Available">Available</option>
                            <option value="Not Available">Not Available</option>
                        </select>
                    </div>
                </div>)}
                {['27'].includes(appId) && (<div className='row mt-2'>
                    <div className="col-2">
                        Applicant Resume:
                    </div>
                    <div className="col-4 align-self-center">
                        <select className="form-control" id="applicant_resume" value={applicantResume} onChange={e => setApplicantResume(e.target.value)}>
                            <option value=""> </option>
                            <option value="Available">Available</option>
                            <option value="Not Available">Not Available</option>
                        </select>
                    </div>
                </div>)}
                {["29"].includes(appId) && (<div className='row mt-2 mb-2'>
                    <div className="pl-3">
                        Middle Class License:
                    </div>
                    <div className="col-4 align-self-center">
                        <select className="form-control" id="middle_class_license" value={middleClassLicense} onChange={(e) => setMiddleClassLicense(e.target.value)}>
                            <option value=""> </option>
                            <option value="Available">Available</option>
                            <option value="Not Available">Not Available</option>
                        </select>
                    </div>
                </div>)}
                Ticket Status: {responseIsOpen ? "Open" : "Closed"}
            </p>

            <form onSubmit={submitResponse}>

                <div className="lead mb-5">
                    <form className="form-inline">
                        <div className="form-group">
                            <label className="mr-1" htmlFor="duration">Attachments:</label>
                            <table className="table-bordered tableBorderDark" style={{ marginLeft: '2.1rem' }}>
                                <tr className="text-center">
                                    <td>Expected</td>
                                    <td>Received</td>
                                    <td>Pending</td>
                                </tr>
                                <tr className="text-center">
                                    <td>
                                        {ticketType === ticketTypeRTK ? (<input type="number" className="bg-light form-control date-form"
                                            value={editExpectedAttachments}
                                            min="0" max={99}
                                            step="1"
                                            onChange={e => setEditExpectedAttachments(e.target.value)}
                                        />) : editExpectedAttachments}
                                    </td>
                                    <td>{existingAttachments}</td>
                                    <td>
                                        {pendingAttachmentCount < 0 ? 0 : pendingAttachmentCount}
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </form>
                </div>
                {/* Do not display the JSON input field for RTD and RTOO */}
                {ticketType === ticketTypeRTK ?
                    <div>
                        {jsonEditable ?
                            <div className="form-group row">
                                <label htmlFor="JSON">Update the DSAR JSON</label>
                                <textarea className="form-control" id="JSON" rows="6" placeholder="Enter valid Response JSON here..."
                                    value={json} onChange={(e) => { setJson(e.target.value) }}
                                    required={responseAutomated === true}
                                >
                                </textarea>
                                <div className="invalid-feedback">
                                    Could not create a response. Please check if JSON is valid.
                                </div>
                            </div>
                            :
                            <div className="bg-light rounded border mb-3">
                                {(<div className="m-2"><pre>{json}</pre></div>)}
                            </div>
                        }
                    </div>
                    :
                    null
                }
                <div className="text-center">
                    <h5>Existing Attachments</h5>

                    <table className="table mt-3">
                        <thead>
                            <tr>
                                <th scope="col">Filename</th>
                                <th scope="col">Type</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {attachments ?
                                attachments.map(attachment => (
                                    <tr key={attachment.filename}>
                                        <td>{attachment.filename}</td>
                                        <td>{attachment.attachment_type}</td>
                                        <td>
                                            <button type="button" className="btn btn-sm btn-primary ml-2" value={attachment.filename} onClick={downloadFile}>
                                                {/* <i class="material-icons">cloud_download</i> */}
                                                Download
                                            </button>
                                            <button type="button" className="btn btn-sm btn-danger ml-2" value={attachment.filename} onClick={markToDelete}>
                                                Delete
                                            </button>
                                        </td>
                                    </tr>
                                ))
                                :
                                null
                            }
                        </tbody>
                    </table>
                </div>
                <Modal isOpen={fileToDelete ? true : false} modalClassName='ssoMainblock'>
                    <div className="text-center p-2 ssoHeader">Delete Confirmation</div>
                    <ModalBody>
                        <div>
                            Are you sure you want to permanently remove this item? This action cannot be undone.
                        </div>
                    </ModalBody>
                    <ModalFooter className='p-2 removeBoder'>
                        <Button color='primary' onClick={() => { deleteAttachment(fileToDelete) }}>Ok</Button>
                        <Button onClick={() => { setFileToDelete(null); }}>Cancel</Button>
                    </ModalFooter>
                </Modal>

                {ticketType === ticketTypeRTK ?
                    <div className="mb-5">
                        <div className="form-group-row text-center">
                            <div className="custom-file mb-5 col-6">
                                <input type="file" multiple className="custom-file-input col-6" id="responseAttachment"
                                    required={attachmentRequired()}
                                    accept=".pdf, .csv, .zip" onChange={(e) => handleFileChange(e)}
                                />
                                <label className="custom-file-label text-left overflow-hidden" htmlFor="responseAttachment">
                                    Upload Attachments
                                </label>
                                <small id="responseHelp" className="form-text text-muted">You may select multiple attachments to upload</small>
                            </div>
                        </div>
                        <hr />
                    </div>
                    : null
                }

                <div className="form-group-row text-center">
                    <div className="custom-file mb-5 col-6">
                        <input type="file" multiple className="custom-file-input col-6" id="responseScreenshot"
                            required={screenshotRequired()}
                            accept="image/*" onChange={(e) => handleFileChange(e)}
                        />
                        <label className="custom-file-label text-left overflow-hidden" htmlFor="responseScreenshot">
                            Upload Screenshots
                        </label>
                        <small id="responseHelp" className="form-text text-muted">You may select multiple screenshots to add</small>
                        <div className="invalid-feedback" id="invalid-feedback"></div>
                    </div>
                </div>
                <div className="mt-2 mb-2 text-center text-danger small" id={ErrorDomId}></div>
                <div className="form-group-row text-center">
                    <Link to={`/ticket/${dsarRequestId}/${appId}/viewResponse`}>
                        <button type="button" className="btn btn-secondary mr-2">Discard Changes</button>
                    </Link>
                    <button type="submit" className="btn btn-warning" id="update-response-btn">Save Changes</button>
                </div>
            </form>

        </div>
    );
}

export default withNavbar(UpdateResponse);
