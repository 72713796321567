import React, { useState } from 'react';
import axios from '../../common/AxiosConfig';

const LabelErrorCls = 'text-danger';

function SettingCard(props) {
    const { app, authToken, isAdmin, updateCardData, cardData } = props;

    const [currentOwner, setCurrentOwner] = useState(cardData.CURRENT_OWNER)
    const [currentSubscriber, setCurrentSubscriber] = useState(cardData.CURRENT_SUBSCRIBER)
    const [litigationVins, setLitigationVins] = useState(cardData.LITIGATION_VINS)
    const [activeRental, setActiveRental] = useState(cardData.ACTIVE_RENTAL)
    const [activeRelationship, setActiveRelationship] = useState(cardData.ACTIVE_RELATIONSHIP);
    const [autocloseSettingError, setAutocloseSettingError] = useState(false)

    const [editMode, setEditMode] = useState(false)

    const exitEditMode = () => {

        setCurrentOwner(cardData.CURRENT_OWNER);
        setCurrentSubscriber(cardData.CURRENT_SUBSCRIBER);
        setLitigationVins(cardData.LITIGATION_VINS);
        setActiveRental(cardData.ACTIVE_RENTAL);
        setActiveRelationship(cardData.ACTIVE_RELATIONSHIP);
        const errorFeedback = document.getElementById("edit-settings-error")
        errorFeedback.innerHTML = ""

        setEditMode(false);
    }

    const updateSettings = () => {

        let payload = {
            "ACTIVE_RELATIONSHIP": activeRelationship
        }
        if (app.requestor === 'Customer') {
            payload = {
                "CURRENT_OWNER": currentOwner,
                "LITIGATION_VINS": litigationVins,
                "CURRENT_SUBSCRIBER": currentSubscriber,
                "ACTIVE_RENTAL": activeRental,

            };
        }

        axios.put(`ticket/autoclose_settings/${app.requestor}`, {
            [app.id]: payload
        }, {
            headers: {
                Authorization: authToken
            }
        })
            .then(res => {

                updateCardData(payload, app);
                setEditMode(false)

            }).catch(err => {
                if (err?.response?.status === 401) {
                    setAutocloseSettingError(err?.response?.data?.message || "You are not an admin!");
                }
                console.log(err)

            })
    }

    const isFieldVisible = (name) => {
        return app?.fields?.includes?.(name) || false;
    }

    const editModeUI = (
        <h4>
            <div className="d-flex flex-row-reverse">
                <span className="small text-danger" id="edit-settings-error">{autocloseSettingError}</span>
            </div>
            {app.name} [Requestor = {app.requestor} ]
            <span className="float-right">
                {editMode ?
                    <div>
                        <button type="button" className="btn btn-sm small-button btn-danger mr-2" onClick={exitEditMode}>
                            <i className="material-icons small-icon">clear</i>
                        </button>
                        <button type="button" className="btn btn-sm small-button btn-success" onClick={updateSettings}>
                            <i className="material-icons small-icon">done</i>
                        </button>
                        <br />
                    </div>
                    :
                    <div>
                        {isAdmin && (
                            <button type="button" className="btn btn-sm btn-warning small-button" onClick={() => { setEditMode(true) }}>
                                <i className="material-icons small-icon">edit</i>
                            </button>)}
                    </div>
                }
            </span>
        </h4>
    )
    return (
        <div className="card mb-2 bg-light">
            <div className="card-body">
                {editModeUI}

                <div className="d-flex d-flex ml-5 mt-3 mb-3">
                    <div>
                        {isFieldVisible('currentOwner') && (<p className="custom-control custom-switch mb-0">
                            <input type="checkbox" className="custom-control-input" id={app.id + app.requestor + "currentOwner"}
                                checked={currentOwner} disabled={!editMode}
                                onChange={e => setCurrentOwner(e.target.checked)}
                            />
                            <label className={`custom-control-label ${!currentOwner ? LabelErrorCls : ""}`} htmlFor={app.id + app.requestor + "currentOwner"}>Current Ownership</label>
                        </p>)}
                        {isFieldVisible('currentSubscriber') && (<p className="custom-control custom-switch mb-0">
                            <input type="checkbox" className="custom-control-input" id={app.id + app.requestor + "currentSubscriber"}
                                checked={currentSubscriber} disabled={!editMode}
                                onChange={e => setCurrentSubscriber(e.target.checked)}
                            />
                            <label className={`custom-control-label ${!currentSubscriber ? LabelErrorCls : ""}`} htmlFor={app.id + app.requestor + "currentSubscriber"}>Active Subscription</label>
                        </p>)}
                        {isFieldVisible('litigationVins') && (<p className="custom-control custom-switch mb-0">
                            <input type="checkbox" className="custom-control-input" id={app.id + app.requestor + "litigationVins"}
                                checked={litigationVins} disabled={!editMode}
                                onChange={e => setLitigationVins(e.target.checked)}
                            />
                            <label className={`custom-control-label ${!litigationVins ? LabelErrorCls : ""}`} htmlFor={app.id + app.requestor + "litigationVins"}>Litigation Hold</label>
                        </p>)}
                        {isFieldVisible('activeRental') && (<p className="custom-control custom-switch mb-0">
                            <input type="checkbox" className="custom-control-input" id={app.id + app.requestor + "activeRental"}
                                checked={activeRental} disabled={!editMode}
                                onChange={e => setActiveRental(e.target.checked)}
                            />
                            <label className={`custom-control-label ${!activeRental ? LabelErrorCls : ""}`} htmlFor={app.id + app.requestor + "activeRental"}>Upcoming Rental</label>
                        </p>)}
                        {isFieldVisible('activeRelationship') && (<p className="custom-control custom-switch mb-0">
                            <input type="checkbox" className="custom-control-input" id={app.id + app.requestor + "activeRelationship"}
                                checked={activeRelationship} disabled={!editMode}
                                onChange={e => setActiveRelationship(e.target.checked)}
                            />
                            <label className={`custom-control-label ${!activeRelationship ? LabelErrorCls : ""}`} htmlFor={app.id + app.requestor + "activeRelationship"}>Active Relationship</label>
                        </p>)}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SettingCard;
