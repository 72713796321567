import React, { useState } from 'react';
import axios from '../../../../common/AxiosConfig';
import moment from 'moment';

const FieldInvalidCls = 'is-invalid';

function BulkUploadByDate(props) {
    const { authToken, userId, system, title, appId, type } = props
    const yesterday = moment(new Date()).subtract(1, 'days').format('YYYY-MM-DD')

    const [date, setDate] = useState(yesterday);
    const [files, setFiles] = useState([]);
    const [fileNames, setFileNames] = useState([]);

    const handleFileChange = (e) => {
        e.persist() // Persist the state of the event
        const files = e.target.files

        // Initialize the new state of 'files'
        const arrayOfFiles = []
        const arrayOfFileNames = []

        for (const file of files) {
            arrayOfFileNames.push(file.name)
            arrayOfFiles.push(file)
        }

        // Set the state after arrayOfFiles has been populated
        setFiles(arrayOfFiles)
        setFileNames(arrayOfFileNames)

        const label = document.getElementById(`bulkAttachments${system}`).nextSibling;
        if (e.target.files.length === 1) {
            label.innerHTML = e.target.files[0].name;
        } else if (e.target.files.length > 1) {
            label.innerHTML = `${e.target.files.length} files`
        }
    }

    const submitBulkResponses = (e) => {
        e.preventDefault()

        // Disable the submit button
        const submitBtn = document.getElementById(`bulk-upload-btn-${system}`)
        submitBtn.setAttribute('disabled', true)
        let bulkapiurl = 'response/bulk_upload_by_date';
        if (type !== "rtd") {
            bulkapiurl = "response/bulk_upload";
        }
        axios.post(bulkapiurl, {
            attachment_filenames: fileNames,
            app_id: appId,
            user_id: userId,
            date: date,
        }, {
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            document.getElementById(`date-${system}`).classList.remove(FieldInvalidCls)
            document.getElementById(`bulkAttachments${system}`).classList.remove(FieldInvalidCls)
            document.getElementById(`bulkAttachments${system}`).classList.add('is-valid')

            document.getElementById(`valid-feedback-attachments-${system}`).innerHTML = res.data.message

            // Upload the attachments using the presigned URLs returned by the API
            const posts = res.data.presignedPosts
            if (!posts.length) { return }

            for (const post of posts) {
                // For the case where there are multiple files, find the proper file from the array
                const filename = post.fields.key.split('/').pop()

                // Populate the form data
                const formData = new FormData()
                for (const key in post.fields) {
                    formData.append(key, post.fields[key])
                }

                // Iterate through all the files and file one with the matching filename
                for (const file of files) {
                    if (file.name === filename) {
                        formData.append('file', file)
                    }
                }

                axios.post(post.url, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                }).then(res => {
                    console.log(res)
                }).catch(err => {
                    console.log(err)
                })
            }
        }).catch(err => {
            console.log(err)
            // Display an error message to the user if the creation did not complete
            const invalidFeedback = document.getElementById(`invalid-feedback-bulk-${system}`)
            invalidFeedback.innerHTML = err.response.data.message
            document.getElementById(`date-${system}`).classList.add(FieldInvalidCls)
        }).finally(() => {
            const modal = document.getElementById(`bulk-modal-${appId}-${type}`)
            modal.classList.remove('show')
            const backdrop = document.getElementsByClassName('modal-backdrop')
            document.body.removeChild(backdrop[0])
        })
    }

    const clear = () => {
        setFiles([])
        setFileNames([])
        const invalidFeedback = document.getElementById(`invalid-feedback-bulk-${system}`)
        invalidFeedback.innerHTML = null
        document.getElementById(`date-${system}`).classList.remove(FieldInvalidCls, 'is-valid')
        const attachmentsInput = document.getElementById(`bulkAttachments${system}`)
        attachmentsInput.value = null
        attachmentsInput.classList.remove(FieldInvalidCls, 'is-valid')
        attachmentsInput.nextSibling.innerHTML = "Upload Screenshots"
    }

    const showModal = (e) => {
        e.preventDefault()
        const showModalBtn = document.getElementById(`show-modal-${appId}-${type}`)
        showModalBtn.click()
    }

    return (
        <div className="card mb-2 bg-light">

            <div className="card-body">
                <h5>
                    {title}
                    <span className="small">&nbsp;{type === "rtd" ? "[Right to Delete]" : "[Remaining Rights]"}</span>
                </h5>

                <form onSubmit={showModal}>

                    <div className="form-group-row text-center mb-3">
                        <div className=" custom-file col-5">
                            <input type="date" id={`date-${system}`} className="form-control date-form" required
                                max={yesterday} min="2020-01-01" value={date} onChange={e => setDate(e.target.value)}
                            />
                            <small className="form-text text-muted">Select the date to update SXM Audio responses in bulk</small>
                            <div className="invalid-feedback" id={`invalid-feedback-bulk-${system}`}></div>
                        </div>
                    </div>

                    <div className="form-group-row text-center mb-3">
                        <div className="custom-file col-6">
                            <input type="file" multiple className="custom-file-input col-6" id={`bulkAttachments${system}`}
                                required={true} accept="image/*" onChange={(e) => handleFileChange(e)}
                            />
                            <label className="custom-file-label text-left overflow-hidden" htmlFor={`bulkAttachments${system}`}>
                                Upload Screenshots
                            </label>
                            <small id="responseHelp" className="form-text text-muted">You may select multiple files to upload</small>
                            <div className="valid-feedback" id={`valid-feedback-attachments-${system}`}>
                                Bulk Upload Completed Succuessfully!
                            </div>
                        </div>
                    </div>

                    <div className="form-group-row text-center">
                        <button type="button" className="btn btn-secondary mr-2" onClick={clear}>Clear</button>
                        <button type="button" id={`show-modal-${appId}-${type}`} className="d-none" data-toggle="modal" data-target={`#bulk-modal-${appId}-${type}`}>Show Modal</button>
                        <button type="submit" className="btn btn-primary" id={`bulk-upload-btn-${system}`} disabled={!files.length}>
                            Create Bulk Response
                        </button>
                    </div>
                </form>
            </div>

            {/* Modal */}
            <div className="modal fade" id={`bulk-modal-${appId}-${type}`} tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog mb-3" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Confirm Bulk Upload</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {type === "rtd" ? `Mark all Right to Delete requests sent to SXM Audio on ${date} as success?` : `Mark all requests except Right to Delete sent to SXM Audio on ${date} as success?`}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary" onClick={submitBulkResponses}>Confirm</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default BulkUploadByDate;
