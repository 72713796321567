import React, { useState } from 'react';
import AdminsOnly from '../../common/AdminsOnly';
import axios from '../../common/AxiosConfig';


function RetentionCard(props) {
    const { app, authToken, isAdmin, fetchData, desc } = props;

    const [editMode, setEditMode] = useState(false)

    const [appRetention, setAppRetention] = useState(app.retention_period)

    let list = [];
    const cps_list_data = [50, 730]
    const cps_list = [];
    const non_cps_list = [];

    if (isAdmin === null) {
        return null
    } else if (isAdmin === false) {
        return <AdminsOnly header="Retention" />
    }

    for (let i = 0; i < cps_list_data.length; i++) {
        cps_list.push(<option key={i + 1}>{cps_list_data[i]}</option>);
    }
    for (let i = 0; i < 30; i++) {
        non_cps_list.push(<option key={i + 1}>{i + 1}</option>);
    }

    if (app.module_name === "CPS") {
        list = cps_list
    }
    else {
        list = non_cps_list
    }

    const updateApp = () => {
        axios.post(`appticket_retention/update`, {
            module_name: app.module_name,
            retention_period: appRetention,
        }, {
            headers: {
                Authorization: authToken
            }
        })
            .then(res => {
                fetchData()
                setEditMode(false)
            }).catch(err => {
                console.log(err)
            })
    }

    const exitEditMode = () => {
        setAppRetention(app.retention_period)
        setEditMode(false)
    }

    return (
        <div className="container mb-5" style={{ paddingLeft: '0px' }}>
            <div className="mb-3">
                <div key="CPS" className="card mb-2 bg-light">
                    <div className="card-body">
                        <h5 className="mb-3">
                            {app.module_name}
                            <span className="float-right">
                                {editMode ?
                                    <div>
                                        <button type="button" className="btn btn-sm small-button btn-danger mr-2" onClick={() => exitEditMode()}>
                                            <i className="material-icons small-icon">clear</i>
                                        </button>
                                        <button type="button" className="btn btn-sm small-button btn-success" onClick={() => updateApp()}>
                                            <i className="material-icons small-icon">done</i>
                                        </button>
                                        <br />
                                    </div>
                                    :
                                    <div>
                                        {isAdmin ?
                                            <button type="button" className="btn btn-sm btn-warning small-button" onClick={() => setEditMode(true)}>
                                                <i className="material-icons small-icon">edit</i>
                                            </button>
                                            : null
                                        }
                                    </div>
                                }
                            </span>
                        </h5>
                        <table className="table table-striped mb-0">
                            <tbody>
                                <tr>
                                    <td className="w-75">{desc}</td>
                                    <td>
                                        {editMode ?
                                            <select className="form-control form-control-sm" value={appRetention} onChange={e => setAppRetention(e.target.value)}>
                                                <option>Forever</option>
                                                {list}
                                            </select>
                                            :
                                            appRetention
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default RetentionCard;


