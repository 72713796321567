// This File Contains all the MUI Components Styled with custom Theme

import {
    Tabs as MuiTabs,
    Tab as MuiTab,
    Accordion as MuiAccordion,
    AccordionSummary as MuiAccordionSummary,
    AccordionDetails as MuiAccordionDetails,
    withStyles,
    CircularProgress
} from '@material-ui/core';


export const Tabs = withStyles({
    root: {
        minHeight: '35px',
    },
    indicator: {
        left: '0px',
        height: '3px'
    }
})(MuiTabs);

export const Tab = withStyles({
    root: {
        background: "#dae3f3",
        marginRight: '5px',
        outline: '0px !important',
        minHeight: '35px',
    },
    wrapper: {
        fontWeight: 'bold',
    },
    selected: {
        background: "white",
    }
})(MuiTab);

export const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        flex: 0,
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
            flex: 1,
            width: '100%'
        },
    },
    expanded: {},
})(MuiAccordion);

export const AccordionSummary = withStyles({
    root: {
        backgroundColor: '#7395d3',
        color: 'white',
        fontWeight: 600,
        minHeight: 40,
        '&$expanded': {
            minHeight: 40,
        },
        margin: '0px 0'
    },
    content: {
        margin: '0px 0',
        '&$expanded': {
            margin: '0px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

export const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiAccordionDetails);

export const LoadingIndicator = withStyles((theme) => ({
    root : {
        position : 'fixed',
        top : '50%',
        left : '50%',
        zIndex : 500
    }
}))(CircularProgress);