import React from 'react';
import '../../../../../css/main.css';
import BulkUploadByDate from './BulkUploadByDate';


function BulkUploadTab(props) {
    const { authToken, userId } = props

    return (
        <div className="tab-pane fade mt-4 mb-5" id="bulk" role="tabpanel" aria-labelledby="bulk-tab">

            {/* <BulkUploadCard userId={userId} authToken={authToken} system={"SXM Audio"} appId={11} /> */}
            <BulkUploadByDate userId={userId} authToken={authToken} system={"SXM Audio - rtd"} title="SXM Audio" appId={11} type={"rtd"} />
            <BulkUploadByDate userId={userId} authToken={authToken} system={"SXM Audio - remaining"} title="SXM Audio" appId={11} type={"remaining"}/>

        </div>
    );
}

export default BulkUploadTab;
