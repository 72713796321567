import { FirstPage, ChevronLeft, ChevronRight, LastPage } from '@material-ui/icons';
import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from '@material-ui/core';


const useStyles = makeStyles(() => ({
    tablePagination: {
        display: 'flex',
        fontSize: '0.7rem',
        
        "& .MuiButtonBase-root" :{
            padding : '0px',
        },
        "& .MuiSvgIcon-root": {
            width: '0.7em',
            height: '0.7em'
        },
        "& .pagination": {
            flex: 1,
            justifyContent: 'flex-end'
        }
    }
}));

const Pagination = (props) => {
    const { currentPage, pageCount, totalResults, pageChange } = props;
    const classes = useStyles();
    const totalPages = Math.ceil(totalResults / pageCount)
    const handlePagination = (page) => {
        pageChange(page)
    }
    return (<nav>
        <div className={classes.tablePagination}>
            <div className="pagination">
                [{((currentPage - 1) * pageCount) + 1}] to [{(currentPage * pageCount) < totalResults ? (currentPage * pageCount) : totalResults}] of [{totalResults}]
            </div>
            <ul className="pagination">
                <li className="page-item mr-2">
                    <IconButton onClick={() => handlePagination(1)} disabled={currentPage === 1}>
                        <FirstPage />
                    </IconButton>
                </li>
                <li className="page-item mr-2">
                    <IconButton onClick={() => handlePagination(currentPage - 1)} disabled={currentPage === 1}>
                        <ChevronLeft />
                    </IconButton>
                </li>
                <li className="page-item">
                    {`Page [${currentPage}] of [${totalPages}]`}
                </li>
                <li className="page-item ml-2">
                    <IconButton onClick={() => handlePagination(currentPage + 1)} disabled={currentPage === totalPages}>
                        <ChevronRight />
                    </IconButton>
                </li>
                <li className="page-item ml-2 mr-4">
                    <IconButton onClick={() => handlePagination(totalPages)} disabled={currentPage === totalPages}>
                        <LastPage />
                    </IconButton>
                </li>
            </ul>
        </div>
    </nav>)
}
export default Pagination;