/**
 * Pending Attachment Report Main index file which is refered in reports Page
 * Component can be accessed from reports -> pending atttachments report tab
 * can also be accessed using the route newui/reports/pendingattachmentreport
 */
import React, { useState, useContext, useEffect } from "react";
import moment from "moment";
import axios from '../../../common/AxiosConfig';
import { AppContext } from '../../../../RoutesWithAuth';
import { Tooltip, Typography } from "@material-ui/core";
import { Link, useHistory } from 'react-router-dom';
import DataGrid from '../grid';
import { LoadingIndicator } from "./reconcilationreport/MuiStyled";
import { DATEFORMATUTC, DATEFORMATLOCAL, v2UIRoutePath } from '../../../common/commons';

function PendingAttachmentsReport(props) {

    const context = useContext(AppContext)
    const history = useHistory();
    const authToken = context.authToken.get
    const [pendingAttachments, setPendingAttachments] = useState([])
    const [isInProgress, setIsInProgress] = useState(false)

    useEffect(() => {
        if (authToken) {
            fetchData()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authToken])

    const fetchData = () => {
        setIsInProgress(true);
        axios.get('/reports/pending-attachments', {
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            setPendingAttachments(res.data?.map((data, index) => {
                return {
                    id: index + 1,
                    ...data,
                    createdDateandTimeLocal: data.created_date ? moment(data.created_date).local().format(DATEFORMATLOCAL) : '--',
                    createdDateandTimeUTC: data.created_date ? moment(data.created_date).utc().format(DATEFORMATUTC) : '--',
                }
            }))
            setIsInProgress(false)
        }).catch(err => {
            console.log(err)
            setIsInProgress(false)
        })
    }

    return (

        <div>
            {isInProgress && (<>
                <LoadingIndicator />
            </>)}
            <Typography variant="h6" className={'tabHeader'}>
                <Link color="primary" onClick={() => {
                    fetchData();
                }}
                >
                    PENDING ATTACHMENTS REPORT
                </Link>
            </Typography>

            <DataGrid
                // customHeight={162}
                customRowHeight={30}
                columns={[{
                    name: "#",
                    mapping: "id",
                    width: 40
                }, {
                    name: 'Request ID',
                    mapping: "dsar_id",
                    width: 380,
                    renderCell: (params) => {
                        return (<span className='ashyperlink'>
                            {params.row["dsar_id"]}
                        </span>)
                    }
                }, {
                    name: "Requestor",
                    mapping: "requestor",
                    width: 120
                }, {
                    name: "Downstream",
                    mapping: "app_name",
                    width: 120
                }, {
                    name: "Pending Files",
                    mapping: "pending_attachments",
                    width: 120
                }, {
                    name: "Created Date",
                    mapping: "created_date",
                    width: 200,
                    renderCell: (params) => (
                        <Tooltip title={params.row["createdDateandTimeLocal"]}>
                            <span>
                                {params.row["createdDateandTimeUTC"]}
                            </span>
                        </Tooltip>
                    ),
                }]}
                onCellClick={(cellparams) => {
                    history.push(v2UIRoutePath + "requestdetails/" + cellparams.row["dsar_id"]);
                }}
                rows={pendingAttachments}
            />
        </div>
    );
}

export default React.memo(PendingAttachmentsReport);
