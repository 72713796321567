import React, { useState, useEffect } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import DownstreamBarGraph from './DownstreamBarGraph';
import axios from '../../../../../common/AxiosConfig';
import moment from 'moment';

function DownstreamChart(props) {
    const { authToken } = props
    const today = moment().format('YYYY-MM')

    const [date, setDate] = useState(today)

    const [downstreamMetrics, setDownstreamMetrics] = useState([])
    const [tableFooter, setTableFooter] = useState(moment().format('MMMM YYYY'))

    useEffect(() => {
        if (authToken) {
            axios.get('/reports/aging', {
                params: {
                    metric_age: "all",
                    metric_date: date,
                    downstream_chart: true,
                },
                headers: {
                    Authorization: authToken
                }
            }).then(res => {
                setDownstreamMetrics(
                    res.data.old.reverse().map(system => {
                        const downstreamTotal = system.data.reduce((sum, num) => sum + num, 0)

                        return {
                            name: system.name + ` (${downstreamTotal})`,
                            RTC: system.data[0],
                            RTD: system.data[1],
                            RTK: system.data[2],
                            RTO: system.data[3],
                        }
                    })
                )

                setTableFooter(moment(date).format('MMMM YYYY'))
            }).catch(err => {
                console.log(err)
            })
        }
    }, [authToken, date])


    return (
        <div className="mb-5">

            <form className="form-inline mt-3">
                <div className="form-group mr-2 mb-2">
                    <label className="mr-1" htmlFor="duration">Select Month:</label>
                    <input type="month" className="form-control date-form" value={date}
                        min="2020-01" max={today} onChange={e => setDate(e.target.value)} required
                    />
                </div>
            </form>

            <h4 className="text-center mt-4">Downstream Requests</h4>

            <div style={{ height: "500px" }}>
                <DownstreamBarGraph data={downstreamMetrics} />
            </div>

            <SwitchTransition>
                <CSSTransition key={tableFooter} timeout={250} classNames="swipeR">
                    <div className="font-italic text-center">Date Range: {tableFooter}</div>
                </CSSTransition>
            </SwitchTransition>
        </div>
    );
}

export default DownstreamChart;
