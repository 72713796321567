import React, { useState, useContext,useEffect } from "react";
import moment from "moment";
import axios from "../../../../common/AxiosConfig";
import { AppContext } from "../../../../../RoutesWithAuth";
import { Typography, makeStyles } from "@material-ui/core";
import FormGroup from "../../FormGroup";
import {RequestorMapping} from '../../../../common/commons';
import RequestCountBarChart from "./RequestCountBarChart";
import { LoadingIndicator } from "../reconcilationreport/MuiStyled";

const useStyles = makeStyles(() => ({
    root : {
        '& svg > g > g:nth-child(3)':{
            '& text' :{
                transform : 'rotate(-90deg)'
            }
        },
        '& svg > g > g:nth-child(2)':{
            '& g' :{
                display : 'none'
            },
            '& line':{
                stroke : '#ddddde !important'
            }
        },
        '& svg > g > g:nth-child(1)':{
            '& line':{
                stroke : '#ddddde !important'
            },
            '& text' :{
                textTransform: 'uppercase'
            }
        }
    },
    formGroupWrapper : {
        width : '600px',
        '& .multiSelectedOptions':{
            width : '140px'
        }
    },
    customInputCls : {
        width : '140px',
    },

}));
function RequestCountReport(props) {
    const context = useContext(AppContext)
    const authToken = context.authToken.get
    const classes = useStyles();
    const today = moment();
    //state for maintaining form related data
    const [formData, setFormData] = useState({
        from_date : "",
        to_date : "",
        requestor : ['All']
    });
    const [requestCountData, setRequestCountData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    
    
    useEffect(() => {
      if(authToken){
        fetchData()
      }
      
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authToken,formData])
    
    const fetchData = ()=>{
        let requestor = formData.requestor.join(', ')?.toLowerCase();
        if(requestor === "all"){
            requestor = "ALL"
        } else if(requestor === ""){
            return false
        }
        setIsLoading(true)
        axios.get("/reports/request_count_chart_newui", {
            headers: {
                Authorization: authToken
            },
            params: {
                ...formData,
                requestor
            }
        }).then(res => {

            const unformatedResponseData = res?.data?.[0] || {};
            const requestTypeRows = [
                ["Right to Correct",'rtc'],
                ["Right to Delete",'rtd'],
                ["Right to Know", 'rtk'],
                ["Right to OptOut","rtoo"],
                ["Total","total"]    
            ];
            const ticket_status = [
                ['_cancelled','Cancelled'],
                ['_closed','Closed'],
                ['_open','Open'],
                ['_review','Review'],
                ['_upload_failed','Upload Failed'],
                ['_uploaded','Uploaded']
            ]
            const mappedUiResponse = requestTypeRows.map((requestTypeRow)=>{
                let requestTypeColumns = {};

                ticket_status.forEach((_ticket_status)=>{
                    requestTypeColumns[_ticket_status[1]] = unformatedResponseData[`${requestTypeRow[1]}${_ticket_status[0]}`]
                })
                return {
                    "name": requestTypeRow[0],
                    "Total" : unformatedResponseData[requestTypeRow[1]],
                    ...requestTypeColumns
                }
            })
            setRequestCountData(mappedUiResponse);
            setIsLoading(false)
        }).catch(err => {
            console.log(err)
            setIsLoading(false)
        })
    }
    
    return (

        <div className={`${classes.root}`}>
            {isLoading && (<LoadingIndicator />)}
            <Typography variant="h6" className={'tabHeader'}>
                REQUEST COUNT REPORT
            </Typography>
            <FormGroup 
                formWrapperClassName={classes.formGroupWrapper}
                fields={[{
                    type : 'date',
                    name : "from_date",
                    label : "From Date",
                    defaultOption : "",
                    inputAdditionalProps : {
                        min : "2020-01-01",
                        max : today.format("YYYY-MM-DD")
                    }
                },{
                    type : 'date',
                    name : "to_date",
                    label : "To Date",
                    defaultOption : "",
                    inputAdditionalProps : {
                        min : formData?.from_date || "2020-01-01",
                        max : today.format("YYYY-MM-DD")
                    }
                },{
                    type : 'multiselect',
                    name : 'requestor',
                    label : 'Requestor',
                    isMulti : true,
                    customInputCls : classes.customInputCls,
                    options : ["All", ...Object.values(RequestorMapping)],
                    defaultOption : 'All',
                    size : 2
                }]}
                disableFormActions = {true}
                customFormUpdates={(formData)=>{
                    // This Method is called when ever there is any change in the form fields
                    setFormData(formData)
                }}
            />
            <div style={{ height: "570px" }}>
                <RequestCountBarChart 
                //Passing dummy data as API is not integrated yet
                    data={requestCountData}
                />
            </div>
        </div>
    );
}

export default RequestCountReport;
