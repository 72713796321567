import React, { useState, useEffect, useContext } from 'react';
import { CSSTransition } from 'react-transition-group';
import { AppContext } from '../../RoutesWithAuth';
import { Link } from 'react-router-dom';
import axios from '../common/AxiosConfig';
import { withNavbar } from '../common/Navbar';
import { copyToClipboard } from "../common/commons";


function ViewResponse(props) {
	const { dsarRequestId, appId } = props.match.params;

	const context = useContext(AppContext)
	const authToken = context.authToken.get
	const isAdmin = context.isAdmin.get

	const [appName, setAppName] = useState('')
	const [appDisplayName, setAppDisplayName] = useState('')
	const [response, setResponse] = useState('Fetching JSON...')
	const [attachments, setAttachments] = useState([])
	const [isOpen, setIsOpen] = useState(false)
	const [expectedAttachments, setExpectedAttachments] = useState(0)
	const [existingAttachments, setExistingAttachments] = useState(0)
	const [action, setAction] = useState("")
	const [requestStatus, setRequestStatus] = useState("")
	const [middleClassLicense, setMiddleClassLicense] = useState("")
	const [driverwaiver, setDriverwaiver] = useState("")
	const [applicantResume, setApplicantResume] = useState("")

	useEffect(() => {
		if (authToken !== null) {
			const fetchData = () => {
				axios.get(`ticket/response/${dsarRequestId}/${appId}`, {
					headers: {
						Authorization: authToken
					}
				}).then(res => {
					setResponse(res.data.response_json ? res.data.response_json : {})
					setAttachments(res.data.attachment_names)
					setAppName(res.data.app_name)
					setAppDisplayName(res.data.app_display)
					setAction(actionMappings[res.data.response_action])
					setRequestStatus(res.data.status)
					setIsOpen(res.data.response_is_open)
					setExpectedAttachments(res.data.expected_attachments)
					setExistingAttachments(res.data.existing_attachments)
					if(typeof res.data.license !== "string"){
						res.data.license = "None"
					}
					setMiddleClassLicense(res.data.license)
					if(typeof res.data.driverwaiver !== "string"){
						res.data.driverwaiver = "None"
					}
					if(typeof res.data.resume !== "string"){
						res.data.resume = "None"
					}
					setApplicantResume(res.data.resume)
					setDriverwaiver(res.data.driverwaiver)

				}).catch(err => {
					console.log(err)
				})
			}

			fetchData()
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authToken])

	const downloadFile = (e) => {
		const filename = e.target.value

		axios.get(`ticket/response/download/${dsarRequestId}/${appId}/${filename}`, {
			headers: {
				Authorization: authToken
			}
		}).then(res => {

			const link = document.createElement('a');
			link.href = res.data.presignedGet;
			link.setAttribute('download', `${filename}`);
			document.body.appendChild(link);
			link.click();
			link.remove()

		})
	}

	const downloadJSON = () => {
		const link = document.createElement('a');
		const file = new Blob([JSON.stringify(response, null, 2)], { type: 'application/json' })
		link.href = URL.createObjectURL(file);
		link.setAttribute('download', `${dsarRequestId}_${appName}_Response.json`);
		document.body.appendChild(link);
		link.click();
		link.remove()
	}
	const pendingAttachmentsCount = expectedAttachments - existingAttachments;
	return (
		<div className="container mb-5">
			<div className="jumbotron bg-transparent py-1 px-0 mb-2">
				<h1 className="display-4" style={{ fontSize: '2.7rem' }}>
					View Ticket Response
					{isAdmin && (requestStatus !== "Uploaded") ?
						<Link to={`/ticket/${dsarRequestId}/${appId}/updateResponse`}>
							<button className="btn btn-warning float-right mt-3">
								<i className="material-icons">edit</i>
							</button>
						</Link>
						: null
					}
				</h1>
				<p className="lead" style={{ marginBottom: '0rem' }}>
					<Link to={`/`}>Home&nbsp;</Link>
					&gt; <Link to={`/ticket/${dsarRequestId}`}>Request ID: {dsarRequestId}&nbsp;</Link>
					&gt; View Response<br />
					System Name: {appDisplayName}<br />
					Response Action: {action}<br />
					{ ['29','28'].includes(appId) && (<>Driver Waiver: {driverwaiver}<br /></>)}
					{ ["29"].includes(appId) && (<>Middle Class License: {middleClassLicense}<br /></>)}
					{ ["27"].includes(appId) && (<>Applicant Resume: {applicantResume}<br /></>)}
					Ticket Status: {isOpen ? "Open" : "Closed"}
				</p>
			</div>

			{/* <div>
				Attachments:
				<table className="table-striped table-bordered table-sm">
					<tr className="text-center">
						<th>Expected</th>
						<th>Received</th>
						<th>Pending</th>
					</tr>
					<tr className="text-center">
						<td>{expectedAttachments}</td>
						<td>{existingAttachments}</td>
						<td>{expectedAttachments - existingAttachments}</td>
					</tr>
				</table>
			</div> */}

			<div className="lead">

				<form className="form-inline">
					<div className="form-group">
						<label className="mr-1" htmlFor="duration">Attachments:</label>
						<table className="table-bordered tableBorderDark" style={{ marginLeft: '2.1rem' }}>
							<tr className="text-center">
								<td>Expected</td>
								<td>Received</td>
								<td>Pending</td>
							</tr>
							<tr className="text-center">
								<td>
									{expectedAttachments}
								</td>
								<td>{existingAttachments}</td>
								<td>
									{pendingAttachmentsCount < 0 ? 0 : pendingAttachmentsCount}
								</td>
							</tr>
						</table>
					</div>
				</form>
			</div>

			{typeof response !== "string" ?
				<div className="text-right mb-2">
					<button className="btn btn-sm btn-primary" onClick={downloadJSON}>Download JSON</button>
				</div>
				:
				null
			}
			<CSSTransition in={typeof response !== "string"} timeout={400} classNames="scrollD-json" onEnter={() => {
				const container = document.getElementById("jsonContainer")
				const json = document.getElementById("json")
				container.style.setProperty("--max", `${json.offsetHeight}px`)
			}}>
				<div className="bg-light rounded border mb-3" id="jsonContainer">
				<span className="float-right mt-2 mr-2">
					<button
						className="btn btn-sm btn-secondary pt-1 pb-0 px-1"
						type="button"
						onClick={() => copyToClipboard(response)}
					>
						<span className="material-icons">content_copy</span>
					</button>
				</span>
					{(<div className="m-2" id="json"><pre>{JSON.stringify(response, null, 2)}</pre></div>)}
				</div>
			</CSSTransition>

			{isOpen && (expectedAttachments - existingAttachments > 0) ?
				<div className="text-center text-danger mb-3">
					Pending {expectedAttachments - existingAttachments} attachment(s) for processing
				</div>
				: null
			}

			{isOpen && (expectedAttachments - existingAttachments > 0) && appName === "Cerence" ?
				<div className="text-center text-danger mb-3">
					PDF generation may be in progress
				</div>
				: null
			}

			<CSSTransition in={attachments.length > 0} timeout={125} classNames="swipeD">
				<div>
					{attachments && attachments.some(file => file.attachment_type === 'attachment') ?
						<div className="text-center">
							<h5>Attachments</h5>

							<table className="table mt-3">
								<thead>
									<tr>
										<th scope="col">Filename</th>
										<th scope="col"></th>
									</tr>
								</thead>
								<tbody>
									{attachments ?
										attachments.map(attachment => {
											if (attachment.attachment_type === 'attachment') {
												return (
													<tr key={attachment.filename}>
														<td>{attachment.filename}</td>
														<td>
															<button className="btn btn-sm btn-primary ml-2" value={attachment.filename} onClick={downloadFile}>
																Download
															</button>
														</td>
													</tr>
												)
											}
											return null;
										})
										:
										null
									}
								</tbody>
							</table>
						</div>
						: null
					}

					{attachments && attachments.some(file => file.attachment_type === 'screenshot') ?
						<div className="text-center">
							<h5>Screenshots</h5>

							<table className="table mt-3">
								<thead>
									<tr>
										<th scope="col">Filename</th>
										<th scope="col"></th>
									</tr>
								</thead>
								<tbody>
									{attachments ?
										attachments.map(attachment => {
											if (attachment.attachment_type === 'screenshot') {
												return (
													<tr key={attachment.filename}>
														<td>{attachment.filename}</td>
														<td>
															<button className="btn btn-sm btn-primary ml-2" value={attachment.filename} onClick={downloadFile}>
																Download
															</button>
														</td>
													</tr>
												)
											}
											return null;
										})
										:
										null
									}
								</tbody>
							</table>
						</div>
						: null
					}
				</div>
			</CSSTransition>
		</div>
	);
}

const actionMappings = {
	success: 'Success',
	not_found: 'Record Not Found',
	error: 'Error',
	data_exempt: 'Data Exempt by Policy'
}

export default withNavbar(ViewResponse);
