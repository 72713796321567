import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from '../../RoutesWithAuth';
import { Link } from 'react-router-dom';
import axios from '../common/AxiosConfig';
import PageHeader from '../common/PageHeader';
import { withNavbar } from '../common/Navbar';


function MarkRequestCreated(props) {
	const { history } = props;
	const { dsarRequestId, appId } = props.match.params;

	const context = useContext(AppContext)
	const authToken = context.authToken.get

	const [appName, setAppName] = useState('');
	const [ticketId, setTicketId] = useState('');

	useEffect(() => {
		if (authToken !== null) {
			// Ideally create a new API for this that just returns the system with the particular app id
			axios.get(`app_tickets/${dsarRequestId}`, {
				headers: {
					Authorization: authToken
				}
			}).then(res => {
				res.data.forEach(app => {
					if (app.id.toString() === appId) {
						setAppName(app.display)
					}
				})
			}).catch(err => {
				console.log('There was an error fetching sub-system data!', err)
			})
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authToken])

	const handleSubmit = (e) => {
		e.preventDefault();

		axios.post('app_tickets/mark_created', {
			dsar_id: dsarRequestId,
			app_id: appId,
			system_generated_ticket_id: ticketId,
		}, {
			headers: {
				Authorization: authToken
			}
		}).then(res => {
			history.replace(`/ticket/${dsarRequestId}`)
		})
	}

	return (
		<div className="container">

			<PageHeader header="Mark Ticket Created" removeMargin={true} />

			<p className="lead mb-4">
				<Link to={`/`}>Home&nbsp;</Link>
				&gt; <Link to={`/ticket/${dsarRequestId}`}>Request ID: {dsarRequestId}&nbsp;</Link>
				&gt; Mark Ticket Created<br />
				System Name: {appName}
			</p>

			<form onSubmit={handleSubmit}>
				<div className="form-group row">
					<label htmlFor="ticketId"></label>
					<input className="form-control" id="ticketId" placeholder="Enter your new ticket ID"
						value={ticketId} onChange={(e) => { setTicketId(e.target.value) }} >
					</input>
				</div>
				<div className="form-group-row text-center">
					<button type="submit" className="btn btn-primary">Mark Created</button>
				</div>
			</form>

		</div>
	);
}

export default withNavbar(MarkRequestCreated);
