import React, { useEffect, useContext } from 'react';
import { AppContext } from '../../../RoutesWithAuth';
import { Link, useHistory, useLocation } from 'react-router-dom';
import axios from '../../common/AxiosConfig';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { makeStyles } from "@material-ui/core/styles";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import IconButton from '@material-ui/core/IconButton';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ErrorIcon from '@material-ui/icons/Error';
import MenuItem from '@material-ui/core/MenuItem';
import { signOut } from '../../common/AuthHelper';
import { v2UIRoutePath } from '../../common/commons';
import { ChevronRight } from '@material-ui/icons';
import { Popover, Snackbar, Tooltip } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import AlertIcon from './AlertIcon';

const useStyles = makeStyles(() => ({
  '@global': {
    "html": {
      fontSize: '93%'
    },
    '& .MuiCheckbox-colorPrimary.Mui-checked': {
      color: '#7f9ed7'
    }
  },
  appBar: {
    boxShadow: '0 0.125rem 0.25rem rgb(0 0 0 / 8%)'
  },
  appBarLogo: {
    marginRight: 10,
    height: 35
  },
  toolbar: {
    backgroundColor: "#000000",
    "&": {
      minHeight: "40px"
    }
  },
  breadcrumbToolbar: {
    // backgroundColor: "#fff",
    backgroundColor: "#f8f9fa",
    "&": {
      minHeight: "40px"
    },
    "& a": {
      color: "rgba(0, 0, 0, 0.87)"
    }
  },

  tabs: {
    '& .MuiTabs-indicator': {
      height: '4px',
      transform: "scale(0.6,1)"
    }
  },
  tab: {
    fontFamily: 'revert',
    color: "#f7f7f7",
    fontSize: "revert",
    textTransform: 'none',
    '&.Mui-selected.MuiTab-textColorInherit': {
      opacity: 1,
    },
    '&.MuiTab-textColorInherit': {
      opacity: 0.7
    },
    "&.Mui-selected": {
      color: "#f7f7f7"
    }
  },
  popovermenuclass: {
    '& .MuiPopover-paper': {
      right: '25px',
      paddingRight: "10px"
    },
    '& .MuiMenuItem-root': {
      paddingTop: '2px',
      paddingBottom: '2px'
    }
  },
  tabWithDropDown: {
    "& .MuiTab-wrapper": {
      display: "flow-root",
      "&::after": {
        display: "inline-block",
        width: 0,
        height: 0,
        marginLeft: "10px",
        verticalAlign: ".255em",
        content: '""',
        borderTop: ".3em solid",
        borderRight: ".3em solid transparent",
        borderBottom: "0",
        borderLeft: ".3em solid transparent"
      }
    }
  }
}
));

const activeLinks = [
  ["Home", v2UIRoutePath, [{
    name: "Request Details View",
    samepath: true
  }]],
  ["Tracking", v2UIRoutePath + "tracking"],
  ["Reports", v2UIRoutePath + "reports"],
  ["Admin", "/newuiadmin"]
]
function Navbar(props) {
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const [value, setValue] = React.useState(props.activeIndex);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [requestHealth, setRequestHealth] = React.useState({});
  const [reportHealth, setReportHealth] = React.useState({});
  const [displayError, setDisplayError] = React.useState(false);

  const handleClick = (event) => {
    const boundedRect = event?.currentTarget?.getBoundingClientRect?.();
    setAnchorEl({
      top: boundedRect?.bottom,
      // left : boundedRect?.right + boundedRect?.width
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChange = (event, newValue) => {
    if (newValue === 4) {
      return;
    }
    setValue(newValue);
  };
  const context = useContext(AppContext)
  const authToken = context.authToken.get
  const userName = context.userName.get
  const email = context.email.get
  const isAdmin = context.isAdmin.get
  const reloadHealth = context.reloadHealth.get

  const cachedHealth = React.useCallback(() => {
    getRequestHealth();
    getReportHealth();
  }, [history?.location?.pathname, reloadHealth]);

  useEffect(() => {
    return cachedHealth(history?.location?.pathname, reloadHealth);
  }, [history?.location?.pathname, reloadHealth])

  const getReportHealth = () => {
    axios.get("reports/health_report", {
      headers: {
        Authorization: authToken
      }
    }).then(res => {
      let totalReportsHealthConsolidated = {};
      let viewLink = v2UIRoutePath + "reports/errorreport";
      if (res.data?.downstream) {
        totalReportsHealthConsolidated = res.data?.downstream
      }
      if (res.data?.upstream) {
        Object.keys(res.data?.upstream).forEach((_total_key) => {
          if (!totalReportsHealthConsolidated[_total_key]) {
            totalReportsHealthConsolidated[_total_key] = 0;
          }
          if(res.data?.upstream[_total_key] != 0){
            viewLink = v2UIRoutePath + "reports/errorreport/upstream";
          }
          totalReportsHealthConsolidated[_total_key] += res.data?.upstream[_total_key];
        })
      }
      let displayErrorIcon = false;
      Object.keys(totalReportsHealthConsolidated).forEach((_key) => {
        if (totalReportsHealthConsolidated[_key] !== 0) {
          displayErrorIcon = true;
        }
      })
      setReportHealth({
        ...totalReportsHealthConsolidated,
        displayErrorIcon,
        viewLink
      });
    })
  }

  const getRequestHealth = () => {
    axios.get("reports/request_health", {
      headers: {
        Authorization: authToken
      }
    }).then(res => {
      setRequestHealth(res.data);
    })
  }

  const buildDate = process?.env?.REACT_APP_BUILD_DATE || "00-00-00";
  const disaplayVersion = ` ${process?.env?.REACT_APP_VERSION?.split('-')[0]?.replace(/v/g, "") || 'Dev'} (${buildDate})`;

  const getDateRangeColor = (calender_comment) => {
    const days = parseInt(calender_comment.split("taking").pop().split("days")[0]);
    if (days <= 3) {
      return "#267db7"
    } else if (days <= 7) {
      return "#ffc000"
    } else if (days > 7) {
      return "#eb0a1e"
    }
  }

  const handleErrorClose = () => {
    setDisplayError(false);
  }
  return (

    <Box sx={{ flexGrow: 1 }}>
      <AppBar className={classes.appBar} position="static">
        <Toolbar className={classes.toolbar}>
          <Link to="/">
            <span className={classes.appBarLogo}>
              <img src="/CT_logo.png" height="38" alt={"logo"} />
            </span>
          </Link>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { xs: "none", sm: "block" } }}
          >
            Consumer Privacy System
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { md: "flex" } }}>
            <Tabs
              className={classes.tabs}
              value={value}
              onChange={handleChange}
              aria-label="nav tabs example"
            >
              <Tab className={classes.tab} component="span" onClick={(event) => { event.preventDefault(); history.push(v2UIRoutePath) }} label="HOME" />
              <Tab className={classes.tab} component="span" onClick={(event) => { event.preventDefault(); history.push(v2UIRoutePath + "tracking") }} label="TRACKING" href="/trash" />
              <Tab className={classes.tab} component="span" onClick={(event) => { event.preventDefault(); history.push(v2UIRoutePath + "reports") }} label="REPORTS" href="/spam" />
              <Tab className={classes.tab} component="span" onClick={(event) => { event.preventDefault(); history.push("/newuiadmin") }} label="ADMIN" />
              <Tab className={`${classes.tabWithDropDown} ${classes.tab}`} component="span" onClick={(event) => { handleClick(event); event.preventDefault(); }} label={userName} href="/spam" />
            </Tabs>
          </Box>
        </Toolbar>
        <Toolbar className={classes.breadcrumbToolbar}>
          <Breadcrumbs aria-label="breadcrumb" separator={(<ChevronRight fontSize='small' />)}>
            <Link color="secondary" to={() => {
              const breadCrumbAutoId = Math.round(Math.random() * 1e5);
              return {
                pathname: activeLinks[value][1],
                state: { 
                  fromBreadCrumb: breadCrumbAutoId,
                  ...(location?.state || {}),
                  allowReset : location?.state ? false : true
                }
              }
            }}
            >
              {activeLinks[value][0]}
            </Link>
            {props.subTabIndex > 0 && activeLinks[value][2] && (<Link color="secondary" to={() => {
              const breadCrumbAutoId = Math.round(Math.random() * 1e5);
              return {
                state: { fromBreadCrumb: breadCrumbAutoId }
              }
            }}
            >
              {activeLinks[value][2][props.subTabIndex - 1].name}
            </Link>
            )}
          </Breadcrumbs>
          <Box sx={{ flexGrow: 1 }} />
          <div>
            <AlertIcon requestHealth={requestHealth} />
            {requestHealth?.calender_comment && <>
              <Tooltip title={requestHealth?.calender_comment}>
                <IconButton aria-label="" onClick={() => {
                  history.push(v2UIRoutePath + "reports/requestsagingreport");
                }}>
                  <DateRangeIcon style={{
                    color: getDateRangeColor(requestHealth.calender_comment)
                  }} />
                </IconButton>
              </Tooltip>
            </>}
            {(reportHealth?.displayErrorIcon) && (<IconButton aria-label="" onClick={() => {
              setDisplayError(true)
            }}>
              {/* <Badge badgeContent={0} color="secondary"> */}
              <ErrorIcon color="error" />
              {/* </Badge> */}
            </IconButton>)}
          </div>
        </Toolbar>
      </AppBar>
      {displayError && (
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={!!displayError} autoHideDuration={6000} onClose={handleErrorClose}>
          <Alert onClose={handleErrorClose} severity="error">
            <Box display={"flex"} flexDirection={"row"}>
              <Box paddingRight={"5px"}>
                Error..! <br />
                CPS was unable to process/upload <br />
                <Box display={"flex"} flexDirection={"row"}>
                  <Box paddingRight={"40px"}>
                    RTD : {reportHealth?.rtd || "0"}<br />
                    RTK : {reportHealth?.rtk || "0"}
                  </Box>
                  <Box>
                    RTO : {reportHealth?.rtoo || "0"} <br />
                    RTC : {reportHealth?.rtc || "0"}
                  </Box>
                </Box>

              </Box>
              <Box display={"flex"} color={"#007bff"} style={{
                cursor : "pointer"
              }} onClick={()=>{
                handleErrorClose();
                history.push(reportHealth.viewLink);
              }}>
                View
              </Box>
            </Box>
          </Alert>
        </Snackbar>
      )}
      <Popover
        // anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorReference="anchorPosition"
        className={classes.popovermenuclass}
        anchorPosition={{ top: anchorEl?.top }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem >Email : {email}</MenuItem>
        <MenuItem >Role : {isAdmin ? "Admin" : "User"}</MenuItem>
        <MenuItem >Version : {disaplayVersion}</MenuItem>
        <MenuItem onClick={() => {
          window.open(`/`, "_blank");
        }}>CPS 1.0</MenuItem>
        <MenuItem onClick={() => signOut(context)}> Sign Out</MenuItem>

      </Popover>
    </Box>

  )
}
export const withNavbar = (ComponentToWrap, activeIndex = 0, subTabIndex = 0) => {
  return (props) => (
    <div className='navWrapper'>
      <Navbar activeIndex={activeIndex} subTabIndex={subTabIndex} />

      <div id="body-container" className="d-flex flex-column">
        <div className="wrapper flex-grow-1" style={{ height: '100%' }}>
          <ComponentToWrap {...props} />
        </div>
      </div>

    </div>
  )
}

export default React.memo(Navbar);
