import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from '../../../RoutesWithAuth';
import { CSSTransition } from 'react-transition-group';
import PageHeader from '../../common/PageHeader';
import AdminsOnly from '../../common/AdminsOnly';
import { withNavbar } from '../../common/Navbar';
import axios from '../../common/AxiosConfig';
import AppCard from './AppCard';


function Automation() {
    const context = useContext(AppContext)
    const authToken = context.authToken.get
    const isAdmin = context.isAdmin.get

    const [appAutomation, setAppAutomation] = useState([]);
    const [requestor, setRequestor] = useState("Customer");
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (authToken !== null && isAdmin === true) {
            fetchData()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authToken, isAdmin, requestor])

    const fetchData = () => {
        setIsLoading(true);
        setAppAutomation([]);
        
        axios.get(`apps`, {
            headers: {
                Authorization: authToken
            },
            params :{
                requestor
            }
        }).then(res => {
            setIsLoading(false);
            setAppAutomation(res.data)
        }).catch(err => {
            console.log(err)
        })
    }

    if (isAdmin === null) {
        return null
    } else if (isAdmin === false) {
        return <AdminsOnly header="Apps" />
    }

    return (
        <div className="container mb-5">
            <div className="mt-2 row">
                <div className="flex-grow-1">
                    <PageHeader header="Apps" />
                </div>
                
                <div className=" row justify-content-end align-items-center text-right">
            <label className="mr-2" htmlFor="requestor">Requestor :</label>
                <div className="">
                    
                    <select className="form-control" id="requestor" value={requestor}
                        onChange={e => {setRequestor(e.target.value) }}
                    >
                        <option value="B2B">B2B</option>
                        <option value="Customer">Customer</option>
                        <option value="Employee">Employee</option>
                        <option value="TCI Customer">TCI Customer</option>
                        
                    </select>
                </div>

            </div>
            </div>
            { isLoading && (<div class="d-flex justify-content-center">
                                <img alt="" className="loading-spin" src={"/rotate_right_black_24dp.svg"} width="120"></img>
                        </div>)}
            { appAutomation.length === 0 && (<p className="text-center mt-4">
                        No Apps for this requestor
            </p>)}
            <CSSTransition in={appAutomation.length > 0} appear={true} timeout={125} classNames="swipeR">
                <div>
                    
                    {appAutomation.map(app => (
                        <AppCard key={`${app.id}-${app.requestor}-appcard`} requestor={requestor} app={app} authToken={authToken} isAdmin={isAdmin}
                            fetchData={fetchData}
                        />
                    ))}
                </div>
            </CSSTransition>

        </div>
    );
}

export default withNavbar(Automation, 4);
