import { Box, Button, IconButton, Tooltip, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(() => ({
    headerList: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '15px',
        marginLeft: '10px',
        marginRight: '20px',
        marginBottom: '15px',
        fontSize: "0.85rem"
    },
    ListInsideViewDetails: {
        justifyContent: 'space-between',
        paddingTop: '15px',
        alignItems: 'baseline'
    },
}));

const ListContent = ({ column, data, enableEdit }) => {
    const { type, display, mapping, tooltip, text, optionset, editable } = column;
    if (editable && enableEdit) {
        return (
            <>
                {column.renderEditField()}
            </>)
    }
    if (type === "empty") {
        return (<></>)
    } else if (type === "icon") {
        return (
            <Box width={"100%"} textAlign={"center"}>
                <IconButton className='customizeIconButton'>
                    {column.icon()}
                </IconButton>
            </Box>)
    } else if (tooltip) {
        return (
            <Tooltip key={`${display}-tooltip`} title={data[tooltip]}>
                <div>
                    {data[mapping] || "--"}
                </div>
            </Tooltip>
        )
    } else if (type === "button") {
        return (
            <Box width={"100%"} textAlign={"center"}>
                <Button
                    variant="contained"
                    color="primary"
                    className={'actionButton'}
                    onClick={column.handleCallback}
                >
                    {text}
                </Button>
            </Box>
        )
    } else if (type === "custom") {
        return (
            <>
                {column.renderCustom()}
            </>
        )
    } else if (optionset) {

        return optionset[data[mapping]] || data[mapping] || "--";
    }
    return data[mapping] || "--"
}

const ListOfItems = ({ items, data, enableEdit = false }) => {
    const classes = useStyles();

    return (
        <>
            <div className={`${classes.headerList} ${classes.ListInsideViewDetails} listOfItemsCls`}>
                {items.map(({ display, ...rest }, index) => {
                    return (
                        <div key={display} style={{ maxWidth: `calc( 100%/${items.length} )` }}>
                            <div>
                                <b>
                                    {display}
                                </b>

                                <br />
                                <ListContent
                                    enableEdit={enableEdit}
                                    column={{
                                        ...rest,
                                        display
                                    }}
                                    data={data}
                                />

                            </div>
                        </div>
                    )
                })}
            </div>
        </>
    )
}
export default React.memo(ListOfItems)