import React from 'react';

function PageFooter() {
    const buildDate = process.env.REACT_APP_BUILD_DATE || "";
    const appVersion = process.env.REACT_APP_VERSION || "-";
    const formatedVersion = `${appVersion.split('-')[0].replace(/v/g, "")} (${buildDate})`;

    return (
        <footer className="page-footer footer font-small bg-light">
            <div className="footer-copyright small text-center py-3">
                Version: {formatedVersion}
            </div>
        </footer>
    );
}

export default PageFooter;
