import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import { UncontrolledTooltip } from 'reactstrap';
import axios from '../../../../common/AxiosConfig';
import moment from 'moment';


function UsersReport(props) {
    const { authToken } = props

    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);

    useEffect(() => {
        if (authToken) {
            axios.get('/users', {
                headers: {
                    Authorization: authToken
                }
            }).then(res => {
                setUsers(res.data)
                setFilteredUsers(res.data);
            }).catch(err => {
                console.log(err)
            })
        }
    }, [authToken])

    const handleChange=(e)=> {
        if(!e.target.value){
            return void 0;
        }
        const filter = e.target.value;
        if(filter==='All'){
            setFilteredUsers(users);
        }
        if(filter==='Disabled'){
            const newUsers = users.filter((p)=>p.user_status !== 'Y');
            setFilteredUsers(newUsers);
        }
        else if(filter === 'Admin' || filter ==='User'){
            const newUsers = users.filter((p)=>p.role === filter && p.user_status === 'Y');
            setFilteredUsers(newUsers);
        }
      }

      const displayTime = (user)=>{
        const id =`last-login-${user.user_id}`
         if(user.last_login_time!==null){
             const localTime =moment(user.last_login_time).local().format('MMM Do, YYYY HH:mm:ss [(local)]');
             const utcTime = moment(user.last_login_time).utc().format('MMM Do, YYYY HH:mm:ss (UTC)')
            return <td id={id}>{utcTime}
            <UncontrolledTooltip placement="bottom" target={id}> {localTime}</UncontrolledTooltip> 
            </td>
         }

        return <td id={id}/>
      }

    return (
        <div className="mb-5">
            <h4 className="text-center mt-4">Users Report</h4>
            <div className="form-inline form-check-inline mb-3">
                    <label className="small mr-1" htmlFor="keyFilter">Filter:</label>
                    <select className="form-control form-control-sm" id="keyFilter" onChange={handleChange}>
                        <option value='All'>All</option>
                        <option value='Admin'>Active - Admins</option>
                        <option value='User'>Active - Users</option>
                        <option value='Disabled'>Disabled</option>
                    </select>
                </div>
            <table className="table table-striped">
                <thead>
                    <tr className="text-center">
                    <th scope="col" className="text-left">#</th>
                        <th scope="col">User</th>
                        <th scope="col">Workday ID</th>
                        <th scope="col">Email</th>
                        <th scope="col">Role</th>
                        <th scope="col">Employee Status</th>
                        <th scope="col">User status</th>
                        <th scope="col">Last login</th>
                    </tr>
                </thead>
                <CSSTransition in={filteredUsers.length > 0} appear={true} timeout={125} classNames="swipeD">
                    <tbody className="text-center">
                        {filteredUsers.map((user, i) => (
                            <tr key={user.user_id} className={user.user_status === 'N' ? "text-danger" : ""}>
                                <th scope="row" className="text-left">{i + 1}</th>
                                <td>{user.display_name}</td>
                                <td>{user.workday_id}</td>
                                <td>{user.email}</td>
                                <td>{user.role ? user.role : "User"}</td>
                                <td>{user.employee_status}</td>
                                <td>{user.user_status === 'Y' ? 'Active' : 'Disabled'}</td>
                                {displayTime(user)}
                                
                            </tr>
                        ))}
                    </tbody>
                </CSSTransition>
            </table>

        </div>
    );
}

export default UsersReport;
