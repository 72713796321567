import React from 'react';
import DailyFeedCard from './DailyFeedCard';


function DailyFeedTab(props) {
    const { authToken, isAdmin } = props

    // Hardcode the Apps and IDs for now
    const dailyApps = [
        { name: 'CC-AMS', id: 3 },
        { name: 'CT BI', id: 5 },
        { name: 'CC-Sustainment', id: 4 },
        { name: 'SXM', id: 6 },
        { name: 'SXM Audio [ Right To Delete ]', id: '11-rtd'},
        { name: 'SXM Audio [ Remaining Rights ]', id: 11 },
    ]

    return (
        <div className="tab-pane fade mt-4 mb-5" id="daily" role="tabpanel" aria-labelledby="daily-tab">

            {dailyApps.map(app => (
                <DailyFeedCard key={app.name} app={app} authToken={authToken} isAdmin={isAdmin} />
            ))}

        </div>
    );
}

export default DailyFeedTab;
