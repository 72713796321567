import React from 'react';
import PropTypes from 'prop-types';

const StatusLight = (props) => {
    return (
        <div className="px-2 mr-2" style={{maxWidth: "65px" ,textAlign: 'center',}}>
            <div className={`my-1  ${props.isGreen ? "led-green" : "led-red"}`}></div>
            <div className="small dash-text">{props.text}</div>
        </div>
    )
}

StatusLight.propTypes = {
    isGreen: PropTypes.bool.isRequired,
    text: PropTypes.string.isRequired,
}

export { StatusLight }