import React, { useEffect, useState } from 'react';
import axios from '../../../../common/AxiosConfig';
import { useContext } from 'react';
import { AppContext } from '../../../../../RoutesWithAuth';
import { Box, Button, IconButton, Tooltip, makeStyles } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';

const useStyles = makeStyles(() => ({
    toolbar: {
        textAlign: "right",
        height: '35px',
        marginRight: '15px',
        '& .MuiButtonBase-root': {
            padding: '5px !important'
        }
    }
}));

const WordDoc = ({
    tabInfo,
    setLoading,
    setDisplayErrMsg,
    setDisplaySuccessMsg
}) => {

    const context = useContext(AppContext)
    const authToken = context.authToken.get
    const [docUrl, setDocUrl] = useState();
    const classes = useStyles();
    // Trigger Fetch Work Document API when there is a change in the Active Tab Name
    useEffect(() => {
        setLoading(true)
        fetchWordDocument();
    }, [tabInfo.tabname])
    // Method triggeres API and stores the presigned URL in docURL state
    const fetchWordDocument = () => {
        const {dsarIdPrefix, dsarRequestId , signedUrlEndPoint } = tabInfo;
        // appending the document name prefix along with dsar ID 
        const dsarId = (dsarIdPrefix || "") + dsarRequestId;
        const apiForDownloadSignedUrl = signedUrlEndPoint || `ticket/response/download/${tabInfo.dsarRequestId}/doc/${dsarId}.docx`;
        axios.get(apiForDownloadSignedUrl, {
            headers: {
                Authorization: authToken,
            },
        }).then((res) => {
            setLoading(false)
            const signedUrl = res.data.presignedGet;
            setDocUrl(signedUrl);
        }).catch((err) => {
            setLoading(false)
            if(err?.response?.status == "404"){
                setDisplayErrMsg("Word doc doesn't exist. Please regenerate")
            }
        });
    }
    /**
     * Triggered when download button is clicked in the UI
     */
    const downloadWordDoc = () => {
        const {dsarIdPrefix, dsarRequestId, downloadFileName} = tabInfo;
        const dsarId = (dsarIdPrefix || "") + dsarRequestId;
        // Generating the Clickable Link to download the Presigned document.
        const link = document.createElement("a");
        link.href = docUrl;
        link.setAttribute("download", downloadFileName || `${dsarId}.docx`);
        document.body.appendChild(link);
        link.click();
        link.remove();
    }
    /**
     * Trigers when regernerate Button is clicked
     * Calls the regenrate api and calls the fetch Data to reload the latest word document
     */
    const regenerateDoc = () => {
        setLoading(true);
        const payload = {
            dsar_id: tabInfo.dsarRequestId,
        }
        // Appending the app_id when the App ID exists in the Tab Info
        if(tabInfo.regenrateAppId){
            payload["app_id"] = tabInfo.regenrateAppId
        }
        axios.post(`regenerate/doc`, payload, {
            headers: {
                Authorization: authToken,
            },
        }).then((res) => {
            setLoading(false);
            setDisplaySuccessMsg("Re-Generated Doc successfully");
            // retriving the latest generated document after 100 ms.
            setTimeout(() => {
                fetchWordDocument();
            }, 100);
        }).catch((err) => {
            setLoading(false);
            setDisplayErrMsg("Failed to Generate Doc!")
        });
    }
    return <Box width={"100%"} height={"100%"} marginTop={'3px'}>
        <div className={classes.toolbar}>
            { !tabInfo.hideregenerateWordDoc && <Button
                size='small'
                color='primary'
                variant="contained"
                onClick={regenerateDoc}
            >
                Regenerate Word Doc
            </Button>}
            <Tooltip title={"download"}>
                <IconButton disabled={!docUrl} onClick={() => { downloadWordDoc() }} aria-label="download">
                    <GetAppIcon />
                </IconButton>
            </Tooltip>
        </div>
        {docUrl && (
            <iframe
                key={"oneframeforworddoc"}
                width={"100%"}
                height={"100%"}
                src={`https://docs.google.com/gview?url=${encodeURIComponent(docUrl)}&embedded=true`} />
        )}

    </Box>
}
export default React.memo(WordDoc);