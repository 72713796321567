import React, { useState, useContext, useEffect } from 'react';
import { AppContext } from '../../../../RoutesWithAuth';
import axios from '../../../common/AxiosConfig';
import DataGrid from '../grid';
import { makeStyles } from "@material-ui/core/styles";
import { RequestorMapping } from '../../../common/commons';
import RowMenuCell from '../grid/RowEditActions';
import RequestTypeWrapper from './RequestTypeWrapper';
import { LoadingIndicator } from '../reports/reconcilationreport/MuiStyled';


const Requestor = [RequestorMapping.B2B, RequestorMapping.CUSTOMER, RequestorMapping.EMPLOYEE, RequestorMapping.TCICUSTOMER];
const COLUMNS = [{
    name: "#",
    mapping: "rowId",
    width: 40
}, {
    name: 'Settings',
    mapping: "setting_display",
}, {
    name: 'Right to Correct',
    mapping: "Right-to-Correct",
    enableEditColumn: "Right-to-Correct_edit",
    editable: true,
    width: 160,
    type: 'singleSelect',
    valueOptions: ['Yes', 'No']
}, {
    name: 'Right to Delete',
    enableEditColumn: "Right-to-Delete_edit",
    mapping: "Right-to-Delete",
    editable: true,
    width: 160,
    type: 'singleSelect',
    valueOptions: ['Yes', 'No'],
}, {
    name: 'Right to Know',
    mapping: "Right-to-Know-Detailed",
    enableEditColumn: "Right-to-Know-Detailed_edit",
    editable: true,
    width: 160,
    type: 'singleSelect',
    valueOptions: ['Yes', 'No']
}, {
    name: 'Right to OptOut',
    mapping: "Right-to-OptOut",
    enableEditColumn: "Right-to-OptOut_edit",
    editable: true,
    width: 160,
    type: 'singleSelect',
    valueOptions: ['Yes', 'No']
}, {
    name: "Edit",
    mapping: "actions",
    adminEnabled: true,
    width: 80,
    editable: false,
    renderCell: (props) => {
        return (
            <>
                <RowMenuCell {...props} />
            </>
        )
    }
}];


const settingDisplayName = {
    CURRENT_OWNER: 'Current Ownership',
    CURRENT_SUBSCRIBER: 'Active Vehicle Association',
    LITIGATION_VINS: 'Litigation Hold',
    ACTIVE_RENTAL: 'Upcoming Rentals',
    ACTIVE_RELATIONSHIP: 'Active Relationship',
    "PRIMARY-REMOTE-SUBSCRIPTION": 'Primary or Remote Subscription'
}

const useStyles = makeStyles(() => ({
    appscopeWrapper: {
        marginTop: '4px',
        marginRight: '15px'
    },
    autoCloseCondition: {
        marginTop: '10px',
        padding: '0px 0px 10px 0px',
        boxShadow: '0px 0px 1px 0px rgb(0 0 0 / 41%)',
        '& .autoCloseHeader': {
            background: '#7f9ed7',
            padding: '5px',
            color: "white",
            fontWeight: 'bold'
        },
        '& .autoCloseContent': {
            fontStyle: 'italic'

        }
    },
    noteWrapper: {
        marginTop: '10px',
        fontStyle: 'italic'
    }
}));
function AutoCloseSetting({ activeTab }) {

    const [appsData, setAppsData] = useState([]);
    const [isProcessing, setIsProcessing] = useState(false);
    const [gridData, setGridData] = useState([]);
    const [selectionModel, setSelectionModel] = useState([]);
    const [autoCloseCondition, setAutoCloseCondition] = useState("");


    const requestor = Requestor[activeTab];
    const context = useContext(AppContext)
    const authToken = context.authToken.get
    const classes = useStyles()
    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authToken, activeTab])
    const formatGridData = (appdata) => {
        const gridrecords = [];
        const app_types = Object.keys(appdata);
        app_types.forEach((app_type) => {
            const appsettings = Object.keys(appdata[app_type]);
            if (appsettings.length > 0 && app_type !== "condition") {
                appsettings.forEach((appsetting) => {
                    gridrecords.push({
                        setting: appsetting,
                        setting_display: settingDisplayName[appsetting],
                        type: app_type,
                        [app_type]: appdata[app_type][appsetting] ? 'Yes' : 'No',
                        [app_type + '_edit']: true,
                        condition: appdata["condition"][appsetting]
                    });
                })

            }
        })
        return gridrecords;
    }
    const fetchData = () => {
        setIsProcessing(true);
        const params = {
            requestor
        }
        axios.get(`ticket/autoclose_settings`, {
            headers: {
                Authorization: authToken
            },
            params
        }).then(res => {


            setAppsData(res.data);
            const formatedGridData = formatGridData(res.data) || [];
            formatedGridData.sort((a, b) => a.setting_display > b.setting_display ? 1 : -1);
            setGridData(formatedGridData)
            let selection = [];
            let addit_rule = "";
            if (formatedGridData.length > 0) {
                selection = [`1-${activeTab}`];
                addit_rule = formatedGridData[0].condition || "None";
            }
            setAutoCloseCondition(addit_rule)
            setSelectionModel(selection);
            setIsProcessing(false);

        }).catch(err => {
            setIsProcessing(false);
            console.log(err)
        })
    }
    const updateApp = (rowInfo) => {
        console.log(appsData, rowInfo);
        axios.put(`ticket/autoclose_settings/${requestor}`, {
            [rowInfo.type]: {
                ...appsData[rowInfo.type],
                [rowInfo.setting]: rowInfo[rowInfo.type] === 'Yes'
            }
        }, {
            headers: {
                Authorization: authToken
            }
        })
            .then(res => {
                fetchData();
            }).catch(err => {
                console.log(err)
            })
    }
    const dataGridColumns = () => {

        return COLUMNS;
    }
    const appsGridData = () => {
        return gridData?.map((row, i) => {
            return {
                rowId: (i + 1),
                originalId: row.id,
                id: `${(i + 1)}-${activeTab}`,
                'Right-to-Delete': 'NA',
                'Right-to-Correct': 'NA',
                'Right-to-Know-Detailed': 'NA',
                'Right-to-OptOut': 'NA',
                ...row
            }
        })
    }
    return (
        <div className={classes.appscopeWrapper} key={`AutoClose-${activeTab}`}>
            {isProcessing && <LoadingIndicator />}
            <DataGrid
                disableSelectionOnClick={false}
                columns={dataGridColumns()}
                selectionModel={selectionModel}
                onSelectionModelChange={(selection) => {
                    !isProcessing && setSelectionModel(selection);
                    let selectedAppData = {};
                    gridData.forEach((app, index) => {
                        if (`${(index + 1)}-${activeTab}` === selection[0]) {
                            selectedAppData = app;
                        }
                    })
                    !isProcessing && setAutoCloseCondition(selectedAppData?.condition || "None");
                }}
                rows={appsGridData()}
                isCellEditable={(cellParams) => {
                    return cellParams.row[cellParams.colDef.enableEditColumn] || false;
                }}
                onRowEditCommit={(rows, event) => {
                    if (event.rowInfo) {
                        updateApp(event.rowInfo);
                    }
                }}
            />
            {(<div className={classes.autoCloseCondition}>
                <div className={"autoCloseHeader"}>
                    Condition
                </div>
                <div className='autoCloseContent'>
                    {autoCloseCondition}
                </div>
            </div>)}
            <div className={classes.noteWrapper}>
                Note : If the check is enabled, then all the requests which passes the condition are auto closed without sending them
                to any downstream system
            </div>
        </div>
    )
}
const MemorizedAutoCloseSettings = React.memo(AutoCloseSetting);
const AutoCloseSettingWrapper = (parentprops) => {
    return (<RequestTypeWrapper
        title={"AUTO CLOSE SETTINGS"}
        routePath={"autoclosesetting"}
        renderTabContent={(props) => {
            return <MemorizedAutoCloseSettings
                {...props} />
        }}
        {...parentprops}
    />)
}
export default MemorizedAutoCloseSettings;
export const AutoCloseSettingsTab = React.memo(AutoCloseSettingWrapper);