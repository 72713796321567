import React, { useState, useEffect } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import axios from '../../../../common/AxiosConfig';
import moment from 'moment';

const ALLRequests = "All Requests";
const requestTypeMapping = ["Right-to-Correct", "Right-to-Delete", "Right-to-Know-Detailed", "Right-to-OptOut", "%"]
const closedTypeMapping = ["no_downstream", "vehicle", "customer", "subscriber", "Rental", "litigation", "active_relationship", "out_of_scope", "total"]


function AutoClosedReport(props) {
    const { authToken } = props
    const today = moment()

    const [duration, setDuration] = useState(ALLRequests)
    const [tableFooter, setTableFooter] = useState(ALLRequests)
    const [date, setDate] = useState(today)

    const [rtcBreakdown, setRtcBreakdown] = useState([])
    const [rtdBreakdown, setRtdBreakdown] = useState([])
    const [rtkBreakdown, setRtkBreakdown] = useState([])
    const [rtooBreakdown, setRtooBreakdown] = useState([])
    const [totalBreakdown, setTotalBreakdown] = useState([])

    useEffect(() => {
        if (authToken) {
            axios.get('/reports/auto_closed', {
                params: {
                    duration: duration === ALLRequests ? null : duration,
                    date: duration === ALLRequests ? null : date.format("YYYY-MM"),
                },
                headers: {
                    Authorization: authToken
                }
            }).then(res => {
                setRtcBreakdown(res.data.rtc)
                setRtkBreakdown(res.data.rtk)
                setRtdBreakdown(res.data.rtd)
                setRtooBreakdown(res.data.rtoo)
                setTotalBreakdown(res.data.total)
                setTableFooter(duration === ALLRequests ? ALLRequests : date.format("MMMM YYYY"))
            }).catch(err => {
                console.log(err)
            })
        }
    }, [authToken, date, duration])

    const downloadQuery = (requestTypeIndex, closedTypeIndex) => {

        axios.get('/reports/auto_closed/download_query', {
            params: {
                duration: duration === ALLRequests ? null : duration,
                date: duration === ALLRequests ? null : date.format("YYYY-MM"),
                request_type: requestTypeMapping[requestTypeIndex],
                closed_type: closedTypeMapping[closedTypeIndex],
            },
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            // Set filename parameters
            const durationText = duration === ALLRequests ? "All" : date.format("YYYY-MM")
            const columnNameText = closedTypeMapping[closedTypeIndex].toUpperCase()
            const requestTypeText = requestTypeMapping[requestTypeIndex] === "%" ? "All" : requestTypeMapping[requestTypeIndex]

            // Download the results to a text file
            let text_file = ""
            for (const dsar_id of res.data.dsar_ids) {
                text_file += dsar_id + "\r\n"
            }
            const downloadLink = document.createElement("a");
            const blob = new Blob([text_file], { type: 'text/plain' })
            const url = URL.createObjectURL(blob)
            downloadLink.href = url
            const timestamp = moment().format("YYYYMMDDHHmm")
            downloadLink.download = `Auto_Closed_Report_${durationText}_${columnNameText}_${requestTypeText}_${timestamp}.txt`
            document.body.appendChild(downloadLink)
            downloadLink.click()
            document.body.removeChild(downloadLink)
        }).catch(err => {
            console.log(err)
        })
    }

    return (
        <div className="mb-5">

            <form className="form-inline mt-3">
                <div className="form-group mr-2 mb-2">
                    <label className="mr-1" htmlFor="duration">Duration:</label>
                    <select className="form-control" id="duration" value={duration} onChange={e => setDuration(e.target.value)}>
                        <option>All Requests</option>
                        <option>Month</option>
                    </select>
                </div>
                {duration === 'Month' ?
                    <div className="form-group mr-2 mb-2">
                        <input type="month" className="form-control date-form" value={date.format("YYYY-MM")}
                            min="2020-01" max={today.format("YYYY-MM")} onChange={e => setDate(moment(e.target.value))} required
                        />
                    </div>
                    : null
                }
            </form>

            <h4 className="text-center mt-4">Auto Closed Report</h4>
            <table className="table table-striped">
                <thead>
                    <tr className="text-center">
                        <th scope="col" className="text-left">Request Type</th>
                        <th scope="col">No Downstream</th>
                        <th scope="col">No Vehicle</th>
                        <th scope="col">Current Customer</th>
                        <th scope="col">Telematics Subscriber</th>
                        <th scope="col">Active Rental</th>
                        <th scope="col">Lit Hold</th>
                        <th scope="col">Active Relationship</th>
                        <th scope="col">Out of Scope</th>
                        <th scope="col">Total</th>
                    </tr>
                </thead>
                <CSSTransition in={rtdBreakdown.length > 0} appear={true} timeout={125} classNames="swipeD">
                    {rtdBreakdown.length ?
                        <tbody className="text-center">
                            <tr>
                                <th scope="row" className="text-left">Right to Correct</th>
                                {rtcBreakdown.map((count, i) => (
                                    <td key={`rtc-count-${closedTypeMapping[i]}`}>
                                        {count > 0 ?
                                            <button className="btn btn-link p-0" onClick={() => downloadQuery(0, i)}>{count}</button>
                                            : count
                                        }
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                <th scope="row" className="text-left">Right to Delete</th>
                                {rtdBreakdown.map((count, i) => (
                                    <td key={`rtd-count-${closedTypeMapping[i]}`}>
                                        {count > 0 ?
                                            <button className="btn btn-link p-0" onClick={() => downloadQuery(1, i)}>{count}</button>
                                            : count
                                        }
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                <th scope="row" className="text-left">Right to Know</th>
                                {rtkBreakdown.map((count, i) => (
                                    <td key={`rtk-count-${closedTypeMapping[i]}`}>
                                        {count > 0 ?
                                            <button className="btn btn-link p-0" onClick={() => downloadQuery(2, i)}>{count}</button>
                                            : count
                                        }
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                <th scope="row" className="text-left">Right to Opt Out</th>
                                {rtooBreakdown.map((count, i) => (
                                    <td key={`rtoo-count-${closedTypeMapping[i]}`}>
                                        {count > 0 ?
                                            <button className="btn btn-link p-0" onClick={() => downloadQuery(3, i)}>{count}</button>
                                            : count
                                        }
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                <th scope="row" className="text-left">Total</th>
                                {totalBreakdown.map((count, i) => (
                                    <td key={`total-count-${closedTypeMapping[i]}`}>
                                        {count > 0 ?
                                            <button className="btn btn-link p-0" onClick={() => downloadQuery(4, i)}>{count}</button>
                                            : count
                                        }
                                    </td>
                                ))}
                            </tr>
                        </tbody>
                        :
                        <tbody />
                    }
                </CSSTransition>
            </table>

            <SwitchTransition>
                <CSSTransition key={tableFooter} timeout={250} classNames="swipeR">
                    <p className="font-italic text-center">Auto Closed Count: {tableFooter}</p>
                </CSSTransition>
            </SwitchTransition>
        </div>
    );
}

export default AutoClosedReport;
