import React, { useState } from 'react';
import axios from '../../common/AxiosConfig';

function ContactCard(props) {
    const { authToken, isAdmin, contact, setContacts } = props
    const feedbackTimeout = 4000

    const [editMode, setEditMode] = useState(false)

    const [primaryName, setPrimaryName] = useState(contact.data.primary.name)
    const [primaryEmail, setPrimaryEmail] = useState(contact.data.primary.email)
    const [primaryPhone, setPrimaryPhone] = useState(contact.data.primary.phone)
    const [escalationName, setEscalationName] = useState(contact.data.escalation.name)
    const [escalationEmail, setEscalationEmail] = useState(contact.data.escalation.email)
    const [escalationPhone, setEscalationPhone] = useState(contact.data.escalation.phone)

    const updateContact = () => {
        const errorFeedback = document.getElementById(`updateError-${contact.app_name}`)

        axios.put(`/contacts/${contact.app_name}`, {
            primary_name: primaryName.trim(),
            primary_email: primaryEmail.trim(),
            primary_phone: primaryPhone.trim(),
            escalation_name: escalationName.trim(),
            escalation_email: escalationEmail.trim(),
            escalation_phone: escalationPhone.trim(),
        }, {
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            setEditMode(false)

            axios.get('/contacts', {
                headers: {
                    Authorization: authToken
                }
            }).then(res => {
                setContacts(res.data)
            }).catch(err => {
                console.log(err)
            })
        }).catch(err => {
            errorFeedback.innerHTML = err.response.data.message
            removeFeedback(`updateError-${contact.app_name}`)
        })
    }

    const exitEditMode = () => {
        setEditMode(false)
        setPrimaryName(contact.data.primary.name)
        setPrimaryEmail(contact.data.primary.email)
        setPrimaryPhone(contact.data.primary.phone)
        setEscalationName(contact.data.escalation.name)
        setEscalationEmail(contact.data.escalation.email)
        setEscalationPhone(contact.data.escalation.phone)
    }

    const removeFeedback = (elementId) => {
        // Remove the form validation after a specified timeout period
        setTimeout(
            function () {
                const field = document.getElementById(elementId)
                if (!field) { return }
                field.innerHTML = ""
            }, feedbackTimeout
        )
    }

    return (
        <div className="mb-3">
            <div key={contact.app_name} className="card mb-2 bg-light">
                <div className="card-body">
                    <h5 className="mb-3">
                        {contact.app_name}
                        <span className="float-right">
                            {editMode ?
                                <div>
                                    <button type="button" className="btn btn-sm small-button btn-danger mr-2" onClick={exitEditMode}>
                                        <i className="material-icons small-icon">clear</i>
                                    </button>
                                    <button type="button" className="btn btn-sm small-button btn-success" onClick={updateContact}>
                                        <i className="material-icons small-icon">done</i>
                                    </button>
                                    <br />
                                </div>
                                :
                                <div>
                                    {isAdmin ?
                                        <button type="button" className="btn btn-sm btn-warning small-button" onClick={() => { setEditMode(true) }}>
                                            <i className="material-icons small-icon">edit</i>
                                        </button>
                                        : null
                                    }
                                </div>
                            }
                        </span>
                    </h5>
                    <p id={`updateError-${contact.app_name}`} className="small text-danger float-right mb-1"></p>
                    <table className="table table-striped table-sm mb-0">
                        <thead>
                            <tr>
                                <th scope="col"></th>
                                <th scope="col">Name</th>
                                <th scope="col">Email</th>
                                <th scope="col">Phone</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">Primary</th>
                                <td>
                                    {editMode ?
                                        <input className="form-control" type="text" value={primaryName} onChange={e => setPrimaryName(e.target.value)} required></input>
                                        :
                                        primaryName
                                    }
                                </td>
                                <td>
                                    {editMode ?
                                        <input className="form-control" type="text" value={primaryEmail} onChange={e => setPrimaryEmail(e.target.value)} required></input>
                                        :
                                        primaryEmail
                                    }
                                </td>
                                <td>
                                    {editMode ?
                                        <input className="form-control" type="text" value={primaryPhone} onChange={e => setPrimaryPhone(e.target.value)} required></input>
                                        :
                                        primaryPhone
                                    }
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">Escalation</th>
                                <td>
                                    {editMode ?
                                        <input className="form-control" type="text" value={escalationName} onChange={e => setEscalationName(e.target.value)} required></input>
                                        :
                                        escalationName
                                    }
                                </td>
                                <td>
                                    {editMode ?
                                        <input className="form-control" type="text" value={escalationEmail} onChange={e => setEscalationEmail(e.target.value)} required></input>
                                        :
                                        escalationEmail
                                    }
                                </td>
                                <td>
                                    {editMode ?
                                        <input className="form-control" type="text" value={escalationPhone} onChange={e => setEscalationPhone(e.target.value)} required></input>
                                        :
                                        escalationPhone
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default ContactCard;
