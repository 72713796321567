import React, { useState } from 'react';
import { feedbackTimeout } from '../../../../common/commons';
import axios from '../../../../common/AxiosConfig';
import moment from 'moment';

const FieldInvalidCls = 'is-invalid';

function DailyFeedCard(props) {
    const { app, authToken, isAdmin } = props
    const yesterday = moment(new Date()).subtract(1, 'days').format('YYYY-MM-DD')

    const [date, setDate] = useState(yesterday)

    const getAppId = (appId) => {
        let fAppId = appId;
        const appObj = {};
        if (typeof appId === "string" && appId?.indexOf?.('-') !== -1) {
            fAppId = parseInt(appId.split('-')[0]);
            appObj[appId.split('-')[1]] = true;
        }
        return {
            app_id: fAppId,
            ...appObj
        }
    }
    const regenerateEmailFile = (appId) => {
        const dateInput = document.getElementById(`date${appId}`)
        const validFeedback = document.getElementById(`date-valid-${appId}`)
        const invalidFeedback = document.getElementById(`date-invalid-${appId}`)

        const regenBtn = document.getElementById(`regenerate${appId}`)

        regenBtn.setAttribute('disabled', true)

        axios.post(`regenerate/email_file`, {
            ...getAppId(appId),
            date: date,
        }, {
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            // Set validity and messages based on the response code
            if (res.status === 201) {
                dateInput.classList.add('is-valid')
                validFeedback.innerHTML = res.data.message
            } else if (res.status === 200) {
                dateInput.classList.add(FieldInvalidCls)
                invalidFeedback.innerHTML = res.data.message
            }
            regenBtn.removeAttribute('disabled')
        }).catch(err => {
            dateInput.classList.add(FieldInvalidCls)
            invalidFeedback.innerHTML = 'Failed to regenerate file!'
            regenBtn.removeAttribute('disabled')
        }).finally(() => removeFeedback(appId))
    }

    const downloadEmailFile = (e, appId, append = "") => {
        const form = document.getElementById(`daily-form-${app.id}`)
        const dateInput = document.getElementById(`date${appId}`)
        const invalidFeedback = document.getElementById(`date-invalid-${appId}`)

        // Make sure the form has valid inputs
        const valid = form.reportValidity()
        if (!valid) { return }

        const downloadParams = getAppId(appId);
        const app_id = downloadParams["app_id"];
        delete downloadParams["app_id"];
        axios.get(`email_file/download/${app_id}${append}/${date}`, {
            headers: {
                Authorization: authToken
            },
            params: {
                ...downloadParams
            }
        }).then(res => {

            const link = document.createElement('a');
            link.href = res.data.presignedGet;
            link.setAttribute('download', `${res.data.filename}`);
            document.body.appendChild(link);
            link.click();
            link.remove()

        }).catch(err => {
            dateInput.classList.add(FieldInvalidCls)
            if (err.response?.data.message) {
                invalidFeedback.innerHTML = err.response.data.message
            }
        }).finally(() => removeFeedback(appId))
    }

    const resendToFTP = (appId) => {
        const dateInput = document.getElementById(`date${appId}`)
        const validFeedback = document.getElementById(`date-valid-${appId}`)
        const invalidFeedback = document.getElementById(`date-invalid-${appId}`)
        const resendBtn = document.getElementById(`resendFTP_${appId}`)

        hideModal(`resend-modal-${appId}`)
        resendBtn.setAttribute('disabled', true)

        axios.post(`downstream/resend_sftp`, {
            ...getAppId(appId),
            date: date,
        }, {
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            dateInput.classList.add('is-valid')
            validFeedback.innerHTML = 'File has been regenerated successfully!'
            resendBtn.removeAttribute('disabled')
        }).catch(err => {
            dateInput.classList.add(FieldInvalidCls)
            if (err.response?.data.message) {
                invalidFeedback.innerHTML = err.response.data.message
            } else {
                invalidFeedback.innerHTML = 'Failed to resend file!'
            }
            resendBtn.removeAttribute('disabled')
        }).finally(() => {
            removeFeedback(appId)
        })
    }

    const removeFeedback = (appId) => {
        // Remove the form validation after a specified timeout period
        setTimeout(
            function () {
                const field = document.getElementById("date" + appId)
                if (!field) { return }
                field.classList.remove(FieldInvalidCls)
                field.classList.remove('is-valid')
                const invalidFeedback = document.getElementById(`date-invalid-${appId}`)
                invalidFeedback.innerHTML = ""
                const validFeedback = document.getElementById(`date-valid-${appId}`)
                validFeedback.innerHTML = ""
            }, feedbackTimeout
        )
    }

    const showModal = (appId) => {
        const showModalBtn = document.getElementById(`show-resend-modal-${appId}`)
        showModalBtn.click()
    }

    const hideModal = (id) => {
        const modal = document.getElementById(id)
        modal.classList.remove('show')
        const backdrop = document.getElementsByClassName('modal-backdrop')
        document.body.removeChild(backdrop[0])
    }
    /**
     * Method is triggered when user clicks on the Process response button
     * @param {*} appId 
     * 
     * Triggers the API daily-feed/process-response/appId and displays the appropriate 
     * Success / Failure Messages based on the response.
     */
    const processResponse = (appId) => {

        const validFeedback = document.getElementById(`date-valid-${appId}`)
        const invalidFeedback = document.getElementById(`date-invalid-${appId}`)
        const processRespId = document.getElementById(`process_response_${appId}`)

        processRespId.setAttribute('disabled', true)
        // parsing the appid since appId contains appid_rtd ex:- 11_rtd
        const appIdParsed = getAppId(appId)?.app_id;
        
        axios.post(`daily-feed/process-response/${appIdParsed}`, {}, {
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            // Set validity and messages based on the response code
            if (res.status === 500) {
                invalidFeedback.innerHTML = res.data.message
            } else if (res.status === 200) {
                validFeedback.innerHTML = res.data.message
            }
            processRespId.removeAttribute('disabled')
        }).catch(err => {

            invalidFeedback.innerHTML = 'Response process encountered an error'
            processRespId.removeAttribute('disabled')
        }).finally(() => removeFeedback(appId))
    }

    const submitForm = (type, appId = app.id) => {
        const form = document.getElementById(`daily-form-${app.id}`)
        // Make sure the form has valid inputs
        const valid = form.reportValidity()
        if (!valid) { return }

        // Perform the appropriate action depending on the button pressed
        if (type === "regenerate") {
            regenerateEmailFile(appId)
        } else if (type === "resend") {
            showModal(appId)
        } else if (type === "process_response") {
            processResponse(appId)
        }
    }

    return (
        <div>

            <div className="card mb-2 bg-light" key={app.id}>
                <div className="card-body">
                    <h4>{app.name}</h4>

                    <div>
                        <form className="form-inline mt-3" id={`daily-form-${app.id}`}>
                            <div className="form-group mr-2 mb-2">
                                <input type="date" className="form-control date-form" id={"date" + app.id}
                                    max={yesterday} value={date} onChange={(e) => setDate(e.target.value)} required
                                />
                            </div>

                            <button type="button" className="btn btn-primary mb-2 mr-2" onClick={e => downloadEmailFile(e, app.id)}>
                                Download File
                            </button>

                            {app.id === 11 || app.id === '11-rtd' ?
                                <button type="button" className="btn btn-primary mb-2 mr-2" id={"regenerate11.5"}
                                    onClick={e => downloadEmailFile(e, app.id, ".5")}
                                >
                                    Download Check File
                                </button>
                                : null
                            }

                            <button type="button" className="btn btn-primary mb-2" id={"regenerate" + app.id}
                                onClick={() => submitForm("regenerate")}
                            >
                                Regenerate File
                            </button>

                            {isAdmin && ([3, 4, 5, 6, 11, '11-rtd'].includes(app.id)) ?
                                <button type="button" className="btn btn-warning mb-2 ml-2" id={`resendFTP_${app.id}`}
                                    onClick={() => submitForm("resend")}
                                >
                                    Resend File
                                </button>
                                : null
                            }
                            <button type="button" className="btn btn-warning mb-2 ml-2" id={`process_response_${app.id}`}
                                onClick={() => submitForm("process_response")}
                            >
                                Process Response
                            </button>
                            <button type="button" id={`show-resend-modal-${app.id}`} className="d-none" data-toggle="modal" data-target={`#resend-modal-${app.id}`}>Show Modal</button>
                        </form>
                        <small className="mb-0 text-danger" id={`date-invalid-${app.id}`} />
                        <small className="mb-0 text-success" id={`date-valid-${app.id}`} />
                    </div>

                </div>
            </div>

            <div className="modal fade" id={`resend-modal-${app.id}`} tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog mb-3" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Confirm Resend to FTP</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            Do you really want to resend the file for {moment(date).format('MMM Do, YYYY')} to {app.name}?
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary" onClick={() => resendToFTP(app.id)}>Confirm</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default DailyFeedCard;
