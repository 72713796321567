import React, { useState, useEffect } from "react";
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import axios from "../../../../../common/AxiosConfig";
import moment from "moment";
import ProcessingTimeChart from "./ProcessingTimeChart";


function AverageProcessingTime(props) {
    const { authToken } = props
    const today = moment()

    const [year, setYear] = useState(today.format("YYYY"))
    const [requestType, setRequestType] = useState("All Requests")
    const [metric, setMetric] = useState("Downstream")

    const [reportData, setReportData] = useState([])
    const [toFilter, setToFilter] = useState([])
    const [filteredReportData, setFilteredReportData] = useState([])

    const [requestTypeFooter, setRequestTypeFooter] = useState(null)
    const [yearFooter, setYearFooter] = useState(null)
    const [metricFooter, setMetricFooter] = useState(null)

    useEffect(() => {
        if (authToken) {
            const yearInput = document.getElementById("year")
            if (!yearInput.reportValidity()) { return }

            axios.get("/reports/avg_processing_time", {
                headers: {
                    Authorization: authToken
                },
                params: {
                    metric: metric === "Downstream" ? null : "uploaded",
                    request_type: requestType !== "All Requests" ? requestType : null,
                    year: year,
                }
            }).then(res => {
                setRequestTypeFooter(requestType === "All Requests" ? requestType : requestTypeMapping[requestType])
                setMetricFooter(metric === "Downstream" ? metric : "Time to Upload")
                setYearFooter(year)
                setReportData(res.data)
                setFilteredReportData(res.data)
            }).catch(err => {
                console.log(err)
            })
        }
    }, [authToken, metric, year, requestType])

    useEffect(() => {
        // Deep Copy
        const reportDataCopy = JSON.parse(JSON.stringify(reportData))
        setFilteredReportData(
            reportDataCopy.map(el => {
                if (toFilter.includes(el.id)) {
                    el.data = []
                }
                return el
            })
        )
    }, [toFilter, reportData])

    return (
        <div className="mb-5">

            <h4 className="text-center mt-4 mb-1">Average Processing Time Chart</h4>
            <div className="small text-center">Click on Legend to toggle display</div>

            <div className="form-inline">
                <div className="form-group">
                    <label className="mr-1" htmlFor="metric">Metric:</label>
                    <select className="form-control" id="metric" value={metric} onChange={e => setMetric(e.target.value)}>
                        <option>Downstream</option>
                        <option>Time to Upload</option>
                    </select>
                </div>
            </div>
            <div className="form-inline mt-2">
                <div className="form-group">
                    <label className="mr-1" htmlFor="year">Select Year:</label>
                    <input type="number" className="form-control date-form" id="year" value={year}
                        min="2020" max={today.format("YYYY")} onChange={e => setYear(e.target.value)} required
                    />
                </div>
            </div>
            {metric === "Downstream" ?
                <div className="form-inline mt-2">
                    <div className="form-group">
                        <label className="mr-1" htmlFor="requestType">Request Type:</label>
                        <select className="form-control" id="requestType" value={requestType} onChange={e => setRequestType(e.target.value)}>
                            <option>All Requests</option>
                            <option value="Right-to-Correct">Right to Correct</option>
                            <option value="Right-to-Delete">Right to Delete</option>
                            <option value="Right-to-Know-Detailed">Right to Know</option>
                            <option value="Right-to-OptOut">Right to Opt Out</option>
                        </select>
                    </div>
                </div>
                : null
            }

            <div style={{ height: "560px" }}>
                <ProcessingTimeChart data={filteredReportData} toFilter={toFilter} setToFilter={setToFilter} />
            </div>

            {metricFooter ?
                <SwitchTransition>
                    <CSSTransition key={metricFooter} timeout={250} classNames="swipeR-delay" appear={true}>
                        <div className="font-italic text-center">Metric: {metricFooter} ({yearFooter})</div>
                    </CSSTransition>
                </SwitchTransition>
                :
                null
            }
            {metricFooter === "Downstream" ?
                <SwitchTransition>
                    <CSSTransition key={requestTypeFooter} timeout={250} classNames="swipeR-delay" appear={true}>
                        <div className="font-italic text-center">Request Type: {requestTypeFooter}</div>
                    </CSSTransition>
                </SwitchTransition>
                :
                null
            }
        </div>
    );
}

export default AverageProcessingTime;


const requestTypeMapping = {
    "Right-to-Correct": "Right to Correct",
    "Right-to-Know-Detailed": "Right to Know",
    "Right-to-Delete": "Right to Delete",
    "Right-to-OptOut": "Right to Opt Out",
}
