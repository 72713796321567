/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:2d81578c-2dfb-4b34-90dc-70275e336e69",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_uWTFnJfO0",
    "aws_user_pools_web_client_id": "41m4pj5mptd3t89kc954knrtg3",
    "oauth": {
        "domain": "devconnectedtechcps.auth.us-east-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://dev1.connectedtechcps.com/waitsignin",
        "redirectSignOut": "https://dev1.connectedtechcps.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
};


export default awsmobile;
