import React from 'react';

function PageOutOfDate({newUIHref}){
    return newUIHref ? (
        <span className={"ml-5"} style={{fontSize: '1rem',fontWeight:500, color : '#dd3f5b'}}>
            This page is out of date. Please navigate to
            <a href={newUIHref} className="ml-1" rel="noopener noreferrer" target="_blank" >
                    CPS 2.0
			</a>
        </span>
    ) : null;
}

function PageHeader(props) {
    const { header, removeMargin, newUIHref } = props

    return (
        <div className={`jumbotron bg-transparent py-1 px-0  ${!removeMargin ? "mb-3" : "mb-0"}`}>
            <h1 className="display-4 d-none d-lg-block d-xl-block mb-0" style={{ fontSize: '2.7rem' }}>
                {header}
                <PageOutOfDate newUIHref={newUIHref}/>
            </h1>
            <h1 className="d-lg-none display-4 mb-0" style={{ fontSize: '2.3rem' }}>
                {header}
                <PageOutOfDate newUIHref={newUIHref}/>
            </h1>

        </div>
    );
}

export default PageHeader;
