import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from '../../../RoutesWithAuth';
import { CSSTransition } from 'react-transition-group';
import axios from '../../common/AxiosConfig';
import Papa from 'papaparse';
import moment from 'moment';
import AttributeRow from './AttributeRow';
import { withNavbar } from '../../common/Navbar';
import PageHeader from '../../common/PageHeader';
import { hideModal, removeFeedback } from '../../common/commons';
import '../../../css/smallComponents.css'

function MasterAttributeKeys() {
    const context = useContext(AppContext)
    const authToken = context.authToken.get
    const isAdmin = context.isAdmin.get
    let userInput = useState("")

    const [masterKeys, setMasterKeys] = useState([])
    const [tableView, setTableView] = useState(false)

    const [filter, setFilter] = useState("Active")
    const [appFilter, appSetFilter] = useState("All")
    const [tipsFilter, tipsSetFilter] = useState("All")
    const [addKeyMode, setAddKeyMode] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const [appName, setAppName] = useState("CTP")
    const [elementType, setElementType] = useState("Transaction")
    const [key, setKey] = useState("")
    const [displayTerm, setDisplayTerm] = useState("")
    const [desc, setDesc] = useState("")
    const [sample, setSample] = useState("")
    const [tips, setTips] = useState("")
    const [active, setActive] = useState(true)

    const fetchFilteredRtkKeys = () => {
        let filterFlag = "",
            sourceFlag = "",
            tipsFlag = "";

        if (filter === "Active") {
            filterFlag = "true"
        } else if (filter === "Inactive") {
            filterFlag = "false"
        }
        if (appFilter !== "All") {
            sourceFlag = appFilter
        }
        if (tipsFilter !== "All") {
            tipsFlag = tipsFilter;
        }
        setMasterKeys([]);
        setIsLoading(true);
        return axios.get('/rtk_keys', {
            headers: {
                Authorization: authToken
            },
            params: {
                filter: filterFlag,
                source: sourceFlag,
                tips: tipsFlag
            }
        }).then(res => {
            setMasterKeys(res.data);
            setIsLoading(false);
            // setFilteredKeys(res.data)
        }).catch(err => {
            setIsLoading(false);
            console.log(err)
        })
    }
    useEffect(() => {
        if (authToken) {
            fetchFilteredRtkKeys();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authToken, filter, appFilter, tipsFilter])

    const expandCollapseView = () => {
        setTableView(!tableView)
    }

    const collapseViewPrompt = () => {
        if (addKeyMode === false) {
            setTableView(false);
        }
        else {
            userInput = window.confirm('Discard changes and navigate to collapsed view ?')
            if (userInput === true) {
                setTableView(false); setAddKeyMode(false);
            }
            else {
                return
            }
        }
    }


    const addKey = () => {
        // Check if the key is empty
        if (key.trim() === "") {
            const errorFeedback = document.getElementById(`error-add-key`)
            errorFeedback.innerHTML = "Key cannot be empty!"
            return
        }

        axios.post(`/rtk_keys/${key.trim()}`, {
            app_name: appName,
            element_type: elementType,
            is_active: active,
            display_term: displayTerm,
            desc: desc,
            tips: tips,
            sample: sample,
        }, {
            headers: {
                Authorization: authToken
            }
        }).then(res => {

            fetchFilteredRtkKeys().then(() => {
                exitAddMode()
            })
        }).catch(err => {
            if (err.response) {
                const errorFeedback = document.getElementById(`error-add-key`)
                errorFeedback.innerHTML = err.response.data.message
            }
        })
    }

    const downloadDataDictionary = () => {
        let filterFlag = "",
            sourceFlag = "",
            tipsFlag = "";

        if (filter === "Active") {
            filterFlag = "true"
        } else if (filter === "Inactive") {
            filterFlag = "false"
        }
        if (appFilter !== "All") {
            sourceFlag = appFilter
        }
        if (tipsFilter !== "All") {
            tipsFlag = tipsFilter;
        }
        axios.get('/rtk_keys/download', {
            headers: {
                Authorization: authToken
            },
            params: {
                filter: filterFlag,
                source: sourceFlag,
                tips: tipsFlag
            }
        }).then(res => {

            if (res.status === 204) {

                const feedback = document.getElementById("download-records-feedback")
                if (feedback) {
                    feedback.innerHTML = "No records to download"
                    removeFeedback("download-records-feedback")
                }
                return;
            }
            const csv = Papa.unparse(res.data)

            const downloadLink = document.createElement("a");
            const blob = new Blob(["\ufeff", csv])
            const url = URL.createObjectURL(blob)
            downloadLink.href = url
            const timestamp = moment().format("YYYYMMDD")
            downloadLink.download = `CPS_DataDictionary_${timestamp}.csv`
            document.body.appendChild(downloadLink)
            downloadLink.click()
            document.body.removeChild(downloadLink)
        }).catch(err => {
            console.log(err)
        })
    }

    const deleteKey = (e) => {
        const keyToDelete = e.target.getAttribute("data-key")
        const errorFeedback = document.getElementById("delete-key-feedback")

        axios.delete(`/rtk_keys/${keyToDelete}`, {
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            setMasterKeys(masterKeys.filter(key => key.key !== keyToDelete))
            hideModal("delete-key-modal")
        }).catch(err => {
            if (err.response && err.response.data) {
                errorFeedback.innerHTML = err.response.data.message
                removeFeedback("delete-key-feedback")
            }
        })
    }

    const addKeyButtonTrigger = () => {
        setAddKeyMode(true)
        setTableView(true)
    }

    const exitAddMode = () => {
        setAddKeyMode(false)
        setTableView(true)
        setAppName("CTP")
        setElementType("Transaction")
        setKey("")
        setDisplayTerm("")
        setDesc("")
        setTips("")
        setSample("")
        setActive(true)
    }

    return (
        <div className={`container mb-5 ${tableView ? "ml-1 w-auto" : ""}`}>
            <PageHeader header="RTK Data Dictionary" newUIHref={"/newuiadmin/systemsettings"} />

            <div className="d-flex align-items-center mb-3 mt-4">
                <div className="form-inline form-check-inline">
                    <label className="small mr-1" htmlFor="keyFilter">Filter:</label>
                    <select className="form-control form-control-sm" id="keyFilter" value={filter} onChange={e => { setFilter(e.target.value) }}>
                        <option>All</option>
                        <option>Active</option>
                        <option>Inactive</option>
                    </select>
                </div>

                <div className="form-inline form-check-inline">
                    <label className="small mr-1" htmlFor="keyFilter">Source:</label>
                    <select className="form-control form-control-sm" id="keyFilter" value={appFilter} onChange={e => { appSetFilter(e.target.value) }}>
                        <option>All</option>
                        <option value='Cerence'>Cerence</option>
                        <option value='CT_MP'>CT Marketing Portal</option>
                        <option value='CTP'>CTP</option>
                        <option value='Digital Key'>Digital Key</option>
                        <option value='DriverWaiver'>Driver Waiver</option>
                        <option value='GAP'>GAP</option>
                        <option value='HireVue'>HireVue</option>
                        <option value='ITGFLEET'>ITG Fleet</option>
                        <option value='Mobility'>Mobility</option>
                        <option value='CT_PAYMENT'>Payment Platform</option>
                        <option value='CT_EV'>Public Charging</option>
                        <option value='Service Connect'>Service Connect</option>
                        <option value='SXM'>SXM</option>
                        <option value='TC'>TC</option>
                        <option value='VGI'>VGI</option>
                    </select>
                </div>

                {tableView ?
                    <div className="form-inline form-check-inline">
                        <label className="small mr-1" htmlFor="keyFilter">TIPS:</label>
                        <select className="form-control form-control-sm" id="keyFilter" value={tipsFilter} onChange={e => { tipsSetFilter(e.target.value) }}>
                            <option>All</option>
                            <option value='null'></option>
                            <option>Available</option>
                            <option>Not Applicable</option>
                            <option>Pending</option>
                        </select>
                    </div>
                    : null}

                {tableView ?
                    <button type="button" className="btn btn-sm small-button btn-link mr-2" onClick={collapseViewPrompt}>Collapsed View</button>
                    :
                    <button type="button" className="btn btn-sm small-button btn-link mr-2" onClick={expandCollapseView}>Expanded View</button>
                }
                <button type="button" className="btn btn-sm small-button btn-primary mr-2" onClick={downloadDataDictionary}>Download</button>
                {isAdmin && !addKeyMode ?
                    <button type="button" className="btn btn-sm btn-warning small-button mr-2" onClick={addKeyButtonTrigger}>Add Key</button>
                    : null
                }
            </div>
            <div className="mt-2 text-center text-danger small" id="download-records-feedback"> </div>
            <table className="table table-striped" id="rtkTable">
                <thead>
                    {tableView ?
                        <tr>
                            <th scope="col">Source</th>
                            <th scope="col">Element Type</th>
                            <th scope="col">Key</th>
                            <th scope="col">Display Term</th>
                            <th scope="col">Description</th>
                            <th scope="col">Sample</th>
                            <th scope="col">TIPS</th>
                            <th scope="col">Active</th>
                            {isAdmin ? <th scope="col">Edit</th> : null}
                        </tr>
                        :
                        <tr>
                            <th scope="col">Source</th>
                            <th scope="col">Element Type</th>
                            <th scope="col">Key</th>
                            <th scope="col">Active</th>
                            {isAdmin ? <th scope="col">Edit</th> : null}
                        </tr>
                    }
                </thead>

                <CSSTransition in={masterKeys.length > 0} appear={true} timeout={125} classNames="swipeD">
                    <tbody>
                        {isLoading && (<><tr></tr><tr>
                            <td colSpan={10}>
                                <div class="d-flex justify-content-center">
                                    <img alt="loading spinner" className="loading-spin" src={"/rotate_right_black_24dp.svg"} width="120"></img>
                                </div>
                            </td>
                        </tr></>)}
                        {addKeyMode ?
                            <tr>
                                <td>
                                    <select className="form-control form-control-sm" value={appName} onChange={e => { setAppName(e.target.value) }}>
                                        <option value='CT_MP'>CT Marketing Portal</option>
                                        <option value='CTP'>CTP</option>
                                        <option value='Digital Key'>Digital Key</option>
                                        <option value='GAP'>GAP</option>
                                        <option value='Mobility'>Mobility</option>
                                        <option value='CT_PAYMENT'>Payment Platform</option>
                                        <option value='CT_EV'>Public Charging</option>
                                        <option value='TC'>TC</option>
                                        <option value='VGI'>VGI</option>
                                    </select>
                                </td>
                                <td>
                                    <select className="form-control form-control-sm" value={elementType} onChange={e => { setElementType(e.target.value) }}>
                                        <option>Transaction</option>
                                        <option>Attribute</option>
                                    </select>
                                </td>
                                <td>
                                    <input className="form-control form-control-sm" type="text" value={key} onChange={e => setKey(e.target.value)} required></input>
                                    <span id={`error-add-key`} className="small text-danger mb-1"></span>
                                </td>
                                <td>
                                    <input className="form-control form-control-sm" type="text" value={displayTerm} onChange={e => setDisplayTerm(e.target.value)} ></input>
                                    <span id={`error-add-key`} className="small text-danger mb-1"></span>
                                </td>
                                <td>
                                    <input className="form-control form-control-sm" type="text" value={desc} onChange={e => setDesc(e.target.value)} ></input>
                                    <span id={`error-add-key`} className="small text-danger mb-1"></span>
                                </td>
                                <td>
                                    <input className="form-control form-control-sm" type="text" value={sample} onChange={e => setSample(e.target.value)} ></input>
                                    <span id={`error-add-key`} className="small text-danger mb-1"></span>
                                </td>
                                <td>
                                    <select className="form-control form-control-sm" value={tips} onChange={e => { setTips(e.target.value) }}>
                                        <option></option>
                                        <option>Available</option>
                                        <option>Not Applicable</option>
                                        <option>Pending</option>
                                    </select>
                                </td>
                                <td>
                                    <select className="form-control form-control-sm" value={active} onChange={e => { setActive(e.target.value === 'true' ? true : false) }}>
                                        <option value={true}>True</option>
                                        <option value={false}>False</option>
                                    </select>
                                </td>
                                <td>
                                    <button type="button" className="btn btn-sm small-button btn-danger mr-1" onClick={exitAddMode}>
                                        <i class="material-icons small-icon">clear</i>
                                    </button>
                                    <button type="button" className="btn btn-sm small-button btn-success" onClick={addKey}>
                                        <i class="material-icons small-icon">done</i>
                                    </button>
                                </td>
                            </tr>
                            : null
                        }
                        {masterKeys.map(key => (
                            <AttributeRow key={key.key} data={key} fetchMasterKeys={() => { fetchFilteredRtkKeys() }} isAdmin={isAdmin} authToken={authToken} tableView={tableView} setTableView={setTableView} addKeyMode={addKeyMode} />
                        ))}
                    </tbody>
                </CSSTransition>
                <tfoot>
                    <tr>
                        <td colSpan={10}>
                            <div className="text-center font-italic">
                                Note: The keys present in the response json is generated by concatenating privacy app id in the request with the data dictionary keys.
                                <br /> For example, "00016-ds-ctp-prod.CT_SXM.personalData".
                            </div>
                        </td>
                    </tr>
                </tfoot>
            </table>

            {/* Delete Key Modal */}
            <button type="button" id="show-delete-key-modal" className="d-none" data-toggle="modal" data-target="#delete-key-modal">Show Modal</button>
            <div className="modal fade" id="delete-key-modal" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog mb-3" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Confirm Key Deletion</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body text-center" id="delete-key-modal-text"></div>
                        <div className="modal-footer pb-0">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-danger" id="delete-key-btn" onClick={deleteKey}>Delete</button>
                        </div>
                        <small className="text-right px-3 py-2 text-danger" id="delete-key-feedback"></small>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default withNavbar(MasterAttributeKeys, 2);
