import { Tabs, Tab, Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Contacts from './Contacts';
import { Alert } from '@material-ui/lab';
import Documents from './Documents';
import { useHistory } from 'react-router-dom';


const useStyles = makeStyles(() => ({

    erroralert: {
        float: 'right'
    },
    catalogTab: {
        height: '100%',
        '& .MuiTabs-root': {
            minHeight: '35px'
        },
        '& .header': {
            padding: '10px 20px',
            fontSize: '1rem',
            fontWeight: 'bold'
        },
        '& .MuiTab-root.Mui-selected': {
            background: "white",
        },
        '& .MuiTab-root': {
            background: "#dae3f3",
            marginRight: '5px',
            outline: '0px',
            minHeight: '35px',
            '& .MuiTab-wrapper': {
                fontWeight: 'bold',
            },
        },
        '& .MuiTabs-indicator': {
            left: '0px',
            height: '3px'
        }
    }
}));
// Variable holds the Available tabs in the Catalog page
const CatalogTabs = ["", "documents"];
const Catalog = ({ active }) => {
    const classes = useStyles();
    const [activeTab, setActiveTab] = useState(0);
    const [error, setError] = useState('');
    const history = useHistory();
    useEffect(() => {
        if (error !== "") {
            // Removing the error messge after 4 sec
            setTimeout(() => {
                setError("")
            }, 4000);
        }
    }, [error])
    useEffect(() => {
        // Activating the Tab based on the tab param in the route url
        let activeTabIndex = CatalogTabs.indexOf(active);
        // activating the first tab when the tab name does not match the list
        if (activeTabIndex < 0) {
            activeTabIndex = 0;
        }
        setActiveTab(activeTabIndex);
    }, [active])


    return (<div className={classes.catalogTab}>
        <Typography variant="h6" className='header'>
            CATALOG
            {error !== "" && (<Alert className={classes.erroralert} severity="error">{error}</Alert>)}
        </Typography>
        <Tabs
            variant="scrollable"
            aria-label="tabs"
            value={activeTab}
            onChange={(_event, newValue) => {
                setActiveTab(newValue);
                // redirecting to the selected tab url so that refresh will retain in same page
                history.push(`/newuiadmin/catalog/${CatalogTabs[newValue]}`)
            }}
            sx={{ borderRight: 1, borderColor: 'divider' }}
        >
            <Tab label="Contacts" />
            <Tab label="documents" />
        </Tabs>
        {activeTab === 0 && (
            <Contacts toggleError={(msg) => {
                setError(msg);
            }} />
        )}
        {activeTab === 1 && (
            <Documents />
        )}

    </div>)
}
export default Catalog;