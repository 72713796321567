import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from '../../../../../RoutesWithAuth';
import axios from '../../../../common/AxiosConfig';
import { LoadingIndicator } from '../../reports/reconcilationreport/MuiStyled';
import DataGrid from '../../grid';
import RowMenuCell from '../../grid/RowEditActions';
import { makeStyles } from "@material-ui/core/styles";
import { renderEditSingleSelectCell } from '../../grid/EditSingleSelectCell';

const NonCPSOptions = Array.from({ length: 30 }, (_, i) => i + 1);
const CPSOptions = ['Forever', '60', '90', '730', '1095'];

const COLUMNS = [{
    name: "#",
    mapping: "id",
    width: 40,
    disableColumnMenu: true
}, {
    name: "Setting",
    mapping: "module_name",
    width: 100,
    disableColumnMenu: true,
},
{
    name: "Description",
    mapping: "message",
    disableColumnMenu: true,
    displayTootip: ["message", 20],
}, {
    name: "B2B",
    mapping: "B2B",
    editable: true,
    disableColumnMenu: true,
    width: 120,
    type: 'singleSelect'
}, {
    name: "Customer",
    mapping: "Customer",
    editable: true,
    type: 'singleSelect',
    width: 120
}, {
    name: "Employee",
    mapping: "Employee",
    editable: true,
    disableColumnMenu: true,
    width: 120,
    type: 'singleSelect'
}, {
    name: "TCI Customer",
    mapping: "TCI Customer",
    editable: true,
    disableColumnMenu: true,
    width: 130,
    type: 'singleSelect'
}, {
    name: "Edit",
    mapping: "actions",
    width: 80,
    disableColumnMenu: true,
    adminEnabled: true,
    editable: false,
    align: 'center',
    headerAlign: 'center',
    renderCell: RowMenuCell
}];

const useStyles = makeStyles(() => ({
    retentionWrapper: {
        marginTop: '4px',
        marginRight: '15px'
    }
}));

function Retention() {
    const context = useContext(AppContext)
    const authToken = context.authToken.get
    const classes = useStyles()
    const [appRetention, setAppRetention] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authToken])

    const fetchData = () => {
        setIsLoading(true);
        axios.get(`appticket_retention`, {
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            setIsLoading(false);
            setAppRetention(res.data?.map((rec, index) => {
                return {
                    id: index + 1,
                    ...rec
                }
            }))
        }).catch(err => {
            setIsLoading(false);
            console.log(err)
        })
    }
    const saveRetentionData = (retention) => {
        setIsLoading(true);
        const { id, ...other } = retention;
        axios.post(`appticket_retention/update`, {
            ...other,
        }, {
            headers: {
                Authorization: authToken
            }
        })
            .then(res => {
                fetchData()
                setIsLoading(false);

            }).catch(err => {
                console.log(err)
                setIsLoading(false);
            })
    }
    return (
        <div className={classes.retentionWrapper}>
            {isLoading && (<LoadingIndicator />)}

            <DataGrid
                columns={COLUMNS.map((column) => {
                    if (column.type === "singleSelect") {
                        return {
                            ...column,
                            getValueOptions: (row) => {
                                if (row.module_name === "CPS") {
                                    return CPSOptions
                                }
                                return NonCPSOptions
                            },
                            renderEditCell: renderEditSingleSelectCell,
                        }
                    }
                    return column;
                })}
                rows={appRetention}
                onRowEditCommit={(rows, event) => {

                    if (event.rowInfo) {
                        saveRetentionData(event.rowInfo)
                    }

                }}
            />
        </div>
    );
}

export default React.memo(Retention);
