import React from 'react';
import '../../../../../css/main.css';


function Request(props) {
	const { history, status } = props;
	const { dsarRequestId, dsarForm, consumerProfileData } = props.data;

	const { dsarRequestType } = dsarForm;

	const showDetails = () => {
		history.push(`/ticket/${dsarRequestId}`)
	}

	const getVins = (consumerProfileData) => {
		const vins = []
		for (const profileData of consumerProfileData) {
			if (profileData.vehicleInfo) {
				for (const vehicleInfo of profileData.vehicleInfo) {
					if (vehicleInfo.vin) {
						vins.push(vehicleInfo.vin)
					}
				}
			}
		}
		return vins
	}

	const vins = getVins(consumerProfileData)

	return (
		<div className="card mb-2 bg-light pointer" onClick={showDetails}>
			<div className="card-body">
				<h5>{dsarRequestId}</h5>

				<p className="mb-0">Request Type: {dsarRequestType}</p>

				<p className="mb-0">Request Status: {status}</p>

				<p className="mb-0">VIN: {vins.length ?
					vins.map((vin, index) => {
						if (index === 0) { return vin }
						return ", " + vin // Add comma separation
					})
					: null
				}</p>

				{consumerProfileData.length > 1 ?
					<p className="mb-0">Number of Customer IDs: {consumerProfileData.length}</p> : null
				}
			</div>
		</div>
	);
}

export default Request;
