import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
    homePageWrapper: {
        fontSize: '0.8rem',
        '& .active-navigation-cell' :{
            border : "2px solid rgb(25 214 91) !important"
        }
    },
    tableHeaderColumn: {
        backgroundColor: '#7f9ed7',
        color: 'white'
    },
    advanceSeachWrapper: {
        background: '#f8f9fa',
        boxShadow: 'inset 0rem 0rem 0.25rem rgb(0 0 0 / 8%)',
        marginBottom: '9px'
    },
    tableBody: {

        '& tr:nth-of-type(odd)': {
            backgroundColor: '#cfd5ea !important'
        },
        '& td': {
            padding: '0.5rem 0rem 0.5rem 0.5rem',
            fontSize: '0.7rem'
        },
        '& tr': {
            backgroundColor: '#e9ebf5'
        }
    },
    headerTitle: {
        textAlign: "center",
        flexGrow: 1,
        fontWeight: "bold",
        marginTop: '10px'
    },
    title: {
        textAlign: "center",
        flexGrow: 1,
        fontWeight: "bold",
        // marginTop: '-25px'
    },
    toolbar: {
        maxHeight: '36px',
        minHeight: '36px',
        "& .selectStyle": {
            padding: '0.5px',
            height: '27px',
            marginTop: '4px',
            fontSize: '0.8rem'
        },
        "& .iconadvSearch svg": {
            fontSize: '20px',
        }
    },
    requestsCount: {
        marginTop: '0px',
        padding: '0px 0px 10px 0px',
        marginBottom: '5px',
        boxShadow: '0px 0px 1px 0px rgb(0 0 0)',
        '& .header': {
            background: '#7f9ed7',
            padding: '5px',
            color: "white",
            fontWeight: 'bold'
        }
    }
}));