import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from '../../../RoutesWithAuth'
import { CSSTransition } from 'react-transition-group';
import PageHeader from '../../common/PageHeader';
import AdminsOnly from '../../common/AdminsOnly';
import { withNavbar } from '../../common/Navbar';
import axios from '../../common/AxiosConfig';
import LitigationVinRow from './LitigationVinRow';


function LitigationHold() {
    const context = useContext(AppContext)
    const authToken = context.authToken.get
    const isAdmin = context.isAdmin.get
    const userId = context.userId.get

    const [Vins, setVins] = useState([])
    const [filteredVins, setFilteredVins] = useState([])

    const [filter, setFilter] = useState("All")
    const [addVinMode, setAddVinMode] = useState(false)
    const [vin, setVin] = useState("")
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [requestedBy, setRequestedBy] = useState("")
    const [requestedByEmail, setRequestedByEmail] = useState("")
    const [active, setActive] = useState(true)

    useEffect(() => {
        if (authToken !== null && isAdmin === true) {
            axios.get(`/litigation_vins`, {
                headers: {
                    Authorization: authToken
                }
            }).then(res => {
                setVins(res.data)
            }).catch(err => {
                console.log(err)
            })
        }
    }, [authToken, isAdmin])

    useEffect(() => {
        setFilteredVins(
            Vins.filter(vin => {
                if (filter === "All") { return true }
                else if (filter === "Active") { return vin.is_active }
                else if (filter === "Inactive") { return !vin.is_active }
                return null;
            })
        )
    }, [filter, Vins])

    const addVin = () => {
        const input = document.getElementById("new-vin")
        if (!input.reportValidity()) {
            return
        }

        axios.post(`/litigation_vins`, {
            vin: vin.trim(),
            start_date: startDate,
            end_date: endDate,
            is_active: active,
            requested_by: requestedBy,
            requested_by_email: requestedByEmail,
            userId: userId,
        }, {
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            // Fetch the new data
            axios.get('/litigation_vins', {
                headers: {
                    Authorization: authToken
                }
            }).then(res => {
                setVins(res.data)
                setAddVinMode(false)
                setVin("")
                setStartDate("")
                setEndDate("")
                setActive(true)
            }).catch(err => {
                console.log(err)
            })
        }).catch(err => {
            if (err.response) {
                const errorFeedback = document.getElementById(`error-add-vin`)
                errorFeedback.innerHTML = err.response.data.message
            }
        })
    }

    const exitAddMode = () => {
        setAddVinMode(false)
        setVin("")
        setStartDate("")
        setEndDate("")
        setActive(true)
    }


    if (isAdmin === null) {
        return null
    } else if (isAdmin === false) {
        return <AdminsOnly header="Litigation Hold" />
    }

    return (
        <div className="container mb-5">

            <PageHeader header="Vehicles Under Litigation Hold" />

            <div className="d-flex align-items-center mb-3 mt-4">
                <div className="form-inline mr-auto">
                    <label className="small mr-1" htmlFor="keyFilter">Filter:</label>
                    <select className="form-control form-control-sm" id="keyFilter" value={filter} onChange={e => { setFilter(e.target.value) }}>
                        <option>All</option>
                        <option>Active</option>
                        <option>Inactive</option>
                    </select>
                </div>

                {isAdmin && !addVinMode ?
                    <button type="button" className="btn btn-sm btn-warning small-button mr-2" onClick={() => { setAddVinMode(true) }}>Add Vin</button>
                    : null
                }
            </div>

            <table className="table table-striped table-sm">
                <thead>
                    <tr className="text-center">
                        <th scope="col">#</th>
                        <th scope="col">VIN</th>
                        <th scope="col">Start Date</th>
                        <th scope="col">End Date</th>
                        <th scope="col">Active</th>
                        <th scope="col">Requested By</th>
                        <th scope="col">Requested By Email</th>
                        {isAdmin ? <th scope="col">Edit</th> : null}
                    </tr>
                </thead>
                <CSSTransition in={filteredVins.length > 0} appear={true} timeout={125} classNames="swipeD">
                    <tbody className="text-center">
                        {addVinMode ?
                            <tr>
                                <td>
                                    {/* Serial # */}
                                </td>
                                <td>
                                    <input className="form-control" id="new-vin" type="text" value={vin} pattern=".{17,17}"
                                        title="VIN must be 17 characters long!" maxLength="17"
                                        onChange={e => setVin(e.target.value.toUpperCase())} required
                                    />
                                    <span id={`error-add-vin`} className="small text-danger mb-1"></span>
                                </td>
                                <td>
                                    <input style={{ width: "150px" }} className="form-control pr-0 pl-1" type="date" value={startDate} onChange={e => setStartDate(e.target.value)} />
                                </td>
                                <td>
                                    <input style={{ width: "150px" }} className="form-control pr-0 pl-1" type="date" value={endDate} onChange={e => setEndDate(e.target.value)} />
                                </td>
                                <td>
                                    <select style={{ width: "65px" }} className="form-control pr-0 pl-1" value={active} onChange={e => { setActive(e.target.value === 'true' ? true : false) }}>
                                        <option value={true}>True</option>
                                        <option value={false}>False</option>
                                    </select>
                                </td>
                                <td>
                                    <input className="form-control" type="text" value={requestedBy} onChange={e => setRequestedBy(e.target.value)} />
                                </td>
                                <td>
                                    <input className="form-control" type="email" value={requestedByEmail} onChange={e => setRequestedByEmail(e.target.value)} />
                                </td>
                                <td>
                                    <button type="button" className="btn btn-sm small-button btn-danger mr-1" onClick={exitAddMode}>
                                        <i className="material-icons small-icon">clear</i>
                                    </button>
                                    <button type="button" className="btn btn-sm small-button btn-success" onClick={addVin}>
                                        <i className="material-icons small-icon">done</i>
                                    </button>
                                </td>
                            </tr>
                            : null
                        }
                        {filteredVins.map((vin, i) => (
                            <LitigationVinRow key={vin.vin} serialNumber={i + 1} data={vin} setVins={setVins} isAdmin={isAdmin} authToken={authToken} />
                        ))}
                    </tbody>
                </CSSTransition>
            </table>
        </div>
    );
}

export default withNavbar(LitigationHold, 4);
