import { Box, IconButton, Tooltip, makeStyles} from '@material-ui/core';
import React, { useState } from 'react';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import Dialog from './Dialog';
import { Link } from 'react-router-dom';
import { v2UIRoutePath } from '../../common/commons';

const useStyles = makeStyles(() => ({
    dialogTitle: {
        padding: '0px',
        '& .MuiButtonBase-root': {
            color: 'white',
            marginTop: '-10px'
        },
    },
    dialogheader: {
        minHeight: 28,
        padding: "10px",
        textAlign: 'left',
        background: "#7395d3",
        fontSize: '1rem',
        color: "white",

        '& span': {
            fontWeight: 'bold'
        },
        '& .modelheader': {
            paddingBottom: '5px',
            fontWeight: 'bold'
        }
    },
}));


const AllAlerts = {
    "closed_ticket_count": "Closed",
    "duplicate_ticket_count": "Duplicate",
    "pending_attachments_count": "Attachment Pending",
    "ingestion_failures": "Processing Queue",
    "review_ticket_count": "Review"
}
const AlertData = [{
    id: "1",
    name: "Attachment Pending",
    success: "/alerts/file_attachment.svg",
    error: "/alerts/file_attachment_error.svg",
    mapping: "pending_attachments_count",
    no_alert: "No pending attachments",
    alert: " requests pending attachments",
    routePath: "reports/pendingattachmentreport",
    additionalImageProps : {
        height : '50px'
    }
}, {
    id: "2",
    name: "Closed",
    success: "/alerts/closed_status.svg",
    error: "/alerts/closed_status_error.svg",
    mapping: "closed_ticket_count",
    no_alert: "No closed requests",
    alert: " requests in closed status",
    routePath: "reports/requestsagingreport",
    additionalRouteData : {
        ticket_state : "Closed"
    }
}, {
    id: "3",
    name: "Duplicate",
    success: "/alerts/duplicate_requests.svg",
    error: "/alerts/duplicate_requests_error.svg",
    mapping: "duplicate_ticket_count",
    no_alert: "No duplicate requests",
    alert: " duplicate requests",
    routePath: "reports/manualactionrequests"
}, {
    id: "4",
    name: "Processing Queue",
    success: "/alerts/data_ingest.svg",
    error: "/alerts/data_ingest_error.svg",
    mapping: "ingestion_failures",
    no_alert: "No processing queue errors",
    alert: " requests errored in processing queue",
    routePath: "tracking/requestqueue",
    additionalRouteData : {
        status : "Failed"
    }
}, {
    id: "5",
    name: "Review",
    success: "/alerts/pending_review.svg",
    error: "/alerts/pending_review_error.svg",
    mapping: "review_ticket_count",
    no_alert: "No review requests",
    alert: " requests under manual review",
    routePath: "reports/manualactionrequests/reviewrequests",
    
}];


const AlertIcon = ({ requestHealth }) => {
    const [isOpen, setIsOpen] = useState(false)
    const alerts = [];
    Object.keys(AllAlerts).forEach((_alert) => {
        if (requestHealth[_alert] && requestHealth[_alert] !== 0) {
            alerts.push(AllAlerts[_alert])
        }
    })
    const classes = useStyles();
    const updateComponentState = () => {
        setIsOpen(false);
    }
    const comment = alerts.length === 0 ? "No additional alerts in CPS" : `${alerts.length} alerts. ${alerts.join(", ")}`;
    return (
        <>
            <Tooltip title={comment}>
                <IconButton aria-label="" onClick={() => { setIsOpen(true) }} data-testid={"notificationButton"}>
                    <NotificationsActiveIcon data-testid={"notificationIcon"} style={{
                        color: alerts.length > 0 ? "rgb(235, 10, 30)" : "green"
                    }} />

                </IconButton>

            </Tooltip>
            {isOpen && (<Dialog
                isOpen={isOpen}
                isLoading={false}
                updateDialogStatus={() => {
                    updateComponentState();
                }}
                popupGridData={AlertData}
                customRowHeight={60}
                dialogTitleClassName={classes.dialogTitle}
                popupColumns={[{
                    name: "Alert Status",
                    mapping: "rowId",
                    width : 150,
                    "align": "center",
                    renderCell: (params) => {
                        let imageUrl = params.row.success;

                        if (requestHealth && requestHealth[params.row.mapping] > 0) {
                            imageUrl = params.row.error;
                        }

                        return <>
                            <img
                                data-testid={`${params.row.mapping}`}
                            alt=""
                            src={imageUrl} {...(params.row.additionalImageProps || { height : "30px"} )} ></img>
                        </>
                    }
                }, {
                    name: 'Alert Description',
                    mapping: 'activity',
                    renderCell: (params) => {
                        let textToDisplay = params.row.no_alert;

                        if (requestHealth && requestHealth[params.row.mapping] > 0) {
                            textToDisplay = `${requestHealth[params.row.mapping]} ${params.row.alert}`;
                        }

                        return <Link color="secondary" onClick={()=>{
                            setIsOpen(false);
                        }} to={() => {
                            
                            return {
                              pathname: `${v2UIRoutePath}${params.row.routePath}`,
                              state: { 
                                ...params.row?.additionalRouteData
                              }
                            }
                          }}
                          >
                            {textToDisplay}
                        </Link>
                    }
                }]}
                enablePagination={false}
                title={(
                    <Box paddingBottom={"5px"}  fontSize={"1rem"}>
                        <div className={classes.dialogheader}>Alerts</div>
                    </Box>
                )}
            />)}
        </>
    )
}
export default AlertIcon;