import React, { useState, useEffect, useContext } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { CSSTransition } from 'react-transition-group';
import { AppContext } from '../../RoutesWithAuth';
import { Link } from 'react-router-dom';
import axios from '../common/AxiosConfig';
import moment from 'moment';
import PageHeader from '../common/PageHeader';
import { withNavbar } from '../common/Navbar';
import { feedbackTimeout, DATEFORMATUTC, DATEFORMATLOCAL } from '../common/commons';

const ValidFeedBackDomId = 'valid-feedback';

function ViewResponseFiles(props) {
	const cutoverDate = moment('5-14-2020')
	const { dsarRequestId } = props.match.params;
	const { history } = props

	const context = useContext(AppContext)
	const authToken = context.authToken.get

	const [response, setResponse] = useState([])
	const [attachments, setAttachments] = useState([])
	const [requestStatus, setRequestStatus] = useState(null)
	const [attachmentUploadedTime, setAttachmentUploadedTime] = useState(null)
	const [tipsJsonReceivedTime, setTipsJsonReceivedTime] = useState(null)
	const [tipsJson, setTipsJson] = useState(null)
	const [createdDate, setCreatedDate] = useState(null)
	const [autoUploadedAttempts, setAutoUploadedAttempts] = useState(null)
	const [manualUploadedAttempts, setManualUploadedAttempts] = useState(null)
	const [requestor, setRequestor] = useState(null)
	const [reuploadProcessing, setReuploadProcessing] = useState(false)

	const fetchData = () => {
		axios.get(`ticket/${dsarRequestId}/complete-response`, {
			headers: {
				Authorization: authToken
			}
		}).then(res => {
			setAttachments(res.data.system_attachments)
			setResponse(res.data.response)
			setRequestor(res.data.requestor)
			setAttachmentUploadedTime(res.data.tips.ftp_placed_time)
			setTipsJsonReceivedTime(res.data.tips.tips_ack_time)
			setTipsJson(res.data.tips.tips_ack_json)
			setRequestStatus(res.data.tips.status)
			setCreatedDate(res.data.request_created_date)
			setAutoUploadedAttempts(res.data.tips.auto_upload_attempts)
			setManualUploadedAttempts(res.data.tips.manual_upload_attempts)
		}).catch(err => {
			console.log(err)
		})
	}
	useEffect(() => {
		if (authToken !== null) {
			fetchData()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authToken])


	const downloadFile = (e) => {
		const filename = e.target.value
		const appId = e.target.dataset.app_id

		axios.get(`ticket/response/download/${dsarRequestId}/${appId}/${filename}`, {
			headers: {
				Authorization: authToken
			}
		}).then(res => {

			const link = document.createElement('a');
			link.href = res.data.presignedGet;
			link.setAttribute('download', `${filename}`);
			document.body.appendChild(link);
			link.click();
			link.remove()

		})
	}

	const downloadTipsJSON = () => {
		const link = document.createElement('a');
		const file = new Blob([JSON.stringify(tipsJson, null, 4)], { type: 'application/json' })
		link.href = URL.createObjectURL(file);
		link.setAttribute('download', `${dsarRequestId}_TIPS_Response.json`);
		document.body.appendChild(link);
		link.click();
		link.remove()
	}

	const regenerateResponse = () => {
		const validFeedback = document.getElementById(ValidFeedBackDomId)
		const invalidFeedback = document.getElementById('invalid-feedback')

		axios.post('response/regenerate', {
			dsar_id: dsarRequestId
		}, {
			headers: {
				Authorization: authToken
			}
		}).then(res => {
			console.log(res)
			validFeedback.innerHTML = res.data.message
		}).catch(err => {
			console.log('There was an error creating the ticket!', err)
			invalidFeedback.innerHTML = err.response.data.message
		}).finally(() => {
			removeFeedback(ValidFeedBackDomId)
			removeFeedback('invalid-feedback')
		})
	}

	const reuploadToTIPS = () => {
		setReuploadProcessing(true)
		const validFeedback = document.getElementById(ValidFeedBackDomId)
		const invalidFeedback = document.getElementById('modal-invalid-feedback')
		invalidFeedback.innerHTML = ""

		axios.post("response/resend_tips", {
			dsar_id: dsarRequestId
		}, {
			headers: {
				Authorization: authToken
			}
		}).then(res => {
			validFeedback.innerHTML = res.data.message
			invalidFeedback.innerHTML = ""
			hideModal('reupload-modal')
			setAttachmentUploadedTime(res.data.ftp_placed_time)
			setTipsJsonReceivedTime(res.data.tips_ack_time)
			setRequestStatus(res.data.new_status)
			tipsJson(res.data.tips_ack_json)
		}).catch(err => {
			if (err.response?.data) {
				invalidFeedback.innerHTML = err.response.data.message
			}
		}).finally(() => {
			setManualUploadedAttempts(manualUploadedAttempts + 1)
			removeFeedback(ValidFeedBackDomId)
			setReuploadProcessing(false)
			fetchData()
		})
	}

	const removeFeedback = (id) => {
		// Remove the form validation after a specified timeout period
		setTimeout(
			function () {
				const field = document.getElementById(id)
				if (!field) { return }
				field.innerHTML = ""
			}, feedbackTimeout
		)
	}

	const hideModal = (id) => {
		const modal = document.getElementById(id)
		modal.classList.remove('show')
		const backdrop = document.getElementsByClassName('modal-backdrop')
		document.body.removeChild(backdrop[0])
	}

	const copyToClipboard = () => {
		const clip = document.createElement('textarea');
		clip.value = JSON.stringify(tipsJson, null, 4)
		document.body.appendChild(clip);
		clip.select();
		document.execCommand('copy');
		document.body.removeChild(clip);
	}

	const viewResponse = () => {
		history.push(`/ticket/${dsarRequestId}/viewResponseJSON`)
	}

	const requestorBasedText = () => {
		if (requestor === "TCI Customer") {
			return "QB64"
		} else {
			return "TIPS"
		}
	}

	return (
		<div className="container mb-5">

			<PageHeader header="View Response Files" removeMargin={true} />

			<p className="lead mb-4">
				<Link to={`/`}>Home&nbsp;</Link>
				&gt; <Link to={`/ticket/${dsarRequestId}`}>Request ID: {dsarRequestId}&nbsp;</Link>
				&gt; View Response Files<br />
				Request Status: {requestStatus}
			</p>

			<CSSTransition in={response.length > 0} timeout={125} classNames="swipeD">
				<div>
					{response.length ?
						<div className="text-center">
							<h5>
								Response
							</h5>

							<table className="table mt-3">
								<thead>
									<tr>
										<th scope="col">Filename</th>
										<th scope="col"></th>
									</tr>
								</thead>
								<tbody>
									{response.map(attachment => {
										if (attachment.app_name === 'Response') {
											return (
												<tr key="response">
													<td>response.json</td>
													<td>
														<button className="btn btn-sm btn-success ml-2" onClick={viewResponse}>
															View
														</button>
														<button className="btn btn-sm btn-primary ml-2" value={attachment.filename}
															data-app_id={attachment.app_id} onClick={downloadFile}
														>
															Download
														</button>
														<button className="btn btn-sm btn-primary ml-2" onClick={regenerateResponse}>Regenerate</button>
														<button className="btn btn-sm btn-primary ml-2" data-toggle="modal" data-target="#reupload-modal"
															disabled={requestStatus === "Cancelled"}
														>
															Reupload to {requestorBasedText()}
														</button>
														<br />
														<small className="text-success" id="valid-feedback"></small>
														<small className="text-danger" id="invalid-feedback"></small>
													</td>
												</tr>
											)
										} else {
											return (
												<tr key={attachment.s3_key}>
													<td>{attachment.app_name}</td>
													<td>{attachment.filename}</td>
													<td>
														<button className="btn btn-sm btn-primary ml-2" value={attachment.filename}
															data-app_id={attachment.app_id} onClick={downloadFile}
														>
															Download
														</button>
													</td>
												</tr>
											)
										}
									})
									}
								</tbody>
							</table>
						</div>
						:
						null
					}

					{attachments.length ?
						<div className="text-center">
							<h5>System Attachments</h5>

							<table className="table mt-3">
								<thead>
									<tr>
										<th scope="col">System</th>
										<th scope="col">Filename</th>
										<th scope="col"></th>
									</tr>
								</thead>
								<tbody>
									{
										attachments.map(attachment => {
											if (attachment.app_name === 'Response') {
												return (
													<tr key={`${attachment.filename}-${attachment.app_id}`}>
														<td>{attachment.app_name}</td>
														<td>response.json</td>
														<td>
															<button className="btn btn-sm btn-success ml-2" onClick={viewResponse}>
																View
															</button>
															<button className="btn btn-sm btn-primary ml-2" value={attachment.filename}
																data-app_id={attachment.app_id} onClick={downloadFile}
															>
																Download
															</button>
														</td>
													</tr>
												)
											} else if (attachment.attachment_type === 'attachment') {
												return (
													<tr key={attachment.s3_key}>
														<td>{attachment.app_name}</td>
														<td>{attachment.filename}</td>
														<td>
															<button className="btn btn-sm btn-primary ml-2" value={attachment.filename}
																data-app_id={attachment.app_id} onClick={downloadFile}
															>
																Download
															</button>
														</td>
													</tr>
												)
											}
											return null
										})
									}
								</tbody>
							</table>
						</div>
						:
						null
					}

					{attachmentUploadedTime || tipsJsonReceivedTime || tipsJson ?
						<div className="mt-3">
							<h5 className="text-center">{requestorBasedText()} Response</h5>

							<p className="my-3">
								Automated Upload Attempts: {autoUploadedAttempts}
								<br />
								Manual Upload Attempts: {manualUploadedAttempts}
								<br />
								Attachments Placed in FTP: {attachmentUploadedTime && attachments ?
									<span>
										<span id="ftp_placed">
											{moment(attachmentUploadedTime).utc().format(DATEFORMATUTC)}
										</span>
										<UncontrolledTooltip placement="bottom" target="ftp_placed">
											{moment(attachmentUploadedTime).local().format(DATEFORMATLOCAL)}
										</UncontrolledTooltip>
									</span>
									:
									"No Attachments to Upload"
								}
								<br />
								{tipsJsonReceivedTime ?
									<span>
										Aggregated Response Sent to {requestorBasedText()}:&nbsp;
										<span id="tips_json">
											{moment(tipsJsonReceivedTime).utc().format(DATEFORMATUTC)}
										</span>
										<UncontrolledTooltip placement="bottom" target="tips_json">
											{moment(tipsJsonReceivedTime).local().format(DATEFORMATLOCAL)}
										</UncontrolledTooltip>
										<br />
										Acknowledgement Received from {requestorBasedText()}:&nbsp;
										<span id="att_upload">
											{moment(tipsJsonReceivedTime).utc().format(DATEFORMATUTC)}
										</span>
										<UncontrolledTooltip placement="bottom" target="att_upload">
											{moment(tipsJsonReceivedTime).local().format(DATEFORMATLOCAL)}
										</UncontrolledTooltip>
									</span>
									: null
								}
							</p>

							{tipsJson ?
								<div>
									<span className="float-right mt-2 mr-2">
										<button className="btn btn-sm btn-secondary ml-2" id="copyBtn" type="button" onClick={copyToClipboard}>Copy</button>
										<UncontrolledTooltip placement="bottom" target="copyBtn" trigger="legacy">Copied!</UncontrolledTooltip>
									</span>
									<div className="bg-light rounded border mb-1">
										{(<div className="m-2"><pre>{JSON.stringify(tipsJson, null, 2)}</pre></div>)}
									</div>

									<div className="text-right mb-2">
										<button className="btn btn-sm btn-primary" onClick={downloadTipsJSON}>
											Download
										</button>
									</div>
								</div>
								:
								<div className="text-center text-danger">
									Response was uploaded manually via {requestorBasedText()} UI
								</div>
							}
						</div>
						: null
					}
				</div>
			</CSSTransition>

			<div className="modal fade" id="reupload-modal" tabIndex="-1" role="dialog" aria-hidden="true">
				<div className="modal-dialog mb-3" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Confirm Reupload to {requestorBasedText()}</h5>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							{moment(createdDate).isSameOrBefore(cutoverDate) ?
								<span>
									This request was created on {moment(createdDate).format('MMM Do, YYYY')} before
									{requestorBasedText()} integration on {cutoverDate.format('MMM Do, YYYY')} and hence
									not an eligible request for uploading response through {requestorBasedText()} API. Do you
									still want to re-upload?
								</span>
								:
								<span>Do you really want to re-upload the response to {requestorBasedText()}?</span>
							}
						</div>
						<div className="modal-footer pb-1">
							<button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
							<button className="btn btn-primary" disabled={reuploadProcessing} onClick={reuploadToTIPS}>
								{reuploadProcessing ? <span className="spinner-border spinner-border-sm mr-2"></span> : null}
								Confirm
							</button>
						</div>
						<small className="text-danger text-right px-3 pb-2" id="modal-invalid-feedback"></small>
					</div>
				</div>
			</div>
		</div>
	);
}

export default withNavbar(ViewResponseFiles);
