import { SvgIcon } from '@material-ui/core';
import React from 'react';

const AdvanceSearchIcon = ({handleClick}) => {
    return (
        <SvgIcon data-testid={"iconadvsearch"} onClick={handleClick} viewBox={"0 0 980.000000 982.000000"}>
            <g transform="translate(0.000000,982.000000) scale(0.100000,-0.100000)"
                fill="inherit" stroke="none">
                <path d="M6040 9804 c-499 -45 -914 -161 -1341 -375 -331 -166 -612 -365 -877
                    -623 -294 -287 -499 -569 -687 -945 -327 -655 -443 -1379 -335 -2092 82 -537
                    279 -1027 613 -1519 85 -124 85 -125 70 -154 -9 -16 -126 -141 -261 -278
                    l-246 -249 -178 178 -178 178 -1310 -1310 -1310 -1310 647 -647 648 -648 1310
                    1310 1310 1310 -178 178 -178 178 223 220 c229 226 305 294 328 294 8 0 75
                    -40 149 -89 232 -152 592 -349 714 -392 37 -13 40 -12 57 8 10 12 39 73 65
                    135 80 192 190 398 297 556 27 40 48 81 46 90 -2 11 -53 38 -147 79 -341 147
                    -589 319 -866 597 -224 224 -353 406 -484 677 -113 233 -194 503 -238 786 -24
                    157 -24 559 0 708 104 658 435 1239 937 1647 288 233 662 420 1022 512 191 49
                    253 56 543 63 369 8 529 -10 800 -93 1329 -403 2110 -1727 1809 -3069 -57
                    -254 -168 -529 -313 -775 -39 -65 -71 -123 -71 -129 0 -5 19 -16 43 -25 23 -8
                    87 -32 142 -52 136 -49 343 -149 484 -234 64 -38 126 -70 138 -70 34 0 69 52
                    173 261 200 402 305 741 362 1161 18 133 22 212 22 433 0 271 -6 356 -44 585
                    -91 542 -321 1080 -652 1522 -343 457 -758 801 -1273 1053 -368 181 -719 287
                    -1125 340 -119 16 -554 28 -660 19z"/>
                <path d="M7390 4280 c-622 -86 -1165 -426 -1516 -951 -181 -272 -303 -605
                    -344 -939 -15 -123 -12 -382 5 -514 71 -543 354 -1044 785 -1389 257 -206 593
                    -365 925 -438 67 -14 147 -21 315 -25 266 -7 436 5 576 40 548 139 1001 456
                    1307 914 139 208 263 504 322 767 21 93 32 538 16 687 -19 191 -88 419 -190
                    633 -305 638 -869 1067 -1578 1201 -102 19 -517 28 -623 14z m1701 -1017 c-55
                    -65 -331 -390 -615 -723 l-515 -605 -1 -657 0 -658 -305 0 -305 0 0 661 0 660
                    -597 702 c-328 386 -603 710 -611 720 -14 16 59 17 1517 17 l1532 0 -100 -117z"/>
            </g>
        </SvgIcon>
    )
}
export default AdvanceSearchIcon;