import React from 'react';
import { withNavbar } from '../common/Navbar';
import PageHeader from '../common/PageHeader';


function Health(props) {
    const { header } = props

    return (
        <div className="container">

            <PageHeader header={header} />

            <div className="mt-4 mb-5">
                <div className="card mb-2 bg-light">
                    <div className="card-body">
                        {linksMap[header].map(l => (
                            <div key={l.title}>
                                <a href={l.link} target="_blank" rel="noopener noreferrer">{l.title}</a>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

        </div>

    );
}

export default withNavbar(Health, 3);


const linksMap = {
    "CPS Health": [
        {
            title: "CTCPS Monitoring Dashboard",
            link: "https://p.datadoghq.com/sb/rh1s03wkc079c6ke-3c1166eea7d1501e9f80cfe9814819aa",
        },
        {
            title: "CTCPS DB Monitoring Dashboard",
            link: "https://tmna-ctp.datadoghq.com/monitors/manage?saved-view-id=504481",
        },
        {
            title: "21mm Monitoring Dashboard",
            link: "https://tmna-ctp.datadoghq.com/dashboard/kfm-bzv-9n5/ctcps21mmqadashboarddraft?from_ts=1603387008112&live=true&to_ts=1603991808112",
        },
    ],
}
