import React from 'react';
import Header from './Header';
import List from './List';

const Applications = ({ systems, requestDetails, handleReconsolidate, reloadInformation,handleViewResponseDownstream }) => {
    return (
        <>
            <Header data={requestDetails} handleReconsolidate={handleReconsolidate} />
            <List data={systems} requestDetails={requestDetails} reloadInformation={reloadInformation} handleViewResponseDownstream={handleViewResponseDownstream} />
        </>
    )
}

export default React.memo(Applications);