import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import axios from '../../common/AxiosConfig';
import moment from 'moment';
import { UncontrolledTooltip } from 'reactstrap';
import Dialog from '../newUI/Dialog';
import { DATEFORMATUTC, DATEFORMATLOCAL } from '../../common/commons';

const getSystemDownStreamButtons = ({ create_ticket_status,
	create_response_status, is_request_automated, is_response_automated,
	response_action }) => {

	let ticketCreationIsAutomated = false;
	let createResponseBtn = false;
	let responseCreationIsAutomated = false;
	let markTicketCreated = false;
	let viewResponseBtn = false;

	if (((is_request_automated && is_response_automated) || (is_request_automated && !is_response_automated))
		&& !create_response_status && !create_ticket_status) {
		ticketCreationIsAutomated = true;
	}
	if (((is_request_automated && is_response_automated) || (!is_request_automated && is_response_automated))
		&& !create_response_status && create_ticket_status) {
		responseCreationIsAutomated = true;
	}
	if (create_response_status) {
		viewResponseBtn = true;
	}
	if (((is_request_automated && !is_response_automated) || (!is_request_automated && !is_response_automated))
		&& ((create_response_status && response_action === "error") || (create_ticket_status && !create_response_status))) {
		createResponseBtn = true;
	}
	if (((!is_request_automated && is_response_automated) || (!is_request_automated && !is_response_automated))
		&& !create_response_status && !create_ticket_status) {
		markTicketCreated = true;
	}
	return {
		ticketCreationIsAutomated,
		createResponseBtn,
		responseCreationIsAutomated,
		markTicketCreated,
		viewResponseBtn
	}
}
function AppsInfo(props) {
	const { history, dsar_id, isAdmin, authToken } = props;
	const {
		name, display, id, created_date,
		system_generated_ticket_id,
		response_created_date,
		response_action, integration_pattern
	} = props.data;
	const [expectedAttachments, setExpectedAttachments] = useState(0)
	const [existingAttachments, setExistingAttachments] = useState(0)
	// displayHubStatusPopup state is used to store popup visibility state
	// true represents Popup to be visible ,false - represents popup should be hidden
	const [displayHubStatusPopup, setDisplayHubStatusPopup] = useState(false);
	// hubStatusData state is used to store hub status API response.
	// null represents Loading , [] - represents Empty response
	const [hubStatusData, setHubStatusData] = useState(null);

	useEffect(() => {
		if (authToken !== null) {
			axios.get(`ticket/response/${dsar_id}/${id}`, {
				headers: {
					Authorization: authToken
				}
			}).then(res => {
				setExpectedAttachments(res.data.expected_attachments)
				setExistingAttachments(res.data.existing_attachments)
			}).catch(err => {
				console.log(err)
			})

		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authToken])

	const [createdDate, setCreatedDate] = useState(created_date)

	const feedbackTimeout = 4000

	const snowDownstreams = [
		'Service Connect',
		'Customer Care-AMS', 'CT-BI',
		'Customer Care-Sustainment',
	]


	const RESPONSE_ACTION_COLOR = {
		success: ["Success", "text-success"],
		not_found: ["Record Not Found", "text-success"],
		error: ["Error", "text-danger"],
		data_exempt: ["Data Exempt by Policy", "text-danger"],
	};

	const createResponse = () => {
		history.push(`/ticket/${dsar_id}/${id}/createResponse`)
	}

	const viewResponse = () => {
		history.push(`/ticket/${dsar_id}/${id}/viewResponse`)
	}

	const markCreated = () => {
		history.push(`/ticket/${dsar_id}/${id}/markCreated`)
	}

	const resendDownstreamMessage = () => {
		const recreateBtn = document.getElementById(`recreate-${name}`)
		const errorFeedback = document.getElementById(`invalid-feedback-${name}`)
		const successFeedback = document.getElementById(`valid-feedback-${name}`)

		recreateBtn.setAttribute('disabled', true)
		axios.post('/downstream/resend_message', {
			dsar_id: dsar_id,
			app_name: name,
		}, {
			headers: {
				Authorization: authToken
			}
		}).then(res => {
			successFeedback.innerHTML = res.data.message
			// Workaround for making another HTTP call to update the created date
			setCreatedDate(moment.utc())
		}).catch(err => {
			console.log(err)
			if (err.response.data.message) {
				errorFeedback.innerHTML = err.response.data.message
				removeFeedback(name)
			}
		})
	}
	const removeFeedback = (name) => {
		// Remove the form validation after a specified timeout period
		setTimeout(
			function () {
				const field = document.getElementById(`invalid-feedback-${name}`)
				if (!field) { return }
				field.innerHTML = ""
				const recreateBtn = document.getElementById(`recreate-${name}`)
				recreateBtn.removeAttribute('disabled')
			}, feedbackTimeout
		)
	}
	/**
	 * triggerHubStatus Function will be called when user clicks on the Hub Icon.
	 */
	const triggerHubStatus = () => {
		// Display the Hub Status Dialog
		setDisplayHubStatusPopup(true);
		// Mark the loading State null represents loading is true
		setHubStatusData(null);
		axios.get(`tc/tc_hub_status?dsar_id=${dsar_id}`, {
			headers: {
				Authorization: authToken
			}
		}).then(res => {
			let tc_hub_response = res?.data || [];
			// when there is no data available
			if (res.status === 204) {
				tc_hub_response = [];
			}
			// Sorting the Response based on the Product Name
			tc_hub_response = tc_hub_response.sort((a, b) => {
				if (a.product < b.product) {
					return -1;
				} else if (a.product > b.product) {
					return 1;
				}
				return 0;
			});
			setHubStatusData(tc_hub_response);
		}).catch(err => {
			console.log(err)
			setHubStatusData([]);
		})

	}

	if (name === 'Zuora') {
		return (
			<div className="col-6">
				<div className="card mb-2 bg-light" style={{ height: "calc(100% - .5rem)" }}>
					<div className="card-body">
						<h5>{display}
							{response_created_date !== null ?
								<small className={`h6 ${RESPONSE_ACTION_COLOR[response_action][1]}`} >&nbsp;({RESPONSE_ACTION_COLOR[response_action][0]})</small>
								: null
							}
							{(expectedAttachments - existingAttachments > 0) ?
								<span>
									<span id="pending_att">
										<img src="/yellow_missing.png" width="25" height="23" alt="pending" />
									</span>
									<UncontrolledTooltip placement="bottom" target="pending_att">
										Pending {expectedAttachments - existingAttachments} attachment(s)
									</UncontrolledTooltip>
								</span>
								: null
							}
						</h5>
						{createdDate !== null ?
							<p className="mb-0">Ticket Created:&nbsp;
								<span id="zuora-created">
									{moment(createdDate).utc().format(DATEFORMATUTC)}
								</span>
								<UncontrolledTooltip placement="bottom" target="zuora-created">
									{moment(createdDate).local().format(DATEFORMATLOCAL)}
								</UncontrolledTooltip>
							</p>
							: null
						}
						{response_created_date !== null ?
							<p className="mb-0">Response Received:&nbsp;
								<span id="zuora-response">
									{moment(response_created_date).utc().format(DATEFORMATUTC)}
								</span>
								<UncontrolledTooltip placement="bottom" target="zuora-response">
									{moment(response_created_date).local().format(DATEFORMATLOCAL)}
								</UncontrolledTooltip>
							</p>
							: null
						}

						<div className="text-center">
							{((integration_pattern === "API Push") && isAdmin) ?
								<p className="text-center m-0">
									<button className="btn btn-warning center mt-3 mr-2" id={`recreate-${name}`} onClick={resendDownstreamMessage}>
										Recreate Ticket
									</button>
									<Link to={`/ticket/${dsar_id}/guidDetails`}>
										<button className="btn btn-success col-lg-up center mx-2 mt-3 mb-sm-2 mb-lg-0">GUID Details</button>
									</Link>
								</p>
								:
								<p className="text-center">
									<Link to={`/ticket/${dsar_id}/guidDetails`}>
										<button className="btn btn-success col-lg-up center mx-2 mt-3 mb-sm-2 mb-lg-0">GUID Details</button>
									</Link>
								</p>
							}
							<p className="text-danger small mb-0" id={`invalid-feedback-${name}`}></p>
							<p className="text-success small mb-0" id={`valid-feedback-${name}`}></p>
						</div>
					</div>
				</div>
			</div>
		)
	}

	const { ticketCreationIsAutomated, createResponseBtn, responseCreationIsAutomated, markTicketCreated, viewResponseBtn } = getSystemDownStreamButtons(props.data);

	return (
		<div className="col-6">
			<div className="card mb-2 bg-light" style={{ height: "calc(100% - .5rem)" }}>
				<div className="card-body">
					<h5>
						{display}
						{response_created_date !== null && response_action in RESPONSE_ACTION_COLOR ?
							<small className={`h6 ${RESPONSE_ACTION_COLOR[response_action][1]}`} >&nbsp;({RESPONSE_ACTION_COLOR[response_action][0]})</small>
							: null
						}
						{(expectedAttachments - existingAttachments > 0) ?
							<span>
								<span id={`${name.replace(' ', '')}-pending_att`}>
									<img src="/yellow_missing.png" width="25" height="23" alt="pending" />
								</span>
								<UncontrolledTooltip placement="bottom" target={`${name.replace(' ', '')}-pending_att`}>
									Pending {expectedAttachments - existingAttachments} attachment(s)
								</UncontrolledTooltip>
							</span>
							: null
						}
						{name === 'TC' && (
							<span className='float-right' onClick={() => { triggerHubStatus() }}>
								<img src="/TCHubStatus.png" height="45" alt="TCHubStatus" />
							</span>
						)}
					</h5>

					{displayHubStatusPopup && (
						<div>
							<Dialog
								isOpen={!!displayHubStatusPopup}
								isLoading={!hubStatusData}
								updateDialogStatus={() => {
									setDisplayHubStatusPopup(null)
								}}
								popupGridData={hubStatusData?.map((_displayHubStatusPopup, index) => {
									// Formating the response to add the ID which is required for the grid for displaying row index
									return {
										id: index + 1,
										..._displayHubStatusPopup
									}
								}) || []}
								popupColumns={[{
									name: "#",
									mapping: "id",
								}, {
									name: 'TC System Name',
									mapping: 'product'
								}, {
									name: 'Response Status',
									mapping: 'status'
								}]}
								enablePagination={false}
								title={(
									<>
										<span>RequestId</span> : {dsar_id}
									</>
								)}
							/>
						</div>
					)}

					{/* Only display the System ID if it is different from the DSAR ID */}
					{system_generated_ticket_id && createdDate !== null && system_generated_ticket_id !== dsar_id ?
						<div>
							<p className="mb-0">Ticket Number:&nbsp;
								{name === 'Cerence' ?
									<a rel="noopener noreferrer" href={`https://tgt.assist.com/portal/incidents/${system_generated_ticket_id}`} target="_blank">
										{system_generated_ticket_id}
									</a>
									:
									null
								}
								{name === 'Servco' || name === 'Qualtrics' ||
									name === 'DriverWaiver' || name === 'HireVue' || name === 'ITGFLEET' ?
									<a rel="noopener noreferrer" href={`https://toyotaconnected.atlassian.net/browse/${system_generated_ticket_id}`} target="_blank">
										{system_generated_ticket_id}
									</a>
									:
									null
								}
								{snowDownstreams.includes(name) ?
									<a rel="noopener noreferrer" href={`https://tmna.service-now.com/sc_req_item_list.do?sysparm_first_row=1&sysparm_query=GOTOnumber%3d${system_generated_ticket_id}`} target="_blank">
										{system_generated_ticket_id}
									</a>
									:
									null
								}
								{!snowDownstreams.includes(name) && !['Cerence', 'Servco',
									'Qualtrics', 'DriverWaiver', 'HireVue', 'ITGFLEET'].includes(name) ?
									system_generated_ticket_id : null
								}
							</p>
							<hr />
						</div>
						: null
					}
					{createdDate !== null ?
						<p className="mb-0">Ticket Created:&nbsp;
							<span id={`${name.replace(/\s/g, '')}-created`}>
								{moment(createdDate).utc().format(DATEFORMATUTC)}
							</span>
							<UncontrolledTooltip placement="bottom" target={`${name.replace(/\s/g, '')}-created`}>
								{moment(createdDate).local().format(DATEFORMATLOCAL)}
							</UncontrolledTooltip>
						</p>
						: null
					}
					{response_created_date !== null ?
						<p className="mb-0">Response Received:&nbsp;
							<span id={`${name.replace(/\s/g, '')}-response`}>
								{moment(response_created_date).utc().format(DATEFORMATUTC)}
							</span>
							<UncontrolledTooltip placement="bottom" target={`${name.replace(/\s/g, '')}-response`}>
								{moment(response_created_date).local().format(DATEFORMATLOCAL)}
							</UncontrolledTooltip>
						</p>
						: null
					}
					<div className="text-center mt-1">
						{ticketCreationIsAutomated && (<>
							<p className="text-center">
								<button className="btn btn-success center mt-3" id="mark-created" disabled>Ticket Creation is Automated</button>
							</p>
						</>)}
						{createResponseBtn && (<>
							<p className="text-center">
								<button className="btn btn-success center mt-3" onClick={createResponse}>Create Response</button>
							</p>
						</>)}
						{responseCreationIsAutomated && (<>
							<p className="text-center">
								<button className="btn btn-success center mt-3" id="mark-created" disabled>Response Creation is Automated</button>
							</p>
						</>)}
						{markTicketCreated && (<>
							<p className="text-center">
								<button className="btn btn-success center mt-3" id="mark-created" onClick={markCreated}>Mark Ticket Created</button>
							</p>
						</>)}
						{viewResponseBtn && (<>
							<p className="row justify-content-center m-0 text-center">
								<button className="btn btn-success col-lg-up center mx-2 mt-3 mb-sm-2 mb-lg-0" onClick={viewResponse}>View Response</button>
							</p>
						</>)}
						<p className="text-danger small mb-0" id={`invalid-feedback-${name}`}></p>
						<p className="text-success small mb-0" id={`valid-feedback-${name}`}></p>
					</div>

					{((integration_pattern === "API Push") && isAdmin) &&
						<div className="text-center mt-1">
							<p className="text-center">
								<button className="btn btn-warning center mt-3 mr-2" id={`recreate-${name}`} onClick={resendDownstreamMessage}>
									Recreate Ticket
								</button>
							</p>
							<p className="text-danger small mb-0" id={`invalid-feedback-${name}`}></p>
							<p className="text-success small mb-0" id={`valid-feedback-${name}`}></p>
						</div>
					}
				</div>
			</div>
		</div>
	);
}

export default AppsInfo;
