/**
 * Data Dictionary component displays list of RTK keys and can perform download, filter, edit add and delete of the RTK Keys
 * This Component is rendered as part of Admin -> system Settings -> Data Dictionary Tab
 * It is available in the route - /newuiadmin/systemsettings
 */
import React, { useState, useContext, useEffect } from 'react';
import { AppContext } from '../../../../../RoutesWithAuth';
import axios from '../../../../common/AxiosConfig';
import DataGrid from '../../grid';
import { makeStyles } from "@material-ui/core/styles";
import RowEditActions from '../../grid/RowEditActions';
import moment from 'moment';
import Pagination from '../../grid/Pagination';
import Papa from 'papaparse';
import { GridEditInputCell } from '../../grid/EditInputCell';
import { LoadingIndicator } from '../../reports/reconcilationreport/MuiStyled';
import { GridEditSelectCell } from '../../grid/EditSelectCell';
import DeleteDialog from './DeleteDialog';

const getColumns = (app_Info) => [{
    name: "#",
    mapping: "rownum",
    width: 40,
    disableColumnMenu: true
}, {
    name: "Source",
    mapping: "display_name",
    type: 'singleSelect',
    valueOptions: app_Info?.map(({ display }) => display),
    editable: true,
    customSingleSelectFilter: true,
    width: '150'
},
{

    name: "Element Type",
    mapping: "element_type",
    editable: true,
    type: 'singleSelect',
    customSingleSelectFilter: true,
    valueOptions: ['Attribute', 'Transaction'],
    width: '125'
}, {
    name: 'Key',
    mapping: 'key',
    editable: true,
    width: 230,
    displayTootip: ["key", 20],
    renderEditCell: (params) => {
        return <GridEditInputCell {...params} />
    }
}, {
    name: 'Display Term',
    mapping: 'display_term',
    editable: true,
    width: 150,
    displayTootip: ["display_term", 10],
    renderEditCell: (params) => {
        return <GridEditInputCell {...params} />
    }

}, {
    name: 'Description',
    mapping: 'description',
    editable: true,
    width: 150,
    displayTootip: ["description", 30],
    renderEditCell: (params) => {
        return <GridEditInputCell {...params} />
    }
}, {
    name: 'Display Term French',
    mapping: 'display_term_fr',
    editable: true,
    width: 160,
    displayTootip: ["display_term_fr", 8],

}, {
    name: 'Description French',
    mapping: 'description_fr',
    editable: true,
    width: 150,
    displayTootip: ["description_fr", 8],

}, {
    name: 'Data Type',
    mapping: 'data_type',
    editable: true,
    type: 'singleSelect',
    valueOptions: ['Text', 'File-to-Attach-to-Privacy-Report'],
    width: '165',
    customSingleSelectFilter: true,
    displayTootip: ["data_type", 10],

}, {
    name: 'Review Category',
    mapping: 'review_category',
    editable: true,
    type: 'singleSelect',
    valueOptions: ['First Name', 'Last Name', 'Not Applicable', 'VIN'],
    width: '165',
    customSingleSelectFilter: true,
},{
    name: 'TIPS',
    mapping: 'tips',
    editable: true,
    type: 'singleSelect',
    valueOptions: ['Available', 'Development', 'Not Applicable', 'Pending'],
    customSingleSelectFilter: true,
    width: '100'
},
{
    name: "Revise Attribute Value",
    mapping: "overwrite",
    editable: true,
    type: 'singleSelect',
    valueOptions: ['Yes', 'No'],
    customSingleSelectFilter: true,
    width: '180'
},
{
    name: "Updated Attribute Value",
    mapping: "canned_message",
    editable: true,
    width: 180,
    displayTootip: ["canned_message", 10],
    renderEditCell: (params) => {
        return <GridEditInputCell {...params} />
    }
},{
    name: "Notes",
    mapping: "notes",
    editable: true,
    width: 150,
    displayTootip: ["notes", 10],
},{
    name : "Applicable Requestor",
    mapping: "applicable_requestor",
    editable: true,
    type: 'singleSelect',
    valueOptions: ['B2B', 'Customer','Employee', 'TCI Customer'],
    customSingleSelectFilter: true,
    width: 200,
    displayTootip: ["applicable_requestor_string", 10],
    renderEditCell: (params) => {
        return <GridEditSelectCell {...params} />
    },
    renderCellKey : "applicable_requestor_string"
},{
    name: "Is Active",
    mapping: "is_active",
    editable: true,
    type: 'singleSelect',
    valueOptions: ['True', 'False'],
    customSingleSelectFilter: true,
    width: '125'
},
{
    name: "Edit",
    mapping: "actions",
    width: 80,
    adminEnabled: true,
    disableColumnMenu: true,
    editable: false,
    align: 'center',
    headerAlign: 'center',
    renderCell: (params) => {
        return <RowEditActions
            customValidation={(rowInfo)=>{
                if(rowInfo?.is_active?.value === "True"){
                    return (rowInfo?.applicable_requestor?.value || [])?.length !== 0
                }
                return true;
            }}
            {...params}
            validationColumns={[
            "display_name",
            "element_type",
            "key",
            "display_term",
            "description"
        ]} />
    }
}, {
    name: "Delete",
    adminEnabled: true,
    mapping: "actions_delete",
    width: 80,
    disableColumnMenu: true,
    editable: false,
    align: 'center',
    headerAlign: 'center',
}];

const useStyles = makeStyles(() => ({
    contactsWrapper: {
        marginTop: '4px',
        marginRight: '15px',
        height: 'calc( 100% - 120px )',
        // Custom Class for the Row When the Row has in_active = true
        '& .isInactive-row-cust-cls': {
            color: 'red'
        },
        // Custom Class for the Cell when element type = Attribute
        '& .attr-cell-cust-cls': {
            color: '#2E6E9E'
        },
        // Custom Class for the Cell when element type = Transaction
        '& .transaction-cell-cust-cls': {
            color: '#B1510F'
        }
    }
}));
function DataDictionary(props) {
    const context = useContext(AppContext)
    const authToken = context.authToken.get
    const apps = context.apps.get
    const isAdmin = context.isAdmin.get


    // Holds all the data irrespective of the Grid Page
    const [appsData, setAppsData] = useState([]);
    const [editRowsModel, setEditRowsModel] = useState({});
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [deleteDataDictionary, setDeleteDataDictionary] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    // Holds the data related to Current Active Page in the Grid
    const [rtkKeys, setRTKkeys] = useState([]);
    const [activeFilters, setActiveFilters] = useState({
        "is_active": {
            columnField: 'is_active',
            operatorValue: '=',
            value: 'True'
        }
    });


    const classes = useStyles()
    // Reloading Grid to page 1 when there is change in Filters
    useEffect(() => {
        setCurrentPage(1);
    }, [activeFilters])

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authToken, activeFilters])

    useEffect(() => {
        // Reloading the Pagination Data when there is change in Data or Active Page
        handleLocalPagination(currentPage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, appsData])

    const handleLocalPagination = (pageToLoad) => {
        if (appsData && appsData.length > 0) {
            // Finding the Active Page Records and loading it into RTK Keys 
            const pageSize = 100;
            const startPageIndex = ((pageToLoad - 1) * pageSize);
            setRTKkeys(appsData.slice(startPageIndex, startPageIndex + pageSize));
        } else if (rtkKeys && rtkKeys.length > 0) {
            setRTKkeys([]);
        }
    }
    const handlePageChange = (page) => {
        setCurrentPage(page);
    }
    const handleDeleteRow = (delete_id, rowInfo) => {
        setDeleteDataDictionary(rowInfo);
        setOpenDeleteDialog(true);
    }
    /**
     * Method will be called when we click on cancel in dialog
     */
    const handleDeleteDialogClose = () => {
        // Resetting the state which holds delete data dictionary data
        setDeleteDataDictionary(false);
        // Closing the dialog state to hide the dialog
        setOpenDeleteDialog(false);
    }
    /**
     * Method is triggered when clicked on submit
     * Triggers the delete API 
     */
    const handleDeleteDialogSubmit = () => {
        const { key } = deleteDataDictionary;
        axios.delete(`/rtk_keys/${key}`, {
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            // Removing the Deleted record from the Grid
            setRTKkeys(rtkKeys.filter(ikey => ikey.key !== key))
            setDeleteDataDictionary(false);
            setOpenDeleteDialog(false);
            // Reloading the Latest Data into the Grid
            fetchData();
        }).catch(err => {
            if (err.response?.data) {
                props.toggleError(err.response.data.message)
            }
        })


    }
   /**
    * Method returns the params data which are built based on the active Filters on Grid.
    * @param {boolean} isDownload Default value is false
    * @returns Object which contains all the active filters along with download param if the isDownload is passed as true for download case handling
    */
    const getFilterPayload = (isDownload = false) => {
        let payload = {};

        const filterKeys = Object.keys(activeFilters)
        filterKeys.forEach((activeFilter) => {
            if (activeFilters[activeFilter] !== false) {
                let activeFilterName = activeFilter;
                // Formating the Payload based on the active filter applied in the UI
                let paramValue = activeFilters[activeFilter].value

                if (activeFilter === "is_active") {
                    paramValue = (paramValue === "True") ? "true" : "false";
                    activeFilterName = "filter";
                } else if (activeFilter === "overwrite") {
                    paramValue = (paramValue === "Yes")
                } else if (activeFilter === "display_name") {
                    activeFilterName = "source";
                    paramValue = apps?.find(({ display }) => display === paramValue)?.name || paramValue
                }
                payload[activeFilterName] = paramValue
            }
        })
        // adding the download param only for the download workflow when triggered from the download button click
        if(isDownload){
            payload["download"] = true
        }
        return {
            ...payload
        }
    }
    /**
     * Method calls the api and try to strore the information into the appsdata state.
     * For download case it will call handleDownloadresponse to trigger the download functionality
     * @param {boolean} isDownload  default value is false
     * 
     */
    const fetchData = (isDownload = false) => {
        setIsLoading(true);
        axios.get('/rtk_keys', {
            headers: {
                Authorization: authToken
            },
            params: getFilterPayload(isDownload),
        }).then(res => {
            setIsLoading(false);
            if(isDownload){
                // for triggering the download functionality
                handleDownloadresponse(res);
            } else {
                setAppsData(res.data);
            }

        }).catch(err => {
            setIsLoading(false);
            console.log(err)
        })
    }
    const createNewDataDictionary = (rowInfo, handleCommitCallBack, callbackRowId) => {
        const {
            key,
            display_name,
            element_type,
            is_active,
            display_term,
            description,
            tips,
            review_category,
            sample = "",
            data_type,
            overwrite,
            canned_message,
            description_fr,
            display_term_fr,
            notes,
            applicable_requestor
        } = rowInfo;
        // Mapping the Display Names with the Name of the App
        const app_name_api = apps?.find(({ display }) => display === display_name)?.name || display_name

        axios.post(`/rtk_keys/${key.trim()}`, {
            app_name: app_name_api,
            element_type,
            is_active: is_active === "True",
            display_term,
            desc: description,
            tips,
            review_category,
            sample,
            data_type,
            overwrite: overwrite === "Yes",
            canned_message,
            "Description-FR": description_fr,
            "Display_Term-FR": display_term_fr,
            notes,
            applicable_requestor
        }, {
            headers: {
                Authorization: authToken
            }
        }).then(res => {

            fetchData();

        }).catch(err => {
            if (err.response) {
                // For maintaining the edit on the new record
                // Formatting the Data according to the Grid
                setRTKkeys(rtkKeys.map((rtkKey) => {
                    if (rtkKey.id === callbackRowId) {
                        return {
                            ...rowInfo,
                            is_active: rowInfo.is_active === "True",
                            overwrite: rowInfo.overwrite === "Yes",
                            updated_at: '',
                        }
                    }
                    return {
                        ...rtkKey
                    };
                }))
                setTimeout(() => {
                    // Enabling the Edit on the Grid Row 
                    handleCommitCallBack(callbackRowId);
                }, 300);
                // Displaying the Error Message in the Grid
                props.toggleError(err.response.data.message);
            }
        })
    }
    const updateDataDictionary = (rowInfo) => {
        const {
            originalKey,
            key,
            display_name,
            element_type,
            is_active,
            display_term,
            description,
            tips,
            review_category,
            sample,
            data_type,
            overwrite,
            canned_message,
            description_fr,
            display_term_fr,
            notes,
            applicable_requestor
        } = rowInfo;
        // Mapping the Display Names with the Name of the App
        const appNameApi = apps?.find(({ display }) => display === display_name)?.name || display_name

        axios.put(`/rtk_keys/${originalKey.trim()}`, {
            app_name: appNameApi,
            element_type,
            is_active: is_active === "True",
            display_term,
            new_key: key,
            desc: description,
            tips,
            review_category,
            sample,
            data_type,
            overwrite: overwrite === "Yes",
            canned_message,
            "Description-FR": description_fr,
            "Display_Term-FR": display_term_fr,
            notes,
            applicable_requestor
        }, {
            headers: {
                Authorization: authToken
            }
        }).then(res => {

            fetchData();

        }).catch(err => {
            if (err.response) {
                props.toggleError(err.response.data.message)
            }
        })
    }
    const updateApp = (rowInfo, { handleCommitCallBack }, callbackRowId) => {
        if (rowInfo.isNewRecord) {
            createNewDataDictionary(rowInfo, handleCommitCallBack, callbackRowId)
        } else {
            updateDataDictionary(rowInfo)
        }
    }

    const dataGridColumns = () => {
        return getColumns(apps).map((column) => {
            if (column.mapping === "actions_delete") {
                // Overriding the RenderCell to render the Edit and Save Icons functionality
                column.renderCell = (params) => {
                    return (<RowEditActions {...params}
                        deleteActionCallBack={handleDeleteRow}
                        enableDeleteAction={true}
                        enableIconCallback={(rowInfo) => {
                            return !(rowInfo.isNewRecord)
                        }}
                    />)
                }
            }
            return column
        });
    }
    /**
     * Method Formats the data based on the Display format
     * 
     * @returns Array of Records
     */
    const appsGridData = () => {

        return rtkKeys?.map((row, i) => {
            return {
                rownum: ((currentPage - 1) * 100) + (i + 1),
                id: `${row.app_name} -- ${row.key}`,
                ...row,
                originalKey: row.originalKey ? row.originalKey : row.key,
                is_active: row.is_active ? "True" : "False",
                overwrite: row.overwrite ? "Yes" : "No",
                applicable_requestor : row.applicable_requestor === "" ? [] : ( row.applicable_requestor?.split(",") || [] ),
                applicable_requestor_string : row.applicable_requestor
            }
        })
    }
    const handleRowsModelChange = React.useCallback((newModel) => {
        const editModel = Object.keys(newModel);
        let isNewRecord = false, recordId;
        // Iterating through the selected Model to check is the record is newly created
        editModel.forEach((model) => {
            isNewRecord = model.startsWith('editNewRecord-')
            recordId = model;
            rtkKeys.forEach((appDat) => {
                if (appDat.id === recordId) {
                    isNewRecord = false;
                }
            })
        });
        // Inserting the newly created record into the Grid Data
        if (isNewRecord) {

            setRTKkeys([
                {
                    id: recordId,
                    isNewRecord: true,
                    is_active: false,
                    tips: 'Development',
                    review_category : "Not Applicable",
                    data_type: 'Text',
                    overwrite: false,
                    canned_message: ''
                },
                ...rtkKeys,
            ])
        }
        const updatedModel = { ...newModel }
        // Saving the rowmodel state and passing to the Grid as prop.
        setEditRowsModel(updatedModel)
    }, [rtkKeys]);
    const updateDeleteApp = ({ id }) => {
        const newAppsData = [];
        appsData.forEach(appsDat => {
            appsDat.id !== id && newAppsData.push(appsDat)
        });
        setAppsData(newAppsData)
    }
    /**
     * This Method is used to transform the data received from the API to download format
     * 
     * @param {API response} res 
     * @returns Download file in the browser
     */
    const handleDownloadresponse = (res) =>{
        // displaying the error message when there are no records available in response.
        if (res.status === 204) {
            props.toggleError("No records to download")
            return;
        }
        // Converting the response to CSV downloadable format
        const csv = Papa.unparse(res.data)
        // Creating a dynamic Hyperlink
        const downloadLink = document.createElement("a");
        // converting the csv into the blob format
        const blob = new Blob(["\ufeff", csv])
        // converting the blob into a URL format
        const url = URL.createObjectURL(blob)
        // appending the link to the dynamically created link
        downloadLink.href = url
        // file name formating CPS_DataDictionary_yyyymmdd format
        const timestamp = moment().format("YYYYMMDD")
        downloadLink.download = `CPS_DataDictionary_${timestamp}.csv`
        // adding the dynamically created link into the body
        document.body.appendChild(downloadLink)
        // explicitly triggering the click functionality so that browser download will triggered
        downloadLink.click()
        // removing the dynamically created link from the body after the download trigger is completed.
        document.body.removeChild(downloadLink)
    }
    const handleDownloadGrid = () => {
        fetchData(true);
    }
    return (
        <div className={classes.contactsWrapper}>
            {isLoading && (<LoadingIndicator />)}
            <DataGrid
                enableCustomFiltering={true}
                disableSelectionOnClick={false}
                columns={dataGridColumns()}
                customHeight={"100%"}
                rows={appsGridData()}
                editRowsModel={editRowsModel}
                getCellClassName={(params) => {
                    // Displaying the Custom column Class for the Element Type 
                    if (params?.field === "element_type") {
                        return params.value === "Attribute" ? 'attr-cell-cust-cls' : 'transaction-cell-cust-cls';
                    }
                    return "";
                }}
                getRowClassName={(params) => {
                    const _classes = [];
                    // For Displaying the Custom Class for the IsActive Row
                    if (params?.row?.is_active === "False") {
                        _classes.push("isInactive-row-cust-cls");
                    }
                    return _classes.join(" ");
                }}
                toolbarConfig={{
                    addIconLabel: "Add Key",
                    downloadLabel: "Download",
                    handleDownloadGrid,
                    isAddIconDisabled: !isAdmin,
                    defaultValuesForNewRecord: {
                        tips: 'Development',
                        review_category : "Not Applicable",
                        data_type: 'Text',
                        is_active: "False",
                        overwrite: 'No',
                        canned_message: ''
                    }
                }}
                onEditRowsModelChange={handleRowsModelChange}
                isCellEditable={(cellParams) => {
                    // Enabling the Edit Functionaly only for the New Records.
                    if (cellParams.colDef.onlyEditForNew) {
                        return cellParams.row.isNewRecord || false
                    }
                    return cellParams.colDef.editable;
                }}
                onRowEditCommit={(rows, event) => {

                    if (event.action === "rowDelete") {
                        handleDeleteRow(event._id);
                    } else if (event.action === "delete") {
                        updateDeleteApp(event.newRecord)
                    }
                    if (event.rowInfo) {
                        updateApp(event.rowInfo, event, event.rowId);
                    }
                    if (event.invalidRowInfo) {
                        if(event.invalidRowInfo?.is_active === "True" && (event.invalidRowInfo?.applicable_requestor || []).length === 0){
                            props.toggleError(`"Applicable Requestor" is mandatory for active keys`);
                        } else {
                            props.toggleError("All fields are required!");
                        }
                    }
                }}
                defaultFilterOrder={["is_active"]}
                defaultFilters={activeFilters}
                remoteFiltering={true}
                applyRemoteFiltering={(filters) => {
                    setActiveFilters(filters)
                }}
            />
            <Pagination
                currentPage={currentPage}
                pageCount={100}
                totalResults={appsData?.length}
                pageChange={handlePageChange}
            />
            <DeleteDialog 
                openDeleteDialog={openDeleteDialog}
                title={"Delete Key Confirmation"}
                handleDeleteDialogClose={handleDeleteDialogClose}
                handleDeleteDialogSubmit={handleDeleteDialogSubmit}
            >
                Are you sure you want to delete the following key:
                <br />
                <div className="deleteDialogKey">
                    {deleteDataDictionary?.key}
                </div>
            </DeleteDialog>
        </div>
    )
}
export default DataDictionary;