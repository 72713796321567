import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import axios from '../../../../common/AxiosConfig';
import moment from 'moment';

const ticketTypeMapping = ['Right-to-Correct', 'Right-to-Delete', 'Right-to-Know-Detailed', 'Right-to-OptOut']

function ErrorReport(props) {
    const { authToken, upstream } = props
    const feedbackTimeout = 4000

    const [errorMetrics, setErrorMetrics] = useState([])

    useEffect(() => {
        if (authToken) {
            axios.get('/reports/error', {
                headers: {
                    Authorization: authToken
                },
                params: {
                    is_upstream: upstream
                }
            }).then(res => {
                const error_report = res.data.error_report;
                error_report.sort(function (a, b) {
                    const x = a.name.toLowerCase();
                    const y = b.name.toLowerCase();
                    if (x < y) { return -1; }
                    if (x > y) { return 1; }
                    return 0;
                });

                setErrorMetrics(error_report)
            }).catch(err => {
                console.log(err)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authToken])

    const downloadDsarIds = () => {
        axios.get('/reports/error/download', {
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            if (res.data.error_dsar_ids.length) {
                let text_file = ""
                for (const dsar_id of res.data.error_dsar_ids) {
                    text_file += dsar_id + "\r\n"
                }
                const downloadLink = document.createElement("a");
                const blob = new Blob([text_file], { type: 'text/plain' })
                const url = URL.createObjectURL(blob)
                downloadLink.href = url
                const timestamp = moment().format("YYYYMMDDHHmm")
                downloadLink.download = `CPS_Error_Report_${timestamp}.txt`
                document.body.appendChild(downloadLink)
                downloadLink.click()
                document.body.removeChild(downloadLink)
            } else {
                populateError('There are no old DSAR IDs!')
            }
        }).catch(err => {
            console.log(err)
            populateError(err.response.data.message)
        })
    }

    const downloadQuery = (appName, ticketType) => {
        // Map the ticketType index to the actual value
        ticketType = ticketTypeMapping[ticketType]

        axios.get('/reports/error/download_query', {
            params: {
                ticket_type: ticketType,
                app_name: appName,
                is_upstream: upstream,
            },
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            if (res.data.dsar_ids.length) {
                let text_file = ""
                for (const dsar_id of res.data.dsar_ids) {
                    text_file += dsar_id + "\r\n"
                }
                const downloadLink = document.createElement("a");
                const blob = new Blob([text_file], { type: 'text/plain' })
                const url = URL.createObjectURL(blob)
                downloadLink.href = url
                const timestamp = moment().format("YYYYMMDDHHmm")
                downloadLink.download = `CPS_Error_${appName}_${timestamp}.txt`
                document.body.appendChild(downloadLink)
                downloadLink.click()
                document.body.removeChild(downloadLink)
            }
        }).catch(err => {
            console.log(err)
        })
    }

    const populateError = (message) => {
        const downloadError = document.getElementById('downloadError')
        downloadError.innerHTML = message
        removeFeedback('downloadError')
    }

    const removeFeedback = (elementId) => {
        // Remove the feedback after a specified timeout period
        setTimeout(
            function () {
                const field = document.getElementById(elementId)
                if (!field) { return }
                field.innerHTML = ""
            }, feedbackTimeout
        )
    }

    return (
        <div className="mb-5">
            <h4 className="text-center mt-4">Error Report - {upstream ? "Upstream" : "Downstream"}</h4>
            <table className="table table-striped">
                <thead>
                    <tr className="text-center">
                        <th scope="col" className="text-left">System</th>
                        <th scope="col">Right to Correct</th>
                        <th scope="col">Right to Delete</th>
                        <th scope="col">Right to Know</th>
                        <th scope="col">Right to Opt Out</th>
                    </tr>
                </thead>
                <CSSTransition in={errorMetrics.length > 0} appear={true} timeout={125} classNames="swipeD">
                    <tbody>
                        {errorMetrics.map(system => (
                            <tr key={system.name}>
                                <th scope="row" className={system.data.reduce((sum, num) => sum + num, 0) > 0 ? "text-danger" : ""}>{system.name}</th>
                                {system.data.map((count, i) => (
                                    <td key={`error-${system.name}-${ticketTypeMapping[i]}`} className="text-center">
                                        {count > 0 ?
                                            <button className="btn btn-link p-0" onClick={() => downloadQuery(system.name, i)}>{count}</button>
                                            : count
                                        }
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </CSSTransition>
            </table>

            {!upstream ?
                <div className="text-right">
                    <button className="btn btn-primary" onClick={downloadDsarIds}>Download Request IDs</button>
                    <br />
                    <small className="text-danger" id="downloadError"></small>
                </div>
                : null
            }
        </div>
    );
}

export default ErrorReport;
