import React from 'react';
import DataGrid from '../../grid';
import { Box, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';

/**
 * Method triggers the download functionality on the URL shared
 * @param {*} url Link of the file to download
 * Example - S3 URL with signed link
 */
const downloadFile = (url) => {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${url}`);
    document.body.appendChild(link);
    link.click();
    link.remove()
}
// Columns Defination for TIPS reconciliation Files Grid
const COLUMNS = [{
    name: "#",
    mapping: "rowId",
    disableColumnMenu: true,
    width: 40
}, {
    name: 'File Name',
    mapping: "file_name",
    disableColumnMenu: true,
    renderCell: (params) => {
        return (<Link onClick={() => downloadFile(params.row["file_url"])} download>{params.row["file_name"]}</Link>)
    }

}, {
    name: 'Date of File Sent to CPS',
    mapping: "last_modified_date",
    width: 250,
    disableColumnMenu: true,
    renderCell: (params) => {
        // Update the Last Modified date to YYYY-MM-DDTHH:MM:SS Format
        let last_modified_date = params.row["last_modified_date"];
        if (last_modified_date?.indexOf("+") !== -1) {
            last_modified_date = last_modified_date?.split("+")[0];
        }
        return last_modified_date;
    }
}];

const useStyles = makeStyles(() => ({
    statusLegend: {
        marginTop: '10px',
        padding: '0px 0px 5px 0px',
        boxShadow: '0px 0px 1px 0px rgb(0 0 0 / 41%)',
        '& .header': {
            background: '#7c7c7c',
            padding: '10px',
            color: "white",
            fontWeight: 'bold'
        },
        '& .content': {
            padding: '10px'
        }
    }

}));
function UpStreamDSAR({ data, isTIPS = true }) {

    const classes = useStyles()

    const statusBasedText = isTIPS ? "TIPS" : "QB64";
    // Columns Defination for Status Legend Grid
    const StatusLegendColumns = [{
        name: "#",
        mapping: "rowId",
        disableColumnMenu: true,
        width: 40
    }, {
        name: `${statusBasedText} Status`,
        mapping: "dsar_status_tips",
        disableColumnMenu: true,
    }, {
        name: `${statusBasedText} Status Description`,
        mapping: "dsar_description",
        disableColumnMenu: true,
    }, {
        name: 'CPS Status',
        mapping: "cps_status",
        disableColumnMenu: true,
    }]

    return (
        <Box width={'100%'}>
            <DataGrid
                customHeight={200}
                columns={COLUMNS}
                enableCustomFiltering={true}
                rows={data?.gridData || []}
            />
            <div className={classes.statusLegend}>
                <div className={"header"}>
                    Status Legend
                </div>
                <div className={"content"}>
                    <DataGrid
                        columns={StatusLegendColumns}
                        customHeight={240}
                        enabledarktheme={true}
                        rows={[{
                            "id": 1,
                            "rowId": 1,
                            "dsar_status_tips": "REQUEST_PENDING_SUBMISSION",
                            "dsar_description": "Request is yet to be pushed to CPS",
                            "cps_status": "-"
                        },
                        {
                            "id": 2,
                            "rowId": 2,
                            "dsar_status_tips": "RESPONSE_PENDING",
                            "dsar_description": "Pushed to CPS but waiting for response",
                            "cps_status": "Open, Review"
                        },
                        {
                            "id": 3,
                            "rowId": 3,
                            "dsar_status_tips": "RESPONSE_PENDING_ATTACHMENTS_FETCH",
                            "dsar_description": "Waiting on attachment verification",
                            "cps_status": "Uploaded"
                        },
                        {
                            "id": 4,
                            "rowId": 4,
                            "dsar_status_tips": "RECEIVED_RESPONSE_ATTACHMENTS",
                            "dsar_description": `Request closed in ${statusBasedText}`,
                            "cps_status": "Uploaded"
                        },
                        {
                            "id": 5,
                            "rowId": 5,
                            "dsar_status_tips": "RECEIVED_RESPONSE",
                            "dsar_description": `Request closed in ${statusBasedText}`,
                            "cps_status": "Uploaded"
                        }]}
                    />
                </div>
            </div>
        </Box>
    )
}
export default UpStreamDSAR;