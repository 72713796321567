import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import { removeFeedback } from '../../../../common/commons';
import axios from '../../../../common/AxiosConfig';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';


function DuplicateRequests(props){

    const { authToken } = props
    const [duplicateRequests, setDuplicateRequests] = useState([])
    const [lastGenerated, setLastGenerated] = useState(null)

    useEffect(() => {
        if (authToken) {
            axios.get('/reports/duplicate-requests', {
                headers: {
                    Authorization: authToken
                }
            }).then(res => {
                setLastGenerated(moment())
                setDuplicateRequests(res.data)
            }).catch(err => {
                console.log(err)
            })
        }
    }, [authToken])

    const regenerate = () => {
        const success = document.getElementById("success-feedback")
        const error = document.getElementById("error-feedback")

        axios.get(`/reports/duplicate-requests`, {
            headers: { Authorization: authToken }
        }).then(res => {
            setLastGenerated(moment())
            success.innerHTML = "Success!"
            setDuplicateRequests(res.data)
        }).catch(err => {
            error.innerHTML = "Could not regenerate!"
            console.log(err)
        }).finally(() => {
            removeFeedback("error-feedback")
            removeFeedback("success-feedback")
        })
    }

    if (duplicateRequests.length > 0){
        return (
        <div className="mb-5">
            <h4 className="text-center">Duplicate Requests</h4>
            <CSSTransition>
                    {lastGenerated ?
                        <p className="text-center">
                            Generated On:&nbsp;
                            <span>
                                <span id="generation-date">
                                    {moment(lastGenerated).utc().format("MMM Do, YYYY HH:mm:ss (UTC)")}
                                </span>
                                <UncontrolledTooltip placement="bottom" target="generation-date">
                                    {moment(lastGenerated).local().format('MMM Do, YYYY HH:mm:ss [(local)]')}
                                </UncontrolledTooltip>
                            </span>
                        </p>
                        :
                        <p>&nbsp;</p>
                    }
            </CSSTransition>
            <div>
            <table className="table table-striped">
                <thead>
                    <tr className="text-center">
                        <th scope="col">#</th>
                        <th scope="col">DSAR ID</th>
                        <th scope="col">Created Date</th>
                        <th scope="col">Request Type</th>
                        <th scope="col">Request Status</th>
                    </tr>
                </thead>
                <CSSTransition in={duplicateRequests.length > 0} appear={true} timeout={125} classNames="swipeD">
                    <tbody className="text-center">
                        {duplicateRequests.map((request, i) => (
                            <tr key={request.dsar_id}>
                                <td>{i + 1}</td>
                                <td><Link id={`reqID-${i}`} to={`/ticket/${request.dsar_id}`}>{request.dsar_id}&nbsp;</Link></td>
                                <td>{request.created_date ? moment(request.created_date).format("MMM Do, YYYY HH:mm:ss (UTC)") : null}</td>
                                <td>{request.ticket_type}</td>
                                <td>{request.status}</td>
                            </tr>
                        ))}
                    </tbody>
                </CSSTransition>
            </table>
            </div>
            <p className="text-center">
                <button type="button" className="btn btn-primary" onClick={regenerate}>
                    Regenerate
                </button>
            </p>

            <p className="text-center small text-danger" id="error-feedback"></p>
            <p className="text-center small text-success" id="success-feedback"></p>
        </div>
        )
    } else {
        return (
        <div className="mb-5">
            <h4 className="text-center">Duplicate Requests</h4>
            <p className="text-center">No duplicate requests found in CPS</p>
        </div>
        )
    }
}

export default DuplicateRequests;