import React, { useState, useEffect } from "react";
import axios from "../../../../common/AxiosConfig";
import moment from "moment";
import { Link } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";

function PendingAttachments(props) {
  const { authToken } = props;
  const [pendingAttachments, setPendingAttachments] = useState([]);

  useEffect(() => {
    regenerate_page_details()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken]);

  const regenerate_page_details = () => {
    if (authToken) {
      axios
        .get("/reports/pending-attachments", {
          headers: {
            Authorization: authToken,
          },
        })
        .then((res) => {
          setPendingAttachments(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  if (pendingAttachments.length > 0) {
    return (
      <div className="mb-5">
        <h4 className="text-center">Requests Pending Attachments
          <button type="button" className="btn btn-sm btn-outline-light pl-2" id="regenerate" onClick={regenerate_page_details}>
              <span className={"material-icons text-success"}>refresh</span>
          </button>
        </h4>
        <UncontrolledTooltip placement="bottom" target="regenerate">
            Regenerate report
        </UncontrolledTooltip>
        <table className="table table-striped">
          <thead>
            <tr className="text-center">
              <th scope="col">#</th>
              <th scope="col">DSAR ID</th>
              <th scope="col">System</th>
              <th scope="col">Pending Files</th>
              <th scope="col">Created Date</th>
            </tr>
          </thead>
          <tbody className="text-center">
            {pendingAttachments.map((request, i) => (
              <tr key={request.dsar_id}>
                <td>{i + 1}</td>
                <td><Link id={`reqID-${i}`} to={`/ticket/${request.dsar_id}`}>
                  {request.dsar_id}&nbsp;
                </Link></td>
                <td>{request.app_name}</td>
                <td>{request.pending_attachments}</td>
                <td>
                  {request.created_date
                    ? moment(request.created_date).format(
                        "MMM Do, YYYY HH:mm:ss (UTC)"
                      )
                    : null}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <p className="text-center small text-danger" id="error-feedback"></p>
        <p className="text-center small text-success" id="success-feedback"></p>
      </div>
    );
  } else {
    return (
      <div className="mb-5">
        <h4 className="text-center">Pending Attachments</h4>
        <p className="text-center">No pending attachments found in CPS</p>
      </div>
    );
  }
}

export default PendingAttachments;
