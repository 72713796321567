export const OtherRequestorGridColumns = [{
    name: "First Name",
    mapping: "firstname",
}, {
    name: "Last Name",
    mapping: "lastname",
}, {
    name: "Email",
    mapping: "email",
    displayTootip: ["email"]
}, {
    name: "Workday ID",
    mapping: "workdayId",
    displayTootip: ["workdayId"]
}, {
    name: "Spin ID",
    mapping: "spinId",
    displayTootip: ["spinId"]
}, {
    name: "Application ID",
    mapping: "applicationId",
    displayTootip: ["applicationId"]
}, {
    name: "Login ID",
    mapping: "loginId",
    displayTootip: ["loginId"]
}, {
    name: "GUID",
    mapping: "GUID",
    displayTootip: ["GUID"]
}, {
    name: "Worker Type",
    mapping: "workertype",
    displayTootip: ["workertype"]
}, {
    name: "Dealer Code",
    mapping: "dealerCode"
}];

export const CustomerDetailsGridColumns = [{
    name: "CUSTID",
    mapping: "custId",
}, {
    name: "First Name",
    mapping: "firstname",
}, {
    name: "Last Name",
    mapping: "lastname",
}, {
    name: "Email",
    mapping: "email",
    displayTootip: ["email"],
}, {
    name: "GUID",
    mapping: "guid",
    displayTootip: ["guid"],
}, {
    name: "VIN(s)",
    mapping: "vins",
    displayTootip: ["vins"],
}];

export const ListOfFields = [{
    display: 'Request ID',
    mapping: 'dsar_id'
}, {
    display: "Requestor",
    mapping: 'requestor'
}, {
    display: "Request Type",
    mapping: 'ticket_type'
}, {
    display: "Request Status",
    mapping: 'status'
}]

export const ListOfrequestDetails = [{
    display: "Created Date Time",
    mapping: 'created_date_utc',
    tooltip: 'created_date_local'
}, {
    display: "Created By",
    mapping: "creator"
}, {
    display: "California Customer",
    mapping: "cali_cust_flag"
}, {
    display: "Workflow",
    mapping: "workflow"
}, {
    display: "Customer Type",
    mapping: "consumer_type"
}, {
    display: "Language",
    mapping: "language"
}, {
    display: "Personas",
    mapping: "personas"
}]

export const DetailsViewTabs = {
    "cpsrequestjson": {
        "tabname": "VIEW REQUEST",
        "tabtype": "viewrequest"
    },
    "ehrequestdoc" :{
        "tabname": "EH REQUEST WORD DOC",
        "tabtype": 'word',
        "dsarIdPrefix" : "EH_",
        "regenrateAppId" : 47
    },
    "requestworddoc": {
        "tabname": "VIEW REQUEST DOC",
        "tabtype": 'word'
    },
    "wgrequestdoc": {
        "tabname": "WG REQUEST WORD DOC",
        "tabtype": 'word',
        "dsarIdPrefix" : "WG_",
        "regenrateAppId" : 45
    },
    "subarurequestjson": {
        "tabname": "VIEW SUBARU REQUEST",
        "tabtype": "viewsubarurequest"
    },
    "upstreamrequestjson": {
        "tabname": "VIEW UPSTREAM REQUEST",
        "tabtype": "viewupstreamrequest"
    },
    "wovenrequestjson": {
        "tabname": "VIEW WOVEN REQUEST",
        "tabtype": "viewwovenrequest"
    },
    "viewnonpijson" :{
        "tabname": "VIEW NON PI REQUEST",
        "tabtype" : "viewnonpijson"
    }
}
export const ViewRequestOptions = [{
    name: "CPS Request JSON",
    mapping: "cpsrequestjson"
},{
    name : "EH Request Word Doc",
    mapping : "ehrequestdoc",
    restrictAppId : 47,
    isHidden : true
},{
    name : "Non PI Request JSON",
    mapping: "viewnonpijson"
},{
    name: "Request Word Doc",
    mapping: "requestworddoc"
},
{
    name: "Upstream Request JSON",
    mapping: "upstreamrequestjson"
},{
    name : "WG Request Word Doc",
    mapping : "wgrequestdoc",
    restrictAppId : 45,
    isHidden : true
}]