import * as React from "react";
import {
  GridColumnMenuContainer,
  GridFilterMenuItem,
  SortGridMenuItems
} from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/styles";


const useStyles = makeStyles(
  {
    root: {
      '&.MuiDataGrid-gridMenuList':{
        minWidth : '100px'
      },
      '& .MuiListItem-root':{
        fontSize: '0.8rem'
      }
      
    }
    
  },
  { name: "MuiGridColumMenuContainer" }
);

const Menu = React.forwardRef(function GridColumnMenu(
  props,
  ref
) {
  const classes = useStyles();

  const { hideMenu, hideFilterMenu, currentColumn } = props;
  return (
    <GridColumnMenuContainer className={classes.root} ref={ref} {...props}>
      <SortGridMenuItems onClick={hideMenu} column={currentColumn} />
      <GridFilterMenuItem onClick={hideFilterMenu} column={currentColumn} />
    </GridColumnMenuContainer>
  );
});
export default Menu;
