import React, { useState, createContext } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import { Authenticator } from 'aws-amplify-react';
import Routes from './Routes';
import Documentation from './components/routes/Documentation';
import SAMLSignIn from './SAMLSignIn';
import WaitSignIn from './WaitSignIn';
import LoginDefaults from './login-defaults';

Amplify.configure(awsconfig);

export const AppContext = createContext()

function RoutesWithAuth() {
    // Auth
    const [authToken, setAuthToken] = useState(LoginDefaults.authToken);
    const [isAdmin, setIsAdmin] = useState(false);
    const [validUser, setValidUser] = useState(LoginDefaults.validUser);
    const [email, setEmail] = useState(LoginDefaults.email);
    const [userId, setUserId] = useState(LoginDefaults.userId);
    const [userName, setUserName] = useState(LoginDefaults.userName);
    // Load once
    const [RTC, setRTC] = useState(null)
    const [RTD, setRTD] = useState(null)
    const [RTK, setRTK] = useState(null)
    const [RTOO, setRTOO] = useState(null)
    const [oldest, setOldest] = useState(null)
    const [systems, setSystems] = useState(null)
    const [failures, setFailures] = useState(null)
    const [closed_ticket_count, setClosed_ticket_count] = useState(null)
    const [duplicate_ticket_count, setDuplicate_ticket_count] = useState(null)
    const [pending_attachments_count, setPending_attachments_count] = useState(null)
    const [tips_error_count, setTips_error_count] = useState(null)
    const [ctp_agg_error_count, setCtp_agg_error_count] = useState(null)
    const [apps, setApps] = useState([])
    const [RTCRedirect, setRTCRedirect] = useState("/reports")
    const [RTDRedirect, setRTDRedirect] = useState("/reports")
    const [RTKRedirect, setRTKRedirect] = useState("/reports")
    const [RTOORedirect, setRTOORedirect] = useState("/reports")
    const [reloadHealth, setReloadHealth] = useState(1)

    const store = {
        authToken: { get: authToken, set: setAuthToken },
        isAdmin: { get: isAdmin, set: setIsAdmin },
        validUser: { get: validUser, set: setValidUser },
        email: { get: email, set: setEmail },
        userId: { get: userId, set: setUserId },
        userName: { get: userName, set: setUserName },
        RTC: { get: RTC, set: setRTC },
        RTD: { get: RTD, set: setRTD },
        RTK: { get: RTK, set: setRTK },
        RTOO: { get: RTOO, set: setRTOO },
        oldest: { get: oldest, set: setOldest },
        systems: { get: systems, set: setSystems },
        failures: { get: failures, set: setFailures },
        closed_ticket_count: { get: closed_ticket_count, set: setClosed_ticket_count },
        duplicate_ticket_count: { get: duplicate_ticket_count, set: setDuplicate_ticket_count },
        pending_attachments_count: { get: pending_attachments_count, set: setPending_attachments_count },
        tips_error_count: { get: tips_error_count, set: setTips_error_count },
        ctp_agg_error_count: { get: ctp_agg_error_count, set: setCtp_agg_error_count },
        RTCRedirect: { get: RTCRedirect, set: setRTCRedirect },
        RTDRedirect: { get: RTDRedirect, set: setRTDRedirect },
        RTKRedirect: { get: RTKRedirect, set: setRTKRedirect },
        RTOORedirect: { get: RTOORedirect, set: setRTOORedirect },
        apps : { get: apps , set: setApps},
        reloadHealth : { get: reloadHealth, set: setReloadHealth}
    }

    return (
        <AppContext.Provider value={store}>
            <Router>
                <Switch>
                    <Route exact path="/documentation" component={Documentation} />
                    <Route path="/waitsignin">
                        <Authenticator hideDefault={true}>
                            <WaitSignIn />
                        </Authenticator>    
                    </Route>
                    <Route>
                        <Authenticator hideDefault={true}>
                            <SAMLSignIn />
                            <Routes />
                        </Authenticator>
                    </Route>
                </Switch>
            </Router>
        </AppContext.Provider>
    );
}

export default RoutesWithAuth;
