import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from '../../../RoutesWithAuth';
import PageHeader from '../../common/PageHeader';
import AdminsOnly from '../../common/AdminsOnly';
import { withNavbar } from '../../common/Navbar';
import axios from '../../common/AxiosConfig';
import { CSSTransition } from 'react-transition-group';
import RetentionCard from './RetentionCard';


function Retention() {
    const context = useContext(AppContext)
    const authToken = context.authToken.get
    const isAdmin = context.isAdmin.get

    const [appRetention, setAppRetention] = useState([]);

    useEffect(() => {
        if (authToken !== null && isAdmin === true) {
            fetchData()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authToken, isAdmin])

    const fetchData = () => {
        axios.get(`appticket_retention`, {
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            setAppRetention(res.data)
        }).catch(err => {
            console.log(err)
        })
    }

    const desc = {
        "CPS": `Number of days a request is retained in CPS once its created:`,
        "CPS 21MM": `Number of days a non-error record is retained in CTP 21MM module:`,
        "CTP Aggregator": `Number of days a non-error record is retained in CTP Aggregator:`
    }

    if (isAdmin === null) {
        return null
    } else if (isAdmin === false) {
        return <AdminsOnly header="Retention" />
    }

    return (
        <div className="mb-5 container">

            <PageHeader header="Retention" newUIHref={"/newuiadmin/systemsettings/retention"} />

                <CSSTransition in={appRetention.length > 0} appear={true} timeout={125} classNames="swipeR">
                    <div>
                        {appRetention.map(app => (
                            <RetentionCard app={app} key={app.module_name} authToken={authToken} isAdmin={isAdmin}
                                fetchData={fetchData} desc={desc[app.module_name] || "None"}
                            />
                        ))}
                    </div>
                </CSSTransition>

        </div>
    );
    
}

export default withNavbar(Retention, 5);
