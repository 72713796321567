import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from '../../RoutesWithAuth';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { withNavbar } from '../common/Navbar';
import PageHeader from '../common/PageHeader';
import AdminsOnly from '../common/AdminsOnly';
import axios from '../common/AxiosConfig';
import moment from 'moment';
import { feedbackTimeout } from '../common/commons';
import { UncontrolledTooltip } from "reactstrap";


function BulkAssign() {
    const context = useContext(AppContext)
    const authToken = context.authToken.get
    const isAdmin = context.isAdmin.get

    const [totalPages, setTotalPages] = useState(0);
    const [pagination, setPagination] = useState([]);
    const [currentPage, setCurrentPage] = useState(1)


    const [unassignedRequests, setUnassignedRequests] = useState([])
    const [users, setUsers] = useState([])
    const [userId, setUserId] = useState('')

    const [selectAll, setSelectAll] = useState(false)
    const [selectedRecords, setSelectedRecords] = useState(0)

    useEffect(() => {
        if (authToken !== null && isAdmin === true) {
            fetchData()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authToken, isAdmin])

    useEffect(() => {
        fetchData(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage])

    // Update the selectAll checkbox if all boxes are checked/unchecked
    useEffect(() => {
        if (!unassignedRequests.length) {
            setSelectedRecords(0)
            setSelectAll(false)
            return
        }

        const selectedRecords = unassignedRequests.filter(req => req.checked === true).length
        setSelectedRecords(selectedRecords)
        if (selectedRecords === unassignedRequests.length) {
            setSelectAll(true)
        } else {
            setSelectAll(false)
        }
    }, [unassignedRequests])

    useEffect(() => {
        const pages = []
        for (let index = 0; index < totalPages; index++) {
            if (index + 1 === currentPage) {
                pages.push(
                    <li className="page-item disabled" key={index}><button className="page-link">{index + 1}</button></li>
                )
            } else {
                pages.push(

                    <li className="page-item">
                        <button className="page-link" onClick={() => { setCurrentPage(index + 1) }}>{index + 1}</button>
                    </li>
                )
            }
        }

        setPagination(pages)
    }, [totalPages, currentPage])

    const fetchData = (fetchUsers = true) => {
        axios.get(`ticket/search`, {
            headers: {
                Authorization: authToken
            },
            params: {
                include_open: true,
                include_closed: false,
                include_uploaded: false,
                include_upload_failed: true,
                assigned_to: "None",
                // page: "All",
                page: currentPage - 1,
            }
        }).then(res => {
            if (!res.data.length) {
                setUnassignedRequests([])
                return
            }
            setTotalPages(Math.ceil(res.data[0].total_results / 20))

            setUnassignedRequests(res.data.map(request => {
                request.checked = false
                return request
            }))
        }).catch(err => {
            console.log('There was an error fetching requests!', err)
        })

        if (fetchUsers) {
            // Get the list of all users
            axios.get('users', {
                headers: {
                    Authorization: authToken
                }
            }).then(res => {
                if (res.data.length) {
                    setUsers(res.data.filter(user => user.user_status === 'Y'))
                    setUserId(res.data[0].user_id)
                }
            }).catch(err => {
                console.log('There was an error fetching users!', err)
            })
        }
    }

    const selectAllBoxes = () => {
        setUnassignedRequests(
            unassignedRequests.map(req => {
                req.checked = !selectAll
                return req
            })
        )

        setSelectAll(!selectAll)
    }

    const assignRequests = (e) => {
        e.preventDefault()
        const submitBtn = document.getElementById('bulk-submit')
        const validFeedback = document.getElementById('bulk-valid-feedback')
        const invalidFeedback = document.getElementById('bulk-invalid-feedback')
        submitBtn.setAttribute('disabled', true)

        axios.put('ticket/bulk_assign', {
            dsar_ids: unassignedRequests.filter(req => req.checked).map(req => req.dsar_id),
            user_id: userId,
        }, {
            headers: {
                Authorization: authToken
            }
        }).then(res => {

            if (currentPage === 1) {
                fetchData(false)
            } else {
                setCurrentPage(1);
            }
            // fetchData(false)
            validFeedback.innerHTML = res.data.message
        }).catch(err => {
            console.log(err)
            invalidFeedback.innerHTML = "Something went wrong. Please try again later."
        }).finally(() => {
            submitBtn.removeAttribute('disabled')
            removeFeedback('bulk-valid-feedback')
            removeFeedback('bulk-invalid-feedback')
        })
    }

    const removeFeedback = (elementId) => {
        // Remove the feedback after a specified timeout period
        setTimeout(
            function () {
                const field = document.getElementById(elementId)
                if (!field) { return }
                field.innerHTML = ""
            }, feedbackTimeout
        )
    }

    if (isAdmin === null) {
        return null
    } else if (isAdmin === false) {
        return <AdminsOnly header="Bulk Assign" />
    }

    return (
        <div className="container">

            <PageHeader header="Bulk Assign" />

            <div className="mt-4 mb-5">

                <div className="row justify-content-center mb-1">
                    <form className="form-inline" onSubmit={assignRequests}>
                        <div className="form-group mr-2">
                            <label className="mr-2" htmlFor="duration">
                                Assign&nbsp;
                                <span className="font-weight-bold">{selectedRecords}</span>&nbsp;
                                {selectedRecords === 1 ? "Request" : "Requests"} to:
                            </label>
                            <select className="form-control" value={userId} onChange={e => setUserId(e.target.value)}>
                                {users.map(user => (
                                    <option key={user.user_id} value={user.user_id}>{user.first_name} {user.last_name}</option>
                                ))}
                            </select>
                        </div>
                        <button type="submit" id="bulk-submit" className="btn btn-sm btn-primary" disabled={!selectedRecords}>Save</button>
                    </form>
                </div>
                <div id="bulk-invalid-feedback" className="small text-danger text-center"></div>
                <div id="bulk-valid-feedback" className="small text-success text-center mb-3"></div>

                <div className="small">
                    Total Records: {unassignedRequests.length ? unassignedRequests[0].total_results : 0}
                </div>
                <div className="mb-5">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">
                                    <input type="checkbox" checked={selectAll} onChange={selectAllBoxes} />
                                </th>
                                <th scope="col">#</th>
                                <th scope="col">Request ID</th>
                                <th scope="col">Request Type</th>
                                <th scope="col">Request Status</th>
                                <th scope="col">Creation Date</th>
                            </tr>
                        </thead>
                        <CSSTransition in={unassignedRequests.length > 0} appear={true} timeout={125} classNames="swipeD">
                            <tbody>
                                {unassignedRequests.map((request, i) => (
                                    <tr key={request.dsar_id}>
                                        <th scope="row">
                                            <input type="checkbox" checked={unassignedRequests[i].checked}
                                                onChange={() => setUnassignedRequests(unassignedRequests.map((req, ind) => {
                                                    if (ind === i) { req.checked = !req.checked }
                                                    return req
                                                }))}
                                            />
                                        </th>
                                        <td>{((currentPage - 1) * 20) + (i + 1)}</td>
                                        <td>
                                            <Link to={`/ticket/${request.dsar_id}`}>{request.dsar_id}</Link>
                                        </td>
                                        <td>{request.ticket_type}</td>
                                        <td>{request.status}</td>
                                        <td >
                                            <span id={`request-${i}-created-on-date`}>
                                                {moment(request.created_date).utc().format("MMM Do, YYYY HH:mm:ss (UTC)")}
                                            </span>
                                            <UncontrolledTooltip
                                                placement="bottom"
                                                target={`request-${i}-created-on-date`}
                                            >
                                                {moment(request.created_date).local().format("MMM Do, YYYY HH:mm:ss [(local)]")}
                                            </UncontrolledTooltip>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </CSSTransition>
                    </table>
                </div>
                <nav>
                    <ul className="pagination justify-content-center">
                        {(totalPages > 1 && totalPages <= 5) ?
                            pagination.map(element => element)
                            :
                            null
                        }
                        {totalPages > 5 ?
                            <ul className="pagination justify-content-center">
                                <li className="page-item mr-2">
                                    <button className="page-link" onClick={() => { setCurrentPage(1) }}>First</button>
                                </li>
                                {pagination.map((element, index) => {
                                    if (currentPage > 3 && currentPage <= totalPages - 3) {
                                        if (index >= currentPage - 3 && index <= currentPage + 1) {
                                            return element
                                        }
                                    } else if (currentPage <= 3 && index <= 4) {
                                        return element
                                    } else if (currentPage >= totalPages - 2 && index >= totalPages - 5) {
                                        return element
                                    }
                                    return null;
                                })}
                                <li className="page-item ml-2">
                                    <button className="page-link" onClick={() => { setCurrentPage(totalPages) }}>Last</button>
                                </li>
                            </ul>
                            : null
                        }
                    </ul>
                </nav>
            </div>

        </div>

    );
}

export default withNavbar(BulkAssign, 4);
