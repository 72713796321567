import React, { useState, useEffect, useContext } from "react";
import { CSSTransition } from "react-transition-group";
import { AppContext } from "../../RoutesWithAuth";
import { Link } from "react-router-dom";
import axios from "../common/AxiosConfig";
import PageHeader from "../common/PageHeader";
import { withNavbar } from "../common/Navbar";
import { copyToClipboard } from "../common/commons";


function ViewCompleteResponse(props) {

  const { dsarRequestId, appId } = props.match.params;
  const { url } = props.match;
  let page = url.split("/").pop();
  if(page === "viewrequestjson"){
    page = appId;
  }
  const context = useContext(AppContext);
  const authToken = context.authToken.get;

  const [json, setJson] = useState(`Fetching ${pageMapping[page].url}...`);
  const [filename, setFilename] = useState("");
  const [error, setError] = useState(null);


  const fetchData = () => {
    let requestUrl = `${pageMapping[page].urlPrefix || 'ticket'}/${dsarRequestId}/${pageMapping[page].url}`;
    if (appId && !pageMapping[page].excludeApp_Id) {
      requestUrl = `${requestUrl}/?app_id=${appId}`;
    }
    axios
      .get(requestUrl, {
        headers: {
          Authorization: authToken,
        },
      })
      .then((res) => {
        if (page === "viewResponseJSON" && res.data.response_s3_key) {
          if (res.data.response_s3_key) {
            setFilename(res.data.response_s3_key.split("/").pop());
          }
          setJson(res.data.response_summary);
        } else {
          setJson(res.data);
        }
      })
      .catch((err) => {
        if(err?.response?.data?.message){
            setError(err.response.data.message)
        }
        console.log(err);
      });
  };
  useEffect(() => {
    if (authToken !== null) {

      fetchData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken]);

  const downloadJSON = () => {
  
    const link = document.createElement("a");
    const file = new Blob([JSON.stringify(json, null, 4)], {
      type: "application/json",
    });
   
    link.href = URL.createObjectURL(file);
    let linkAttr;
    if(page ==='viewRequest'){
      linkAttr = dsarRequestId
    } else if(page==='viewResponseJSON'){
      linkAttr = filename
    } else {
      linkAttr = `${dsarRequestId}_Subaru.json`
    } 
    linkAttr = pageMapping[page]?.downloadFileName?.(dsarRequestId) || linkAttr;
    link.setAttribute("download", `${linkAttr}`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  return (
    <div className="container mb-5">
      <PageHeader header={pageMapping[page].title} removeMargin={true} />

      <p className="lead mb-2">
        <Link to={`/`}>Home&nbsp;</Link>
        &gt;{" "}
        <Link to={`/ticket/${dsarRequestId}`}>
          Request ID: {dsarRequestId}&nbsp;
        </Link>
        {page === "viewResponseJSON" ? (
          <span>
            &gt; &nbsp;
            <Link to={`/ticket/${dsarRequestId}/viewResponseFiles`}>
              View Response Files&nbsp;
            </Link>
          </span>
        ) : null}
        &gt; {pageMapping[page].title}
        <br />
      </p>
      <div className="text-right mb-2">
        <button
          className="btn btn-sm btn-primary"
          disabled={typeof json === "string"}
          onClick={downloadJSON}
        >
          {pageMapping[page].btnName}
        </button>
      </div>

      <CSSTransition
        in={typeof json !== "string"}
        timeout={400}
        classNames="scrollD-json"
        onEnter={() => {
          const container = document.getElementById("jsonContainer");
          const json = document.getElementById("json");
          container.style.setProperty("--max", `${json.offsetHeight}px`);
        }}
      >
        <div className="bg-light rounded border" id="jsonContainer">
          <span className="float-right mt-2 mr-2">
            <button
              className="btn btn-sm btn-secondary pt-1 pb-0 px-1"
              type="button"
              onClick={() => copyToClipboard(json)}
            >
              <span className="material-icons">content_copy</span>
            </button>
          </span>
          {
            <div className="m-2" id="json">
              <pre>{JSON.stringify(json, null, 2)}</pre>
            </div>
          }
        </div>
      </CSSTransition>
      { error && (<div><div className="mt-2 mb-2 text-center text-danger small" >{error.split(",")[0]}</div>
      <div className="mt-2 mb-2 text-center text-danger small" >{error.split(",")[1]}</div></div>)}
    </div>
  );
}

const pageMapping = {
  viewRequest: {
    url: "request",
    title: "View Request",
    btnName: "Download Request JSON",
  },
  viewOriginalRequest :{
    url: "request",
    urlPrefix : 'tickets',
    excludeApp_Id : true,
    title: "View Original Request",
    btnName: "Download Original Request JSON",
    downloadFileName: (dsrId)=>{
      return `${dsrId}_Original.json`;
    }
  },
  viewResponseJSON: {
    url: "response",
    title: "View Response JSON",
    btnName: "Download Response JSON",
  },
  Subaru: {
    url: "request",
    title: "View Subaru Request",
    btnName: "Download Subaru Request JSON",
  },
  WOVENCORE:{
    url: "request",
    title: "View Woven Request",
    btnName: "Download Woven Request JSON",
    downloadFileName: (dsrId)=>{
      return `${dsrId}_Woven.json`;
    }
  }
};

export default withNavbar(ViewCompleteResponse);
