
import React from 'react';
import { ResponsiveBar } from '@nivo/bar'


export const assignColor = (bar) => {
    const colors = {
        "Open" : '#8ca9da',
        "Review" : "#A020F0",
        "Closed" : "#ed7d31",
        "Uploaded" : "#a5a5a5",
        "Upload Failed" : "#ffc000",
        "Cancelled" : "#5b9bd5",
        "Total" : "#70ad47"
    }

    return colors[bar.id]
}
//Displaying tooltip for chart
const getTooltip = (data) => {
    return `${data.id}: ${data.value}`
}

const RequestCountBarChart = ({ data }) => (
    <ResponsiveBar
        //configuration for chart
        data={data}
        keys={["Open", "Review", "Closed","Uploaded","Upload Failed","Cancelled","Total"]}
        //Based on name chart will be grouped
        indexBy="name"
        margin={{ top: 50, right: 140, bottom: 180, left: 180 }}
        padding={0.3}
        colors={assignColor}
        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        labelSkipWidth={-1}
        innerPadding={2}
        labelSkipHeight={-1}
        enableGridY={false}
        enableGridX={false}
        tooltip={getTooltip}
        groupMode="grouped"
        labelFormat={(label)=>{
            // Calculating the word cound of the label
            const wordCount = label?.toString?.().length || 2;
            /* Default x position is on the bar end. based on the word count 
                we are calculating the X position so that label is positioned 
                exactly after some top space on the bar
            */ 
            const position = 5 + (wordCount *  3 );
            // label y position is static only the x is calculated based on word count
            return (<tspan y={4} x={position}>{label}</tspan>)
        }}
        axisLeft={{
            legend: 'REQUEST COUNT',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legendPosition: 'middle',
            legendOffset: -40

        }}
        axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'REQUEST TYPE',
            legendPosition: 'middle',
            legendOffset: 40
        }}
        labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        legends={[
            {
                dataFrom: 'keys',
                anchor: 'bottom',
                direction: 'row',
                justify: false,
                translateX: 0,
                translateY: 100,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemOpacity: 1
                        }
                    }
                ]
            }
        ]}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
    />
)

export default RequestCountBarChart;
