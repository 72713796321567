import { Box, IconButton, MenuItem, Select, TextField, makeStyles } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import axios from '../../../../common/AxiosConfig';
import { AppContext } from '../../../../../RoutesWithAuth';
import ListOfItems from '../ListOfItems';
import { Edit } from '@material-ui/icons';
import ResponseJsonContent from './ResponseJsonContent';
import GuidDetails from './GuidDetails';
import { getFormatedDate } from '../../../../common/commons';
import { RESPONSE_ACTION_MAPPING } from '../applications/constants';
import { APPIDS } from '../../../../common/constants';
import Attachments from './attachments';


const useStyles = makeStyles(() => ({
    root: {
        '& .MuiButtonBase-root': {
            padding: '5px !important'
        },
        '& .customizeIconButton': {
            width: '27px',
            height: '27px'
        },
        '& .customLink': {
            textDecoration: "underline",
            // color : "#007bff"
        },
        '& .customselectInput .MuiSelect-root': {
            paddingTop: '10.5px',
            paddingBottom: '10.5px'

        }
    },
    "attachmentsWrapper": {
        '& .header': {
            background: '#7f9ed7',
            padding: '5px',
            color: "white",
            fontWeight: 'bold'
        },
    }
}));


const ViewResponse = ({ tabInfo, setDisplayErrMsg, setLoading, reloadInformation, requestStatus }) => {
    const context = useContext(AppContext);
    const authToken = context.authToken.get;
    const classes = useStyles();

    const [responseData, setResponseData] = useState({});
    const [guidDetails, setGuidDetails] = useState([]);
    const [displayAttachments, setDisplayAttachments] = useState(false);
    const [editMode, setEditMode] = useState(false);
    
    useEffect(() => {
        fetchData();
    }, [tabInfo.tabtype, tabInfo.app_id, requestStatus]);

    const fetchTicketResponseDetails = () => {
        return axios.get(`ticket/response/${tabInfo.dsarRequestId}/${tabInfo.app_id}`, {
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            const responseData = res.data;
            const expectedAttachments = responseData["expected_attachments"].toString();
            const existingAttachments = responseData["existing_attachments"];
            const pendingAttachments = expectedAttachments - existingAttachments;
            responseData["expected_attachments_count"] = Number(expectedAttachments).toString();
            responseData["existing_attachments_count"] = Number(existingAttachments).toString();
            responseData["pending_attachments_count"] = pendingAttachments >= 0 ? Number(pendingAttachments).toString() : 0;
            responseData["attachements_formated"] = responseData["attachment_names"] || [];
            responseData["attachements_formated"] = responseData["attachements_formated"].sort((a, b) => a.attachment_type.localeCompare(b.attachment_type) || a.filename.localeCompare(b.filename));
            responseData["attachements_formated"] = responseData["attachements_formated"]?.map((attachment, index) => {
                return {
                    ...attachment,
                    id: index + 1,
                    appId: tabInfo.app_id,
                    dsarRequestId: tabInfo.dsarRequestId
                }
            })
            responseData["ticketStatus"] = responseData["status"];
            responseData["status"] = responseData["response_is_open"] ? "Open" : "Closed";
            setResponseData(responseData);

        }).catch(err => {
            if (err?.response?.data?.message) {
                setDisplayErrMsg(err.response.data.message)
            }
        })
    }
    const fetchGuidDetails = () => {
        return axios.get(`ticket/${tabInfo.dsarRequestId}/guids`, {
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            let formatedGuidDetails = res.data;
            formatedGuidDetails = formatedGuidDetails.map((formatedGuidDetail) => {
                return {
                    created_date_local: getFormatedDate(formatedGuidDetail.created_date),
                    created_date_utc: getFormatedDate(formatedGuidDetail.created_date, true),
                    updated_date_utc: getFormatedDate(formatedGuidDetail.updated_date, true),
                    updated_date_local: getFormatedDate(formatedGuidDetail.updated_date),
                    response_json: formatedGuidDetail.json_response,
                    ...formatedGuidDetail
                }
            })
            setGuidDetails(formatedGuidDetails);
        }).catch(err => {
            if (err?.response?.status === 404) {
                setGuidDetails("no guids")
            }
        })
    }
    const fetchData = () => {
        setLoading(true);
        const allRequests = [];
        allRequests.push(fetchTicketResponseDetails());
        if (tabInfo.app_id === APPIDS.APP_ID_ZUORA) {
            allRequests.push(fetchGuidDetails());
        }
        Promise.all(allRequests).then(() => {
            setLoading(false);
        }).catch(() => {
            setLoading(false);
        })

    };

    return (<Box className={`${classes.root} requestDetailsWrapper`} width={"100%"} height={"100%"} >
        <ListOfItems
            enableEdit={editMode}
            items={[{
                display: "Ticket Status",
                mapping: "status"
            }, {
                display: "Response Action",
                mapping: "response_action",
                optionset: RESPONSE_ACTION_MAPPING,
                editable: true,
                renderEditField: () => {
                    return <>
                        <Select
                            variant="outlined"
                            size={"small"}
                            className='customselectInput'
                            id={"response_action"}
                            multiple={false}
                            fullWidth
                            onChange={(event) => {
                                responseData["response_action_edit"] = event.target.value;
                                setResponseData({
                                    ...responseData
                                });
                            }}
                            value={(responseData["response_action_edit"] || responseData["response_action"])}
                        >
                            {Object.keys(RESPONSE_ACTION_MAPPING).map((_option) => (
                                <MenuItem key={_option} value={_option}>
                                    {RESPONSE_ACTION_MAPPING[_option]}
                                </MenuItem>
                            ))}
                        </Select>
                    </>
                }
            }, {
                display: "Expected Attachments",
                mapping: "expected_attachments_count",
                editable: true,
                renderEditField: () => {
                    return <>
                        <TextField
                            size={"small"}
                            type={"number"}
                            onChange={(event) => {
                                let count = parseInt(event.target.value);
                                if (count > 99) {
                                    count = 99;
                                } else if (count < 0) {
                                    count = 0;
                                } else if (isNaN(count)) {
                                    count = 0;
                                }
                                responseData["expected_attachments_count_edit"] = count;
                                setResponseData({
                                    ...responseData
                                });
                            }}
                            value={(responseData["expected_attachments_count_edit"] || responseData["expected_attachments_count"])}
                            fullWidth
                            id={"expected_attachments_count"}
                            label=""
                            variant="outlined"

                        />
                    </>
                }
            }, {
                display: "Received Attachments",
                mapping: "existing_attachments_count"
            }, {
                display: "Pending Attachments",
                mapping: "pending_attachments_count"
            }, {
                display: "File Upload",
                type: "custom",
                renderCustom: () => {
                    return (
                        <a
                            href="void(0)"
                            className='customLink'
                            data-testid={"fileUploadLink"}
                            onClick={(event) => {
                                event.preventDefault();
                                setDisplayAttachments(!displayAttachments);
                            }}
                        >
                            View / Upload Document(s)
                        </a>
                    )
                }
            }, {
                display: "Edit Response",
                type: "icon",
                icon: () => {
                    return (
                        <IconButton

                            onClick={() => {
                                setDisplayAttachments(true);
                                setEditMode(true);
                            }}
                            disabled={responseData.ticketStatus === "Uploaded" || editMode}
                        >
                            <Edit />
                        </IconButton>)
                }
            }]}
            data={responseData}
        />
        {displayAttachments && (
            <>
                <Attachments
                    attachments={responseData.attachements_formated || []}
                    setLoading={setLoading}
                    setDisplayAttachments={setDisplayAttachments}
                    editEnabled={editMode}
                    responseData={responseData}
                    tabInfo={tabInfo}
                    setDisplayErrMsg={setDisplayErrMsg}
                    handleAttachmentSuccess={() => {
                        setEditMode(false);
                        fetchData();
                        reloadInformation()
                    }}
                    handleAttachmentsReset={() => {
                        if ("response_action_edit" in responseData) {
                            delete responseData["response_action_edit"];
                        }
                        if ("expected_attachments_count_edit" in responseData) {
                            delete responseData["expected_attachments_count_edit"];
                        }
                        setResponseData({
                            ...responseData
                        })
                    }}
                />
            </>
        )}
        {tabInfo.app_id === APPIDS.APP_ID_ZUORA ? (
            <GuidDetails
                guidDetails={guidDetails}
                responseData={responseData}
                tabInfo={tabInfo}
            />
        ) : (
            <ResponseJsonContent
                responseData={responseData}
                tabInfo={tabInfo}
            />
        )}

    </Box>)
}
export default React.memo(ViewResponse);

