import React from 'react';
import { Dialog as MuiDialog, DialogContent, makeStyles } from '@material-ui/core';
import ReportPopup, { DialogTitle } from './reports/ReportCommonPopup';
import { LoadingIndicator } from './reports/reconcilationreport/MuiStyled';

const useStyles = makeStyles(() => ({
    dialogheader: {
        minHeight: 28,
        textAlign: 'center',
        fontSize: '0.75rem',
        '& span': {
            fontWeight: 'bold'
        },
        '& .modelheader': {
            paddingBottom: '5px',
            fontWeight: 'bold'
        }
    },
}));
const Dialog = ({
    isOpen,
    updateDialogStatus,
    title,
    dialogContent,
    popupGridData,
    popupColumns,
    enablePagination = true,
    isLoading = false,
    customRowHeight,
    dialogTitleClassName=""
}) => {
    const classes = useStyles();
    return (
        <MuiDialog open={isOpen} maxWidth={"md"} fullWidth={true} PaperProps={{
            style: {
                width: '860px'
            }
        }}>
            <DialogTitle classes={{
                root: dialogTitleClassName
            }}
            onClose={() => { updateDialogStatus(false) }}>
                <div className={classes.dialogheader}>
                    {title}
                </div>
            </DialogTitle>
            <DialogContent>
                {isLoading && <LoadingIndicator />}
                {dialogContent || (<ReportPopup
                    customRowHeight={customRowHeight}
                    enablePagination={enablePagination}
                    modelData={popupGridData}
                    columns={popupColumns} />)}

            </DialogContent>
        </MuiDialog>
    )
}
export default Dialog;