import React, { useEffect, useContext, useState,useRef, useMemo } from 'react';
import { AppContext } from '../../../RoutesWithAuth';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import Papa from 'papaparse';
import { withNavbar } from './Navbar';
import FormGroup from './FormGroup';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';
import CloseIcon from '@material-ui/icons/Close';
import DataGrid from './grid';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { getFormatedDate, parseBool, v2UIRoutePath } from '../../common/commons';
import axios from '../../common/AxiosConfig';
import { Grid, Tooltip } from '@material-ui/core';
import RequestsCount from './RequestsCount';
import { LoadingIndicator } from './reports/reconcilationreport/MuiStyled';
import Pagination from './grid/Pagination';
import AdvanceSearchIcon from './home/AdvanceSearchIcon';
import { getAdvancedSearchFields, getHomePageColumns } from './home/constant';
import { useStyles } from './home/styles';

const HomePATH = '/newui';

const useQuery = () => {
    return new URLSearchParams(useLocation().search)
}

function Requests(props) {
    const { routerProps } = props
    const classes = useStyles();
    const query = useQuery();
    const { history, match, location } = routerProps;
    const routeState = location?.state;

    const context = useContext(AppContext)
    const formGroupRef = useRef();

    const authToken = context.authToken.get
    const apps = context.apps.get

    const { page } = match?.params;
    const paramsPageNum = parseInt(page ? page : 1);

    const [currentPage, setCurrentPage] = useState(paramsPageNum);
    const [requests, setRequests] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [totalResults, setTotalResults] = useState(null);
    const [pageCount, setPageCount] = useState(20);
    const [displayErrMsg, setDisplayErrMsg] = useState("");
    const [successMsg, setSuccessMsg] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [showAdvSearch, setShowAdvSearch] = useState(query.get('showAdvSearch') ? parseBool(query.get('showAdvSearch')) : false);
    const [toggleSwitch, setToggleSwitch] = useState(false);
    const [advancedFilter, setAdvancedFilter] = useState({});
    const [activeFilters, setActiveFilters] = useState({});
    const [activeRequestId , setActiveRequestId] = useState();
    const [activeSortModel, setActiveSortModel] = useState([]);

    useEffect(()=>{
        setCurrentPage(paramsPageNum);
    },[paramsPageNum]);

    const advancedSearchFields = getAdvancedSearchFields(apps);
    const COLUMNS = useMemo(()=>{
        return getHomePageColumns(apps, showAdvSearch ? advancedFilter : {});
    },[advancedFilter, apps])
    // Reloading Grid to page 1 when there is change in Filters
    useEffect(() => {
        const filterKeys = Object.keys(activeFilters)
        if (filterKeys.length > 0 || advancedFilter) {
            history.replace(`${HomePATH}/page/1`)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeFilters,advancedFilter])

    useEffect(() => {
        if (routeState) {
            setShowAdvSearch(routeState.showAdvSearch || false);
            setAdvancedFilter(routeState.advancedFilter || {});
            setPageCount(routeState.pageCount || 20);
            setActiveFilters(routeState.activeFiltersFormatted || {});
            setCurrentPage(routeState.currentPage || 1 );
            setActiveRequestId(routeState.requestId);
        }
    }, [routeState?.fromBreadCrumb])
    useEffect(() => {
        let timeOutAPI;
        if (authToken !== null) {
            timeOutAPI = setTimeout(() => {
                searchRequests(null)
            }, 100);
        }
        return ()=>{
            clearTimeout(timeOutAPI)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, pageCount, advancedFilter, activeFilters, activeSortModel]);

    const getVins = (consumerProfileData) => {
        const vins = []
        for (const profileData of consumerProfileData) {
            if (profileData.vehicleInfo) {
                for (const vehicleInfo of profileData.vehicleInfo) {
                    if (vehicleInfo.vin) {
                        vins.push(vehicleInfo.vin)
                    }
                }
            }
        }
        return vins
    }

    const getAzureGUID = (consumerProfileData) => {
        const guids = []
        for (const profileData of consumerProfileData) {
            if (profileData.linkedIds) {
                for (const vehicleInfo of profileData.linkedIds) {
                    if (vehicleInfo.azureUserIds) {
                        guids.push(vehicleInfo.azureUserIds)
                    }
                }
            }
        }
        return guids
    }

    const getCustomerIds = (consumerProfileData) => {
        const customerID = [];
        for (const profileData of consumerProfileData) {
            if (profileData.consumerProfileMetadata) {
                customerID.push(profileData.consumerProfileMetadata.customerId || '--');
            }
        }
        return customerID
    }
    const createBlobAndDownload = (csv) => {
        const downloadLink = document.createElement("a");
        const blob = new Blob(["\ufeff", csv])
        const url = URL.createObjectURL(blob)
        downloadLink.href = url
        const timestamp = moment().format("YYYYMMDDHHmmss")
        downloadLink.download = `Search_Results_${timestamp}.csv`
        document.body.appendChild(downloadLink)
        downloadLink.click()
        document.body.removeChild(downloadLink)
    }
    const getadvancedFilterParams = () => {
        const params = {};
        advancedSearchFields.forEach(({ name, isMulti, defaultOption, enableTrim = false }) => {
            if (isMulti && advancedFilter[name]) {
                if (advancedFilter[name].join(",") !== [defaultOption].join(",")) {
                    params[name] = advancedFilter[name].join(", ");
                    if (name === "app") {
                        params[name] = advancedFilter[name].map((appName) => {
                            return apps?.find(({ display }) => display === appName)?.id
                        }).join(", ");
                    }
                }
            } else if (name === "cali_customer") {
                if (advancedFilter[name] !== defaultOption) {
                    let cali_customer_value = '';
                    if (advancedFilter[name] === "Yes") {
                        cali_customer_value = true;
                    } else if (advancedFilter[name] === "No") {
                        cali_customer_value = false;
                    }
                    params[name] = cali_customer_value
                }
            } else {
                params[name] = advancedFilter[name];
            }
            // Trim the Trailing spaces only for the Field which has the enableTrim Flag
            if(enableTrim){
                params[name] = params[name]?.trim();
            }
        })
        return params;
    }
    const getSortPayload = ()=>{
        let payload = {
            sort_order : "DESC",
            sort_column : "CREATED_DATE"
        }
        if(activeSortModel && activeSortModel?.length > 0){
            payload = {
                sort_order : activeSortModel[0].sort?.toUpperCase(),
                sort_column : activeSortModel[0].field?.toUpperCase()
            }
        }
        return payload;
    }
    /**
    * Method returns the params data which are built based on the active Filters on Grid.
    * @returns Object which contains all the active filters 
    */
    const getFilterPayload = () => {
        let payload = {};

        const filterKeys = Object.keys(activeFilters)
        filterKeys.forEach((activeFilter) => {
            if (activeFilters[activeFilter] !== false) {
                let activeFilterName = activeFilter;
                // Formating the Payload based on the active filter applied in the UI
                let paramValue = activeFilters[activeFilter].value
                if (activeFilter === "downstreamApps") {
                    activeFilterName = "app";
                    paramValue = apps?.find(({ display }) => display === paramValue)?.id
                } else if (activeFilterName === "requestId") {
                    activeFilterName = "id";
                }
                payload[activeFilterName] = paramValue
            }
        })
        return {
            ...payload
        }
    }
    const searchRequests = (e, download_csv = false) => {
        setIsLoading(true);
        axios.get(`ticket/search`, {
            headers: {
                Authorization: authToken
            },
            params: {
                ...getadvancedFilterParams(),
                ...getFilterPayload(),
                ...getSortPayload(),
                download_csv: download_csv,
                page: currentPage - 1,
                request_count: pageCount
            }
        }).then(res => {
            setIsLoading(false);
            if (res.status === 204) {
                console.log('No search records found')
                if(!download_csv)
                    setRequests([])
                
                setDisplayErrMsg("No search records found")
            } else if (download_csv) {
                if (res.data.email_sent === "1") {
                    setSuccessMsg(res.data.message)

                } else if (res.data.includes("/ADVANCED_DOWNLOAD/")) {
                    const link = document.createElement('a');
                    link.href = res.data;
                    document.body.appendChild(link);
                    link.click();
                    link.remove()
                }
                else {
                    // Don't update the state if download_csv is set to true
                    // Download the csv file
                    const csv = Papa.unparse(res.data)
                    createBlobAndDownload(csv)
                }
            } else {
                if (res.data[0]) {
                    const totalPagesRes = Math.ceil(res.data[0].total_results / pageCount)
                    setTotalPages(totalPagesRes)
                    setTotalResults(res.data[0].total_results)
                    setToggleSwitch(!toggleSwitch)
                    setRequests(res.data)
                } else {
                    setRequests([])
                    setTotalPages(0)
                    setTotalResults(0)
                }

            }

        }).catch(err => {
            setIsLoading(false);
        })
    }

    const handleClose = () => {
        setDisplayErrMsg("");
        setSuccessMsg("");
    }
    const sortDownStreamApps = (sortableList) => {
        const sortedDownStreams = sortableList.split(",").sort(function (a, b) {
            const x = a.trim().toLowerCase();
            const y = b.trim().toLowerCase();
            if (x < y) { return -1; }
            if (x > y) { return 1; }
            return 0;
        });
        return sortedDownStreams.join(',');
    }
    return (
        <div className={`pl-2 pr-2 ${classes.homePageWrapper}`}>
            {isLoading && (<LoadingIndicator />)}
            <Typography variant="h6" className={classes.headerTitle}>
                Privacy Requests
            </Typography>
            <Snackbar open={!!displayErrMsg} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} elevation={6} variant="filled" severity="error">
                    {displayErrMsg}
                </Alert>
            </Snackbar>
            <Snackbar open={!!successMsg} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} elevation={6} variant="filled" severity="success">
                    {successMsg}
                </Alert>
            </Snackbar>

            <Toolbar className={classes.toolbar}>
                <Tooltip title={"Download Search Results"}>
                    <IconButton data-testid={"download-icon"} onClick={() => { searchRequests(null, true) }}>
                        <GetAppIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title={"Advanced Search"}>
                    <IconButton  className={"iconadvSearch"}>
                        <AdvanceSearchIcon 
                            handleClick={() => { setShowAdvSearch(true) }}
                        />
                    </IconButton>
                </Tooltip>
                <Typography variant="h6" className={classes.title}>
                    {/* Customer Requests */}
                </Typography>
                <div className="row">
                    <label htmlFor="inputEmail3" className="col-form-label">Records Page :</label>
                    <div className="mr-4">
                        <select onChange={(event) => { setPageCount(event.target.value); history.replace(`${HomePATH}/page/1`) }} className="selectStyle form-control ml-2" value={pageCount} id="inputEmail3">
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            {/* <option value="500">500</option> */}
                        </select>
                    </div>
                </div>

            </Toolbar>
            {showAdvSearch && (
                <Grid container className={classes.advanceSeachWrapper}>
                    <Grid item xs={12} >
                        <Toolbar xs={12}>
                            <Typography variant="h6" className={classes.title}>
                                Advanced Search
                            </Typography>
                            
                            <IconButton onClick={() => { setShowAdvSearch(false) }} >
                                <CloseIcon />
                            </IconButton>
                            
                        </Toolbar>
                    </Grid>

                    <FormGroup
                        defaultFormData={advancedFilter}
                        fields={advancedSearchFields}
                        ref={formGroupRef}
                        refreshGridWithSearch={(advdata) => {
                            setAdvancedFilter(advdata);
                            setCurrentPage(1);
                            setActiveFilters({})
                        }}
                        handleClearForm={()=>{
                            setCurrentPage(1);
                            setAdvancedFilter({});
                            setTimeout(() => {
                                formGroupRef.current.handleFormDataState({});                                
                            }, 50);

                        }}
                    />
                </Grid>
            )}

            <DataGrid
                enableCustomFiltering={true}
                // clearFilterSortState={routeState?.fromBreadCrumb}
                columns={COLUMNS}
                defaultFilters={activeFilters}
                remoteFiltering={true}
                applyRemoteSorting={(sortModel)=>{
                    if(JSON.stringify(sortModel) !==  JSON.stringify(activeSortModel)){
                        setCurrentPage(1);
                    }
                    setActiveSortModel(sortModel)
                }}
                applyRemoteFiltering={(filters) => {
                    if(JSON.stringify(filters) !==  JSON.stringify(activeFilters)){
                        setCurrentPage(1);
                    }
                    setActiveFilters(filters)
                }}
                onCellClick={(cellparams) => {
                    const filtersData = {};
                    Object.keys(activeFilters).forEach((activeFilter)=>{
                        delete activeFilters[activeFilter].columnInfo;
                        filtersData[activeFilter] = activeFilters[activeFilter];
                    })
                    history.push(v2UIRoutePath + "requestdetails/" + cellparams.row.requestId,{
                        currentPage : currentPage,
                        showAdvSearch : showAdvSearch,
                        advancedFilter : advancedFilter,
                        pageCount : pageCount,
                        activeFiltersFormatted : filtersData,
                        requestId : cellparams.row.requestId
                    });
                }}
                getCellClassName={(params) => {
                    // Displaying the Custom column Class for the Element Type 
                    if (params?.field === "requestId" && params.value === activeRequestId) {
                        return 'active-navigation-cell';
                    }
                    return "";
                }}
                rows={requests?.map((row, i) => {
                    row['App Name List'] = row['App Name List'] ? sortDownStreamApps(row['App Name List']) : null;
                    return {
                        id: ((currentPage - 1) * pageCount) + (i + 1),
                        requestId: row.dsar_id || '--',
                        requestor: row?.dsar?.dsarForm?.subjectSelfClassification || '--',
                        ticket_type: row.ticket_type || '--',
                        status: row.status || '--',
                        createdDateandTimeLocal: getFormatedDate(row.created_date, false) || "--",
                        createdDateandTime: row.created_date ? moment(row.created_date).format("MM-DD-YYYY") : '--',
                        createdDateandTimeUTC: getFormatedDate(row.created_date, true) || "--",
                        downstreamApps: row['App Name List'] ? row['App Name List'].split(',').slice(0, 4).join(',') : '--',
                        noOfVins: (row.dsar?.consumerProfileData?.length > 0) ? getVins(row.dsar.consumerProfileData).length : '--',
                        noOfGUIDS: (row.dsar?.consumerProfileData?.length > 0) ? getAzureGUID(row.dsar.consumerProfileData).length : '--',
                        noOfCustIds: (row.dsar?.consumerProfileData?.length > 0) ? getCustomerIds(row.dsar.consumerProfileData).length : '--',
                        ...row
                    }
                })}
            />
            <Pagination
                currentPage={currentPage}
                pageCount={pageCount}
                totalResults={totalResults}
                pageChange={(page) => {
                    setCurrentPage(page);
                    history.push(`${HomePATH}/page/${page}`)
                }}
            />
            <div className={classes.requestsCount}>
                <div className={"header"}>
                    Requests Count
                </div>
                <div>
                    <RequestsCount />
                </div>
            </div>
        </div>
    );
}

export default withNavbar(React.memo(Requests), 0);
