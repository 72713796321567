import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from '../../../RoutesWithAuth';
import { CSSTransition } from 'react-transition-group';
import PageHeader from '../../common/PageHeader';
import AdminsOnly from '../../common/AdminsOnly';
import { withNavbar } from '../../common/Navbar';
import axios from '../../common/AxiosConfig';
import SettingCard from './SettingCard';
import { ticketTypeRTD, RequestTypeMapping } from '../../common/commons';

const visibleRequestor = ['Customer', 'B2B', 'Employee'];
const VisibleSettings = [{
    id: ticketTypeRTD,
    name: RequestTypeMapping[ticketTypeRTD],
    requestor: "Customer",
    fields: ['currentOwner', 'currentSubscriber', 'litigationVins', 'activeRental']
}, {
    id: ticketTypeRTD,
    name: RequestTypeMapping[ticketTypeRTD],
    requestor: "B2B",
    fields: ['activeRelationship']
}, {
    id: ticketTypeRTD,
    name: RequestTypeMapping[ticketTypeRTD],
    requestor: "Employee",
    fields: ['activeRelationship']
}];

function AutocloseSettings() {
    const context = useContext(AppContext)
    const authToken = context.authToken.get
    const isAdmin = context.isAdmin.get

    const [autocloseSettings, setAutocloseSettings] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (authToken !== null && isAdmin === true) {
            fetchData()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authToken, isAdmin])

    const updateCardData = (data, app) => {
        setAutocloseSettings({
            ...autocloseSettings,
            [app.requestor]: {
                ...autocloseSettings[app.requestor],
                [app.id]: data
            }

        });
    }
    const fetchData = () => {
        const autoCloseData = {};
        setIsLoading(true);
        Promise.all(visibleRequestor.map((requestor) => {
            return axios.get(`ticket/autoclose_settings`, {
                params: {
                    requestor
                },
                headers: {
                    Authorization: authToken
                }
            }).then(res => {
                autoCloseData[requestor] = res.data;
                // setAutocloseSettings(autoCloseData)
                return res.data;
            })
        })).then(res => {
            setIsLoading(false);
            console.log(autoCloseData);
            setAutocloseSettings(autoCloseData)
        })
    }

    if (isAdmin === null) {
        return null
    } else if (isAdmin === false) {
        return <AdminsOnly header="Autoclose Settings" />
    }

    return (
        <div className="container mb-5">

            <PageHeader header="Autoclose Settings" newUIHref={"/newuiadmin/autoclosesetting"} />

            {isLoading && (<div class="d-flex justify-content-center">
                <img alt="" className="loading-spin" src={"/rotate_right_black_24dp.svg"} width="120"></img>
            </div>)}

            <CSSTransition in={autocloseSettings} appear={true} timeout={125} classNames="swipeR">
                <>
                    <div>
                        {VisibleSettings.map(app => (
                            autocloseSettings[app.requestor]?.[app.id] && (<SettingCard
                                cardData={autocloseSettings[app.requestor]?.[app.id]} key={app.id + app.requestor} app={app} authToken={authToken} isAdmin={isAdmin}
                                updateCardData={updateCardData}
                            />)
                        ))}
                    </div>
                    <div>
                        Note :
                        <span className="font-italic ml-1">
                            {'If the check is enabled, then all requests which passes the condition are auto closed without sending them to any downstream system.'}
                        </span>
                    </div>
                </>
            </CSSTransition>

        </div>
    );
}

export default withNavbar(AutocloseSettings, 4);
